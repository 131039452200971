<div class="background">
    <div class="text-center picture">
        <div>
            <img src="../../../assets/images/no-page-found/err-404.png" />
        </div>
    </div>
    <div class="row content-div">
        <div class="col-md-6 text-left">
            <div class="content text-center white-background">
                <h3 class="tytle-first">אופס,</h3>
                <h3 class="tytle-second">אבל הפעם זה לא אנחנו</h3>
                <h4>הדף שחיפשת לא נמצא</h4>
                <button [routerLink]="['/main']" routerLinkActive="active" id="login-btn" class="btn-nativ">
                    בחזרה לדף הבית</button>
            </div>
        </div>
        <div class="col-md-6 text-left">
            <div class="content text-center">
                <h3 class="tytle-first">Sorry!</h3>
                <h3 class="tytle-second">But this time it's not our fault</h3>
                <h4>Page not found</h4>
                <button [routerLink]="['/main']" routerLinkActive="active" id="login-btn" class="btn-nativ">
                    Back to Home Page</button>
                <!-- </div> -->
            </div>
        </div>
    </div>