import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { Router } from "@angular/router";
import { ApiService } from '../../services/api/api.service';
import { NavbarService } from '../../services/navbar/navbar.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
  checklist1 = []
  checklist2 = []
  checklist3 = []
  enableSubmit = true;
  feedbackForm: any;
  question1Val = "0"
  question2Val = "0"
  question3Val = "0"
  phoneCallId = ""
  feedbackHeader = ""
  feedbackContent = ""
  q1Text = ""
  q2Text = ""
  q3Text = ""
  feedbackType = 1;
  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    public nav: NavbarService,
    public translateService: TranslateService) {
    this.feedbackForm = this.formBuilder.group({
      question1: '',
      question2: '',
      question3: '',
      comments: ''
    });
    this.checklist1 = [
      { id: "5", value: "5", isSelected: false },
      { id: "4", value: "4", isSelected: false },
      { id: "3", value: "3", isSelected: false },
      { id: "2", value: "2", isSelected: false },
      { id: "1", value: "1", isSelected: false },
    ];
    this.checklist2 = [
      { id: "5", value: "5", isSelected: false },
      { id: "4", value: "4", isSelected: false },
      { id: "3", value: "3", isSelected: false },
      { id: "2", value: "2", isSelected: false },
      { id: "1", value: "1", isSelected: false },
    ];
    this.checklist3 = [
      { id: "5", value: "5", isSelected: false },
      { id: "4", value: "4", isSelected: false },
      { id: "3", value: "3", isSelected: false },
      { id: "2", value: "2", isSelected: false },
      { id: "1", value: "1", isSelected: false },
    ];
  }

  ngOnInit(): void {
    this.nav.hide();
    this.feedbackForm = new FormGroup({
      question1: new FormControl('', Validators.required),
      question2: new FormControl('', Validators.required),
      question3: new FormControl('', Validators.required),
      comments: new FormControl()
    });
  }

  ngAfterViewInit() {
    this.route
      .queryParams
      .subscribe(params => {
        if (params["type"] && params["type"] == "2") {
          this.feedbackType = 2;
          this.translateService.get('feedback-api.header-content').subscribe((translated: string) => {
            this.feedbackHeader = translated;
          });
          this.translateService.get('feedback-api.content').subscribe((translated: string) => {
            this.feedbackContent = translated;
          });
          this.translateService.get('feedback-api.question-1').subscribe((translated: string) => {
            this.q1Text = translated;
          });
          this.translateService.get('feedback-api.question-2').subscribe((translated: string) => {
            this.q2Text = translated;
          });
          this.translateService.get('feedback-api.question-3').subscribe((translated: string) => {
            this.q3Text = translated;
          });
        }
        else {
          this.translateService.get('feedback.header-content').subscribe((translated: string) => {
            this.feedbackHeader = translated;
          });
          this.translateService.get('feedback.content').subscribe((translated: string) => {
            this.feedbackContent = translated;
          });
          this.translateService.get('feedback.question-1-2').subscribe((translated: string) => {
            this.q1Text = translated;
          });
          this.translateService.get('feedback.question-2-2').subscribe((translated: string) => {
            this.q2Text = translated;
          });
          this.translateService.get('feedback.question-3-2').subscribe((translated: string) => {
            this.q3Text = translated;
          });
        }
        if (params["pcid"] && params["pcid"] != "") {
          this.phoneCallId = params["pcid"]
          this.apiService.checkPhoneCallid(this.phoneCallId).subscribe(
            data => {
              console.log('success checkPhoneCallid', data)
              const status = data["d"]
              if (status == 0) {
                this.router.navigate(['/main'])
                Swal.fire("", "שגיאה!", 'error')
              }
              else if (status == 1) {
                this.router.navigate(['/main'])
                Swal.fire("תגובתך נקלטה במערכת", " תודה על השתתפותך בתהליך הבקרה ושיפור השירות של נציגנו.\n\n\nבברכה\n צוות נתיב", 'success')
              }
              else if (status != 2) {
                this.router.navigate(['/main'])
                Swal.fire("", "שגיאה!", 'error')
              }

            },
            error => {
              console.log('error checkPhoneCallid', error)
              console.log('error contact', error)
              Swal.fire("", "אירעה שגיאה בשליחת הפניה", 'error')
            }
          )
        }
        else {
          this.router.navigate(['/main'])
          Swal.fire("", "שגיאה!", 'error')
        }
      })
  }
  change1(item) {
    this.checklist1.forEach(val => {
      if (val.id == item.id) {
        val.isSelected = !val.isSelected
      }
      else {
        val.isSelected = false;
      }
    });
    this.question1Val = item.value
  }
  change2(item) {
    this.checklist2.forEach(val => {
      if (val.id == item.id) {
        val.isSelected = !val.isSelected;
      }
      else {
        val.isSelected = false;
      }
    });
    this.question2Val = item.value
  }
  change3(item) {
    this.checklist3.forEach(val => {
      if (val.id == item.id) {
        val.isSelected = !val.isSelected
      }
      else {
        val.isSelected = false;
      }
    });
    this.question3Val = item.value
  }

  onSubmit(formData: any) {
    this.enableSubmit = false;
    console.log(this.question1Val, this.question2Val, this.question3Val)
    if (this.feedbackForm.invalid) {
      this.enableSubmit = true;
      return;
    }
    this.apiService.saveFeedback(this.question1Val, this.question2Val, this.question3Val, formData.comments ? formData.comments : "", this.phoneCallId, this.feedbackType).subscribe(
      data => {
        console.log('success saveFeedback', data)
        this.enableSubmit = true;
        this.router.navigate(['/main'])
        Swal.fire("", "תודה רבה על שיתוף הפעולה!", 'success')
      },
      error => {
        console.log('error saveFeedback', error)
        this.enableSubmit = true;
        Swal.fire("", "אירעה שגיאה בשליחת הטופס", 'error')
      }
    )

  }

}
