<!-- <div class="desktop-2"> -->
<div class="row background-page">
    <div class="col-xs-12 col-md-9  all-page">
        <!-- <img class="desktop-2__rectangle-1" src="./../../assets/images/add-site/arachim/bg-img.png" /> -->
        <!-- <svg class="desktop-2__" width="752" height="71" viewBox="0 0 752 71" fill="none" -->
        <div class="float-right row add-site">
            <svg class="tytle" width="752" height="71" viewBox="0 0 752 71" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M24.469 13.02C41.749 13.02 46.069 23.55 46.069 37.23V60H30.319V37.86C30.319 31.38 27.079 27.87 21.319 27.87H0.259033V13.02H24.469Z"
                    fill="#FDB913" />
                <path
                    d="M95.543 37.86C95.543 31.38 92.303 27.87 86.543 27.87H75.473V60H50.093V45.15H59.723V27.87H51.803V13.02H87.893C105.173 13.02 111.293 23.55 111.293 37.23V60H95.543V37.86Z"
                    fill="#FDB913" />
                <path
                    d="M113.778 13.11H133.668L147.348 30.57L147.618 30.39C151.038 28.5 153.378 25.98 153.378 19.32V13.11H168.768V18.78C168.768 30.48 164.088 37.5 156.168 41.91L170.388 60H150.408L136.008 41.64C133.218 43.44 131.418 46.14 131.418 51.99V60H116.028V52.53C116.028 41.55 120.168 34.71 127.188 30.3L113.778 13.11Z"
                    fill="#FDB913" />
                <path
                    d="M239.859 37.86C239.859 31.38 236.619 27.87 230.859 27.87H219.789V60H194.409V45.15H204.039V27.87H196.119V13.02H232.209C249.489 13.02 255.609 23.55 255.609 37.23V60H239.859V37.86Z"
                    fill="#FDB913" />
                <path
                    d="M286.264 45.33C289.594 45.33 291.394 43.62 291.394 40.47V27.69H275.374V31.2H281.044V42.09H260.164V13.02H307.144V42.72C307.144 52.98 300.034 60 288.064 60H260.074V45.33H286.264Z"
                    fill="#FDB913" />
                <path
                    d="M337.316 61.62C320.936 61.62 311.216 49.83 311.216 36.51V36.42C311.216 23.28 320.936 11.49 337.316 11.49H337.676C354.056 11.49 363.776 23.28 363.776 36.42V36.51C363.776 49.83 354.056 61.62 337.676 61.62H337.316ZM326.966 36.51C326.966 42.27 331.286 46.77 337.316 46.77H337.676C343.706 46.77 348.026 42.27 348.026 36.51V36.42C348.026 30.84 343.706 26.34 337.676 26.34H337.316C331.286 26.34 326.966 30.84 326.966 36.42V36.51Z"
                    fill="#FDB913" />
                <path d="M367.828 60V13.02H383.488V60H367.828Z" fill="#FDB913" />
                <path
                    d="M387.955 35.52H403.165V60H387.955V35.52ZM387.955 27.87V13.02H416.485C433.765 13.02 439.885 23.55 439.885 37.23V60H424.135V37.86C424.135 31.38 420.895 27.87 415.135 27.87H387.955Z"
                    fill="#FDB913" />
                <path
                    d="M491.112 37.5C491.112 52.44 480.042 60 467.892 60H460.872V45.15H465.372C471.852 45.15 475.452 42.27 475.452 36.87V27.87H443.502V0.599976H459.162V13.02H491.112V37.5Z"
                    fill="#FDB913" />
                <path
                    d="M517.242 35.52H532.452V60H517.242V35.52ZM517.242 27.87V13.02H545.772C563.052 13.02 569.172 23.55 569.172 37.23V60H553.422V37.86C553.422 31.38 550.182 27.87 544.422 27.87H517.242Z"
                    fill="#FDB913" />
                <path
                    d="M624.158 30.75V13.02H639.908V31.38C639.908 49.29 627.398 61.89 607.058 61.89H606.518C586.178 61.89 573.668 49.29 573.668 31.38V13.02H589.418V25.35H594.278C597.968 25.35 598.958 22.83 598.958 19.77V13.02H614.618V21.3C614.618 32.64 606.518 38.4 595.898 38.4H591.038C593.738 44.07 599.678 47.04 606.608 47.04H606.968C616.598 47.04 624.158 41.37 624.158 30.75Z"
                    fill="#FDB913" />
                <path
                    d="M644.87 70.8V34.44H660.53V70.8H644.87ZM644.42 27.87V13.02H694.37V37.5C694.37 52.44 683.3 60 671.15 60H667.73V45.15H668.63C675.11 45.15 678.71 42.27 678.71 36.87V27.87H644.42Z"
                    fill="#FDB913" />
                <path d="M698.912 60V45.15H728.342V27.87H698.912V13.02H744.092V45.15H751.652V60H698.912Z"
                    fill="#FDB913" />
            </svg>
        </div>
        <form [formGroup]="addSiteForm" (ngSubmit)="onSubmit(addSiteForm.value)" autocomplete="off">
            <div class="row w-100">
                <div class="col-md-4 button  input-div" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                    <input type="text" formControlName="name" class="form-control" id="name" placeholder="שם המבקש"
                        [ngClass]="{ 'is-invalid': submitted && f.name.errors }" [ngModel]="name">
                </div>
                <!-- <div class="col-md-3 button  input-div" [ngClass]="{ 'is-invalid': submitted && f.project.errors }">
                    <input type="text" formControlName="project" class="form-control" id="project" placeholder="תפקיד"
                        [ngClass]="{ 'is-invalid': submitted && f.project.errors }">
                </div> -->
                <div class="col-md-4 button  input-div" [ngClass]="{ 'is-invalid': submitted && f.username.errors }">
                    <input [attr.disabled]="disabledUsername ? '' : null" type="text" formControlName="username"
                        class="form-control" id="username" placeholder="רמת סינון" [ngModel]="displayUserName"
                        [ngClass]="{ 'is-invalid': submitted && f.username.errors }">
                </div>
                <div class="col-md-4 button  input-div" [ngClass]="{ 'is-invalid': submitted && f.ip.errors }">
                    <input disabled type="text" formControlName="ip" class="form-control" id="ip" placeholder="כתובת IP"
                        [ngModel]="reqIp" [ngClass]="{ 'is-invalid': submitted && f.ip.errors }">
                </div>
            </div>
            <div class="row w-100 button input-div" [ngClass]="{ 'is-invalid': submitted && f.url.errors }">
                <input type="text" autocomplete="disabled" formControlName="url" class="form-control" id="url"
                    placeholder="כתובת האתר שביקש המשתמש" [ngModel]="url"
                    [ngClass]="{ 'is-invalid': submitted && f.url.errors }">
            </div>
            <div class="row w-100 button input-div" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                <input type="text" autocomplete="disabled" formControlName="email" class="form-control" id="email"
                    placeholder="כתובת המייל של המשתמש" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
            </div>
            <!-- desktop-2__button4 -->
            <!-- <div class="row w-100 button input-div" [ngClass]="{ 'is-invalid': submitted && f.siteDesc.errors }">
                <input type="text" formControlName="siteDesc" class="form-control" id="siteDesc"
                    placeholder="תיאור האתר" [ngClass]="{ 'is-invalid': submitted && f.siteDesc.errors }">
            </div> -->
            <!-- desktop-2__button3 -->
            <!-- <div class="row w-100 button input-div" [ngClass]="{ 'is-invalid': submitted && f.comments.errors }">
                <input type="text" formControlName="comments" class="form-control" id="comments"
                    placeholder="הסיבה לבקשה" [ngClass]="{ 'is-invalid': submitted && f.comments.errors }">
            </div> -->
            <div class="row w-100 button">
                <div class="form-control yellow">
                    פתיחת אתר לצורך פרטי מחייבת צירוף מייל אישור מהמנהל הישיר
                </div>
            </div>
            <div class="row">
                <div class="col-lg-3 col-md-4 send-button btn-click" [ngClass]="{ 'disabled': !enableSubmit }">
                    <button [disabled]="!enableSubmit" type="submit">
                        <!-- <svg class="btn-click" (click)="onSubmit(addSiteForm.value)" viewBox="0 0 152 27"
                            fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0.393799 3.34709H16.9327V21.9999H10.8705V8.0103H0.393799V3.34709ZM0.393799 21.9999V11.43H6.45598V21.9999H0.393799Z"
                                fill="#0831A1" />
                            <path
                                d="M36.6483 3.34709H42.4618V13.5751C42.4618 19.3575 39.7261 21.9999 32.9799 21.9999H22.0058L19.8919 3.34709H25.7675L27.3219 17.3367H31.8608C35.6535 17.3367 36.6483 16.0621 36.6483 12.9533V3.34709ZM34.5343 14.6632H28.8452V3.34709H34.5343V14.6632Z"
                                fill="#0831A1" />
                            <path
                                d="M45.7366 3.34709H62.7107V14.0103C62.7107 19.8238 60.1926 21.9999 55.1252 21.9999H53.6019V17.3367H54.1304C55.4672 17.3367 56.6485 17.0258 56.6485 14.1036V8.0103H45.7366V3.34709ZM45.7366 26.6632V11.43H51.7988V26.6632H45.7366Z"
                                fill="#0831A1" />
                            <path
                                d="M79.446 3.34709V17.3367H81.6533V21.9999H65.4874V17.3367H73.3838V8.0103H65.4874V3.34709H79.446Z"
                                fill="#0831A1" />
                            <path
                                d="M92.2404 3.34709H102.779C107.474 3.34709 109.463 5.46108 109.463 11.2435V21.9999H103.401V11.3367C103.401 8.84968 102.873 8.0103 101.007 8.0103H98.3026V21.9999H92.2404V3.34709Z"
                                fill="#0831A1" />
                            <path
                                d="M120.596 21.9999H114.254L118.917 8.0103H111.176V0.238281H116.959V3.34709H126.689L120.596 21.9999Z"
                                fill="#0831A1" />
                            <path
                                d="M145.231 3.34709H151.044V13.5751C151.044 19.3575 148.308 21.9999 141.562 21.9999H130.588L128.474 3.34709H134.35L135.904 17.3367H140.443C144.236 17.3367 145.231 16.0621 145.231 12.9533V3.34709ZM143.117 14.6632H137.427V3.34709H143.117V14.6632Z"
                                fill="#0831A1" />
                        </svg> -->
                        <div class="form-control blue">
                            שלח בקשה
                        </div>
                    </button>
                </div>
            </div>
            <!-- <div class="desktop-2___01-1"></div>
            <div class="desktop-2__4"></div>
            <div class="desktop-2__5"></div>
            <div class="desktop-2__6"></div>
            <div class="desktop-2__7"></div> -->
        </form>
    </div>
</div>