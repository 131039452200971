import { Component, OnInit, ViewChild, ElementRef, HostListener, ApplicationRef } from '@angular/core';
import { ApiService } from '../../services/api/api.service';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import Swal from 'sweetalert2';
import { Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { UtilsService } from '../../services/utils/utils.service';
import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-my-sites',
  templateUrl: './my-sites.component.html',
  styleUrls: ['./my-sites.component.scss']
})

export class MySitesComponent implements OnInit {
  selectedPage: number = 1;
  id = "";
  isChecked1 = false;
  isChecked2 = false;
  computerNames: string[];
  windowsUsers: string[];
  login: any;
  model = "";
  modelLogin = ""
  windowsUser = "";
  page = 1;
  pageSize = 20;
  sitesData = [];
  collectionSize: number;
  lastPage: number;
  sites = [];
  sitesLength = 0;
  loading = false;
  siteId = null;
  noData = false;
  isUserLoggedIn = false;
  protocol = window.location.protocol;
  refreshing = false;
  enableSubmit = true;
  displaySimActions = false;
  windowsMachine = "";
  invalidPass = false;
  emptyPass = false;
  submittedPass = false;
  syncSiteId: any;
  submitEmail = false;
  syncPassForm: any;
  filterValue: string[] = [];
  filterKey = "";
  manager = false;
  sorted = "";
  submitted = false;
  arrays;
  targetModal: any;
  showRadio = false;
  status: string[] = [];
  dates: string[] = [];
  sitesArray: any;
  selectedItemsStatus: any;
  selectedItemsUrl = [];
  selectedItemsDate: any;
  dropdownSettingsStatus = {};
  dropdownSettingsUrl = {};
  dropdownSettingsDate = {};
  statusArr: { id: number, status: string }[] = [];
  sitesUrl: { id: number, url: string }[] = [];
  dateArr: { id: number, date: string }[] = [];
  lang: string;
  loadingAllTable = true;
  newSitesLength = 1;
  usefulUrl = [
    "https://www.", "http://www.", ".com", "co.il/", ".html"
  ]
  nShowFilterMenu = true;
  showAdvs = false;
  advsData = [
    { src: "../../../assets/images/advs/malam/adv-1.jpg", show: true, link: "malam" },
    { src: "../../../assets/images/advs/malam/adv-1.jpg", show: true, link: "malam" },
    { src: "../../../assets/images/advs/malam/adv-2.jpg", show: false, link: "malam" }
  ]
  isCadenceUser: boolean = false;

  constructor(private apiService: ApiService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private translateService: TranslateService,
    private utilsService: UtilsService,) {
    this.lang = translateService.currentLang.toUpperCase()
    if (this.lang == 'HE')
      this.status[0] = "הכל";
    else
      this.status[0] = "All";
    this.translateService.get('my-sites.table').subscribe((translated: string) => {
      this.statusArr = [
        // { id: 1, status: this.translateService.instant('my-sites.table.everything') },
        { id: 2, status: translated['not-approved'] },
        { id: 3, status: translated['not-approved-email'] },
        { id: 4, status: translated['for-further-treatment'] },
        { id: 5, status: translated['approved'] },
        { id: 6, status: translated['on-hold'] },
        { id: 7, status: translated['existing-handle'] },
        { id: 8, status: translated['handle-removed'] },
        { id: 9, status: translated['approved-expireson'] },
      ];
    })
    this.refreshSites();
    this.authenticationService.authenticated$.subscribe(value => {
      this.isUserLoggedIn = value;
      if (this.isUserLoggedIn) {
        if (this.authenticationService.userData.lowVersion && this.authenticationService.userData.BlockActivity.includes("1")) {
          Swal.fire("", "לקוח/ה יקר/ה \n\n במחשב זה מותקנת גירסה ישנה של נתיב, \n לא ניתן לגשת לרשימת האתרים בגירסה זו. \n נא ליצור קשר עם מוקד התמיכה לצורך שידרוג מיידי במספר 5365* או 036199199 ", 'warning')
          this.router.navigate(["/main"])
        }
        this.apiService.getSiteName().subscribe(succ => {
          this.sitesArray = succ
          this.sitesUrl = this.sitesArray.urls;
          this.dateArr = this.sitesArray.dates;
          // this.loadingAllTable = false;
          if (this.sitesUrl && this.sitesUrl.length > 0) {
            this.sitesUrl.sort((a, b) => (a.url || '').localeCompare(b.url || ''));
            this.sitesUrl = this.sitesUrl.filter((value) => value.url !== null && value.url !== undefined && value.url !== '').filter((obj, index, self) => index === self.findIndex((o) => (o.url === obj.url)));
          }
          if (this.dateArr && this.dateArr.length > 0) {
            this.dateArr.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
            this.dateArr = this.dateArr.filter((value) => value.date !== null && value.date !== undefined && value.date !== '').filter((obj, index, self) => index === self.findIndex((o) => (o.date === obj.date)));
          }
        })
        this.apiService.hasSimActionsPermmissions(this.authenticationService.userData.id).subscribe(
          data => {
            console.log('hasSimActionsPermmissions success');
            if (data["d"] == true) {
              this.displaySimActions = true;
            }
            // this.windowsMachine = this.authenticationService.userData.windowsMachine;
            // this.windowsMachine = "DESKTOP-GNO7N8Q";
          },
          error => console.log('error hasSimActionsPermmissions', error)
        )
      }
    })
    // if (this.authenticationService.userData.id == environment.cadenceUserId) {
    //   this.isCadenceUser = true
    // }
    this.authenticationService.authenticated$.subscribe(value => {
      this.isUserLoggedIn = value;
      if (this.isUserLoggedIn) {
        if (this.isCadenceUser) {
          this.mySitesOnly()
        }
        else
          this.getMySitesFirst(0);
      }
      this.checkShowAdvs()
    });
    // this.changeAdvsPlace()
  }
  @ViewChild('scrollBtn') scrollBtnEl: ElementRef;
  @HostListener("window:scroll", ["$event"])
  onWindowScroll(event) {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      this.scrollBtnEl.nativeElement.style.display = "block";
    }
    else {
      this.scrollBtnEl.nativeElement.style.display = "none";
    }
    const scrollThreshold = 400;
    if (window.innerHeight + window.scrollY + scrollThreshold >= document.body.offsetHeight && !this.loading && !this.loadingAllTable && this.sitesLength > 0 && this.newSitesLength > 0) {
      this.page += 1;
      this.onPageChange(this.page);
    }
  }

  changeAdvsPlace() {
    const now = new Date();
    const minute = now.getMinutes();
    if (minute % 3 === 0) {
      this.advsData = [
        { src: "../../../assets/images/advs/malam/adv-1.jpg", show: true, link: "malam" },
        { src: "../../../assets/images/advs/malam/adv-1.jpg", show: true, link: "malam" },
        { src: "../../../assets/images/advs/malam/adv-2.jpg", show: false, link: "malam" }
      ]
    }
    else if (minute % 3 === 1) {
      this.advsData = [
        { src: "../../../assets/images/advs/malam/adv-1.jpg", show: true, link: "malam" },
        { src: "../../../assets/images/advs/malam/adv-1.jpg", show: true, link: "malam" },
        { src: "../../../assets/images/advs/malam/adv-2.jpg", show: false, link: "malam" }
      ]
    }
  }

  ngOnInit(): void {
    // if (this.authenticationService.userData.id == environment.cadenceUserId) {
    //   this.isCadenceUser = true
    // }
    this.openAdv(2)
    this.dropdownSettingsStatus = {
      singleSelection: false,
      idField: 'id',
      textField: 'status',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 0,
      allowSearchFilter: true,
      enableCheckAll: false
    };
    this.dropdownSettingsUrl = {
      singleSelection: false,
      idField: 'id',
      textField: 'url',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      noDataAvailablePlaceholderText: 'אין מידע זמין',
      itemsShowLimit: 0,
      allowSearchFilter: true,
      enableCheckAll: false
    };
    this.dropdownSettingsDate = {
      singleSelection: false,
      idField: 'id',
      textField: 'date',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      noDataAvailablePlaceholderText: 'אין מידע זמין',
      itemsShowLimit: 0,
      allowSearchFilter: true,
      enableCheckAll: false
    };

  }

  topFunction() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  refreshSites() {
    this.sites = this.sitesData
      .map((country, i) => ({ id: i + 1, ...country }))
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }

  getMySites() {
    this.loadingAllTable = true;
    // this.loading = true;
    let count;
    const obj = []
    // this.apiService.getUserSitesCount(this.status, this.filterKey, this.filterValue, this.id).subscribe(
    //   data => {
    //     console.log('getUserSitesCount ', data["d"]);
    //     count = data["d"];
    //     if (count == 0) {
    //       // this.loading = false;
    //       this.loadingAllTable = false;
    //       this.noData = true;
    //     }
    //     else {
    //       const length = Math.ceil(count / 50);
    //       let j = 0;
    //       for (let index = 0; index < length; index++) {
    //         obj.push(j)
    //         j += 50
    //       }
    //       loop(obj.shift())
    //     }
    //   },
    //   error => console.log('error getUserSitesCount', error)
    // )
    let loop = (id: number) => {
      this.apiService.getUserSites(id, this.sorted, this.status, this.filterKey, this.filterValue, this.id).subscribe(
        data => {
          console.log('getUserSites ', data["d"]);
          this.sitesData = this.sitesData.concat(data["d"]);
          this.newSitesLength = data["d"].length
          if (obj.length) {
            loop(obj.shift())
          }
          else {
            this.collectionSize = this.sitesData.length;
            this.refreshSites();
            // this.loading = false;
            this.loadingAllTable = false;
            this.lastPage = Math.ceil(this.collectionSize / this.pageSize);
            // this.apiService.updateUserMailsCount();
          }
        },
        error => console.log('error getUserSites', error)
      )
    }
  }

  getMySitesFirst(index) {
    console.log("filter status", this.filterKey, this.filterValue, this.status)
    // this.loading = true;
    this.loadingAllTable = true;
    // this.apiService.getUserSitesCount(this.status, this.filterKey, this.filterValue, this.id).subscribe(
    //   data => {
    //     console.log('getUserSitesCount ', data["d"]);
    //     this.collectionSize = data["d"];
    //     this.lastPage = Math.ceil(this.collectionSize / this.pageSize);
    this.apiService.getUserSites(index, this.sorted, this.status, this.filterKey, this.filterValue, this.id).subscribe(
      data => {
        console.log('getUserSites ', data["d"]);
        this.sitesData = this.sites = data["d"];
        this.sitesLength = this.sitesData.length;
        this.newSitesLength = data["d"].length
        // this.loading = false;
        this.loadingAllTable = false;
        // this.apiService.updateUserMailsCount();
      },
      error => console.log('error getUserSites', error)
    )
    // }
    //   error => console.log('error getUserSitesCount', error)
    // )
    this.refreshSites();
  }

  onPageChange(pageNumber) {
    const index = (pageNumber - 1) * 20
    this.loading = true;
    this.apiService.getUserSites(index, this.sorted, this.status, this.filterKey, this.filterValue, this.id).subscribe(
      data => {
        console.log('getUserSites ', data["d"]);
        this.newSitesLength = data["d"].length
        this.sitesData = this.sites = this.sitesData.concat(data["d"]);
        this.loading = false;
        this.sitesLength = this.sitesData.length
      },
      error => console.log('error getUserSites', error)
    )
  }

  removeSite(site) {
    if (site.remove) {
      if (site.removeTitle.includes("בקשה") || site.removeTitle.includes("request")) {
        Swal.fire({
          title: 'מחיקת בקשה',
          text: "ביקשת להסיר את הבקשה " + site.url + ".האם להסיר ?",
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'כן',
          cancelButtonText: 'לא',
        }).then((result) => {

          if (result.isConfirmed) {
            try {
              // this.loading = true;
              this.loadingAllTable = true;
              this.apiService.removeUserRequest(site.url).subscribe(
                data => {
                  // this.loading = false;
                  this.loadingAllTable = false;
                  const response = data["d"]
                  console.log('removeUserRequest ', response);
                  if (response) {
                    this.refreshTable();
                    // window.location.reload();
                  }
                },
                error => {
                  // this.loading = false;
                  this.loadingAllTable = false;
                  console.log('error removeUserRequest', error)
                  Swal.fire("", "אירעה שגיאה במחיקת הבקשה", 'error')
                }
              )
            }
            catch (error) {
              console.log(error)
              Swal.fire("", "אירעה שגיאה במחיקת הבקשה", 'error')
            }
          }
        })
      }
      else {
        Swal.fire({
          title: 'מחיקת אתר',
          text: " ביקשת להסיר את האתר מרשימת האתרים האישית שלך. האם להסיר את " + site.url + "?",
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'כן',
          cancelButtonText: 'לא',
        }).then((result) => {

          if (result.isConfirmed) {
            try {
              // this.loading = true;
              this.loadingAllTable = true;
              this.apiService.removeUserSite(site.siteid).subscribe(
                data => {
                  // this.loading = false;
                  this.loadingAllTable = false;
                  const response = data["d"]
                  console.log('removeUserSite ', response);
                  if (response) {
                    this.refreshTable();
                  }
                },
                error => {
                  this.loading = false;
                  this.loadingAllTable = false;
                  console.log('error removeUserSite', error)
                  Swal.fire("", "אירעה שגיאה במחיקת האתר", 'error')
                }
              )
            }
            catch (error) {
              console.log(error)
              Swal.fire("", "אירעה שגיאה במחיקת האתר", 'error')
            }
          }
        })
      }
    }
  }

  refreshTable() {
    this.page = 1;
    this.getMySitesFirst(0);
  }

  refreshNativ() {
    if (!this.refreshing) {
      // window.open('https://bo.enativ.com/refresh', '_blank');
      this.refreshing = true;
      try {
        this.apiService.refreshNativ().subscribe(
          data => {
            this.refreshing = false;
            if (data.indexOf("נכשל") != -1) {
              console.log('error refreshNativ', data)
              Swal.fire("", "אירעה שגיאה ברענון ההגדרות", 'error')
            }
            else {
              console.log('refreshNativ success');
              Swal.fire("", data, 'success')
            }
          },
          error => {
            this.refreshing = false;
            console.log('error refreshNativ', error)
            Swal.fire("", "אירעה שגיאה ברענון ההגדרות", 'error')
          }
        )
      }
      catch (error) {
        this.refreshing = false;
        console.log('error refreshNativ', error)
        Swal.fire("", "אירעה שגיאה ברענון ההגדרות", 'error')
      }
    }
  }

  sync(siteId) {
    console.log("sync " + siteId)
    this.enableSubmit = false;
    this.apiService.syncSiteForUser(this.authenticationService.userData.id, siteId).subscribe(
      data => {
        this.enableSubmit = true;
        console.log('success syncSim', data)
        if (data["res"] != "sync start") {
          Swal.fire("", "אירעה שגיאה", 'error')
        }
        else {
          Swal.fire("", "תהליך הסנכרון החל. התהליך יסתיים בעוד כ-2 דקות", 'success')
        }
      },
      error => {
        this.enableSubmit = true;
        console.log('error sync', error)
        Swal.fire("", "אירעה שגיאה", 'error')
      }
    )
  }

  addSite(url) {
    this.router.navigate(['/add-sites'], { queryParams: { url } })
  }

  sorting(str) {
    if (str == this.sorted)
      this.sorted += " desc"
    else
      this.sorted = str;
    this.page = 1
    this.getMySitesFirst((this.page - 1) * 20);
  }

  mySitesOnly() {
    if (this.isChecked1 || this.isCadenceUser) {
      this.filterKey = 'windowsMachine';
      this.filterValue[0] = this.authenticationService.userData.WindowsMachine;
    }
    else {
      this.filterKey = "";
      this.filterValue = [];
    }
    this.selectedPage = 1;
    this.getMySitesFirst(0);
  }

  statusSelect() {
    this.getMySitesFirst((this.page - 1) * 20);
  }

  toggleRadio() {
    this.showRadio = !this.showRadio;
  }

  unfiltering() {
    this.sorted = "";
    this.selectedItemsStatus = [];
    this.selectedItemsUrl = [];
    this.selectedItemsDate = []
    this.filterKey = "";
    this.filterValue = [];
    this.status = []
    if (this.translateService.currentLang.toUpperCase() == 'HE')
      this.status[0] = "הכל";
    else
      this.status[0] = "All";
    this.getMySitesFirst(0);
  }
  onFilterChange(filterText: string) {
    filterText = filterText.trim();
    if (filterText.length > 2 && !this.usefulUrl.some((element) => element.includes(filterText)))
      this.nShowFilterMenu = false;
    else
      this.nShowFilterMenu = true;
  }

  exitUrlSelect() {
    this.nShowFilterMenu = true;
  }

  onItemSelect(key, value = []) {
    //value= הוא אוביקט המכיל שתי שדות 1. מזהה 2. שדה ספציפי
    //צריך לדעת מה שם השדה השני כדי לגשת אליו
    let field = Object.keys(value[0])[1];
    //שלא ישארו בטעות מקומות במערך שמאותחלים מדברים קודמים
    this.filterValue = [];
    //כשמסננים מביא כל הסטטוסים
    this.status = []
    if (this.lang == 'HE')
      this.status[0] = "הכל";
    else
      this.status[0] = "All";
    this.selectedItemsStatus = [];
    //מוריד מהמערך רווחים מיותרים
    for (let i = 1; i < value.length; i++) {
      value[i][field] = value[i][field].trim();
    }
    this.filterKey = key;
    if (key != "date") {
      for (let i = 0; i < value.length; i++) {
        this.filterValue[i] = value[i][field];
      }
      this.selectedItemsDate = [];
    }
    else {
      for (let i = 0; i < value.length; i++) {
        this.filterValue[i] = value[i][field];
      }
      this.selectedItemsUrl = [];
    }
    this.page = 1
    this.getMySitesFirst((this.page - 1) * 20);
  }

  onItemDeSelect(key, value = []) {
    if (value.length > 0)
      this.onItemSelect(key, value);
    else {
      this.unfiltering();
    }
  }

  onItemSelectStatus() {
    this.filterKey = '';
    this.filterValue = [];
    this.selectedItemsDate = [];
    this.selectedItemsUrl = [];
    this.status = [];
    for (let i = 0; i < this.selectedItemsStatus.length; i++) {
      this.status[i] = this.selectedItemsStatus[i].status
    }
    this.getMySitesFirst((this.page - 1) * 20);
  }

  onItemDeSelectStatus() {
    this.status = [];
    if (this.selectedItemsStatus.length > 0) {
      this.onItemSelectStatus();
    }
    else {
      if (this.lang == 'HE')
        this.status[0] = "הכל";
      else
        this.status[0] = "All";
      this.getMySitesFirst((this.page - 1) * 20);
    }
  }

  navigateToAdvSite(site, location) {
    if (site == "asraf") {
      const to = "0587804574a@gmail.com"
      const subject = "הי ישראל הגעתי מנתיב גם אני רוצה לקבל בדיקה חינם ולחסוך אלפי שקלים בחודש"
      const body = encodeURI("נא למלא פרטים\nשם:\nטלפון:")
      window.open(`https://mail.google.com/mail/u/0/?view=cm&fs=1&to=${to}&su=${subject}&body=${body}&tf=1`, '_blank');
    }
    else if (site == "news-hot") {
      window.open("https://achadashhot.com/", '_blank');
    }
    else if (site == "news-hot-mail") {
      site = "news-hot"
      const to = "Hachadashhot@gmail.com"
      const subject = "גם אני רוצה להתעדכן בחדשות מבית חדשHOT"
      window.open(`https://mail.google.com/mail/u/0/?view=cm&fs=1&to=${to}&su=${subject}&body=&tf=1`, '_blank');
    }
    else if (site == "infinity-ground") {
      window.open("https://www.lighter-life.co.il/?utm_source=nativ", '_blank');
    }
    else if (site == "elisheva-mail") {
      site = 'bool-ey'
      const to = "elishevadout@gmail.com"
      const subject = "הגעתי מאתר נתיב אשמח לקבל קטלוג למייל"
      window.open(`https://mail.google.com/mail/u/0/?view=cm&fs=1&to=${to}&su=${subject}&body=&tf=1`, '_blank');
    }
    else if (site == "elisheva-site") {
      site = 'bool-ey'
      window.open("https://bool-ey.studio/gallery/", '_blank');
    }
    else if (site == "besadno") {
      window.open("https://bsdnosk.co.il/podcast/?utm_source=netiv&utm_medium=baner", '_blank');
    }
    else if (site == "shtiglitz-group") {
      window.open("https://lp.shtiglitz.co.il/smart-j/?utm_source=netiv&utm_medium=baner", '_blank');
    }
    else if (site == "tzameret") {
      window.open("https://tsameret.co.il/adhd/", '_blank');
    }
    else if (site == "artex-home") {
      window.open("https://rtex.co.il/", '_blank');
    }
    else if (site == "ezer-mitzion") {
      window.open("https://www.ami.org.il/donation-campaign/?utm_source=whatsappharedi&utm_medium=nativ&utm_campaign=7-9&utm_content=5", "_blank")
    }
    else if (site == "malam") {
      window.open("https://www.malaam.org.il/lp?utm_source=nativ&utm_medium=banner&utm_campaign=%D7%A0%D7%AA%D7%99%D7%91&utm_term=%D7%90%D7%97%D7%A8&utm_content=%D7%90%D7%97%D7%A8&utm_client=%D7%9E%D7%9C%D7%9E", "_blank")
    }
    else {
      window.open(`https://enativ.com`, '_blank');
    }
    this.apiService.saveAdvActions({ action: 'click', adv: site, page: 'my-sites', bunnerLocation: location, sourceSite: 'nativ-site' }).subscribe(
      data => {
        console.log(`success saveAdvActions ${data}`)
      },
      error => {
        console.log(`error saveAdvActions ${error}`)
      }
    )
  }

  openAdv(advNum) {
    const time = 1000 * 5
    const that = this
    setTimeout(() => {
      that.advsData[advNum].show = true;
    }, time);
  }

  closeAdv(advNum) {
    const time = 1000 * 60 * 5
    this.advsData[advNum].show = false;
    const that = this
    setTimeout(() => {
      that.advsData[advNum].show = true;
    }, time);
  }

  checkShowAdvs() {
    if (this.authenticationService.userData?.usertypeid) {
      const userData = this.authenticationService.userData
      const userType = this.utilsService.getUserType(userData.usertypeid)
      if (userType.IS_USER_MAIL_ONLY || (userData.usertypeid.substring(userData.usertypeid.length - 2, userData.usertypeid.length - 1) == "4")) {
        this.showAdvs = false
      }
      // else
      //   this.showAdvs = true
    }
  }
}
