import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api/api.service';
import { ResponsiveService } from '../../services/responsive/responsive.service'

@Component({
  selector: 'app-daily-apps',
  templateUrl: './daily-apps.component.html',
  styleUrls: ['./daily-apps.component.scss']
})
export class DailyAppsComponent implements OnInit {
  stations = [];
  weather = { maximum_temperature: 0, days: [], description: "", weather_code: "", imgSrc: "" };
  days = ["'א'", "ב'", "ג'", "ד'", "ה'", "ו'", "ש"];
  loading = false;
  noData = false;
  isMobile = false;

  constructor(private apiService: ApiService,
    public responsiveService: ResponsiveService) {
    this.responsiveService.getMobileStatus().subscribe(isMobile => {
      this.isMobile = isMobile;
    });
  }

  ngOnInit(): void {
    this.responsiveService.checkWidth();
    this.onResizeScreen();
    // this.getStations();
    // this.getWeather('178');
    this.loadWeather('script', 'weatherwidget-io-js');
  }
  onResizeScreen() {
    this.responsiveService.getMobileStatus().subscribe(isMobile => {
      this.isMobile = isMobile;
    });
  }
  loadWeather(s, id) {
    var js, fjs = document.getElementsByTagName(s)[0];
    js = document.createElement(s);
    js.id = id;
    js.src = 'https://weatherwidget.io/js/widget.min.js';
    fjs.parentNode.insertBefore(js, fjs);
  }
  getStations() {
    this.loading = true
    this.apiService.getStations().subscribe(
      data => {
        console.log('getStations ', data)
        const dataArr = Object.keys(data["res"]["data"]).map((key) => data["res"]["data"][key]);
        dataArr.forEach(st => {
          this.stations.push({ name: st.name, value: st.lid });
        });
        this.stations.sort();
        this.loading = false
        console.log(this.stations)
      },
      error => {
        this.loading = false
        this.noData = true
        console.log('error getWeather', error)
      }
    )

  }
  getWeather(stId) {
    this.loading = true
    this.apiService.getWeather(stId).subscribe(
      data => {
        const date = new Date().toISOString().split("T")[0]
        this.weather = data["res"].data[date].daily
        this.weather.description = data["res"].data[date].country.description.split("היום:")[1].split(".")[0]
        this.weather.imgSrc = "../../../assets/images/weather/" + this.weather.weather_code + ".svg";
        this.weather.days = []
        for (let i = 0; i < 6; i++) {
          this.weather.days[i] = data["res"].data[this.getNextDay(i + 1)].daily
          this.weather.days[i].day = this.days[new Date(this.weather.days[i].forecast_date).getDay()]
          this.weather.days[i].imgSrc = "../../../assets/images/weather/" + this.weather.days[i].weather_code + ".svg";
        }
        this.loading = false
        console.log('getWeather ', this.weather)
      },
      error => {
        this.loading = false
        this.noData = true
        console.log('error getWeather', error)
      }
    )

  }

  getNextDay(days) {
    const today = new Date()
    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + days)
    return tomorrow.toISOString().split("T")[0]
  }

}
