<div class="first-div enter-div">
    <div class="col-md-8 text-center mx-auto">
        <div class="justify-content-center align-items-center form-div">
            <div class="col-md-5">
                <form [formGroup]="loginForm" class="home-form text-center" (ngSubmit)="onSubmit(loginForm.value)">
                    <h4 class="form-header">{{ 'home.enter' | translate }}</h4>
                    <div class="form-group">
                        <div class="input-container">
                            <i class="fa fa-user icon"></i>
                            <input formControlName="username" type="text" (focus)="onFocusInput($event)"
                                class="form-control text-right" id="username"
                                placeholder="{{ 'form.username' | translate }}"
                                [ngClass]="{ 'is-invalid': submitted && f.username.errors }">
                            <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                                <div *ngIf="f.username.errors.required">
                                    {{ 'form.validation.emptyUsername' | translate }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="input-container">
                            <i class="fa fa-lock icon"></i>
                            <input formControlName="password" [type]="isPasswordVisible ? 'text' : 'password'" (focus)="onFocusInput($event)"
                                class="form-control text-right" id="password"
                                placeholder="{{ 'form.password' | translate }}"
                                [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                            <i [ngClass]="isPasswordVisible ? 'fa fa-eye' : 'fa fa-eye-slash'" class="col-2 eye-slash icon"
                                (click)="togglePasswordVisibility()"></i>
                            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                <div *ngIf="f.password.errors.required">
                                    {{ 'form.validation.emptyPassword' | translate }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div *ngIf="!authenticated" class="invalid-submit">{{authenticatedError}}</div>
                            <button type="submit" class="btn-nativ form-btn square-btn">{{ 'global.enter' | translate
                                }}</button>
                        </div>
                    </div>
                    <div class="row forgot-btn-div">
                        <div class="col-12">
                            <a (click)="openModal(modal)">{{ 'forgot-pass.link' | translate }}</a>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    </div>
</div>
<ng-template #modal let-modal class="forgot-modal modal-md">
    <div class="modal-header">
        <div class="row">
            <div class="col-md-1 close-modal-header">
                <button type="button" class="close" (click)="close(modal)" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 dep-modal-header text-right">
                <div class="row">
                    <div class="col-md-12 text-center">
                        <h5 class="modal-title">{{ 'forgot-pass.header' | translate }}</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-body">
        <form class="home-form text-center" [formGroup]="forgotForm"
            (ngSubmit)="onSubmitForgot(forgotForm.value,modal)">
            <label>{{ 'forgot-pass.sub-header' | translate }}:
            </label>
            <div class="row text-center">
                <div class="col-md-12">
                    <div class="form-group">
                        <input formControlName="username" type="text" class="form-control text-right" id="userName"
                            [ngClass]="{ 'is-invalid': forgotSubmitted && fp.username.errors }">
                        <div *ngIf="forgotSubmitted && fp.username.errors" class="invalid-feedback">
                            <div *ngIf="fp.username.errors.required">
                                {{ 'form.validation.emptyUsername' | translate }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <button type="submit" class="btn-nativ form-btn square-btn">{{ 'global.send' | translate }}</button>
                </div>
            </div>
            <div class="row forgot-username-div">
                <div class="col-12">
                    <a (click)="close(modal)" [routerLink]="['/contact', 'tech-support']">{{
                        'forgot-pass.forgot-username' | translate }}</a>
                </div>
            </div>
        </form>
    </div>
</ng-template>