import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-copy-emails-guide',
  templateUrl: './copy-emails-guide.component.html',
  styleUrls: ['./copy-emails-guide.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CopyEmailsGuideComponent implements OnInit {

  constructor(private router: Router) { }
  pdfSrc = "../../../assets/images/new/copy-emails-guide.pdf"
  ngOnInit(): void {
    this.router.navigate(['/copy-emails-guide.pdf'])
  }

}
