import { Component, OnInit } from '@angular/core';
import { ApiService } from './../../services/api/api.service';
import { UtilsService } from '../../services/utils/utils.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

class ImageSnippet {
  constructor(public src: string, public file: File) { }
}

@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss']
})
export class UploadImageComponent implements OnInit {

  selectedFile: ImageSnippet;
  resAlert = ""
  resAlert1366 = ""
  // bgURL = 'http://localhost:5000/user-bg.png'
  bgURL = 'https://www.enativ.com/user-bg.png'
  bgURL1366 = 'https://www.enativ.com/user-bg-1366.png'
  alertForm: any;
  curAlert = ""
  alertResAlert = ""
  imagesList = []

  constructor(private apiService: ApiService,
    private utilsService: UtilsService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute) {
    this.alertForm = this.formBuilder.group({
      alertText: ''
    });
  }

  ngOnInit(): void {
    this.router.navigate(
      ['.'],
      { relativeTo: this.route }
    );
    this.apiService.getAlert().subscribe(
      data => {
        this.curAlert = data;
      },
      error => {
        console.log('error getAlert', error)
      }
    )
    this.apiService.getBgImages().subscribe(
      data => {
        const files = JSON.parse(data)
        this.imagesList = files["d"];
      },
      error => {
        console.log('error getBgImages', error)
      }
    )
  }

  onSubmit(formData) {
    this.apiService.saveAlert(formData.alertText).subscribe(
      data => {
        this.alertResAlert = "ההודעה נשמרה בהצלחה"
        console.log('success saveAlert', data)
      },
      error => {
        console.log('error saveAlert', error)
      }
    )
  }

  clearAlert() {
    this.alertResAlert = ""
  }

  processFile(imageInput: any) {
    this.resAlert = ""
    const file: File = imageInput.files[0];
    const reader = new FileReader();

    reader.addEventListener('load', (event: any) => {

      this.selectedFile = new ImageSnippet(event.target.result, file);

      this.apiService.uploadImage(this.selectedFile.file).subscribe(
        (res) => {
          this.resAlert = "התמונה נשמרה בהצלחה"
          this.bgURL = this.bgURL.split("?")[0] + '?' + Date.now()
          // this.utilsService.updateImage(this.bgURL)
        },
        (err) => {
          this.resAlert = "אירעה שגיאה בשמירת התמונה: " + err.error
        })
    });

    reader.readAsDataURL(file);
  }

  processFile1366(imageInput: any) {
    this.resAlert1366 = ""
    const file: File = imageInput.files[0];
    const reader = new FileReader();

    reader.addEventListener('load', (event: any) => {

      this.selectedFile = new ImageSnippet(event.target.result, file);

      this.apiService.uploadImage1366(this.selectedFile.file).subscribe(
        (res) => {
          this.resAlert1366 = "התמונה נשמרה בהצלחה"
          this.bgURL = this.bgURL1366 + '?' + Date.now()
          // this.utilsService.updateImage(this.bgURL)
        },
        (err) => {
          this.resAlert1366 = "אירעה שגיאה בשמירת התמונה: " + err.error
        })
    });

    reader.readAsDataURL(file);
  }

  updateImage(image) {
    if (image !== "-1") {
      this.resAlert = ""
      this.apiService.uploadExistsImage(image).subscribe(
        data => {
          this.resAlert = "התמונה נשמרה בהצלחה"
          this.bgURL = this.bgURL.split("?")[0] + '?' + Date.now()
        },
        error => {
          this.resAlert = "אירעה שגיאה בשמירת התמונה: " + error.message
          console.log('error getBgImages', error)
        }
      )
    }
  }
}
