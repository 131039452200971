import { Component, HostListener, OnInit, Renderer2 } from '@angular/core';
// import { formatDate } from '@angular/common';
import { AuthenticationService } from '../app/services/authentication/authentication.service'
import { ApiService } from '../app/services/api/api.service'
import { ChatService } from '../app/services/chat/chat.service'
import { Router, NavigationEnd } from '@angular/router';
import { ResponsiveService } from '../app/services/responsive/responsive.service'
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { UtilsService } from '../app/services/utils/utils.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [ResponsiveService]
})
export class AppComponent implements OnInit {
  title = 'nativ-new-site';
  isMobile = false;
  pendingMsgsCount = 0;
  _countSubscription: any;
  isUserLoggedIn = false;
  date: any;
  heDate: any;
  days = ['ראשון', 'שני', 'שלישי', 'רביעי', 'חמישי', 'שישי', 'שבת'];
  langs = ['en', 'he'];
  displayFrTel = false;
  frTel = "Pour joindre NATIV France : 06 86 56 93 48";
  enableSubmit = true;
  syncPassForm: any;
  invalidPass = false;
  submitted = false;
  profilePassRequired = false;
  isShowChat = false

  constructor(public authenticationService: AuthenticationService,
    public apiService: ApiService,
    public chatService: ChatService,
    public router: Router,
    public responsiveService: ResponsiveService,
    private titleService: Title,
    public translateService: TranslateService,
    private ccService: NgcCookieConsentService,
    private utilsService: UtilsService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private renderer: Renderer2) {
    this.pendingMsgsCount = this.apiService.pendingMsgsCount
    this._countSubscription = this.apiService.pendingMsgsCountChange.subscribe((value) => {
      this.pendingMsgsCount = value;
      // if (this.pendingMsgsCount > 0) {
      //   this.titleService.setTitle("Nativ (" + (this.pendingMsgsCount.toString()) + ")");
      // }
    })
    this.authenticationService.authenticated$.subscribe(value => {
      this.isUserLoggedIn = value;
    });

    this.chatService.displayChat$.subscribe(value => {
      this.isShowChat = value;
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      let loader = this.renderer.selectRootElement('#loader');
      this.renderer.setStyle(loader, 'display', 'none');
    }, 500);

  }

  ngOnInit() {
    if (localStorage.getItem('nativLang')) {
      this.saveLanguage(localStorage.getItem('nativLang'))
    }
    else {
      this.saveLanguage('he')
    }
    this.authenticationService.isLoggedIn();

    // setInterval(() => {
    //   if (this.isUserLoggedIn) {
    //     this.apiService.updateUserMailsCount()
    //   }
    // }, 30000)

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
    this.responsiveService.getMobileStatus().subscribe(isMobile => {
      if (isMobile) {
        this.isMobile = true;
      }
      else {
        this.isMobile = false;
      }
    });
    this.onResize();
    this.syncPassForm = new FormGroup({
      password: new FormControl('', Validators.required),
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event: Event) {
    localStorage.removeItem('adminUser');
  }


  saveLanguage(lang) {
    this.translateService.setDefaultLang(lang);
    this.translateService.use(lang);
    localStorage.setItem('nativLang', lang);
    document.body.classList.add(lang === "he" ? "rtl" : "ltr");
    document.body.classList.remove(lang === "he" ? "ltr" : "rtl");
    this.translateService//
      .get(['cookie.header', 'cookie.message', 'cookie.dismiss', 'cookie.allow', 'cookie.deny', 'cookie.link', 'cookie.policy'])
      .subscribe(data => {

        this.ccService.getConfig().content = this.ccService.getConfig().content || {};
        // Override default messages with the translated ones
        this.ccService.getConfig().content.header = data['cookie.header'];
        this.ccService.getConfig().content.message = data['cookie.message'];
        this.ccService.getConfig().content.dismiss = data['cookie.dismiss'];
        this.ccService.getConfig().content.allow = data['cookie.allow'];
        this.ccService.getConfig().content.deny = data['cookie.deny'];
        this.ccService.getConfig().content.link = data['cookie.link'];
        this.ccService.getConfig().content.policy = data['cookie.policy'];

        this.ccService.destroy();//remove previous cookie bar (with default messages)
        this.ccService.init(this.ccService.getConfig()); // update config with translated messages
      });
  }

  onResize() {
    this.responsiveService.checkWidth();
  }

  toggleChat(value) {
    // this.isShowChat = value;
    this.chatService.showChat(value)
  }
}
