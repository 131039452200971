import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from '../../services/api/api.service';
import { Router } from "@angular/router";
import { AuthenticationService } from '../../services/authentication/authentication.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-remove-site',
  templateUrl: './remove-site.component.html',
  styleUrls: ['./remove-site.component.scss']
})
export class RemoveSiteComponent implements OnInit {

  removeSiteForm: any;
  submitted = false;
  isUserLoggedIn = false;
  displayUserName = "";
  enableSubmit = true;

  constructor(private formBuilder: FormBuilder,
    private apiService: ApiService,
    private router: Router,
    public authenticationService: AuthenticationService) {
    this.removeSiteForm = this.formBuilder.group({
      username: '',
      url: '',
      url1: '',
      url2: '',
      url3: '',
      url4: ''
    });
    this.authenticationService.authenticated$.subscribe(value => {
      this.isUserLoggedIn = value;
      if (this.isUserLoggedIn) {
        this.displayUserName = this.authenticationService.userData.username;
      }
    });
  }

  ngOnInit(): void {
    this.removeSiteForm = new FormGroup({
      username: new FormControl('', Validators.required),
      url: new FormControl('', [Validators.required, Validators.pattern("^\\S*$")]),
      url1: new FormControl(),
      url2: new FormControl(),
      url3: new FormControl(),
      url4: new FormControl()
    });
  }

  get f() {
    return this.removeSiteForm.controls;
  }

  onSubmit(siteData: any) {
    this.submitted = true;
    this.enableSubmit = false;
    if (this.removeSiteForm.invalid) {
      this.enableSubmit = true;
      return;
    }
    siteData["sites"] = [{ url: siteData.url }]
    if (siteData.url1 != null && siteData.url1.trim().length > 4) {
      siteData["sites"].push({ url: siteData.url1 })
    }
    if (siteData.url2 != null && siteData.url2.trim().length > 4) {
      siteData["sites"].push({ url: siteData.url2 })
    }
    if (siteData.url3 != null && siteData.url3.trim().length > 4) {
      siteData["sites"].push({ url: siteData.url3 })
    }
    if (siteData.url4 != null && siteData.url4.trim().length > 4) {
      siteData["sites"].push({ url: siteData.url4 })
    }
    siteData["sites"] = JSON.stringify(siteData["sites"]);

    console.log('remove site has been submitted', siteData);
    try {
      this.apiService.removeSite(siteData).subscribe(
        data => {
          this.enableSubmit = true;
          console.log('success removeSite', data)
          if (data["d"]) {
            this.router.navigate(['/my-sites'])
          }
        },
        error => {
          this.enableSubmit = true;
          console.log('error removeSite', error)
        }
      )
    }
    catch (error) {
      this.enableSubmit = true;
      console.log(error)
      Swal.fire("", "אירעה שגיאה בשליחת הבקשה", 'error')
    }
  }
}
