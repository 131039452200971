<div class="doc-content text-center">
    <div class="exit-div text-left">
        <button class="btn-nativ" (click)="exit()">יציאה
        </button>
    </div>
    <div class="form-content col-md-4 text-center mx-auto">
        <div class="justify-content-center align-items-center form-div">
            <form id="approval-form" [formGroup]="approvalForm" (ngSubmit)="onSubmit(approvalForm.value)">
                <h1 class="doc-header">טופס טכנאים</h1>
                <div class="responsive-div input-div">
                    <h3>פתיחת אינטרנט</h3>
                    <div class="label-div form-check form-check-inline">
                        <label class="form-check-label" for="inlineCheckbox1">נא לפתוח את המשתמש שלי לשעה
                            ולשלוח את הסיסמא למייל</label>
                    </div>
                    <input type="text" formControlName="action1Mail" class="form-control" id="action1Mail"
                        placeholder="כתובת מייל" [ngClass]="{ 'is-invalid': submitted && f.action1Mail.errors }">
                    <div>
                        <button [disabled]="!enableSubmit1" type="submit" class="btn-nativ form-btn">בצע
                        </button>
                    </div>
                </div>
                <div class="responsive-div input-div">
                    <h3>איפוס רשיון</h3>
                    <div class="label-div">
                        <label>נא לאפס רשיון של משתמש</label>
                    </div>
                    <input type="text" formControlName="action2Username" class="form-control" id="action2Username"
                        placeholder="שם משתמש" [ngClass]="{ 'is-invalid': submitted && f.action2Username.errors }">
                    <div>
                        <button [disabled]="!enableSubmit2" type="submit" class="btn-nativ form-btn">בצע
                        </button>
                    </div>
                </div>
                <div *ngIf="deleteSerialList && serialsData.length" class="input-div serials">
                    <h3>איפוס רשיון</h3>
                    <div class="label-div">
                        <label>נא לאפס רשיון של מחשב:</label>
                    </div>
                    <div class="main-div">
                        <div class="table-div">
                            <table id="schedulingTable" *ngIf="!loadingSerials" class="table table-striped text-center">
                                <thead>
                                    <tr>
                                        <th scope="col" class="name"> </th>
                                        <th scope="col" class="name">שם משתמש
                                        </th>
                                        <th scope="col" class="name">שם מחשב</th>
                                        <th scope="col" class="status">גירסת נתיב </th>
                                        <th scope="col" class="date">תאריך כניסה אחרון</th>
                                        <th scope="col" class="name"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let row of serialsData; index as i" id="row_{{i}}">
                                        <td class="name">
                                            {{ i+1 }}</td>
                                        <td [attr.data-label]="'scheduling.table.template' | translate" class="name">
                                            {{ row.userName }}</td>
                                        <td [attr.data-label]="'scheduling.table.template' | translate" class="name">
                                            {{ row.computerName }}</td>
                                        <td [attr.data-label]="'scheduling.table.type' | translate" class="name">
                                            {{ row.enativVersion }}</td>
                                        <td [attr.data-label]="'scheduling.table.date' | translate" class="date">{{
                                            row.computerLastLogin | date :'hh:mm:ss dd/MM/yyyy' }}
                                        </td>
                                        <td class="name text-right">
                                            <button *ngIf="row.serial&&row.serial!==''" [disabled]="!enableReset[i]"
                                                (click)="resetSerials(row,i)" class="btn-nativ form-btn">אפס רשיון
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
                <div class="responsive-div input-div d-none">
                    <h3>סיסמא למשתמש</h3>
                    <div class="label-div">
                        <label>נא לשלוח לי במייל את הסיסמא של משתמש</label>
                    </div>
                    <input type="text" formControlName="action3Username" class="form-control" id="action3Username"
                        placeholder="שם משתמש" [ngClass]="{ 'is-invalid': submitted && f.action3Username.errors }">
                    <input type="text" formControlName="action3Mail" class="form-control" id="action3Mail"
                        placeholder="כתובת מייל" [ngClass]="{ 'is-invalid': submitted && f.action3Mail.errors }">
                    <div>
                        <button [disabled]="!enableSubmit3" type="submit" class="btn-nativ form-btn">בצע
                        </button>
                    </div>
                </div>
                <div class="responsive-div input-div">
                    <h3>בקשה להסרת תוכנה</h3>
                    <div class="label-div">
                        <label>נא לשלוח לי במייל טופס בקשת הסרה עבור משתמש</label>
                    </div>
                    <input type="text" formControlName="action4Username" class="form-control" id="action4Username"
                        placeholder="שם משתמש" [ngClass]="{ 'is-invalid': submitted && f.action4Username.errors }">
                    <div>
                        <button [disabled]="!enableSubmit4" type="submit" class="btn-nativ form-btn">בצע
                        </button>
                    </div>
                </div>
            </form>
            <form *ngIf="displayHelpme" id="helpme-form" [formGroup]="helpmeForm"
                (ngSubmit)="onSubmitHelpme(helpmeForm.value)">
                <div class="responsive-div input-div">
                    <h3>הסרה Help Me</h3>

                    <input type="text" formControlName="serialNum" class="form-control" id="serialNum"
                        [ngClass]="{ 'is-invalid': submitted && fh.serialNum.errors }" placeholder="מספר סידורי">
                    <div *ngIf="submitted && fh.serialNum.errors" class="invalid-feedback">
                        <div *ngIf="fh.serialNum.errors.required">נא למלא מספר סידורי</div>
                    </div>
                    <input type="text" formControlName="auditNum" class="form-control" id="auditNum" [ngClass]="
                            { 'is-invalid' : submitted && fh.auditNum.errors }" placeholder="מספר ביקורת">
                    <div *ngIf="submitted && fh.auditNum.errors" class="invalid-feedback">
                        <div *ngIf="fh.auditNum.errors.required">נא למלא מספר ביקורת</div>
                    </div>
                    <button [disabled]="!enableSubmit5" type="submit" class="btn-nativ form-btn">צור קוד
                    </button>
                    <input type="text" *ngIf="codeRes!=''" class="res-btn btn-nativ" value="{{codeRes}}" disabled />
                </div>
            </form>
            <a class="" href="/contact/tech-support" target="_blank">לפתיחת פניה לחץ כאן</a>
        </div>
    </div>
</div>