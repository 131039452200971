import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  userType = { IS_USER_FR: false, IS_USER_PERSONAL: false, USER_OPEN_ALL_SITES: false, IS_USER_GUR: false, IS_USER_BOYAN: false, IS_USER_MAIL_ONLY: false }
  private homeBgImage = new Subject<any>();

  constructor() { }

  getUserType(userTypeId) {
    if (userTypeId.substring(userTypeId.length - 1, userTypeId.length) == "5") {
      this.userType.IS_USER_FR = true;
    }
    if (userTypeId.substring(userTypeId.length - 1, userTypeId.length) == "7") {
      this.userType.IS_USER_GUR = true;
    }
    if (userTypeId.substring(2, 3) == "2") {
      this.userType.USER_OPEN_ALL_SITES = true;
    }
    if (userTypeId.substring(userTypeId.length - 1, userTypeId.length) == "A") {
      this.userType.IS_USER_BOYAN = true;
    }
    if (userTypeId.charAt(5) == 7 || userTypeId.charAt(5) == "C") {
      this.userType.IS_USER_MAIL_ONLY = true;
    }
    if (userTypeId.charAt(5) == 4) {
      this.userType.IS_USER_PERSONAL = true;
    }
    return this.userType;
  }

  stringToHex(str) {
    let hex, i;
    let result = "";
    for (i = 0; i < str.length; i++) {
      hex = str.charCodeAt(i).toString(16);
      result += ("000" + hex).slice(-4);
    }
    return result
  }


  updateImage(image) {
    this.homeBgImage.next(image);
  }

  clearMessages() {
    this.homeBgImage.next();
  }

  getImage(): Observable<any> {
    return this.homeBgImage.asObservable();
  }
}
