<div *ngIf='!permission'>
    <div class="row first-div no-permission-mail">
        <div class="col-md-5 text-right">
            <h1 class="header">{{ 'mail.no-permission.header' | translate }}</h1>
            <h3 class="sub-header">{{ 'mail.no-permission.sub-header' | translate }}</h3>
            <button routerLink="/main" class="btn-nativ bold">{{ 'mail.no-permission.btn' | translate }}</button>
        </div>
        <div class="col-md-7">
            <img src="../../../assets/images/mail/no-mail-1920.jpg">
        </div>
    </div>
</div>
<div *ngIf='permission' class="mail-div first-div">
    <button #scrollBtn (click)="topFunction()" class="btn-nativ scroll-btn" title="Go to top"><i
            class="fas fa-chevron-up"></i></button>
    <div class="mail-header-div text-center row align-items-center"
        [ngClass]='{ "bg-en": this.translateService.currentLang=="en" }'>
        <div class="text-center col-9">
            <h1 class="mail-header header">{{ 'app.mail' | translate }}</h1>
            <h4 class="mail-sub-header">{{ 'mail.header' | translate }}</h4>
        </div>
    </div>
    <div *ngIf="loading" id="loading" class="d-flex justify-content-center">
        <div class="spinner-border my-auto" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <div *ngIf="noData" class="d-flex justify-content-center no-data-div">
        <div>
            <span>{{ 'global.no-data' | translate }}</span>
        </div>
    </div>
    <div *ngIf="!loading && !noData" class="d-flex justify-content-center p-4">
        <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize"
            (pageChange)="refreshEmails()" [boundaryLinks]="false" [maxSize]="5">
        </ngb-pagination>
    </div>
    <table *ngIf="!loading && !noData" class="mails-table table table-striped text-center">
        <thead>
            <tr>
                <th scope="col" class="date text-left">{{ 'mail.table.date' | translate }}</th>
                <th scope="col" class="subject">{{ 'mail.table.subject' | translate }}</th>
                <th scope="col" class="content">{{ 'mail.table.content' | translate }}</th>

            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let email of emails; index as i">
                <td class="date text-left"> {{ email.Date }} </td>
                <td class="subject">{{ email.msgSubject }}</td>
                <td *ngIf="email.msgContent" class="content">
                    <br *ngIf='email.site' />
                    <span class="url">{{ email.site ? email.site : "" }}</span>
                    <div class="bold" *ngIf='email.msgHeader'>{{ email.msgHeader ? email.msgHeader : "" }}</div>
                    {{ email.msgContent }}
                    <a *ngIf="email.msgLink" class="here-span bold" href="{{email.msgLink}}">כאן</a>
                    <br *ngIf='email.msgAfterLink' />
                    <span *ngIf='email.msgAfterLink'>{{ email.msgAfterLink ? email.msgAfterLink : "" }}</span>

                    <a *ngIf="email.msgLink2" class="here-span bold" href="{{email.msgLink2}}">כאן</a>
                    <br *ngIf='email.msgAfterLink2' />
                    <span *ngIf='email.msgAfterLink2'>{{ email.msgAfterLink2 ? email.msgAfterLink2 : "" }}</span>

                    <br *ngIf='email.msgWarning' />
                    <span *ngIf='email.msgWarning'>{{ email.msgWarning ? email.msgWarning : "" }}</span>
                    <br *ngIf='email.msgAfterWarning' />
                    <span *ngIf='email.msgAfterWarning'>{{ email.msgAfterWarning ? email.msgAfterWarning : "" }}</span>
                    <table *ngIf="email.sites" class="sites-table">
                        <thead>
                            <tr>
                                <th scope="col" class="url">כתובת האתר(URL)</th>
                                <th scope="col" class="name">שם האתר</th>
                                <th scope="col" class="category">קטגוריה</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let site of email.sites; index as i">
                                <td class="url"><a href="{{site.originalRequest ? site.originalRequest : site.url}}"
                                        target="_blank">{{ site.url }}</a></td>
                                <td class="subject">{{ site.sitename }}</td>
                                <td class="category">
                                    {{ site.category.slice(-1) == "," ? site.category.slice(0, -1) : site.category }}
                                </td>
                            </tr>
                            <br />
                            <tr>לרענון רשימת האתרים לחץ <a (click)="refreshNativ()" class="here-span bold">כאן</a></tr>
                        </tbody>
                    </table>
                    <span class="continue-span" (click)="openModal(modal,email)">להמשך קריאה</span>
                </td>
                <td *ngIf="!email.msgContent" [innerHTML]="email.msgBody">
                    <span class="continue-span" (click)="openModal(modal,email)">להמשך קריאה</span>
                </td>
                <!-- <td class="content"> -->
                <!-- <div [innerHTML]="email.msgBody"></div> -->
                <!-- <span class="continue-span" (click)="openModal(modal,email)">להמשך קריאה</span> -->
                <!-- </td> -->
            </tr>
        </tbody>
    </table>

    <ng-template #modal let-modal class="mail-modal">
        <div class="modal-header">
            <div class="row">
                <div class="col-md-1 close-modal-header">
                    <button type="button" class="close" (click)="close(modal)" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 dep-modal-header text-center">
                    <h5 class="modal-title">{{emailData.msgSubject}}</h5>
                </div>
            </div>
        </div>

        <div class="modal-body text-center">
            <div>
                <h5 class="body-header">לכבוד {{authenticationService.userData.firstname}}
                    {{authenticationService.userData.lastname}} ({{authenticationService.userData.username}}) </h5>
                <h6 class="body-sub-header">לקוח/ה נכבד/ה שלום רב,</h6>
                <div class="row justify-content-center align-items-center text-center">
                    <div class="col-md-8">
                        <br *ngIf='emailData.site' />
                        <span class="url">{{emailData.site}}</span>
                        <h5 class="bold" *ngIf='emailData.msgHeader'>{{ emailData.msgHeader ? emailData.msgHeader : ""
                            }}</h5>
                        {{emailData.msgContent}}

                        <a *ngIf="emailData.msgLink" class="here-span bold" href="{{emailData.msgLink}}">כאן</a>
                        <br *ngIf='emailData.msgAfterLink' />
                        <span *ngIf='emailData.msgAfterLink'>{{ emailData.msgAfterLink ? emailData.msgAfterLink : ""
                            }}</span>

                        <a *ngIf="emailData.msgLink2" class="here-span bold" href="{{emailData.msgLink2}}">כאן</a>
                        <br *ngIf='emailData.msgAfterLink2' />
                        <span *ngIf='emailData.msgAfterLink2'>{{ emailData.msgAfterLink2 ? emailData.msgAfterLink2 : ""
                            }}</span>

                        <br *ngIf='emailData.msgWarning' />
                        <br *ngIf='emailData.msgWarning' />
                        <h5 class="bold" *ngIf='emailData.msgWarning'>שימו לב!</h5>
                        <span class="bold" *ngIf='emailData.msgWarning'>{{ emailData.msgWarning ? emailData.msgWarning :
                            "" }}</span>
                        <br *ngIf='emailData.msgAfterWarning' />
                        <span *ngIf='emailData.msgAfterWarning'>{{ emailData.msgAfterWarning ? emailData.msgAfterWarning
                            : "" }}</span>
                        <a *ngIf="emailData.msgAfterWarningLink" class="here-span bold"
                            href="{{emailData.msgAfterWarningLink}}">כאן</a>

                        <table *ngIf="emailData.sites" class="sites-table">
                            <thead>
                                <tr>
                                    <th scope="col" class="url first">כתובת האתר(URL)</th>
                                    <th scope="col" class="name">שם האתר</th>
                                    <th scope="col" class="category">קטגוריה</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let site of emailData.sites; index as i">
                                    <td class="url first"><a href="{{site.url}}" target="_blank">{{ site.url }} </a>
                                    </td>
                                    <td class="subject">{{ site.sitename }}</td>
                                    <td class="category">
                                        {{ site.category.slice(-1) == "," ? site.category.slice(0, -1) : site.category}}
                                    </td>
                                </tr>
                                <tr>
                                    <br />
                                    <div class="row">
                                        <div>לרענון רשימת האתרים לחץ <a (click)="refreshNativ()" class="here-span bold">
                                                כאן </a></div>
                                    </div>
                                </tr>
                            </tbody>
                        </table>
                        <!-- <br *ngIf='emailData.warning' />
                        <div class="warning bold">
                            {{emailData.warning ? emailData.warning : ""}}
                        </div>
                        <br *ngIf='emailData.warningApproval' />
                        {{emailData.warningApproval ? emailData.warningApproval : ""}}
                        <span
                            class="here-span bold">{{emailData.warningApprovalBtn ? emailData.warningApprovalBtn : ""}}</span> -->
                        <br *ngIf='emailData.msgEnd' />
                        <div class="email-ending">
                            {{emailData.msgEnd ? emailData.msgEnd : "בברכה,"}}
                            <br />
                            צוות נתיב
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</div>
<app-bottom-nav></app-bottom-nav>