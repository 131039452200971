import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  displayChat$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() { }

  public showChat(value) {
    this.displayChat$.next(value);
  }
}
