import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from '../../services/api/api.service';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-enter-site',
  templateUrl: './enter-site.component.html',
  styleUrls: ['./enter-site.component.scss']
})
export class EnterSiteComponent implements OnInit {
  loginForm: any;
  submitted = false;
  authenticated = true;
  authenticatedError = "";
  isNativInstalled = true;

  forgotForm: any;
  forgotSubmitted = false
  isPasswordVisible =false;

  constructor(private apiService: ApiService,
    private formBuilder: FormBuilder,
    private router: Router,
    public authenticationService: AuthenticationService,
    private modalService: NgbModal) {

    this.loginForm = this.formBuilder.group({
      username: '',
      password: ''
    });

    this.forgotForm = this.formBuilder.group({
      username: ''
    });

    this.authenticationService.isNativInstalled$.subscribe(value => {
      this.isNativInstalled = value;
      if (!this.isNativInstalled && this.submitted) {
        Swal.fire("", "לא ניתן היה לזהות את גירסת תוכנת נתיב המותקנת במחשבך.", 'error')
      }
    }
    )
  }

  ngOnInit(): void {
    this.loginForm = new FormGroup({
      username: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required)
    });
    this.forgotForm = new FormGroup({
      username: new FormControl('', Validators.required),
    });
  }

  get f() {
    return this.loginForm.controls;
  }

  get fp() {
    return this.forgotForm.controls;
  }

  onFocusInput(event) {
    this.authenticated = true;
  }

  onSubmit(loginData: any) {
    this.submitted = true;
    console.warn('login has been submitted', loginData);
    if (this.loginForm.invalid) {
      return;
    }
    try {
      this.apiService.login(loginData).subscribe(
        data => {
          try {
            console.log('success login', data)
            if (data["data"]["UserData"].usertypeid[0] == '0') {
              data["data"]["UserData"].username = loginData.username;
              this.authenticationService.enterSite(data["data"]["UserData"]);
            }
            else {
              this.router.navigate(['/main'])
            }
          }
          catch (error) {
            console.log(error)
            Swal.fire("", "אירעה שגיאה בכניסה לאתר", 'error')
          }
        },
        error => {
          this.authenticated = false;
          // this.authenticatedError = 'זמנית השרות אינו פעיל';
          if (error.error && error.error.text && error.error.text === "User does not exists!") {
            this.authenticatedError = 'שם משתמש שגוי';
          }
          else {
            this.authenticatedError = 'שם משתמש או סיסמא שגויים'
          }
          console.log('error login', error)
        }
      )
    }
    catch (error) {
      console.log(error)
      Swal.fire("", "אירעה שגיאה בכניסה לאתר", 'error')
    }
  }

  openModal(targetModal) {
    const modal = this.modalService.open(targetModal, {
      centered: true,
      backdrop: true,
      size: 'md'
    });
  }
  close(modal) {
    modal.dismiss()
  }
  onSubmitForgot(forgotData: any, modal) {
    modal.dismiss()
    this.forgotSubmitted = true;
    if (this.forgotForm.invalid) {
      return;
    }
    try {
      this.apiService.forgotPass(forgotData).subscribe(
        data => {
          if (data["d"] == "true") {
            Swal.fire("", "הסיסמא נשלחה לכתובת המייל הרשומה במערכת", 'success')
          }
          else {
            Swal.fire("", data["d"], 'warning')
          }
        },
        error => {
          Swal.fire("", error.message, 'error')
          console.log('error forgotPass', error)
        }
      )
    }
    catch (error) {
      console.log(error)
      Swal.fire("", "אירעה שגיאה בשליחת הבקשה", 'error')
    }
  }
  
  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }
}
