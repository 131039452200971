import { Component, OnInit, ViewChild, ElementRef, HostListener, ApplicationRef } from '@angular/core';
import { ApiService } from '../../services/api/api.service';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import Swal from 'sweetalert2';
import { Router } from "@angular/router";
//search select
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, merge, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { AuthenticationGuardService } from 'src/app/authentication-guard.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-my-sites-expanded',
  templateUrl: './my-sites-expanded.component.html',
  styleUrls: ['./my-sites-expanded.component.scss']
})

export class MySitesExpandedComponent implements OnInit {
  // lastPage: number;
  selectedPage: number = 1;
  // id = "";
  isChecked1 = false;
  isChecked2 = false;
  page = 1;
  pageSize = 20;
  sitesData = [];
  collectionSize: number;
  sites = [];
  sitesLength = 0;
  loading = false;
  loadingAllTable = true;
  siteId = null;
  noData = false;
  isUserLoggedIn = false;
  protocol = window.location.protocol;
  refreshing = false;
  enableSubmit = true;
  displaySimActions = false;
  windowsMachine = "";
  invalidPass = false;
  emptyPass = false;
  submittedPass = false;
  filterKey = "";
  filterValue: string[] = []
  filterKeyBasic = "";
  filterValueBasic: string[] = [];
  manager = false;
  sorted = "";
  status: string[] = [];
  dates: string[] = [];
  submitted = false;
  arrays;
  targetModal: any;
  lang: any;
  userData;
  isExpanded = "true";
  selectedItemsWindowsMachine = [];
  selectedItemsWindowsUser = [];
  selectedItemsLogin = [];
  selectedItemsUrl = [];
  selectedItemsDate = [];
  selectedItemsStatus: any;
  dropdownSettingsWindowsMachine = {};
  dropdownSettingsWindowsUser = {};
  dropdownSettingsLogin = {};
  dropdownSettingsStatus = {};
  dropdownSettingsUrl = {};
  dropdownSettingsDate = {};
  windowsMachines: { id: number, windowsMachine: string }[] = [];
  windowsUsers: { id: number, windowsUsers: string }[] = [];
  logins: { id: number, login: string }[] = [];
  statusArr: { id: number, status: string }[] = [];
  dateArr: { id: number, date: string }[] = [];
  sitesUrl: { id: number, url: string }[] = []
  noComputerData: boolean = true;
  newSitesLength = 1;
  sitesArray: any;
  windowsMachineList = [];
  windowsUserList = [];
  nShowFilterMenu = true;
  usefulUrl = [
    "https://www.", "http://www.", ".com", "co.il/", ".html"
  ]
  // dropdownIsOpen: boolean = false;//try
  constructor(private apiService: ApiService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private authGuardService: AuthenticationGuardService,
    private translateService: TranslateService) {
    this.lang = translateService.currentLang.toUpperCase()
    if (this.lang == 'HE')
      this.status[0] = "הכל";
    else
      this.status[0] = "All";
    this.statusArr = [
      // { id: 1, status: this.translateService.instant('my-sites.table.everything') },
      { id: 1, status: this.translateService.instant('my-sites.table.not-approved') },
      { id: 2, status: this.translateService.instant('my-sites.table.not-approved-email') },
      { id: 3, status: this.translateService.instant('my-sites.table.for-further-treatment') },
      { id: 4, status: this.translateService.instant('my-sites.table.approved') },
      { id: 5, status: this.translateService.instant('my-sites.table.on-hold') },
      { id: 6, status: this.translateService.instant('my-sites.table.existing-handle') },
      { id: 7, status: this.translateService.instant('my-sites.table.handle-removed') },
      { id: 8, status: this.translateService.instant('my-sites.table.approved-expireson') },
    ];
    this.refreshSites();
    this.authenticationService.authenticated$.subscribe(value => {
      this.isUserLoggedIn = value;
      if (this.isUserLoggedIn) {
        if (this.authenticationService.userData.lowVersion && this.authenticationService.userData.BlockActivity.includes("1")) {
          Swal.fire("", "לקוח/ה יקר/ה \n\n במחשב זה מותקנת גירסה ישנה של נתיב, \n לא ניתן לגשת לרשימת האתרים בגירסה זו. \n נא ליצור קשר עם מוקד התמיכה לצורך שידרוג מיידי במספר 5365* או 036199199 ", 'warning')
          this.router.navigate(["/main"])
        }
        this.apiService.getComputerName().subscribe(succ => {
          this.arrays = succ;
          this.windowsMachines = this.arrays.computerNames;
          this.windowsUsers = this.arrays.windowsUsers;
          this.logins = this.arrays.ids;
          this.sitesUrl = this.arrays.sites;
          this.dateArr = this.arrays.dates;
          if (this.windowsMachines && this.windowsMachines.length > 0) {
            this.windowsMachines.sort((a, b) => (a.windowsMachine || '').localeCompare(b.windowsMachine || ''));
            this.windowsMachines = this.windowsMachines.filter((value) => value.windowsMachine !== null && value.windowsMachine !== undefined && value.windowsMachine !== '').filter((obj, index, self) => index === self.findIndex((o) => (o.windowsMachine === obj.windowsMachine)));
          }
          if (this.windowsUsers && this.windowsUsers.length > 0) {
            this.windowsUsers.sort((a, b) => (a.windowsUsers || '').localeCompare(b.windowsUsers || ''));
            this.windowsUsers = this.windowsUsers.filter((value) => value.windowsUsers !== null && value.windowsUsers !== undefined && value.windowsUsers !== '').filter((obj, index, self) => index === self.findIndex((o) => (o.windowsUsers === obj.windowsUsers)));
          }
          if (this.logins && this.logins.length > 0) {
            this.logins.sort((a, b) => (a.login || '').localeCompare(b.login || ''));
            this.logins = this.logins.filter((value) => value.login !== null && value.login !== undefined && value.login !== '').filter((obj, index, self) => index === self.findIndex((o) => (o.login === obj.login)));
          }
          if (this.sitesUrl && this.sitesUrl.length > 0) {
            this.sitesUrl.sort((a, b) => (a.url || '').localeCompare(b.url || ''));
            this.sitesUrl = this.sitesUrl.filter((value) => value.url !== null && value.url !== undefined && value.url !== '').filter((obj, index, self) => index === self.findIndex((o) => (o.url === obj.url)));
          }
          if (this.dateArr && this.dateArr.length > 0) {
            this.dateArr.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
            this.dateArr = this.dateArr.filter((value) => value.date !== null && value.date !== undefined && value.date !== '').filter((obj, index, self) => index === self.findIndex((o) => (o.date === obj.date)));
          }
          this.noComputerData = false;
        })
        this.apiService.hasSimActionsPermmissions(this.authenticationService.userData.id).subscribe(
          data => {
            console.log('hasSimActionsPermmissions success');
            if (data["d"] == true) {
              this.displaySimActions = true;
            }
          },
          error => console.log('error hasSimActionsPermmissions', error)
        )
      }
    })
    this.authenticationService.authenticated$.subscribe(value => {
      this.isUserLoggedIn = value;
      if (this.isUserLoggedIn) {
        this.page = 1
        this.getMySitesFirst(this.page - 1);
      }
    });
  }

  @ViewChild('scrollBtn') scrollBtnEl: ElementRef;
  @HostListener("window:scroll", ["$event"])
  onWindowScroll(event) {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      this.scrollBtnEl.nativeElement.style.display = "block";
    }
    else {
      this.scrollBtnEl.nativeElement.style.display = "none";
    }
    const scrollThreshold = 400;
    // Check if user has reached the bottom of the page
    console.log("sitesLength: ", this.sitesLength, " newSitesLength: ", this.newSitesLength)
    if (window.innerHeight + window.scrollY + scrollThreshold >= document.body.offsetHeight && !this.loading && !this.loadingAllTable && this.sitesLength > 0 && this.newSitesLength > 0) {
      this.page += 1;
      this.onPageChange(this.page);
    }
  }

  topFunction() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  ngOnInit(): void {
    // document.addEventListener('click', this.onDocumentClick);//try
    this.dropdownSettingsWindowsMachine = {
      singleSelection: false,
      idField: 'id',
      textField: 'windowsMachine',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      noDataAvailablePlaceholderText: 'אין מידע זמין',
      itemsShowLimit: 0,
      allowSearchFilter: true,
      enableCheckAll: false
    };
    this.dropdownSettingsWindowsUser = {
      singleSelection: false,
      idField: 'id',
      textField: 'windowsUsers',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      noDataAvailablePlaceholderText: 'אין מידע זמין',
      itemsShowLimit: 0,
      allowSearchFilter: true,
      enableCheckAll: false
    };
    this.dropdownSettingsLogin = {
      singleSelection: false,
      idField: 'id',
      textField: 'login',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      noDataAvailablePlaceholderText: 'אין מידע זמין',
      itemsShowLimit: 0,
      allowSearchFilter: true,
      enableCheckAll: false
    };
    this.dropdownSettingsStatus = {
      singleSelection: false,
      idField: 'id',
      textField: 'status',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 0,
      allowSearchFilter: true,
      enableCheckAll: false
    };
    this.dropdownSettingsUrl = {
      singleSelection: false,
      idField: 'id',
      textField: 'url',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      noDataAvailablePlaceholderText: 'אין מידע זמין',
      itemsShowLimit: 0,
      allowSearchFilter: true,
      enableCheckAll: false
    };
    this.dropdownSettingsDate = {
      singleSelection: false,
      idField: 'id',
      textField: 'date',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      noDataAvailablePlaceholderText: 'אין מידע זמין',
      itemsShowLimit: 0,
      allowSearchFilter: true,
      enableCheckAll: false
    };
  }

  refreshSites() {
    this.sites = this.sitesData
      .map((country, i) => ({ id: i + 1, ...country }))
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }

  getMySites() {
    // this.loading = true;
    this.loadingAllTable = true
    let count;
    const obj = []
    // this.apiService.getUserSitesCount(this.status, this.filterKey, this.filterValue, this.isExpanded).subscribe(
    //   data => {
    //     console.log('getUserSitesCount ', data["d"]);
    //     count = data["d"];
    //     if (count == 0) {
    //       // this.loading = false;
    //       this.loadingAllTable = true
    //       this.noData = true;
    //     }
    //     else {
    //       const length = Math.ceil(count / 50);
    //       let j = 0;
    //       for (let index = 0; index < length; index++) {
    //         obj.push(j)
    //         j += 50
    //       }
    //       loop(obj.shift())
    //     }
    //   },
    //   error => console.log('error getUserSitesCount', error)
    // )
    let loop = (id: number) => {
      this.apiService.getUserSites(id, this.sorted, this.status, this.filterKey, this.filterValue, this.isExpanded, this.filterKeyBasic, this.filterValueBasic).subscribe(
        data => {
          console.log('getUserSites ', data["d"]);
          this.sitesData = this.sitesData.concat(data["d"]);
          this.newSitesLength = data["d"].length
          if (obj.length) {
            loop(obj.shift())
          }
          else {
            this.collectionSize = this.sitesData.length;
            this.refreshSites();
            // this.loading = false;
            this.loadingAllTable = false;
            // this.lastPage = Math.ceil(this.collectionSize / this.pageSize);
          }
        },
        error => console.log('error getUserSites', error)
      )
    }
  }

  getMySitesFirst(index) {
    console.log("filter status", this.status)
    // this.loading = true;
    this.loadingAllTable = true;
    // this.apiService.getUserSitesCount(this.status, this.filterKey, this.filterValue, this.isExpanded).subscribe(
    //   data => {
    //     console.log('getUserSitesCount ', data["d"]);
    //     this.collectionSize = data["d"];
    // this.lastPage = Math.ceil(this.collectionSize / this.pageSize);
    this.apiService.getUserSites(index, this.sorted, this.status, this.filterKey, this.filterValue, this.isExpanded, this.filterKeyBasic, this.filterValueBasic).subscribe(
      data => {
        console.log('getUserSites ', data["d"]);
        this.sitesData = this.sites = data["d"];
        this.sitesLength = this.sitesData.length
        this.newSitesLength = data["d"].length
        // this.loading = false;
        this.loadingAllTable = false;
        // this.apiService.updateUserMailsCount();
      },
      error => console.log('error getUserSites', error)
    )
    //   error => console.log('error getUserSitesCount', error)
    // )
    this.refreshSites();
  }

  onPageChange(pageNumber) {
    console.log("page number", pageNumber)
    const index = (pageNumber - 1) * 20
    this.loading = true;
    this.apiService.getUserSites(index, this.sorted, this.status, this.filterKey, this.filterValue, this.isExpanded, this.filterKeyBasic, this.filterValueBasic).subscribe(
      data => {
        console.log('getUserSites ', data["d"]);
        this.newSitesLength = data["d"].length
        this.sitesData = this.sites = this.sitesData.concat(data["d"]);
        this.loading = false;
        this.sitesLength = this.sitesData.length
        console.log("num of sites", this.collectionSize, " now there is : ", this.sitesLength)
      },
      error => console.log('error getUserSites', error)
    )
  }

  removeSite(site) {
    if (site.remove) {
      if (site.removeTitle.includes("בקשה") || site.removeTitle.includes("request")) {
        Swal.fire({
          title: 'מחיקת בקשה',
          text: "ביקשת להסיר את הבקשה " + site.url + ".האם להסיר ?",
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'כן',
          cancelButtonText: 'לא',
        }).then((result) => {
          if (result.isConfirmed) {
            try {
              // this.loading = true;
              this.loadingAllTable = true;
              this.apiService.removeUserRequest(site.url).subscribe(
                data => {
                  // this.loading = false;
                  this.loadingAllTable = false;
                  const response = data["d"]
                  console.log('removeUserRequest ', response);
                  if (response) {
                    this.refreshTable();
                    // window.location.reload();
                  }
                },
                error => {
                  // this.loading = false;
                  this.loadingAllTable = false;
                  console.log('error removeUserRequest', error)
                }
              )
            }
            catch (error) {
              console.log(error)
              Swal.fire("", "אירעה שגיאה במחיקת הבקשה", 'error')
            }
          }
        })
      }
      else {
        Swal.fire({
          title: 'מחיקת אתר',
          text: " ביקשת להסיר את האתר מרשימת האתרים האישית שלך. האם להסיר את " + site.url + "?",
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'כן',
          cancelButtonText: 'לא',
        }).then((result) => {
          if (result.isConfirmed) {
            try {
              // this.loading = true;
              this.loadingAllTable = true;
              this.apiService.removeUserSite(site.siteid).subscribe(
                data => {
                  // this.loading = false;
                  this.loadingAllTable = false;
                  const response = data["d"]
                  console.log('removeUserSite ', response);
                  if (response) {
                    this.refreshTable();
                    // window.location.reload();
                    // this.siteId = site.siteid;
                  }
                },
                error => {
                  // this.loading = false;
                  this.loadingAllTable = false;
                  console.log('error removeUserSite', error)
                }
              )
            }
            catch (error) {
              console.log(error)
              Swal.fire("", "אירעה שגיאה במחיקת האתר", 'error')
            }
          }
        })
      }
    }
  }

  refreshTable() {
    this.page = 1;
    this.getMySitesFirst(this.page - 1);
  }

  refreshNativ() {
    if (!this.refreshing) {
      // window.open('https://bo.enativ.com/refresh', '_blank');
      this.refreshing = true;
      try {
        this.apiService.refreshNativ().subscribe(
          data => {
            this.refreshing = false;
            if (data.indexOf("נכשל") != -1) {
              console.log('error refreshNativ', data)
              Swal.fire("", "אירעה שגיאה ברענון ההגדרות", 'error')
            }
            else {
              console.log('refreshNativ success');
              Swal.fire("", data, 'success')
            }
          },
          error => {
            this.refreshing = false;
            console.log('error refreshNativ', error)
            Swal.fire("", "אירעה שגיאה ברענון ההגדרות", 'error')
          }
        )
      }
      catch (error) {
        this.refreshing = false;
        console.log('error refreshNativ', error)
        Swal.fire("", "אירעה שגיאה ברענון ההגדרות", 'error')
      }
    }
  }

  sync(siteId) {
    console.log("sync " + siteId)
    this.enableSubmit = false;
    this.apiService.syncSiteForUser(this.authenticationService.userData.id, siteId).subscribe(
      data => {
        this.enableSubmit = true;
        console.log('success syncSim', data)
        if (data["res"] != "sync start") {
          Swal.fire("", "אירעה שגיאה", 'error')
        }
        else {
          Swal.fire("", "תהליך הסנכרון החל. התהליך יסתיים בעוד כ-2 דקות", 'success')
        }
      },
      error => {
        this.enableSubmit = true;
        console.log('error sync', error)
        Swal.fire("", "אירעה שגיאה", 'error')
      }
    )
  }

  addSite(url) {
    this.router.navigate(['/add-sites'], { queryParams: { url } })
  }

  onItemSelect(key, value = []) {
    //שלא ישארו בטעות מקומות במערך שמאותחלים מדברים קודמים
    this.filterValue = [];
    this.filterKeyBasic = "";
    this.filterValueBasic = [];
    this.windowsMachineList = [];
    this.windowsUserList = [];
    //value= הוא אוביקט המכיל שתי שדות 1. מזהה 2. שדה ספציפי
    //צריך לדעת מה שם השדה השני כדי לגשת אליו
    let field
    if (key == 'p.userid')
      field = "id";
    else
      field = Object.keys(value[0])[1];
    //כשמסננים מביא כל הסטטוסים
    this.status = []
    if (this.lang == 'HE')
      this.status[0] = "הכל";
    else
      this.status[0] = "All";
    this.selectedItemsStatus = [];
    //מוריד מהמערך רווחים מיותרים
    if (typeof value[0][field] === 'string') {
      for (let i = 1; i < value.length; i++) {
        value[i][field] = value[i][field].trim();
      }
    }
    this.filterKey = key;
    for (let i = 0; i < value.length; i++) {
      this.filterValue[i] = value[i][field];
    }
    switch (key) {
      case 'windowsMachine': {
        this.selectedItemsWindowsUser = [];
        this.selectedItemsUrl = [];
        this.selectedItemsDate = [];
        break;
      }
      case 'windowsUsers': {
        this.selectedItemsWindowsMachine = [];
        this.selectedItemsUrl = [];
        this.selectedItemsDate = [];
        break;
      }
      case 'p.userid': {
        this.selectedItemsWindowsMachine = [];
        this.selectedItemsWindowsUser = [];
        this.selectedItemsUrl = [];
        this.selectedItemsDate = [];
        break;
      }
    }
    if (key == 'windowsMachine' || key == 'windowsUsers') {
      if (this.selectedItemsWindowsMachine.length > 0 || this.selectedItemsWindowsUser.length > 0)
        for (let i = 0; i < this.selectedItemsWindowsMachine.length; i++) {
          this.windowsMachineList[i] = this.selectedItemsWindowsMachine[i].windowsMachine
        }
      for (let i = 0; i < this.selectedItemsWindowsUser.length; i++) {
        this.windowsUserList[i] = this.selectedItemsWindowsUser[i].windowsUsers
      }
      this.apiService.getSiteName(this.windowsMachineList, this.windowsUserList).subscribe(succ => {
        this.sitesArray = succ
        this.sitesUrl = this.sitesArray.urls;
        this.dateArr = this.sitesArray.dates;
        if (this.sitesUrl && this.sitesUrl.length > 0) {
          this.sitesUrl.sort((a, b) => (a.url || '').localeCompare(b.url || ''));
          this.sitesUrl = this.sitesUrl.filter((value) => value.url !== null && value.url !== undefined && value.url !== '').filter((obj, index, self) => index === self.findIndex((o) => (o.url === obj.url)));
        }
        if (this.dateArr && this.dateArr.length > 0) {
          this.dateArr.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
          this.dateArr = this.dateArr.filter((value) => value.date !== null && value.date !== undefined && value.date !== '').filter((obj, index, self) => index === self.findIndex((o) => (o.date === obj.date)));
        }
      })
    }
    else if (key == 'p.userid') {
      // אם יש סינון לשם משתמש מסוים יראה רק את המחשבים שלו אחרת יראה כל מה שתחת אותו לקוח (יכול להיות תחת שמות משתמשים שונים)
      let specificFilter
      if (value.length < 1)
        specificFilter = "false"
      else
        specificFilter = "true"
      this.apiService.getComputerName(this.filterValue, specificFilter).subscribe(succ => {
        this.arrays = succ;
        this.windowsMachines = this.arrays.computerNames;
        this.windowsUsers = this.arrays.windowsUsers;
        this.logins = this.arrays.ids;
        this.sitesUrl = this.arrays.sites;
        this.dateArr = this.arrays.dates;
        if (this.windowsMachines && this.windowsMachines.length > 0) {
          this.windowsMachines.sort((a, b) => (a.windowsMachine || '').localeCompare(b.windowsMachine || ''));
          this.windowsMachines = this.windowsMachines.filter((value) => value.windowsMachine !== null && value.windowsMachine !== undefined && value.windowsMachine !== '').filter((obj, index, self) => index === self.findIndex((o) => (o.windowsMachine === obj.windowsMachine)));
        }
        if (this.windowsUsers && this.windowsUsers.length > 0) {
          this.windowsUsers.sort((a, b) => (a.windowsUsers || '').localeCompare(b.windowsUsers || ''));
          this.windowsUsers = this.windowsUsers.filter((value) => value.windowsUsers !== null && value.windowsUsers !== undefined && value.windowsUsers !== '').filter((obj, index, self) => index === self.findIndex((o) => (o.windowsUsers === obj.windowsUsers)));
        }
        if (this.logins && this.logins.length > 0) {
          this.logins.sort((a, b) => (a.login || '').localeCompare(b.login || ''));
          this.logins = this.logins.filter((value) => value.login !== null && value.login !== undefined && value.login !== '').filter((obj, index, self) => index === self.findIndex((o) => (o.login === obj.login)));
        }
        if (this.sitesUrl && this.sitesUrl.length > 0) {
          this.sitesUrl.sort((a, b) => (a.url || '').localeCompare(b.url || ''));
          this.sitesUrl = this.sitesUrl.filter((value) => value.url !== null && value.url !== undefined && value.url !== '').filter((obj, index, self) => index === self.findIndex((o) => (o.url === obj.url)));
        }
        if (this.dateArr && this.dateArr.length > 0) {
          this.dateArr.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
          this.dateArr = this.dateArr.filter((value) => value.date !== null && value.date !== undefined && value.date !== '').filter((obj, index, self) => index === self.findIndex((o) => (o.date === obj.date)));
        }
      })
    }
    this.page = 1
    this.getMySitesFirst((this.page - 1) * 20);
  }

  onItemSelectBasic(key, value) {
    let field = Object.keys(value[0])[1];
    this.filterKeyBasic = key;
    this.filterValueBasic = [];
    this.selectedItemsStatus = [];
    this.status = [];
    switch (key) {
      case 'url': {
        this.selectedItemsDate = [];
        for (let i = 0; i < value.length; i++) {
          this.filterValueBasic[i] = value[i][field];
        }
        break;
      }
      case 'date': {
        this.selectedItemsUrl = [];
        for (let i = 0; i < value.length; i++) {
          this.filterValueBasic[i] = value[i][field];
        }
        break;
      }
    }

    this.page = 1;
    this.getMySitesFirst((this.page - 1) * 20);
  }

  onItemDeSelectBasic(key, value) {
    if (value.length > 0) {
      this.onItemSelectBasic(key, value);
    }
    else {
      this.filterKeyBasic = '';
      this.filterValueBasic = [];
      this.page = 1;
      this.getMySitesFirst((this.page - 1) * 20);
    }
  }

  sorting(str) {
    if (str == this.sorted)
      this.sorted += " desc"
    else
      this.sorted = str;
    this.page = 1;
    this.getMySitesFirst((this.page - 1) * 20);
  }

  navigateUserProfile() {
    this.authGuardService.setButtonClickUserProfile(true);
    this.router.navigate(['/user-profile']);
  }
  onFilterChange(filterText: string) {
    this.nShowFilterMenu = false;
    console.log("onFilterChange: ", filterText)
    if (filterText.length > 2 && !this.usefulUrl.some((element) => element.includes(filterText)))
      this.nShowFilterMenu = false;
    else
      this.nShowFilterMenu = true;
  }

  exitUrlSelect() {
    this.nShowFilterMenu = true;
  }

  unfiltering() {
    this.filterKeyBasic = "";
    this.filterValueBasic = [];
    this.sorted = "";
    this.selectedItemsLogin = [];
    this.selectedItemsWindowsMachine = [];
    this.selectedItemsWindowsUser = [];
    this.selectedItemsStatus = [];
    this.selectedItemsUrl = [];
    this.selectedItemsDate = []
    this.filterKey = "";
    this.filterValue = [];
    // this.id = "";
    this.status = []
    if (this.translateService.currentLang.toUpperCase() == 'HE')
      this.status[0] = "הכל";
    else
      this.status[0] = "All";
    this.apiService.getComputerName().subscribe(succ => {
      this.arrays = succ;
      this.windowsMachines = this.arrays.computerNames;
      this.windowsUsers = this.arrays.windowsUsers;
      this.logins = this.arrays.ids;
      this.sitesUrl = this.arrays.sites;
      this.dateArr = this.arrays.dates;
      if (this.windowsMachines && this.windowsMachines.length > 0) {
        this.windowsMachines.sort((a, b) => (a.windowsMachine || '').localeCompare(b.windowsMachine || ''));
        this.windowsMachines = this.windowsMachines.filter((value) => value.windowsMachine !== null && value.windowsMachine !== undefined && value.windowsMachine !== '').filter((obj, index, self) => index === self.findIndex((o) => (o.windowsMachine === obj.windowsMachine)));
      }
      if (this.windowsUsers && this.windowsUsers.length > 0) {
        this.windowsUsers.sort((a, b) => (a.windowsUsers || '').localeCompare(b.windowsUsers || ''));
        this.windowsUsers = this.windowsUsers.filter((value) => value.windowsUsers !== null && value.windowsUsers !== undefined && value.windowsUsers !== '').filter((obj, index, self) => index === self.findIndex((o) => (o.windowsUsers === obj.windowsUsers)));
      }
      if (this.logins && this.logins.length > 0) {
        this.logins.sort((a, b) => (a.login || '').localeCompare(b.login || ''));
        this.logins = this.logins.filter((value) => value.login !== null && value.login !== undefined && value.login !== '').filter((obj, index, self) => index === self.findIndex((o) => (o.login === obj.login)));
      }
      if (this.sitesUrl && this.sitesUrl.length > 0) {
        this.sitesUrl.sort((a, b) => (a.url || '').localeCompare(b.url || ''));
        this.sitesUrl = this.sitesUrl.filter((value) => value.url !== null && value.url !== undefined && value.url !== '').filter((obj, index, self) => index === self.findIndex((o) => (o.url === obj.url)));
      }
      if (this.dateArr && this.dateArr.length > 0) {
        this.dateArr.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
        this.dateArr = this.dateArr.filter((value) => value.date !== null && value.date !== undefined && value.date !== '').filter((obj, index, self) => index === self.findIndex((o) => (o.date === obj.date)));
      }

    })
    this.page = 1
    this.getMySitesFirst((this.page - 1) * 20);;
  }

  onItemDeSelect(key, value = []) {
    if ((key == 'url' || key == 'date') && value.length < 1) {
      if (this.selectedItemsWindowsMachine.length > 0) {
        key = 'windowsMachine';
        value = this.selectedItemsWindowsMachine
      }
      else if (this.selectedItemsWindowsUser.length > 0) {
        key = 'windowsUsers';
        value = this.selectedItemsWindowsUser
      }
    }
    //האם נשאר ברשימה 
    if (value.length > 0) {
      this.onItemSelect(key, value);
    }
    else {
      if (key == 'windowsMachine' || key == 'windowsUsers') {
        this.sitesUrl = this.arrays.sites;
        this.dateArr = this.arrays.dates;
      }
      if (this.selectedItemsLogin.length > 0) {
        this.onItemSelect('p.userid', this.selectedItemsLogin);
      }
      else {
        this.unfiltering();
      }
    }
  }

  onItemSelectStatus() {
    this.status = [];
    this.selectedItemsDate = [];
    this.selectedItemsUrl = [];
    this.filterValueBasic = [];
    this.filterKeyBasic = '';
    for (let i = 0; i < this.selectedItemsStatus.length; i++) {
      this.status[i] = this.selectedItemsStatus[i].status
    }
    this.page = 1;
    this.getMySitesFirst((this.page - 1) * 20);
  }

  onItemDeSelectStatus() {
    this.status = [];
    if (this.selectedItemsStatus.length > 0) {
      this.onItemSelectStatus();
    }
    else {
      if (this.lang == 'HE')
        this.status[0] = "הכל";
      else
        this.status[0] = "All";
      this.page = 1;
      this.getMySitesFirst((this.page - 1) * 20);
    }
  }
} 
