import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiService } from '../../services/api/api.service';
import Swal from 'sweetalert2';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-verify-code-modal',
  templateUrl: './verify-code-modal.component.html',
  styleUrls: ['./verify-code-modal.component.scss']
})
export class VerifyCodeModalComponent implements OnInit {
  enableVerify = true;
  sentCode = "";
  loadingCode = false;
  isUserLoggedIn = false;
  phoneNumber = "--";
  validPhoneCode = true;
  enableSubmit = true;
  errMessage = "";
  verifyCode = "";
  submitted = false;
  verifyCodeHeader = ""
  verifyForm: any;
  invalidNumber = false;
  sendTimes = 0;
  smsOption = true;
  callType = "Call"
  showCodeForm = false
  functionsNames = {
    "techLogin": "כניסת טכנאי",
    "chat": "אימות בצאט"
  }
  @Input() docId = '';
  @Input() c;
  @Input() d;
  @Input() formData;
  phoneNumber2: any;
  selectedPhoneNumber: any;
  number = "";
  number2 = "";
  key = "verifyCode-" + this.docId

  constructor(private apiService: ApiService,
    public authenticationService: AuthenticationService,
    private router: Router,
    public translateService: TranslateService,
    private formBuilder: FormBuilder) {
    this.authenticationService.authenticatedTech$.subscribe(value => {
      if (value) {
        this.number = "-"
        const userData = this.authenticationService.techUserData;
        if (userData.userPhonenumber) {
          this.number = userData.userPhonenumber.replace(/\D/g, '');
        }
        else {
          const numberData = userData.MainPhone ? userData.MainPhone : userData.MobilePhone ? userData.MobilePhone : userData.homephone;
          this.number = (numberData ? numberData : "").replace(/\D/g, '');
        }
        this.number2 = (userData.WorkPhone ? userData.WorkPhone : "").replace(/\D/g, '');
        if (this.number2 !== "" && this.number !== this.number2) {
          this.phoneNumber2 = this.toDisplayNumber(this.number2);
        }

        this.phoneNumber = this.toDisplayNumber(this.number);
        this.selectedPhoneNumber = this.number
        if (this.phoneNumber.length < 9 || this.phoneNumber.length > 15) {
          if (this.phoneNumber2.length < 9 || this.phoneNumber2.length > 15) {
            this.invalidNumber = true;
            Swal.fire("", "מספר הטלפון הקיים במערכת אינו תקין. פנה למוקד התמיכה: 5365*", 'error')
          }
          else {
            if (this.phoneNumber2.length >= 9 && this.phoneNumber2.length <= 15) {
              this.phoneNumber = this.phoneNumber2
              this.phoneNumber2 = ""
              this.selectedPhoneNumber = this.number2
            }
            else {
              this.invalidNumber = true;
              Swal.fire("", "מספר הטלפון הקיים במערכת אינו תקין. פנה למוקד התמיכה: 5365*", 'error')
            }
          }
        }
      }
    })
    this.authenticationService.authenticated$.subscribe(value => {
      this.isUserLoggedIn = value;
      let number, number2
      if (this.isUserLoggedIn) {
        const userData = this.authenticationService.userData;
        const numberData = userData.MainPhone ? userData.MainPhone : userData.MobilePhone ? userData.MobilePhone : userData.homephone;
        number = (numberData ? numberData : "").replace(/\D/g, '');
        number2 = (userData.WorkPhone ? userData.WorkPhone : "").replace(/\D/g, '');
        if (number2 !== "" && number !== number2) {
          this.phoneNumber2 = this.toDisplayNumber(number2);
        }
      }
      this.phoneNumber = this.toDisplayNumber(number);
      this.selectedPhoneNumber = number
      if (this.phoneNumber.length < 9 || this.phoneNumber.length > 15) {
        if (this.phoneNumber2.length < 9 || this.phoneNumber2.length > 15) {
          this.invalidNumber = true;
          Swal.fire("", "מספר הטלפון הקיים במערכת אינו תקין. פנה למוקד התמיכה: 5365*", 'error')
        }
        else {
          if (this.phoneNumber2.length >= 9 && this.phoneNumber2.length <= 15) {
            this.phoneNumber = this.phoneNumber2
            this.phoneNumber2 = ""
            this.selectedPhoneNumber = number2
          }
          else {
            this.invalidNumber = true;
            Swal.fire("", "מספר הטלפון הקיים במערכת אינו תקין. פנה למוקד התמיכה: 5365*", 'error')
          }
        }
      }
      else {
        return
      }
    });
    this.verifyForm = this.formBuilder.group({
      phoneCode: ''
    });
  }

  toDisplayNumber(number) {
    number = this.replaceAt(number, 0, "*")
    number = this.replaceAt(number, 1, "*")
    number = this.replaceAt(number, 2, "*")
    number = this.replaceAt(number, 3, "*")
    number = this.replaceAt(number, 4, "*")
    number = this.replaceAt(number, 5, "*")
    return number
  }

  replaceAt(s, n, t) {
    return s.substring(0, n) + t + s.substring(n + 1);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.c('Close click')
    }, 600000);
    this.verifyCodeHeader = this.translateService.instant('verify-code.sub-header');
    this.verifyForm = new FormGroup({
      phoneCode: new FormControl('', Validators.required)
    });
    // this.smsOption = this.formData.isTech
  }

  get f() {
    return this.verifyForm.controls;
  }

  sendVerifyCodeAgain() {
    this.showCodeForm = false
    this.sentCode = ''
    this.verifyCodeHeader = this.translateService.instant('verify-code.sub-header');
    this.verifyForm.controls['phoneCode'].setValue('');
    this.verifyForm.controls['phoneCode'].clearValidators()
    this.loadingCode = false;
  }

  changePhoneNumber(phoneNumber) {
    this.selectedPhoneNumber = phoneNumber
  }

  sendVerifyCode() {
    if (!this.invalidNumber) {
      let times = localStorage.getItem(this.key) ? parseInt(localStorage.getItem(this.key)) : 0
      if (times < 3) {
        times += 1
        localStorage.setItem(this.key, times.toString())
        // this.c('Close click')
        this.showCodeForm = true
        this.verifyCodeHeader = `בדקה הקרובה תקבלו ${this.callType == "SMS" ? "הודעת SMS " : "הודעה קולית  "} בה ימסר לכם קוד האימות`
        // this.enableVerify = false;

        this.loadingCode = true
        this.apiService.sendVerifyCode(this.callType, this.functionsNames[this.formData.function], this.formData.isTech && this.authenticationService.techUserData.userPhonenumber ? this.authenticationService.techUserData.userPhonenumber : "").subscribe(
          data => {
            this.enableVerify = true;
            const res = data["res"];
            if (res["verifyCode"]) {
              this.sentCode = res["verifyCode"]
              this.loadingCode = false;
              this.apiService.codeSent$.next(this.sentCode);
            }
            else {
              this.exit()
              Swal.fire("", "שליחת קוד האימות נכשלה", 'error')
            }
          },
          error => {
            this.exit()
            this.enableVerify = true;
            console.log('error sendVerifyCode', error)
            // this.apiService.codeSent$.next("error");
            if (error.error && error.error.res) {
              if (error.error.res == "failed" || error.error.res == "no phone number") {
                Swal.fire("", "מספר הטלפון הקיים במערכת אינו תקין. פנה למוקד התמיכה: 5365*", 'error')
              }
              else {
                Swal.fire("", "שליחת קוד האימות נכשלה", 'error')
              }
            }
            else {
              Swal.fire("", "שליחת קוד האימות נכשלה", 'error')
            }
          }
        )
      }
      else {
        this.exit()
        Swal.fire("", "מספר נסיונות השליחה רב מידי. פנה למוקד התמיכה: 5365*", 'error')
      }
    }
    else {
      Swal.fire("", "מספר הטלפון הקיים במערכת אינו תקין. פנה למוקד התמיכה: 5365*", 'error')
    }
  }

  continue(formData) {
    this.submitted = true;
    this.enableSubmit = false;
    if (this.verifyForm.invalid) {
      this.enableSubmit = true;
      return;
    }
    else {
      this.validPhoneCode = this.sentCode !== "" && (formData.phoneCode == this.sentCode);
      this.sendTimes += 1
      this.apiService.updateVerifySendingAttempts({ userCode: formData.phoneCode, sentCode: this.sentCode, isValid: this.validPhoneCode, times: this.sendTimes, reason: this.functionsNames[this.formData.function] })
      if (this.validPhoneCode) {
        this.apiService.codeSent$.next("valid-" + this.docId);
        try {
          this.apiService.checkValidDoc(this.docId).then((res) => {
            if (!res) {
              Swal.fire("", "טופס לא תקין", 'error')
              this.router.navigate(['/main'])
            }
            else {
              switch (this.formData.function) {
                case "techLogin":
                  this.c('Close click')
                  this.enableSubmit = true;
                  this.router.navigate(['/tech'])
                  break;
                case "chat":
                  this.c('Close click')
                  this.enableSubmit = true;
                  //this.router.navigate(['/tech'])
                  break;
                default:
                  break;
              }

            }
          })
        }
        catch (error) {
          this.enableSubmit = true;
          console.log(error)
          Swal.fire("", "אירעה שגיאה בשליחת הבקשה", 'error')
        }
      }
      else {
        this.enableSubmit = true;
        this.verifyForm.get('phoneCode').setErrors('invalid password')
      }
    }
  }
  chooseType(type) {
    this.callType = type;
  }
  exit() {
    localStorage.setItem(this.key, "0")
    if (this.formData.function == "chat") {
      this.apiService.codeSent$.next("exit");
    }
    this.c('Close click')
  }
}
