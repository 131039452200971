import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-tech-exit',
  templateUrl: './tech-exit.component.html',
  styleUrls: ['./tech-exit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TechExitComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
