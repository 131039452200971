import { Component, OnInit } from '@angular/core';
// import * as KosherZmanim from "kosher-zmanim";
import { ApiService } from '../../services/api/api.service'

@Component({
  selector: 'app-kosher-zmanim',
  templateUrl: './kosher-zmanim.component.html',
  styleUrls: ['./kosher-zmanim.component.scss']
})
export class KosherZmanimComponent implements OnInit {

  zmanim: any = {};
  date: any;
  getByPlace = false;
  places = [
    { name: "ירושלים", value: "31.788,35.218,800" },
    { name: "תל אביב", value: "32.06,34.77,0" },
    { name: "בני ברק", value: "32.08,34.82,0" },
    { name: "חיפה", value: "32.8,34.991,0" },
    { name: "באר שבע", value: "31.24,34.79,0" },
  ]
  place = "";

  constructor(private apiService: ApiService) {
    if (localStorage.getItem('userPlace')) {
      this.place = localStorage.getItem('userPlace')
    }
    else {
      this.place = "בני ברק"
    }
  }

  ngOnInit(): void {
    // this.getKosherZmanim()
    // this.getChazonShamaim()
    // this.getDate();

  }

  ngAfterViewInit() {
    this.getChazonShamaim();
    this.runOncePerDay();
  }

  getTime(date) {
    const newDate = new Date(date).toLocaleTimeString('it-IT').split(":");
    return newDate[0] + ":" + newDate[1];
  }

  getChazonShamaim() {
    // לפי מיקום המשתמש
    // const that = this;
    // navigator.geolocation.getCurrentPosition(function (position) {
    //   const lat = position.coords.latitude;
    //   const long = position.coords.longitude;
    //   that.getZmanimApi(lat, long)
    // }, function (error) {
    //   console.log("getCurrentPosition error " + error.message);
    //   if (error.message.indexOf("Only secure origins are allowed") > -1) {
    // that.getByPlace = true;
    // const lat = 32.08513246453734
    // const long = 34.84378268435648
    // that.getZmanimApi(lat, long)
    // }
    // });
    if (localStorage.getItem('userPlace')) {
      // this.place = localStorage.getItem('userPlace')
      let value = this.places.find(o => o.name === this.place).value;
      let values = value.split(",")
      this.getZmanimApi(values[0], values[1], values[2], this.place)
    }
    else {
      // this.place = "בני ברק"
      this.getByPlace = true;
      const lat = 32.08
      const long = 34.82
      this.getZmanimApi(lat, long, 0, this.place)
    }
  }

  fillData(data) {
    const dates = data["DailyZmanim"];
    var datesObjs = dates.map(x => ({
      name: x[0],
      date: x[1],
      index: x[2]
    }));

    this.zmanim.parasha = datesObjs.find(o => o.name === 'פרשה').date;
    this.zmanim.date = datesObjs.find(o => o.name === 'תאריך עברי').date;
    this.zmanim.desc = datesObjs.find(o => o.name === 'תיאור היום') ? datesObjs.find(o => o.name === 'תיאור היום').date : "";
    this.zmanim.sunrise = datesObjs.find(o => o.name === 'זריחה').date;
    this.zmanim.sofZmanShmaMGA = datesObjs.find(o => o.name === 'סזק״ש מג״א לחומרא').date;
    this.zmanim.sofZmanShmaGRA = datesObjs.find(o => o.name === 'סזק״ש גר״א').date;
    this.zmanim.sofZmanTfilaMGA = datesObjs.find(o => o.name === 'סז״ת מג״א לחומרא').date;
    this.zmanim.sofZmanTfilaGRA = datesObjs.find(o => o.name === 'סז״ת גר״א').date;
    this.zmanim.chatzos = datesObjs.find(o => o.name === 'חצות היום').date;
    this.zmanim.minchaGedola = datesObjs.find(o => o.name === 'מנחה גדולה').date;
    this.zmanim.minchaKetana = datesObjs.find(o => o.name === 'מנחה קטנה').date;
    this.zmanim.plagHamincha = datesObjs.find(o => o.name === 'פלג המנחה').date;
    this.zmanim.sunset = datesObjs.find(o => o.name === 'שקיעה').date;
    this.zmanim.tzais = datesObjs.find(o => o.name === 'צה״כ').date;
    this.zmanim.nerotBB = datesObjs.find(o => o.name === 'הדלקת נרות בני ברק').date;
    this.zmanim.nerotYM = datesObjs.find(o => o.name === 'הדלקת נרות י-ם').date;
    this.zmanim.nerotTA = datesObjs.find(o => o.name === 'הדלקת נרות תל אביב').date;
    this.zmanim.nerotHA = datesObjs.find(o => o.name === 'הדלקת נרות חיפה').date;
    this.zmanim.nerotBS = datesObjs.find(o => o.name === 'הדלקת נרות באר שבע').date;
    this.zmanim.tzaitBB = datesObjs.find(o => o.name === 'מוצ״ש בני ברק').date;
    this.zmanim.tzaitYM = datesObjs.find(o => o.name === 'מוצ״ש ירושלים').date;
    this.zmanim.tzaitTA = datesObjs.find(o => o.name === 'מוצ״ש תל אביב').date;
    this.zmanim.tzaitHA = datesObjs.find(o => o.name === 'מוצ״ש חיפה').date;
    this.zmanim.tzaitBS = datesObjs.find(o => o.name === 'מוצ״ש באר שבע').date;
    switch (this.place) {
      case 'בני ברק':
        this.zmanim.nerotShabbat = this.zmanim.nerotBB
        this.zmanim.tzaitShabbat = this.zmanim.tzaitBB
        break;
      case 'תל אביב':
        this.zmanim.nerotShabbat = this.zmanim.nerotTA
        this.zmanim.tzaitShabbat = this.zmanim.tzaitTA
        break;
      case 'ירושלים':
        this.zmanim.nerotShabbat = this.zmanim.nerotYM
        this.zmanim.tzaitShabbat = this.zmanim.tzaitYM
        break;
      case 'חיפה':
        this.zmanim.nerotShabbat = this.zmanim.nerotHA
        this.zmanim.tzaitShabbat = this.zmanim.tzaitHA
        break;
      case 'באר שבע':
        this.zmanim.nerotShabbat = this.zmanim.nerotBS
        this.zmanim.tzaitShabbat = this.zmanim.tzaitBS
        break;
      default:
        this.zmanim.nerotShabbat = this.zmanim.nerotBB
        this.zmanim.tzaitShabbat = this.zmanim.tzaitBB
        break;
    }
  }

  getZmanimApi(lat, long, altitude, place) {
    localStorage.setItem('userPlace', place);
    this.place = place;
    let date: any = new Date().toLocaleDateString()
    date = date.replaceAll(".", "/")
    this.apiService.getKosherZmanim(date, lat, long, altitude).subscribe(
      data => {
        console.log('success getKosherZmanim', data)
        this.fillData(data)
      },
      error => {
        console.log('error getKosherZmanim', error)
      }
    )
  }

  getDate() {
    this.apiService.getHebrewDate().subscribe(data => {
      this.date = data["d"]
    },
      error => console.log('error', error)
    )
  }

  runOncePerDay() {
    const hourInMilliseconds = 1000 * 60 * 60;
    const that = this
    setInterval(function () {
      var hour = new Date().getHours();
      if (hour == 7) {
        console.log("Get daily times once a day: ", new Date())
        that.getDate()
        that.getChazonShamaim();
      }
    }, hourInMilliseconds);
  }

}
