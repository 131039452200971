<div class="about-div">
    <button #scrollBtn (click)="topFunction()" class="btn-nativ scroll-btn" title="Go to top"><i
            class="fas fa-chevron-up"></i></button>
    <div class="first-div">
        <div class="about-header-div text-center row align-items-center">
            <div class="text-center col-12 mx-auto align-self-center">
                <h1 class="about-header header">{{ 'about.header' | translate }}</h1>
            </div>
        </div>
    </div>
    <div id="section-1">
        <div class="text-center bold">
            <h3>
                {{ 'about.sections.section-1.header' | translate }}
            </h3>
        </div>
        <div class="row align-items-center">
            <div class="img-div col-md-12 text-center">
                <img src="../../../assets/images/about/quote.jpg" />
            </div>
            <!-- <div
                class="content-div text-center col-md-6 col-lg-9 mx-auto align-self-center justify-content-center d-flex align-items-center">
            </div> -->
        </div>
    </div>
    <div id="section-2">
        <div class="row">
            <div data-aos="zoom-in" data-aos-delay="100" data-aos-duration="1000"
                class="col-6 col-div text-left img-div" [ngClass]="moreData ? 'expanded' : ''">
                <img src="../../../assets/images/about/section-2.jpg" />
            </div>
            <div class="col-6 col-div content-div text-justify">
                <div class="content" [ngClass]="moreData ? 'expanded' : ''"
                    [innerHTML]="'about.sections.section-2.content' | translate">
                </div>
                <div class="show-more-div text-center">
                    <a #showMoreData class="show-more-data" (click)="readMore()">{{ 'about.read-more' | translate }}</a>
                </div>
            </div>

        </div>
    </div>
    <div id="section-3" class="row d-flex justify-content-center">
        <div class="col-6 text-justify">
            <div class="content" [ngClass]="moreDataNativ ? 'expanded-nativ' : ''"
                [innerHTML]="'about.sections.section-3.content' | translate">
            </div>
            <a #showMore class="show-more-data" (click)="showMoreNativ()">{{ 'about.read-more' | translate }}</a>
        </div>
    </div>
    <div id="section-4">
        <div class="row">
            <div data-aos="zoom-in" data-aos-delay="100" data-aos-duration="1000" class="col-4 text-left">
                <img src="../../../assets/images/about/section-4.jpg" />
            </div>
            <div class="col-8 text-justify">
                <div class="content" [innerHTML]="'about.sections.section-4.content' | translate">
                </div>
            </div>
        </div>
    </div>
    <div id="section-5" class="row d-flex justify-content-center">
        <div class="div-content col-12 text-right">
            <div class="content" [innerHTML]="'about.sections.section-5.content' | translate">
            </div>
        </div>
        <img data-aos="zoom-in" data-aos-delay="100" data-aos-duration="1000"
            src="../../../assets/images/about/section-5.jpg" />
    </div>
    <div class="row d-flex justify-content-center">
        <a class="btn-nativ btn-large" [routerLink]="['/sales']" routerLinkActive="active">{{ 'about.btn'
            | translate }} &#10095;&#10095;</a>
    </div>
</div>
<app-bottom-nav></app-bottom-nav>