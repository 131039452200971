import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot, NavigationEnd } from '@angular/router';
import { AuthenticationService } from '../app/services/authentication/authentication.service'
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuardService implements CanActivate {
  private isButtonClick = false;
  private previousUrl = '';
  constructor(private router: Router,
    public authenticationService: AuthenticationService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    //chats-recordsמאיזה כתובת הגיע ל
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (!event.url.includes("/chats-records"))
          this.previousUrl = event.url;
        console.log('Previous page URL:', this.previousUrl);
      }
    });
    if (state.url.includes("/main") && !(state.url.includes("/main-in") || state.url.includes("/main-out"))) {
      if (localStorage.getItem('nativUser')) {
        this.router.navigate(['/main-in'], { queryParams: route.queryParams });
      }
      else {
        this.authenticationService.shortNativLogin().subscribe(
          loginData => {
            console.log('success shortNativLogin', loginData)
            if (loginData != null && loginData["UserName"] != "nouser" && loginData["UserID"] != "-1") {
              this.router.navigate(['/main-in'], { queryParams: route.queryParams });
            }
            else {
              this.router.navigate(['/main-out'], { queryParams: route.queryParams });
            }
          },
          error => {
            console.log('error shortNativLogin', error)
            this.authenticationService.nativLogin().subscribe(
              loginData => {
                console.log('success nativLogin', loginData)
                if (loginData != null && loginData["UserName"] != "nouser" && loginData["UserID"] != "-1") {
                  this.router.navigate(['/main-in'], { queryParams: route.queryParams });
                }
                else {
                  this.router.navigate(['/main-out'], { queryParams: route.queryParams });
                }
              },
              error => {
                console.log('error nativLogin', error)
                this.router.navigate(['/main-out'], { queryParams: route.queryParams });
              }
            )
          }
        )
      }
    }
    else if (state.url == "/mails" || state.url == "/user-profile" || state.url == "/my-sites" || state.url == "/my-sites-expanded") {
      const userData = JSON.parse(localStorage.getItem('nativUser'))
      if (!localStorage.getItem('nativUser')) {
        if (state.url == "/user-profile") {
          this.router.navigate(["/sales"]);
        }
        else {
          this.router.navigate(["/main"]);
        }
      }
      else if (state.url == "/my-sites-expanded" || state.url == "/user-profile") {
        if (this.isButtonClick || userData.ManagerPassword == "") {
          this.isButtonClick = false; // Reset the flag for subsequent requests
          return true; // Allow navigation to UserProfileComponent
        }
        else {
          this.router.navigate(['/main']); // Redirect to home page for external link access
          return false;
        }
      }
      else if (state.url == "/my-sites" && ( userData.id == environment.iaiServerUserId || userData.id == environment.eltaServer )) {
        this.router.navigate(['/main'], { queryParams: { mysitepass: 'true' } }); // Redirect to home page for managerPass
        return false;
      }
      else {
        return true
      }
    }
    else if (state.url.includes("/main.aspx") || state.url.includes("/kn_template.aspx") || state.url.includes("/default.aspx") || state.url.includes("/getinfo.aspx")) {
      this.router.navigate(['/main'], { queryParams: route.queryParams });
      return false;
    }
    else if (state.url.includes("/addsite.aspx") || state.url.includes("/Addsite.aspx") || state.url.includes("/AddSite.aspx")) {
      this.router.navigate(['/add-sites'], { queryParams: route.queryParams });
      return false;
    }
    else if (state.url.includes("/contact.aspx")) {
      this.router.navigate(['/contact'], { queryParams: route.queryParams });
      return false;
    }
    else if (state.url.includes("/feedback.aspx")) {
      this.router.navigate(['/feedback'], { queryParams: route.queryParams });
      return false;
    }

    else if ((state.url.includes("/upload-image")) || (state.url.includes("/chats-records")) || (state.url.includes("/chat-details"))) {
      const key = new Date().getDate() * 147;
      const adminUser = JSON.parse(sessionStorage.getItem('adminUser'));
      if ((!state.url.includes("/upload-image")) && adminUser && adminUser.adminid != undefined && adminUser.adminlogin != undefined) {
        return true;
      }
      if (route.queryParams.adminid && route.queryParams.adminlogin) {
        return true
      }
      else if (route.queryParams.key && route.queryParams.key == key) {
        return true
      }
      else {
        this.router.navigate(['/main'])
        return false;
      }
    }
    else if ((state.url.includes("/chat-support-management")) || (state.url.includes("/emails")) || ((state.url.includes("/edit-email")))) {
      const adminUser = JSON.parse(sessionStorage.getItem('adminUser'));
      if (route.queryParams.adminid && route.queryParams.adminlogin) {
        if (this.isManager(route.queryParams.adminlogin))
          return true;
        else {
          return false;
        }
      }
      else if (adminUser && this.isManager(adminUser.adminlogin)) {
        return true;
      }
      else {
        return false;
      }
    }
    // else if ((state.url.includes("/chat-documentation/chat-details"))) {
    //   const adminUser = JSON.parse(sessionStorage.getItem('adminUser'));
    //   if (adminUser && adminUser.adminid != undefined && adminUser.adminlogin != undefined) {
    //     return true;
    //   }
    //   else {
    //     return false;
    //   }
    // }
    else if ((state.url.includes("/channels-admin"))) {
      let adminUser = JSON.parse(localStorage.getItem('adminUser'));
      if (adminUser && adminUser.adminid != undefined && adminUser.adminlogin != undefined) {
        return true;
      }
      adminUser = JSON.parse(sessionStorage.getItem('adminUser'));
      if (adminUser && adminUser.adminid != undefined && adminUser.adminlogin != undefined) {
        localStorage.setItem('adminUser', JSON.stringify(adminUser))
        return true;
      }
      if (route.queryParams.adminid && route.queryParams.adminlogin) {
        return true
      }
      else {
        window.location.href = 'https://admin.enativ.com/login.aspx'
        return false;
      }
    }
    else {
      return true
    }
  }
  setButtonClickUserProfile(value: boolean) {
    this.isButtonClick = value;
  }
  getPreviousUrl() {
    return this.previousUrl;
  }
  isManager(login) {
    if (login == 's_h' || login == 'ronit' || login == 'shlomi' || login == 'nechami448' || login == 'tziviak')
      return true;
    else
      return false;
  }
}

