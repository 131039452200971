import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { ApiService } from '../../services/api/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { Router } from "@angular/router";
import { AuthenticationGuardService } from '../../authentication-guard.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})

export class UserProfileComponent implements OnInit {
  netType: any;
  isp: any;
  simNum: any;
  simUsed: any;
  simCompany: any;
  sticksData = [];
  showTable = false;
  isUserLoggedIn = false;
  userData: any;
  displayFullname = "";
  displatUsername = "";
  displayEmail = "";
  displayAcivelist = "";
  displayPath = "";
  displayNmboflicenses = "";
  displayQuota = "";
  displaySimActions = false;
  createSimForm: any;
  enableSubmit = true;
  submitted = false;
  packages = [];
  companyPackages = [];
  invalidSerNum = false;
  invalidSimNum = false;
  showSimNum = false;
  invalidPass = false;
  syncPassForm: any;
  emptyPass = false;
  syncSimNum: any;
  invalidSerNumLength = false;
  enableSubmitDelete = true;
  serialsData = []
  showSerialsTable = false
  showSerialsTableMySites = false
  selectedItemsComputerName = [];
  selectedItemsComputerLastLogin = [];
  selectedItemsEnativVersion = [];
  selectedItemsLogin = [];
  selectedItemsUsers = { id: this.authenticationService.userData.id, user: this.authenticationService.userData.login };
  dropdownSettingsComputerName = {};
  dropdownSettingsComputerLastLogin = {};
  dropdownSettingsEnativVersion = {};
  dropdownSettingsLogin = {};
  dropdownSettingsUsers = {};
  computerNames: { id: number, computerName: string }[] = [];
  computerLastLogins: { id: number, computerLastLogin: string }[] = [];
  enativVersions: { id: number, enativVersion: string }[] = [];
  logins: { id: number, login: string }[] = [];
  users: { id: number, user: string }[] = [];
  serialsFilterData: any[];
  sorted = "";
  details: any;
  userDataDetails: any;
  sorting: string;
  lang: string;

  constructor(public authenticationService: AuthenticationService,
    private apiService: ApiService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private router: Router,
    private authGuardService: AuthenticationGuardService, private translateService: TranslateService) {
    this.lang = translateService.currentLang.toUpperCase()
    this.authenticationService.authenticated$.subscribe(value => {
      this.isUserLoggedIn = value;
      if (this.isUserLoggedIn) {
        this.initializeData()
      }
    });
    this.createSimForm = this.formBuilder.group({
      simCompany: '',
      package: '',
      simNum: '',
      serialNum: '',
    });
    this.syncPassForm = this.formBuilder.group({
      password: '',
    });
  }

  ngOnInit(): void {
    this.createSimForm = new FormGroup({
      simCompany: new FormControl('', Validators.required),
      package: new FormControl('', Validators.required),
      simNum: new FormControl('', [Validators.minLength(12), Validators.maxLength(12), Validators.pattern("^[0-9]*$")]),
      serialNum: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$")]),
    });
    this.syncPassForm = new FormGroup({
      password: new FormControl('', Validators.required),
    });
    this.createSimForm.get('package').disable();
    this.dropdownSettingsComputerName = {
      singleSelection: false,
      idField: 'id',
      textField: 'computerName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 0,
      allowSearchFilter: true,
      enableCheckAll: false,
    };
    this.dropdownSettingsComputerLastLogin = {
      singleSelection: false,
      idField: 'id',
      textField: 'computerLastLogin',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 0,
      allowSearchFilter: true,
      enableCheckAll: false
    };
    this.dropdownSettingsEnativVersion = {
      singleSelection: false,
      idField: 'id',
      textField: 'enativVersion',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 0,
      allowSearchFilter: true,
      enableCheckAll: false
    };
    this.dropdownSettingsLogin = {
      singleSelection: false,
      idField: 'id',
      textField: 'login',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 0,
      allowSearchFilter: true,
      enableCheckAll: false
    };
    this.dropdownSettingsUsers = {
      singleSelection: true,
      idField: 'id',
      textField: 'user',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 0,
      allowSearchFilter: true,
      enableCheckAll: false
    };
  }

  initializeData(username = this.authenticationService.userData.username) {
    this.sticksData = [];
    this.userData = this.authenticationService.userData;
    try {
      this.apiService.getUserData(username).subscribe(
        data => {
          this.details = data;
          this.userDataDetails = this.details.userData;
          this.users = this.details.users
          this.userData = this.userDataDetails.d;
          console.log('success getUserData', this.userData, "users", this.users)
          this.displayFullname = this.userData.firstname + " " + this.userData.lastname
          this.displatUsername = this.userData.username;
          this.displayEmail = (this.userData.email.split(","))[0];
          this.displayAcivelist = this.userData.activelist;
          this.displayPath = this.userData.path;
          this.displayNmboflicenses = this.userData.nmboflicenses;
          if (this.userData.serials && this.userData.serials.length) {
            const userDataSerials = this.userData.serials
            this.serialsData = []
            this.serialsFilterData = []
            userDataSerials.forEach((serial, index) => {
              const serialData = serial.split("*");
              const data = { computerName: serialData[0], computerLastLogin: serialData[1], enativVersion: serialData[2], login: serialData[4] }
              this.serialsData.push(data);
              this.computerNames.push({ id: index + 1, computerName: serialData[0] });
              this.computerLastLogins.push({ id: index + 1, computerLastLogin: serialData[1] });
              this.enativVersions.push({ id: index + 1, enativVersion: serialData[2] });
              this.logins.push({ id: index + 1, login: serialData[4] });
            });
            this.serialsData.sort((a, b) => {
              const dateA = new Date(b.computerLastLogin);
              const dateB = new Date(a.computerLastLogin);
              return dateA.getTime() - dateB.getTime();
            });
            this.serialsData.sort((a, b) => a.login.localeCompare(b.login));
            this.serialsFilterData = this.serialsData.slice();
            this.sortLicenseArrays();
            this.logins = this.logins.filter((obj, index, self) =>
              index === self.findIndex((o) => (
                o.login === obj.login
              ))
            );
            this.logins.sort((a, b) => a.login.localeCompare(b.login));
          }
          this.displayQuota = this.userData.quota < 0 ? "0" : this.userData.quota;
          this.apiService.hasSimActionsPermmissions(this.authenticationService.userData.id).subscribe(
            data => {
              console.log('hasSimActionsPermmissions success');
              if (data["d"] == true) {
                this.displaySimActions = true;
                this.apiService.getCompanyPackages().subscribe(
                  data => {
                    console.log('getCompanyPackages success');
                    if (data["d"]) {
                      this.companyPackages = data["d"];

                    }
                  },
                  error => console.log('error getCompanyPackages', error)
                )
              }
            },
            error => console.log('error hasSimActionsPermmissions', error)
          )
          if (this.userData.isp == null) {
            this.isp = ""
          }
          else {
            if (this.userData.isp[0] && this.userData.isp[1]) {
              this.isp = this.userData.isp[0] + "," + this.userData.isp[1]
            }
            else {
              if (this.userData.isp[0]) {
                this.isp = this.userData.isp[0]
              }
              else {
                this.isp = this.userData.isp[1]
              }
            }
          }
          if (this.userData.isp == null && this.userData.stick == null) {
            this.netType = "לא קיים חיבור דרך נתיב"
          }
          else {
            if (this.userData.stick) {
              this.netType = "סטיק"
            }
            else {
              this.netType = "ספק כשר : " + this.isp
            }
          }
          // ["972521683192*0*1"]
          // מס סים*ניצול*חברה
          if (this.netType == "סטיק") {
            // userData.stick.push(.userData.stick[0])
            const stickData = this.userData.stick[0].split("*");
            this.simNum = stickData[0];
            const simUsed = this.formatSimUsed(stickData[1])
            this.simUsed = simUsed + "%";
            this.simCompany = (stickData[2] == 1 || stickData[2] == 3) ? "סלקום" : "019";
          }
          if (this.userData.stick && this.userData.stick.length > 1) {
            const userDataStick = this.userData.stick;
            // userDataStick.splice(0, 1);
            this.sticksData = []
            userDataStick.forEach(stick => {
              const stickData = stick.split("*");
              const simUsed = this.formatSimUsed(stickData[1])
              const data = { simNum: stickData[0], simUsed: simUsed + "%", simCompany: (stickData[2] == 1 || stickData[2] == 3) ? "סלקום" : "019" }
              this.sticksData.push(data);
            });
          }
        },
        error => {
          console.log('error getUserData', error)
        }
      )
    }
    catch (error) {
      console.log('error getUserData', error)
    }
  }

  formatSimUsed(used) {
    const usedArr = used.split(".")
    const simUsed = usedArr.length > 1 ? usedArr[0] + "." + usedArr[1][0] : used
    return simUsed
  }

  upgrade(simCompany, simNum) {
    if (simCompany == "סלקום") {
      window.open(
        "http://api.enativ.com/upgradecellcom.aspx?nt=" + simNum,
        '_blank'
      );
    }
    else {
      window.open(
        "http://api.enativ.com/update019.aspx?nt=" + simNum,
        '_blank'
      );
    }

  }

  onFocus() {
    this.syncPassForm.get('password').setErrors(null);
    this.invalidPass = false;
  }

  onSubmitPass(formData: any, modal) {
    this.invalidPass = false;
    this.submitted = true;
    this.enableSubmit = false;
    if (this.syncPassForm.invalid) {
      this.enableSubmit = true;
      return;
    }
  }

  sync(simNum) {
    console.log("sync " + simNum)
    this.enableSubmit = false;
    this.apiService.syncSim(this.authenticationService.userData.id, simNum).subscribe(
      data => {
        this.enableSubmit = true;
        console.log('success syncSim', data)
        if (data["res"] != "sync start") {
          Swal.fire("", "אירעה שגיאה", 'error')
        }
        else {
          Swal.fire("", "תהליך הסנכרון החל. התהליך יסתיים בעוד כ-2 דקות", 'success')
        }
      },
      error => {
        this.enableSubmit = true;
        console.log('error sync', error)
        if (error["error"].error == "invalid password") {
          this.syncPassForm.get('password').setErrors('invalid password');
          this.invalidPass = true;
        }
        else {
          Swal.fire("", "אירעה שגיאה", 'error')
        }

      }
    )
  }

  delete(simNum, event: MouseEvent) {
    // console.log("delete " + simNum)
    (event.target as HTMLButtonElement).disabled = true;
    this.apiService.deleteSim(this.authenticationService.userData.id, simNum).subscribe(
      data => {
        (event.target as HTMLButtonElement).disabled = false;
        // this.enableSubmitDelete = true;
        console.log('success delete', data)
        if (data["res"] != "Success") {
          Swal.fire("", `ביטול הסים ${simNum} נכשל : ${data["res"]}`, 'error')
        }
        else {
          Swal.fire("", `ביטול הסים ${simNum} בוצע בהצלחה`, 'success')
          this.initializeData();
        }
      },
      error => {
        // this.enableSubmitDelete = true;
        (event.target as HTMLButtonElement).disabled = false;
        console.log('error delete', error)
        Swal.fire("", "אירעה שגיאה", 'error')
      }
    )
  }

  toggleTable() {
    this.showTable = !this.showTable
  }

  toggleSerialsTable() {
    this.showSerialsTable = !this.showSerialsTable
  }

  navigateMySites() {
    this.authGuardService.setButtonClickUserProfile(true); // Set the flag for button click
    this.router.navigate(['/my-sites-expanded']);
  }

  navigateScheduling() {
    this.router.navigate(['/scheduling']);
  }

  get f() {
    return this.createSimForm.controls;
  }

  get fp() {
    return this.syncPassForm.controls;
  }

  openModal(targetModal) {
    const modal = this.modalService.open(targetModal, {
      centered: true,
      backdrop: true,
      size: 'lg'
    });
    modal.result.then(() => { this.submitted = false; this.createSimForm.reset(); }, () => { this.submitted = false; this.createSimForm.reset(); })
  }

  openPassModal(targetModal, simNum) {
    this.syncSimNum = simNum;
    const modal = this.modalService.open(targetModal, {
      centered: true,
      backdrop: true,
      size: 'sm'
    });
    modal.result.then(() => { this.submitted = false; this.syncPassForm.reset(); }, () => { this.submitted = false; this.syncPassForm.reset(); })
  }

  openLicenseModal(targetModal) {
    const modal = this.modalService.open(targetModal, {
      centered: false,
      backdrop: true,
      size: 'xl',
      windowClass: 'modal-top'
    });
    modal.result.then(() => { this.initializeFilters(); }, () => { this.initializeFilters(); })
  }

  close(modal) {
    this.submitted = false;
    modal.dismiss()
  }

  closeLicense(modal) {
    this.initializeFilters();
    modal.dismiss()
  }

  onSubmit(formData: any, modal) {
    this.submitted = true;
    this.enableSubmit = false;
    if (this.createSimForm.invalid) {
      this.enableSubmit = true;
      return;
    }
    if (formData.simCompany == "1" && formData.serialNum.indexOf("89972020") !== 0) {
      this.createSimForm.get('serialNum').setErrors('invalid serialNum');
      this.invalidSerNum = true;
      this.enableSubmit = true;
    }
    if (formData.simCompany == "1" && formData.simNum.indexOf("97252") !== 0) {
      if (formData.simNum.indexOf("9725") !== 0 && formData.simNum.indexOf("9758") !== 0) {
        this.createSimForm.get('simNum').setErrors('invalid simNum');
        this.invalidSimNum = true;
        this.enableSubmit = true;
      }
    }
    if (formData.simCompany == "2" && formData.serialNum.indexOf("89972191") !== 0) {
      this.createSimForm.get('serialNum').setErrors('invalid serialNum')
      this.invalidSerNum = true;
      this.enableSubmit = true;
    }
    if (formData.simCompany == "2" && !this.invalidSerNum && formData.serialNum.length !== 19) {
      this.createSimForm.get('serialNum').setErrors('invalid serialNum')
      this.invalidSerNumLength = true;
      this.enableSubmit = true;
    }
    if (!this.enableSubmit) {
      this.apiService.createSim(this.authenticationService.userData.id, formData.package, formData.simCompany, formData.serialNum, formData.simCompany == "1" ? formData.simNum : '').subscribe(
        data => {
          this.enableSubmit = true;
          console.log('success createSim', data)
          if (data["res"] != "") {
            Swal.fire("", " יצירת סים נכשלה:" + "<br />" + data["res"], 'error')

          }
          else {
            modal.dismiss();
            this.initializeData();
            Swal.fire("", "הסים נוצר בהצלחה", 'success')
          }
        },
        error => {
          this.enableSubmit = true;
          Swal.fire("", " יצירת סים נכשלה:" + "<br />" + error["error"], 'error')
          console.log('error createSim', error)
        }
      )
    }
  }

  getPackages(company) {
    this.packages = this.companyPackages[company]
    this.createSimForm.get('package').setValue("")
    this.createSimForm.get('package').enable();
    this.createSimForm.get('simNum').reset();
    this.createSimForm.get('serialNum').reset();
    if (company == "1") {
      this.showSimNum = true;
      this.createSimForm.get('simNum').setValidators([Validators.required, Validators.minLength(12), Validators.maxLength(12), Validators.pattern("^[0-9]*$")])
    }
    else {
      this.showSimNum = false;
      this.createSimForm.get('simNum').clearValidators();
      this.createSimForm.get('simNum').setErrors(null);
    }
  }
  onItemSelectUsers(item: any) {
    this.initializeData(item.user);
  }
  onItemDeSelectUsers() {
    this.initializeData();
  }
  //סינון טבלת הרשיונות  עפ"י רשימת הפריטים המסומנים של המחשב תאריך וגרסא
  onItemSelect(item: any) {
    if (Object.keys(item)[1] == 'login')
      this.updateLogin();
    else {
      this.serialsFilterData = this.serialsData.filter((obj) =>
        (this.selectedItemsLogin?.length === 0 || this.selectedItemsLogin.some((dObj) => dObj.login === obj.login)) &&
        (this.selectedItemsComputerName?.length === 0 || this.selectedItemsComputerName.some((bObj) => bObj.computerName === obj.computerName)) &&
        (this.selectedItemsComputerLastLogin?.length === 0 || this.selectedItemsComputerLastLogin.some((cObj) => cObj.computerLastLogin === obj.computerLastLogin)) &&
        (this.selectedItemsEnativVersion?.length === 0 || this.selectedItemsEnativVersion.some((dObj) => dObj.enativVersion === obj.enativVersion))
      );
      console.log("filterin one", this.serialsFilterData);
    }
    if (this.sorted != "") {
      this.sorting = this.sorted;
      if (!this.sorted.includes('desc'))
        this.sorting += ' desc';
      this.licenseTableSort(this.sorting);
    }
  }

  onItemDeSelect(item: any, selectesList) {
    let key = Object.keys(item)[1];
    if (key == 'login') {
      this.updateLogin();
    }
    //אם הסירו את כל הפריטים מהרשימה של הסינון 
    else if (selectesList.length == 0)
      this.onItemSelect(item)
    else {
      //איזה שדה מדובר
      this.serialsFilterData = this.serialsFilterData.filter(obj => obj[key] !== item[key]);
      console.log("deselect", this.serialsFilterData)
    }
    if (this.sorted != "") {
      this.sorting = this.sorted;
      if (!this.sorted.includes('desc'))
        this.sorting += ' desc';
      this.licenseTableSort(this.sorting);
    }
  }


  licenseTableSort(field: any) {
    if (field != this.sorted) {//עולה
      if (field.includes('desc')) {
        field = field.replace('desc', '');
        field = field.trimEnd();
      }
      this.sorted = field;
      if (field.includes('computerLastLogin')) {
        this.serialsFilterData.sort((a, b) => {
          const dateA = new Date(a.computerLastLogin);
          const dateB = new Date(b.computerLastLogin);
          return dateA.getTime() - dateB.getTime();
        });
      }
      else if (field.includes('enativVersion')) {
        this.serialsFilterData.sort((a, b) => {
          const versionA = a.enativVersion.split('.').map(Number);
          const versionB = b.enativVersion.split('.').map(Number);

          for (let i = 0; i < versionA.length; i++) {
            if (versionA[i] !== versionB[i]) {
              return versionA[i] - versionB[i];
            }
          }
          return 0;
        });
      }
      else {
        this.serialsFilterData.sort((a, b) => a[field].localeCompare(b[field]));
      }
    }
    else {//יורד
      this.sorted += " desc"
      if (field.includes('desc')) {
        field = field.replace('desc', '');
        field = field.trimEnd();
      }
      if (field.includes('computerLastLogin')) {
        this.serialsFilterData.sort((a, b) => {
          const dateA = new Date(b.computerLastLogin);
          const dateB = new Date(a.computerLastLogin);
          return dateA.getTime() - dateB.getTime();
        });
      }
      else if (field.includes('enativVersion')) {
        this.serialsFilterData.sort((a, b) => {
          const versionA = a.enativVersion.split('.').map(Number);
          const versionB = b.enativVersion.split('.').map(Number);

          for (let i = 0; i < versionA.length; i++) {
            if (versionA[i] !== versionB[i]) {
              return versionB[i] - versionA[i];
            }
          }
          return 0;
        });
      }
      else {
        this.serialsFilterData.sort((a, b) => b[field].localeCompare(a[field]));
      }
    }
  }

  initializeFilters() {
    //ממיין עפי שם משתמש ותאריך
    this.serialsData.sort((a, b) => {
      const dateA = new Date(b.computerLastLogin);
      const dateB = new Date(a.computerLastLogin);
      return dateA.getTime() - dateB.getTime();
    });
    this.serialsData.sort((a, b) => a.login.localeCompare(b.login));
    this.serialsFilterData = this.serialsData.slice();
    this.logins = this.serialsFilterData.map((obj, index) => ({
      id: index + 1,
      login: obj.login,
    }))
    this.computerLastLogins = this.serialsFilterData.map((obj, index) => ({
      id: index + 1,
      computerLastLogin: obj.computerLastLogin,
    }))
    this.computerNames = this.serialsFilterData.map((obj, index) => ({
      id: index + 1,
      computerName: obj.computerName,
    }))
    this.enativVersions = this.serialsFilterData.map((obj, index) => ({
      id: index + 1,
      enativVersion: obj.enativVersion,
    }))
    this.selectedItemsComputerName = [];
    this.selectedItemsComputerLastLogin = [];
    this.selectedItemsEnativVersion = [];
    this.selectedItemsLogin = [];
    this.sorted = "";
    this.sortLicenseArrays();
    this.logins = this.logins.filter((obj, index, self) =>
      index === self.findIndex((o) => (
        o.login === obj.login
      ))
    );
    this.logins.sort((a, b) => a.login.localeCompare(b.login));
  }

  sortLicenseArrays() {
    this.computerNames = this.computerNames.filter((obj, index, self) =>
      index === self.findIndex((o) => (
        o.computerName === obj.computerName
      ))
    );
    this.computerNames.sort((a, b) => a.computerName.localeCompare(b.computerName));
    this.computerLastLogins = this.computerLastLogins.filter((obj, index, self) =>
      index === self.findIndex((o) => (
        o.computerLastLogin === obj.computerLastLogin
      ))
    );
    this.computerLastLogins.sort((a, b) => {
      const dateA = new Date(a.computerLastLogin);
      const dateB = new Date(b.computerLastLogin);
      return dateA.getTime() - dateB.getTime();
    });
    this.enativVersions = this.enativVersions.filter((obj, index, self) =>
      index === self.findIndex((o) => (
        o.enativVersion === obj.enativVersion
      ))
    );
    this.enativVersions.sort((a, b) => {
      const versionA = a.enativVersion.split('.').map(Number);
      const versionB = b.enativVersion.split('.').map(Number);

      for (let i = 0; i < versionA.length; i++) {
        if (versionA[i] !== versionB[i]) {
          return versionA[i] - versionB[i];
        }
      }
      return 0;
    });
  }

  updateLogin() {
    if (this.selectedItemsLogin.length == 0) {
      this.initializeFilters();
    }
    else {
      let index = 0;
      //מאפס את כל הפרטים הנבחרים ומסנן רק עפי שם המשתמש
      //מעדכן את המערכים רק במה שיש בשמות המשתמשים הנבחרים
      this.selectedItemsComputerLastLogin = [];
      this.selectedItemsComputerName = [];
      this.selectedItemsEnativVersion = [];
      this.computerLastLogins = [];
      this.computerNames = [];
      this.enativVersions = []
      for (let i = 0; i < this.serialsData.length; i++) {
        if (this.selectedItemsLogin.some(obj => obj.login === this.serialsData[i].login)) {
          this.computerNames.push({ id: index, computerName: this.serialsData[i].computerName });
          this.computerLastLogins.push({ id: index, computerLastLogin: this.serialsData[i].computerLastLogin });
          this.enativVersions.push({ id: index, enativVersion: this.serialsData[i].enativVersion });
          index++
        }
      }
      this.sortLicenseArrays();
      ///מסנן רק עפי השם משתמש
      this.serialsFilterData = this.serialsData.filter((obj) =>
        this.selectedItemsLogin?.length === 0 || this.selectedItemsLogin.some((dObj) => dObj.login === obj.login))
    }
  }

}


