<div id="currencies-div" class="text-center app-div">
    <ul [ngClass]="moreCurrencies ? 'expanded' : ''">
        <li *ngFor="let currency of currencies">
            <div class="row currency">
                <div class="col-9">
                    <div class="currency-name">{{currency.name}} = </div>
                    <br />
                    <div class="row">
                        <div class="col-md-12 currency-value">{{currency.value}} שקל ישראלי חדש</div>
                        <!-- <div class="col-md-9 shekel">שקל ישראלי חדש</div> -->
                    </div>
                    <br />
                    <div class="change-value">שינוי יומי: {{currency.changeValue}}</div>
                </div>
                <div class="col-3">
                    <img src="../../assets/images/currencies/new/{{currency.id}}.png" />
                </div>
            </div>
        </li>
    </ul>
    <a #showMoreData id="show-more-cur" (click)="showMore()">ראה עוד</a>
</div>