import { Component, OnInit, ViewChild, TemplateRef, ElementRef, HostListener, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { ApiService } from '../../services/api/api.service';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-mail',
  templateUrl: './mail.component.html',
  styleUrls: ['./mail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MailComponent implements OnInit {
  @ViewChild('modal') public modalRef: TemplateRef<any>;
  @ViewChild('scrollBtn') scrollBtnEl: ElementRef;

  @HostListener("window:scroll", ["$event"])
  onWindowScroll(event) {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      this.scrollBtnEl.nativeElement.style.display = "block";
    } else {
      this.scrollBtnEl.nativeElement.style.display = "none";
    }
  }

  permission: boolean;
  emails = [];
  emailsData = [];
  emailData: any;
  loading = true;
  page = 1;
  pageSize = 10;
  collectionSize: number;
  noData = false;
  protocol = window.location.protocol;
  isUserLoggedIn = false;

  constructor(private modalService: NgbModal,
    public authenticationService: AuthenticationService,
    private apiService: ApiService,
    public translateService: TranslateService) {
    this.refreshEmails()
    this.authenticationService.authenticated$.subscribe(value => {
      this.isUserLoggedIn = value;
      if (this.isUserLoggedIn) {
        this.getPermission();
        if (this.permission) {
          this.getEmails()
        }
        this.clearMailsCount();
      }
    });
  }

  ngOnInit(): void {
    // this.getPermission();
    // if (this.permission) {
    // this.getEmails()
    // }
    // this.clearMailsCount();
  }

  topFunction() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  refreshEmails() {
    this.emails = this.emailsData
      .map((country, i) => ({ id: i + 1, ...country }))
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }

  getPermission() {
    this.permission = this.authenticationService.userData.watchmail == "1";
  }

  clearMailsCount() {
    this.apiService.clearMailsCount().subscribe(
      data => {
        console.log('clearMailsCount ', data["d"])
        this.apiService.updateUserMailsCount()
      },
      error => console.log('error getSites', error)
    )
  }

  getEmails() {
    this.loading = true;
    let count;
    const obj = []
    this.apiService.getUserMailsCount(false).subscribe(
      data => {
        console.log('getUserMailsCount ', data["d"]);
        count = data["d"];
        if (count == 0) {
          this.loading = false;
          this.noData = true;
        }
        else {
          const length = Math.ceil(count / 30);
          let j = 0;
          for (let index = 0; index < length; index++) {
            obj.push(j)
            j += 30
          }
          loop(obj.shift())
        }
      },
      error => console.log('error getUserMailsCount', error)
    )
    let loop = (id: number) => {
      this.apiService.getUserMails(id).subscribe(
        data => {
          console.log('getUserMails ', data["d"]);
          this.emailsData = this.emailsData.concat(data["d"]);
          if (obj.length) {
            loop(obj.shift())
          }
          else {
            this.collectionSize = this.emailsData.length;
            this.refreshEmails();
            this.loading = false;
          }
        },
        error => console.log('error getUserMails', error)
      )

    }
  }

  openModal(targetModal, email) {
    this.emailData = email;
    const modal = this.modalService.open(targetModal, {
      centered: true,
      backdrop: true,
      size: 'lg'
    });
  }
  close(modal) {
    modal.dismiss()
  }

  refreshNativ() {
    try {
      this.apiService.refreshNativ().subscribe(
        data => {
          console.log('refreshNativ success');
          Swal.fire("", data, 'success')
        },
        error => {
          console.log('error refreshNativ', error)
          Swal.fire("", "אירעה שגיאה ברענון ההגדרות", 'error')
        }
      )
    }
    catch (error) {
      console.log('error refreshNativ', error)
      Swal.fire("", "אירעה שגיאה ברענון ההגדרות", 'error')
    }
  }

}
