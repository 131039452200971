<div class="feedback-page">
    <h4 class="header text-center">{{ 'feedback.header' | translate }}</h4>
    <div class="row text-center justify-content-center align-items-center">
        <div class="col-md-6">
            <div class="header-content content-div">
                <img src="../../../assets/images/feedback/logo.png" />
                <h6 class="bold">{{feedbackHeader}}</h6>
                <div class="content">
                    {{ feedbackContent }}
                </div>
            </div>
            <form [formGroup]="feedbackForm" class="feedback-form text-center"
                (ngSubmit)="onSubmit(feedbackForm.value)">
                <div class="content-div">
                    <div class="content text-right">{{ q1Text }}</div>
                    <div class="list-div">
                        <ul class="list-group">
                            <div class="form-check form-check-inline">
                                <li class="list-group-item"><img src="../../../assets/images/feedback/good.png" /></li>
                                <li class="list-group-item" *ngFor="let item of checklist1">
                                    <input type="checkbox" value="{{item.id}}" [checked]="item.isSelected"
                                        (change)="change1(item)" formControlName="question1" />
                                    <label class="form-check-label" for="gridRadios1">
                                        {{item.value}}
                                    </label>
                                </li>
                                <li class="list-group-item"><img class="last"
                                        src="../../../assets/images/feedback/bad.png" /></li>
                            </div>
                        </ul>
                    </div>
                </div>
                <div class="content-div">
                    <div class="content text-right">{{ q2Text }}</div>
                    <div class="list-div">
                        <ul class="list-group">
                            <div class="form-check form-check-inline">
                                <li class="list-group-item"><img src="../../../assets/images/feedback/good.png" /></li>
                                <li class="list-group-item" *ngFor="let item of checklist2">
                                    <input type="checkbox" value="{{item.id}}" [checked]="item.isSelected"
                                        (change)="change2(item)" formControlName="question2" />
                                    <label class="form-check-label" for="gridRadios1">
                                        {{item.value}}
                                    </label>
                                </li>
                                <li class="list-group-item"><img class="last"
                                        src="../../../assets/images/feedback/bad.png" /></li>
                            </div>
                        </ul>
                    </div>
                </div>
                <div class="content-div">
                    <div class="content text-right">{{ q3Text }}</div>
                    <div class="list-div">
                        <ul class="list-group">
                            <div class="form-check form-check-inline">
                                <li class="list-group-item"><img src="../../../assets/images/feedback/good.png" /></li>
                                <li class="list-group-item" *ngFor="let item of checklist3">
                                    <input type="checkbox" value="{{item.id}}" [checked]="item.isSelected"
                                        (change)="change3(item)" formControlName="question3" />
                                    <label class="form-check-label" for="gridRadios1">
                                        {{item.value}}
                                    </label>
                                </li>
                                <li class="list-group-item"><img class="last"
                                        src="../../../assets/images/feedback/bad.png" /></li>
                            </div>
                        </ul>
                    </div>
                </div>
                <div class="content-div comments">
                    <textarea type="text" placeholder="{{ 'feedback.comments' | translate }}"
                        formControlName="comments"></textarea>
                </div>
                <div class="row">
                    <div class="col-12">
                        <button [disabled]="!enableSubmit" type="submit" class="btn-nativ form-btn">{{ 'global.send' |
                            translate }}
                            &#10095;&#10095;</button>
                    </div>
                </div>
            </form>
        </div>

    </div>
</div>