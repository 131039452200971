<div class="row background-color">
    <div class="col-md-3 image-container">
        <div class="small-image-container">
            <img id="icon" src="../../../assets/images/contact/new/icon.png" />
            <h1 class="contact-header header not-mobile"> _____________</h1>
            <h1 class="contact-header header not-mobile"> {{ 'contact.header' | translate }}</h1>
            <h4 class="contact-sub-header not-mobile">{{ 'contact.sub-header' | translate }}</h4>
            <h1 class="contact-header header mobile">{{ 'app.contact' | translate }}</h1>
        </div>
    </div>
    <div class="col-md-9 row">
        <div class="col d-flex flex-column justify-content-center align-items-center">
            <div class="text-center">
                <h4 class="dep-header">{{ 'contact.deps.header' | translate }}</h4>
            </div>
            <div class="makeBorder">
                <div class="dep-div responsive-div text-center">
                    <div class="contact-dep buy" [routerLink]="['/sales']" routerLinkActive="active">
                        <a class="btn-nativ private"> {{ 'contact.deps.buy.header' | translate }}</a>
                    </div>
                    <div class="contact-dep sites-support" (click)="openModal(modal,'sites-support')">
                        <a class="btn-nativ business"> {{ 'contact.deps.sites-support.header' | translate }}</a>
                    </div>
                    <div class="contact-dep unfit-content" (click)="openModal(modal,'unfit-content')">
                        <a class="btn-nativ direct"> {{ 'contact.deps.unfit-content.header' | translate }}</a>
                    </div>
                    <div class="contact-dep money" (click)="openModal(modal,'money')">
                        <a class="btn-nativ private"> {{ 'contact.deps.money.header' | translate }}</a>
                    </div>
                    <div class="contact-dep tech-support" (click)="openModal(modal,'tech-support')">
                        <a class="btn-nativ business"> {{ 'contact.deps.tech-support.header' | translate }}</a>
                    </div>
                    <div class="contact-dep serious" (click)="openModal(modal,'serious')">
                        <a class="btn-nativ direct"> {{ 'contact.deps.serious.header' | translate }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="load-images-div">
        <img class="upload1" />
        <img class="upload2" />
        <img class="upload3" />
        <img class="upload4" />
        <img class="upload5" />
        <img class="upload6" />
    </div>

    <div class="upload d-none"></div>
</div>
<ng-template #modal let-modal class="modal contact-modal">
    <div class="modal-header">
        <div class="row">
            <div class="col-md-1 close-modal-header">
                <button type="button" class="close" (click)="close(modal)" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 dep-modal-header text-right">
                <div class="row">
                    <div class="col-3 pl-0 text-left">
                        <img *ngIf="modalName!=='docs'" src="../../../assets/images/contact/i-{{modalName}}.png" />
                    </div>
                    <div class="col-9 pr-0">
                        <h5 class="modal-title">{{modalHeader}}</h5>
                        <div class="not-mobile">{{ 'contact.modal-header' | translate }}</div>
                    </div>
                </div>
            </div>
        </div>
        <h5 class="mobile m-modal-header">{{ 'contact.modal-header' | translate }}</h5>
    </div>

    <div class="modal-body">
        <form [formGroup]="contactForm" class="contact-form text-center" (ngSubmit)="onSubmit(contactForm.value,modal)">
            <div class="row">
                <div class="col-md-5 input-div text-right">
                    <div class="form-group">
                        <label for="fullname">*{{ 'form.fullname' | translate }}</label>
                        <div class="row">
                            <input type="text" formControlName="fullname" class="form-control" id="fullname"
                                [ngClass]="{ 'is-invalid': submitted && f.fullname.errors }"
                                [ngModel]="authenticationService.userData && authenticationService.userData.firstname && authenticationService.userData.lastname ? authenticationService.userData.firstname  + ' ' +  authenticationService.userData.lastname : ''">
                            <div *ngIf="submitted && f.fullname.errors" class="invalid-feedback">
                                <div *ngIf="f.fullname.errors.required">{{ 'form.validation.emptyName' | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-5 input-div text-right">
                    <div class="form-group">
                        <label for="username">{{ 'form.username' | translate }}</label>
                        <div class="row">
                            <input type="text" formControlName="username" class="form-control" id="username"
                                [ngClass]="{ 'is-invalid': submitted && f.username.errors }"
                                [ngModel]="authenticationService.userData ? authenticationService.userData.username : ''">
                            <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                                <div *ngIf="f.username.errors.required">{{ 'form.validation.emptyUsername' | translate
                                    }} </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-5 input-div text-right">
                    <div class="form-group">
                        <label for="phonenumber">*{{ 'form.phonenumber' | translate }}</label>
                        <div class="row">
                            <input *ngIf="modalName=='docs'" type="text" formControlName="phonenumber"
                                class="form-control" id="phonenumber"
                                [ngClass]="{ 'is-invalid': submitted && f.phonenumber.errors }"
                                [ngModel]="authenticationService.userData && authenticationService.userData.MainPhone ? authenticationService.userData.MainPhone  : ''">
                            <input *ngIf="modalName!=='docs'" type="text" formControlName="phonenumber"
                                class="form-control" id="phonenumber"
                                [ngClass]="{ 'is-invalid': submitted && f.phonenumber.errors }">
                            <div *ngIf="submitted && f.phonenumber.errors" class="invalid-feedback">
                                <div *ngIf="f.phonenumber.errors.required">{{ 'form.validation.emptyPhonenumber' |
                                    translate }}</div>
                            </div>
                            <div *ngIf="submitted && f.phonenumber.errors" class="invalid-feedback">
                                <div *ngIf="f.phonenumber.errors.pattern"> {{ 'form.validation.invalidPhonenumber' |
                                    translate }} </div>
                            </div>
                            <div *ngIf="submitted && !validPhoneNimber" class="invalid-feedback">
                                <div *ngIf="!validPhoneNimber">{{ 'form.validation.invalidPhonenumber' | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-5 input-div text-right">
                    <div class="form-group">
                        <label for="email">*{{ 'form.email' | translate }}</label>
                        <div class="row">
                            <input *ngIf="modalName=='docs'" type="email" formControlName="email" class="form-control"
                                id="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                                [ngModel]="authenticationService.userData && authenticationService.userData.email ? authenticationService.userData.email.split(',')[0] : ''">
                            <input *ngIf="modalName!=='docs'" type="email" formControlName="email" class="form-control"
                                id="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" [ngModel]="email">
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                <div *ngIf="f.email.errors.required">{{ 'form.validation.emptyEmail' | translate }}
                                </div>
                            </div>
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                <div *ngIf="f.email.errors.email || f.email.errors.pattern">{{
                                    'form.validation.invalidEmail' | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 text-right">
                <div class="form-group">
                    <label for="content">*{{ 'form.content' | translate }}</label>
                    <div class="row">
                        <textarea type="text" formControlName="content" class="form-control" id="content"
                            [ngClass]="{ 'is-invalid': submitted && f.content.errors }" placeholder="{{userContent}}"
                            [ngModel]="buyContent">
                        </textarea>
                        <div *ngIf="submitted && f.content.errors" class="invalid-feedback">
                            <div *ngIf="f.content.errors.required">{{ 'form.validation.emptyContent' | translate }}
                            </div>
                            <div *ngIf="f.content.errors.minlength">{{ 'form.validation.emptyContent' | translate }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer justify-content-center">
                <div *ngIf="!contacted" class="invalid-submit">{{contactedError}}</div>
                <button [disabled]="!enableSubmit" type="submit" class="btn-nativ form-btn">{{ 'global.send' | translate
                    }}</button>
            </div>
        </form>
    </div>
</ng-template>
<app-bottom-nav [displayAddress]="true"></app-bottom-nav>