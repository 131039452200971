import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../services/api/api.service';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { BnNgIdleService } from 'bn-ng-idle';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-tech',
  templateUrl: './tech.component.html',
  styleUrls: ['./tech.component.scss']
})
export class TechComponent implements OnInit {
  approvalForm: any;
  helpmeForm: any;
  submitted = false;
  enableSubmit1 = true;
  enableSubmit2 = true;
  enableSubmit3 = true;
  enableSubmit4 = true;
  enableSubmit5 = true;
  displayHelpme = false
  isChecked = false;
  actionType = 0
  codeRes = ''
  deleteSerialList = true
  serialsData = []
  loadingSerials = false
  enableReset = {}
  types = { "open-user": 1, "delete-serial": 2, "send-pass": 3, "send-remove-doc": 4 }
  constructor(private formBuilder: FormBuilder,
    private bnIdle: BnNgIdleService,
    private authenticationService: AuthenticationService,
    private apiService: ApiService,
    private router: Router) {
    this.approvalForm = this.formBuilder.group({
      action1Mail: '',
      action2Username: '',
      action2SerialUsername: '',
      action2Serial: '',
      action3Username: '',
      action3Mail: '',
      action4Username: ''
    });

    this.helpmeForm = this.formBuilder.group({
      serialNum: '',
      auditNum: ''
    });

    if (localStorage.getItem('nativTechLoggedin') && this.authenticationService.techUserData) {
      localStorage.removeItem('nativTechLoggedin');
      this.displayHelpme = this.authenticationService.techUserData.helpme
      this.getSerialsData(this.authenticationService.techUserData.Login)
    }
    else {
      this.router.navigate(['/tech-login'])
    }
  }

  ngOnInit(): void {
    this.helpmeForm = new FormGroup({
      serialNum: new FormControl(''),
      auditNum: new FormControl('')
    });
    this.approvalForm = new FormGroup({
      action1Mail: new FormControl(''),
      action2Username: new FormControl(''),
      action2SerialUsername: new FormControl(''),
      action2Serial: new FormControl(''),
      action3Username: new FormControl(''),
      action3Mail: new FormControl(''),
      action4Username: new FormControl('')
    });
    this.bnIdle.startWatching(600).subscribe((isTimedOut: boolean) => {
      if (isTimedOut) {
        this.exit()
      }
    });

  }

  get f() {
    return this.approvalForm.controls;
  }

  get fh() {
    return this.helpmeForm.controls;
  }

  getSerialsData(username) {
    this.loadingSerials = true
    this.apiService.getSerialsData(username).subscribe(
      data => {
        console.log('success getUserData', data["d"])
        const userData = data["d"];
        if (userData.serials && userData.serials.length) {
          const userDataSerials = userData.serials
          this.serialsData = []
          let i = 0
          userDataSerials.forEach(serial => {
            const serialData = serial.split("*");
            const data = { computerName: serialData[0], computerLastLogin: serialData[1], enativVersion: serialData[2], serial: serialData[3], userName: serialData[4] }
            this.serialsData.push(data);
            this.enableReset[i] = true
            this.loadingSerials = false
            i++
          });
        }
      },
      error => {
        this.loadingSerials = false
      }
    )
  }

  resetSerials(row, rowIndex) {
    this.enableReset[rowIndex] = false
    const formData = {}
    this.actionType = this.types["delete-serial"]
    formData["action2Serial"] = row.serial
    formData["action2SerialUsername"] = row.userName
    formData["action2Username"] = ""
    formData["action1Mail"] = ""
    this.apiService.techAction(this.actionType, formData).subscribe(
      data => {
        this.approvalForm.reset()
        if (data["d"]) {
          Swal.fire("", "הפעולה בוצעה בהצלחה!", 'success')
        }
        else {
          this.enableReset[rowIndex] = true
          Swal.fire("", "אירעה שגיאה בשליחת הבקשה", 'error')
        }
      },
      error => {
        this.approvalForm.reset()
        this.enableReset[rowIndex] = true
        console.log("onSubmit error " + error)
        Swal.fire("", "אירעה שגיאה בשליחת הבקשה", 'error')
      })
  }

  onSubmit(formData: any) {
    this.actionType = 0;
    this.submitted = true;
    if (formData.action1Mail) {
      this.enableSubmit1 = false;
      this.actionType = this.types["open-user"]
    }
    else if (formData.action2Username) {
      this.enableSubmit2 = false;
      this.actionType = this.types["delete-serial"]
    }
    else if (formData.action3Username) {
      this.enableSubmit3 = false;
      this.actionType = this.types["send-pass"]
    }
    else if (formData.action4Username) {
      this.enableSubmit4 = false;
      this.actionType = this.types["send-remove-doc"]
    }
    if (this.actionType != 0) {
      this.apiService.techAction(this.actionType, formData).subscribe(
        data => {
          this.approvalForm.reset()
          if (data["d"]) {
            Swal.fire("", "הפעולה בוצעה בהצלחה!", 'success')
            if (this.actionType == 1) this.enableSubmit1 = true;
            if (this.actionType == 2) this.enableSubmit2 = true;
            if (this.actionType == 3) this.enableSubmit3 = true;
            if (this.actionType == 4) this.enableSubmit4 = true;
          }
        },
        error => {
          this.approvalForm.reset()
          if (this.actionType == 1) this.enableSubmit1 = true;
          if (this.actionType == 2) this.enableSubmit2 = true;
          if (this.actionType == 3) this.enableSubmit3 = true;
          if (this.actionType == 4) this.enableSubmit4 = true;
          console.log("onSubmit error " + error)
          Swal.fire("", "אירעה שגיאה בשליחת הבקשה", 'error')
        })
    }
    else {
      Swal.fire("", "נא למלא את השדות המתאימים", 'warning')
    }
  }

  onSubmitHelpme(formData: any) {
    this.submitted = true;
    this.enableSubmit5 = false;

    if (formData.serialNum && formData.auditNum) {
      this.apiService.getHelpmeCode(formData).subscribe(
        data => {
          if (data["d"]) {
            this.codeRes = data["d"]
            this.enableSubmit5 = true;
            this.helpmeForm.reset();
          }
        },
        error => {
          this.enableSubmit5 = true;
          console.log("getHelpmeCode error " + error)
          Swal.fire("", "אירעה שגיאה בשליחת הבקשה", 'error')
        })
    }
    else {
      this.enableSubmit5 = true;
      Swal.fire("", "נא למלא את השדות המתאימים", 'warning')
    }
  }

  onChange(event) {
    this.isChecked = event.target.checked;
  }

  exit() {
    this.authenticationService.deauthenticateTech()
    this.router.navigate(['/tech-exit'])
  }

}
