import { Component, OnInit } from '@angular/core';
import { NavbarService } from '../../services/navbar/navbar.service';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { ApiService } from '../../services/api/api.service';
import { UtilsService } from '../../services/utils/utils.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { formatDate } from '@angular/common';
import { AuthenticationGuardService } from '../../authentication-guard.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'
  ]
})

export class NavbarComponent implements OnInit {
  isMobile = false;
  pendingMsgsCount = 0;
  _countSubscription: any;
  isUserLoggedIn = false;
  date: any;
  heDate: any;
  days = ['ראשון', 'שני', 'שלישי', 'רביעי', 'חמישי', 'שישי', 'שבת'];
  langs = ['en', 'he'];
  displayFrTel = false;
  frTel = "Pour joindre NATIV France : 06 86 56 93 48";
  enableSubmit = true;
  syncPassForm: any;
  invalidPass = false;
  submitted = false;
  profilePassRequired = false;
  isStickUser = false;
  omerDate = {
    "days": "",
    "weeks": ""
  };
  activeTab = "main"
  homePage: any;
  isPasswordVisible = false;


  constructor(public nav: NavbarService,
    public authenticationService: AuthenticationService,
    public apiService: ApiService,
    private utilsService: UtilsService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    public translateService: TranslateService,
    public router: Router,
    public route: ActivatedRoute,
    private authGuardService: AuthenticationGuardService) {
    this.pendingMsgsCount = this.apiService.pendingMsgsCount
    this._countSubscription = this.apiService.pendingMsgsCountChange.subscribe((value) => {
      this.pendingMsgsCount = value;
    })
    this.authenticationService.authenticated$.subscribe(value => {
      this.isUserLoggedIn = value;
      if (this.isUserLoggedIn) {
        if (!this.authenticationService.userData.usertypeid || this.authenticationService.userData.UserName !== this.authenticationService.userData.login) {
          this.authenticationService.login(this.authenticationService.userData).subscribe(
            data => {
              this.authenticationService.userData = { ...this.authenticationService.userData, ...data["UserData"] }
              localStorage.setItem('nativUser', JSON.stringify(this.authenticationService.userData));
              this.initData()
            },
            error => {
              console.log("isLoggedIn-login error:" + JSON.stringify(error))
            }
          )
        }
        else {
          this.initData()
        }

      }
    });
    this.syncPassForm = this.formBuilder.group({
      password: '',
    });
    this.router.events.subscribe((val) => {
      this.homePage = this.router.url === '/main-in';
    });
  }

  initData() {
    if (this.authenticationService.userData.isStickUser == true) {
      this.isStickUser = true
    }
    const userType = this.utilsService.getUserType(this.authenticationService.userData.usertypeid)
    if (userType.IS_USER_FR) {
      this.displayFrTel = true;
    }
    if (this.authenticationService.userData.ManagerPassword !== "") {
      this.profilePassRequired = true;
    }
  }

  ngAfterViewInit() {
    this.checkIsChangeLogin()
    this.runOncePerDay();
    this.initNavItemsCollapse();
  }

  ngOnInit(): void {
    this.getDate();
    this.date = formatDate(new Date(), 'dd/MM/yyyy', 'en');
  }

  initNavItemsCollapse() {
    const menuToggle = document.getElementById('navbarNav')
    const navLinks = document.querySelectorAll('.nav-item')
    navLinks.forEach((l) => {
      l.addEventListener('click', () => { menuToggle.classList.remove('show') })
    })
  }

  logout(type) {
    switch (type) {
      case 'exit':
        this.authenticationService.deauthenticate(false);
        this.router.navigate(['/main-out'])
        break;
      case 'diff':
        window.open("http://bo.enativ.com/loginasdifferentuser", '_blank');
        break;
      case 'logout':
        this.authenticationService.deauthenticate(true);
        window.open("http://bo.enativ.com/logout", '_blank');
        this.router.navigate(['/main-out'])
        break;
      default:
        break;
    }

  }

  clearMailsCount() {
    this.apiService.clearMailsCount().subscribe(
      data => {
        console.log('clearMailsCount ', data["d"])
        this.apiService.updateUserMailsCount()
      },
      error => console.log('error getSites', error)
    )
  }

  getDate() {
    this.apiService.getHebrewDate().subscribe(data => {
      this.heDate = data["d"]
    },
      error => console.log('error', error)
    )
  }

  get fp() {
    return this.syncPassForm.controls;
  }

  openPassModal(targetModal) {
    const modal = this.modalService.open(targetModal, {
      centered: true,
      backdrop: true,
      size: 'sm'
    });
    modal.result.then(() => { this.submitted = false; this.syncPassForm.reset(); }, () => { this.submitted = false; this.syncPassForm.reset(); })
  }

  close(modal) {
    this.submitted = false;
    modal.dismiss()
  }

  onFocus() {
    this.syncPassForm.get('password').setErrors(null);
    this.invalidPass = false;
  }

  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  navigateUserProfile() {
    this.authGuardService.setButtonClickUserProfile(true);
    this.router.navigate(["/user-profile"])
  }

  onSubmitPass(formData: any, modal) {
    this.invalidPass = false;
    this.submitted = true;
    if (this.syncPassForm.invalid) {
      this.enableSubmit = true;
      return;
    }
    if (formData.password == this.authenticationService.userData.ManagerPassword) {
      modal.dismiss();
      this.authGuardService.setButtonClickUserProfile(true);
      this.router.navigate(["/user-profile"])
    }
    else {
      this.invalidPass = true;
      this.syncPassForm.get('password').setErrors('invalid password');
    }
  }
  public useLanguage(lang: string): void {
    this.saveLanguage(lang)
    if (this.isUserLoggedIn) {
      this.apiService.initCategories(lang)
    }
  }

  saveLanguage(lang) {
    this.translateService.setDefaultLang(lang);
    this.translateService.use(lang);
    localStorage.setItem('nativLang', lang);
  }

  runOncePerDay() {
    const hourInMilliseconds = 1000 * 60 * 60;
    const that = this
    setInterval(function () {
      var hour = new Date().getHours();
      if (hour == 7) {
        console.log("Get date once a day: ", new Date())
        that.getDate()
        that.date = formatDate(new Date(), 'dd/MM/yyyy', 'en');
      }
    }, hourInMilliseconds);
  }

  checkIsChangeLogin() {
    const minuteInMs = 10000//60000;
    const that = this
    setInterval(function () {
      console.log("check login change : ", new Date())
      that.authenticationService.isChangedLoggedIn()
    }, minuteInMs);
  }

  active(tab) {
    this.activeTab = tab
  }
}

