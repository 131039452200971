<div class="my-sites-expanded margin-top">
    <button #scrollBtn (click)="topFunction()" class="btn-nativ scroll-btn" title="Go to top"><i
            class="fas fa-chevron-up"></i></button>
    <div class="first-div">
        <div (click)="navigateUserProfile()" [title]="'my-sites.back-to-userProfile' |
    translate" class="text-right float-right arrowUserProfile">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                class="bi bi-arrow-90deg-right" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                    d="M14.854 4.854a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 4H3.5A2.5 2.5 0 0 0 1 6.5v8a.5.5 0 0 0 1 0v-8A1.5 1.5 0 0 1 3.5 5h9.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4z" />
            </svg>
        </div>
        <div class="d-flex justify-content-center align-items-center text-center">
            <div class="makeBorder">
                <h5 class="sites-header" [title]="'my-sites.sub-header' | translate">{{ 'my-sites.header' | translate }}
                </h5>
                <!-- <h6 class="sites-sub-header">{{ 'my-sites.sub-header' | translate }}</h6> -->
                <!-- <span (click)="navigateUserProfile()" [title]="'my-sites.back-to-userProfile' |
                translate"><i class="bi bi-arrow-90deg-right"></i>gfd</span> -->
                <!--רענון אתרים-->
                <!-- <a *ngIf="!refreshing" class="btn-nativ" (click)="refreshNativ()" target="_blank">{{
                'global.refresh' |
                translate
                }}</a>
            <button *ngIf="refreshing" class="btn-nativ refreshing">
                {{ 'global.refresh-now' | translate }}
                <img src='../../../assets/images/new/nativ-gif-2.gif' />
            </button> -->
                <!--דפדוף-->
                <!-- <div class="d-flex justify-content-center paging">
                <div *ngIf="!noData" class="paging-div">
                    <div class="pagination-container">
                        <span class="pagination-label">{{ 'my-sites.page-num' | translate }}</span>
                        <input type="number" class="pagination-input" min="1" max="{{ collectionSize }}"
                            [(ngModel)]="page" (ngModelChange)="onPageChange(page)">
                        <span class="pagination-label"> {{ 'my-sites.page-num-continue' | translate }} {{
                            lastPage }} | </span>
                        <div class="request-count" *ngIf="!loading && !noData"><span>{{ 'my-sites.sites-found'
                                |translate:{'num-sites':
                                sitesLength } }} {{collectionSize}}</span>
                        </div>
                    </div>
                </div>
            </div> -->
            </div>
        </div>
        <div *ngIf="!noComputerData && !noData" class="text-center initializeFilters" (click)="unfiltering()"> {{
            'my-sites.reset-filters' | translate }}</div>
        <table class="table table-striped text-center mySitesExpandedTable">
            <thead *ngIf="!noComputerData">
                <tr>
                    <th scope="col" [ngClass]="lang === 'EN' ? 'dateEN' : 'date'">
                        <div class="d-flex align-items-center justify-content-center">
                            <div class="arrows">
                                <div (click)="sorting('date')">
                                    <i *ngIf="sorted !== 'date'&&sorted !== 'date desc'" class="fa fa-sort"
                                        aria-hidden="true"></i>
                                    <i *ngIf="sorted === 'date desc'" class="fa fa-sort-desc" aria-hidden="true"></i>
                                    <i *ngIf="sorted === 'date'" class="fa fa-sort-asc" aria-hidden="true"></i>
                                </div>
                            </div>
                            <ng-multiselect-dropdown [settings]="dropdownSettingsDate" [data]="dateArr"
                                [(ngModel)]="selectedItemsDate" (onSelect)="onItemSelectBasic('date',selectedItemsDate)"
                                (onDeSelect)="onItemDeSelectBasic('date',selectedItemsDate)" [placeholder]="' '">
                            </ng-multiselect-dropdown>
                            <!-- <div class="full-width">{{ 'my-sites.table.date' | translate }}</div> -->
                        </div>
                    </th>
                    <th scope="col" [ngClass]="lang === 'EN' ? 'urlEN' : 'url'">
                        <div class="d-flex align-items-center justify-content-center"
                            [ngClass]="{'show-filter': nShowFilterMenu}">
                            <div class="arrows">
                                <div (click)="sorting('url')">
                                    <i *ngIf="sorted !== 'url'&&sorted !== 'url desc'" class="fa fa-sort"
                                        aria-hidden="true"></i>
                                    <i *ngIf="sorted === 'url desc'" class="fa fa-sort-desc" aria-hidden="true"></i>
                                    <i *ngIf="sorted === 'url'" class="fa fa-sort-asc" aria-hidden="true"></i>
                                </div>
                            </div>
                            <ng-multiselect-dropdown [settings]="dropdownSettingsUrl" [data]="sitesUrl"
                                [(ngModel)]="selectedItemsUrl" (onFilterChange)="onFilterChange($event)"
                                (onSelect)="onItemSelectBasic('url',selectedItemsUrl)"
                                (onDeSelect)="onItemDeSelectBasic('url',selectedItemsUrl)" [placeholder]="' '"
                                (onDropDownClose)="exitUrlSelect()">
                            </ng-multiselect-dropdown>
                            <!-- <div class="full-width">{{ 'my-sites.table.name' | translate }}</div> -->
                        </div>
                    </th>
                    <th scope="col" [ngClass]="lang === 'EN' ? 'statusEN' : 'status'">
                        <ng-multiselect-dropdown [settings]="dropdownSettingsStatus" [data]="statusArr"
                            [(ngModel)]="selectedItemsStatus" (onSelect)="onItemSelectStatus()"
                            (onDeSelect)="onItemDeSelectStatus()" [placeholder]="' '">
                        </ng-multiselect-dropdown>
                    </th>
                    <th scope="col" class="text-right"
                        [ngClass]="lang === 'EN' ? 'windowsMachineEN' : 'windowsMachine'">
                        <div class="d-flex align-items-center justify-content-center">
                            <div (click)="sorting('windowsMachine')" class="arrows">
                                <i *ngIf="sorted !== 'windowsMachine'&&sorted !== 'windowsMachine desc'"
                                    class="fa fa-sort" aria-hidden="true"></i>
                                <i *ngIf="sorted === 'windowsMachine desc'" class="fa fa-sort-desc"
                                    aria-hidden="true"></i>
                                <i *ngIf="sorted === 'windowsMachine'" class="fa fa-sort-asc" aria-hidden="true"></i>
                                <!-- <i (click)="openSearchWindowsMachine()" class="fa fa-search" aria-hidden="true"></i> -->
                            </div>
                            <ng-multiselect-dropdown [settings]="dropdownSettingsWindowsMachine"
                                [data]="windowsMachines" [(ngModel)]="selectedItemsWindowsMachine"
                                (onSelect)="onItemSelect('windowsMachine',selectedItemsWindowsMachine)"
                                (onDeSelect)="onItemDeSelect('windowsMachine',selectedItemsWindowsMachine)"
                                [placeholder]="' '">
                            </ng-multiselect-dropdown>
                        </div>
                    </th>
                    <th scope="col" class="text-right" [ngClass]="lang === 'EN' ? 'windowsUsersEN' : 'windowsUsers'">
                        <div class="d-flex align-items-center justify-content-center">
                            <div class="arrows" (click)="sorting('windowsUsers')">
                                <i *ngIf="sorted !== 'windowsUsers'&&sorted !== 'windowsUsers desc'" class="fa fa-sort"
                                    aria-hidden="true"></i>
                                <i *ngIf="sorted === 'windowsUsers desc'" class="fa fa-sort-desc"
                                    aria-hidden="true"></i>
                                <i *ngIf="sorted === 'windowsUsers'" class="fa fa-sort-asc" aria-hidden="true"></i>
                            </div>
                            <ng-multiselect-dropdown [settings]="dropdownSettingsWindowsUser" [data]="windowsUsers"
                                [(ngModel)]="selectedItemsWindowsUser"
                                (onSelect)="onItemSelect('windowsUsers',selectedItemsWindowsUser)"
                                (onDeSelect)="onItemDeSelect('windowsUsers',selectedItemsWindowsUser)"
                                [placeholder]="' '">
                            </ng-multiselect-dropdown>

                        </div>
                    </th>
                    <th scope="col" class="text-right" [ngClass]="lang === 'EN' ? 'loginEN' : 'login'">
                        <div class="d-flex align-items-center justify-content-center">
                            <div class="arrows" (click)="sorting('login')">
                                <i *ngIf="sorted !== 'login'&&sorted !== 'login desc'" class="fa fa-sort"
                                    aria-hidden="true"></i>
                                <i *ngIf="sorted === 'login desc'" class="fa fa-sort-desc" aria-hidden="true"></i>
                                <i *ngIf="sorted === 'login'" class="fa fa-sort-asc" aria-hidden="true"></i>
                            </div>
                            <ng-multiselect-dropdown [settings]="dropdownSettingsLogin" [data]="logins"
                                [(ngModel)]="selectedItemsLogin"
                                (onSelect)="onItemSelect('p.userid',selectedItemsLogin)"
                                (onDeSelect)="onItemDeSelect('p.userid',selectedItemsLogin)" [placeholder]="' '">
                            </ng-multiselect-dropdown>

                        </div>
                    </th>
                    <th scope="col" class="remove text-right">{{ 'my-sites.table.remove' | translate }}</th>
                </tr>
            </thead>
            <tbody *ngIf="!loadingAllTable">
                <tr *ngFor="let site of sites; index as i" id="id_{{site.id}}"
                    [ngClass]="{'tr-border':  siteId!=null && siteId.toString() ==  site.siteid.toString()}">
                    <td class="d-none">{{site.siteid}}</td>
                    <td [attr.data-label]="'my-sites.table.date' | translate" class="date text-left">{{ site.date }}
                    </td>
                    <td [attr.data-label]="'my-sites.table.name' | translate" class="name" title="{{ site.name }}">
                        {{site.sitename ? site.sitename : ""}} <br *ngIf='site.sitename' /><a *ngIf='site.isUrl'
                            href="{{site.url.startsWith('http') ? site.url : 'http://'+site.url }}" target="_blank">{{
                            site.url
                            }}</a>{{ site.isUrl ? "" : site.url }}</td>
                    <td [attr.data-label]="'my-sites.table.status' | translate"
                        *ngIf='!site.status.includes("מנוע טיפול")' class="status">{{ site.status }}<br />
                    </td>
                    <td [attr.data-label]="'my-sites.table.status' | translate"
                        *ngIf='site.status.includes("מנוע טיפול")' class="status pointer">
                        <a (click)="addSite(site.url)">{{ site.status }}</a>
                    </td>

                    <td [attr.data-label]="'my-sites.table.computer-name' | translate">
                        {{site.windowsMachine}}
                    </td>
                    <td [attr.data-label]="'my-sites.table.windows-name' | translate">
                        {{site.windowsUsers}}
                    </td>
                    <td [attr.data-label]="'my-sites.table.nativ-username' | translate">
                        {{site.login}}
                    </td>
                    <td [attr.data-label]="'my-sites.table.remove' | translate" class="garbage">
                        <div type="button" placement="top"
                            ngbTooltip='{{site.remove ? site.removeTitle.includes("בקשה") ? "למחיקת הבקשה" : "באם סיימתם את השימוש באתר ואינכם נצרכים לו- לחצו כאן להסרת האתר מהמאגר האישי שלכם" : ""}}'
                            [ngClass]='{"garbage1 btn btn-outline-secondary me-2 pointer": site.remove, "no-pointer": !site.remove}'
                            (click)="removeSite(site)">
                            <ng-container *ngIf="site.remove">
                                <img class="garbage-image" src="../../../assets/images/my-sites/garbage.png" />
                            </ng-container>
                            <ng-container *ngIf="!site.remove">
                                ---
                            </ng-container>
                        </div>
                    </td>
                    <!-- <td *ngIf='(site.remove || site.status.includes("טופל-קיים")) && displaySimActions' class="name">
                    <button (click)="sync(site.siteid)" class="btn-nativ">{{
                        'profile.table.btn-sync' | translate }}</button>
                </td> -->
                </tr>
            </tbody>
        </table>
        <div *ngIf="loading || loadingAllTable" id="loading"
            class="d-flex align-items-center justify-content-center sites-loader table-sites">
            <img src='../../../assets/images/new/nativ-gif-2.gif' />
        </div>

        <!--דפדוף-->
        <!-- <div *ngIf="!noData" class="d-flex justify-content-center p-4">
        <ngb-pagination (pageChange)="onPageChange($event)" [collectionSize]="collectionSize" [(page)]="page"
            [pageSize]="pageSize" (pageChange)="refreshSites()" [boundaryLinks]="false" [maxSize]="5">
        </ngb-pagination>
    </div> -->
    </div>
    <app-bottom-nav></app-bottom-nav>