export const environment = {
  production: true,
  iaiServerUserId: "46279",
  iaiServer1UserId: "46512",
  arachimL1UserId: "46317",
  arachimL2UserId: "46318",
  arachimL3UserId: "46319",
  arachimL4UserId: "46320",
  arachimL5UserId: "46321",
  arachimL6UserId: "46322",
  arachimL7UserId: "46323",
  eltaServer: "47301",
  eltaServerIP1: "207.232.27.5",
  eltaServerIP2: "185.253.72.114",
  cadenceUserId : "45975"
};
