import { Component, OnInit, HostBinding, ViewChild, ElementRef, HostListener } from '@angular/core';
import { ApiService } from '../../services/api/api.service';
import { SalesService } from '../../services/sales/sales.service';
import { getLocaleDirection } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import * as AOS from 'aos';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication/authentication.service'
import { UtilsService } from '../../services/utils/utils.service'
import { ResponsiveService } from '../../services/responsive/responsive.service'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";
import { ChatService } from 'src/app/services/chat/chat.service';

@Component({
  selector: 'app-home-main',
  templateUrl: './home-main.component.html',
  styleUrls: ['./home-main.component.scss']
})
export class HomeMainComponent implements OnInit {

  loginForm: any;
  contactForm: any;
  searchSiteForm: any;
  submitted = false;
  contacted = false;
  authenticated = true;
  authenticatedError = "";
  contactSuccess = "";
  showNativContent1 = false;
  showNativContent2 = false;
  showNativContent3 = false;
  showNativContent4 = false;
  isOpen = true;
  isMobile = false;
  invalidLogin = false;
  sites = [];
  sitesData = [];
  showSites = false;
  sitesLength = 0;
  loading = false;
  pendingDocs = [];
  userParams = {};
  stations = [];
  validPhoneNimber = true;
  disabledAddSites = false;
  enableSubmit = true;
  innerWidth = 0
  subscription: Subscription;
  omerDate = {
    "days": "",
    "weeks": ""
  };
  alertText = "";
  alertHeader = "";
  isShowChat = false;
  refreshing = false;
  displayInSmallScreen = false;

  constructor(private apiService: ApiService,
    private salesService: SalesService,
    private formBuilder: FormBuilder,
    private router: Router,
    public authenticationService: AuthenticationService,
    public responsiveService: ResponsiveService,
    public translateService: TranslateService,
    private route: ActivatedRoute,
    private utilsService: UtilsService,
    public chatService: ChatService) {

    this.showAlerts()

    this.innerWidth = window.innerWidth;

    this.loginForm = this.formBuilder.group({
      username: '',
      password: ''
    });
    this.contactForm = this.formBuilder.group({
      name: '',
      phoneNumber: '',
      mail: ''
    });
    this.searchSiteForm = this.formBuilder.group({
      search: ''
    });
  }
  @ViewChild('scrollBtn') scrollBtnEl: ElementRef;

  @HostListener("window:scroll", ["$event"])
  onWindowScroll(event) {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      this.scrollBtnEl.nativeElement.style.display = "block";
    } else {
      this.scrollBtnEl.nativeElement.style.display = "none";
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 1366) {
      this.displayInSmallScreen = true;
    }
    else {
      this.displayInSmallScreen = false;
    }
  }
  toggleChat(value) {
    this.isShowChat = value;
  }
  topFunction() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 1366) {
      this.displayInSmallScreen = true;
    }
    else {
      this.displayInSmallScreen = false;
    }
    this.onResizeScreen();
    this.responsiveService.checkWidth();
    this.getConfirmation()
    this.getInfo();
    AOS.init({
      offset: 200,
      duration: 600,
      easing: 'ease-in-sine',
      delay: 100,
      mirror: true
    });
    this.initForms()
    this.route.paramMap.subscribe(params => {
      if (Object.keys(params["params"]).length !== 0) {
        if (params.get('type') == "exit") {
          this.authenticationService.deauthenticate(true);
        }
      }
    })
    this.getOmerDays();
  }

  getConfirmation() {
    this.route
      .queryParams
      .subscribe(params => {
        if (params["confirmationcode"]) {
          this.router.navigate(
            ['.'],
            { relativeTo: this.route }
          );
          this.salesService.verifyUser(params["confirmationcode"]).subscribe(
            data => {
              if (data["res"]) {
                console.log('verifyUser success')
                Swal.fire({
                  text: "האימות עבר בהצלחה",
                  icon: "info"
                })
              }
              else {
                Swal.fire({
                  text: "האימות נכשל",
                  icon: "error"
                })
              }
            },
            error => {
              console.log('error verifyUser', error)
              Swal.fire({
                text: "אימות נכשל",
                icon: "error"
              })
            }

          )
        }
      }
      )
  }

  showAlerts() {
    this.route
      .queryParams
      .subscribe(params => {
        let text;
        let type = 'info';
        let title = "";
        let res;
        if (params["path"]
          || (params["d"] && params["url"] && params["sid"] && params["notrec"])
          || params["mcbiuid"]
          || params["plmiuid"]
          || (params["fsdfewhrewirhxcbapprvdmvuddspbz"] && params["ud"] && params["url"] && ('cmnt' in params))
          || (params["675hggjgjg24"] && params["typvbboodfg"] && params["ieuryeiuryeiuryweiru"] && params["sid"])
          || (params["rtyrtfdjgk6546858654yhghb"] && params["akytuerhmxsdk"] && params["878798hgbvukibkoyoioy"] && params["sid"])
          || (params["url"] && params["sid"] && params["approve"] && params["uid"])
          || (params["ufgthpo"] && params["11joLgn161"])) {
          this.apiService.knTemplate(params).subscribe(
            data => {
              console.log('knTemplate success');
              res = data[0];
              text = this.getText(res);
              Swal.fire({
                title: title,
                text: text,
                icon: type == "warning" ? "warning" : "info"
              })
              if (params["d"] && params["url"] && params["sid"] && params["notrec"]) {
                this.router.navigate(['/my-sites'])
              }
            },
            error => console.log('error knTemplate', error)
          )
        }
      });
  }

  getOmerDays() {
    this.apiService.getOmer({ addDay: false }).subscribe(data => {
      var response = data != null ? data["d"] : "";
      if (response != '' && response != null) {
        response = response.split('שהם');
        this.omerDate.days = response[0];
        if (response.length > 1)
          this.omerDate.weeks = 'שהם' + response[1];
      }

    },
      error => console.log('error', error)
    )
  }

  openContact019(userData) {
    const content = 'לקוח אקספון מעוניין בהקמת ספק 019';
    this.apiService.contact(content, 5, userData.login, userData.homephone, userData.email.split(',')[0], userData.firstname, userData.lastname, 2).subscribe(
      data => {
        console.log('success contact', data)
        Swal.fire("", "פנייתך נפתחה בהצלחה ונציג ייצור איתך קשר בהקדם", 'success')
      },
      error => {
        console.log('error contact', error)
        Swal.fire("", "אירעה שגיאה בשליחת הפניה", 'error')
      }
    )
  }

  getText(res) {
    switch (res) {
      case "1":
        return "בקשתך נקלטה ותענה בהקדם";
        break;
      case "2":
        return "הטופס נשלח לכתובת המייל הרשומה במערכת";
        break;
      case "3":
        return "החסימה הוסרה מהקישור המבוקש";
        break;
      case "4":
        return "האתר נוסף לחשבונכם";
        break;
      case "5":
        return "האתר קיים בחשבונכם";
        break;
      case "6":
        return "על פי בקשתך התווסף לרשימת האתרים שלך פרופיל תיירות ונופש";
        break;
      case "7":
        return "הפרופיל כבר קיים ברשימת האתרים שלך";
        break;
      default:
        return "בקשתך התקבלה";
        break;
    }
  }

  getInfo() {
    this.route
      .queryParams
      .subscribe(params => {
        if (params["infotype"] && params["iarg1"] && params["iarg2"]) {
          window.location.href = 'http://api.enativ.com/GetInfo.aspx?InfoType=3&IArg1=4&IArg2=7';
        }
      })
  }

  onResizeScreen() {
    this.responsiveService.getMobileStatus().subscribe(isMobile => {
      this.isMobile = isMobile;
    });
  }

  onSlide($event: any) {

  }
  close(modal) {
    this.submitted = false;
    modal.dismiss()
  }

  initForms() {
    this.loginForm = new FormGroup({
      username: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required)
    });
    this.contactForm = new FormGroup({
      name: new FormControl('', Validators.required),
      phoneNumber: new FormControl('', [Validators.required, Validators.pattern(/^0(([23489]{1}\d{7})|[5]{1}\d{8}|(([7]\d{1,2}\-\d{7})|([7]\d{8})))$/)]),
      mail: new FormControl('', [Validators.email, Validators.email, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[A-Za-z]{2,4}$")])
    });
    this.searchSiteForm = new FormGroup({
      search: new FormControl('', Validators.required)
    });
  }
  loadWeather(s, id) {
    var js, fjs = document.getElementsByTagName(s)[0];
    js = document.createElement(s);
    js.id = id;
    js.src = 'https://weatherwidget.io/js/widget.min.js';
    fjs.parentNode.insertBefore(js, fjs);
  }
  getStations() {
    this.apiService.getStations().subscribe(
      data => {
        console.log('getStations ', data)
        this.stations = data["res"].map(st => st.shortName).sort();
      },
      error => console.log('error getWeather', error)
    )

  }
  getWeather() {
    this.apiService.getWeather("178").subscribe(
      data => {
        console.log('getWeather ', data)
      },
      error => console.log('error getWeather', error)
    )

  }
  get f() {
    return this.loginForm.controls;
  }
  get cf() {
    return this.contactForm.controls;
  }
  onSubmit(loginData: any) {
    this.submitted = true;
    console.warn('login has been submitted', loginData);
    if (this.loginForm.invalid) {
      return;
    }
    this.apiService.login(loginData).subscribe(
      data => {
        console.log('success login', data)
        data["data"]["UserData"].username = loginData.username
        this.authenticationService.authenticate(data["data"]["UserData"]);
      },
      error => {
        this.authenticated = false;
        if (error.error && error.error.text && error.error.text === "User does not exists!") {
          this.authenticatedError = 'שם משתמש שגוי';
        }
        else {
          this.authenticatedError = 'שם משתמש או סיסמא שגויים';
        }
        console.log('error login', error)
      }
    )
  }
  onContact(data: any) {
    this.contacted = true;
    this.enableSubmit = false;
    console.log('contact has been submitted', data);
    if (this.contactForm.invalid) {
      const pn = data.phoneNumber.replace(/\D/g, '');
      if (pn.length > 10) {
        if (data.mail == '') {
          this.contactForm.get('mail').setErrors({ required: 'true' })
          this.contactForm.get('phoneNumber').clearValidators();
          this.contactForm.get('phoneNumber').setErrors({ required: 'IS' });
        }
        else {
          this.contactForm.get('phoneNumber').clearValidators();
          this.contactForm.get('phoneNumber').setErrors();
        }
      }
      else {
        this.contactForm.get('mail').clearValidators();
        this.contactForm.get('mail').setErrors(null);
      }
      this.enableSubmit = true;
      return;
    }
    const phoneNumber = data.phoneNumber.replace(/\D/g, '');
    if (phoneNumber.length < 9 || phoneNumber.length > 15) {
      this.contactForm.get('phoneNumber').setErrors('invalid phonenumber')
      this.validPhoneNimber = false;
      this.enableSubmit = true;
      return
    }
    try {
      data.phoneNumber = data.phoneNumber.replace(/\D/g, '');
      const name = data.name.split(" ")
      const fname = name[0];
      const lname = name[1] ? name[1] : "";
      const params = `Content=פרטים על סינון נתיב&fname=${fname}&lname=${lname}&tel=${data.phoneNumber}&mail=` + (data.mail ? data.mail : '')
      const contactData = `${params}&Dept=3&Login=NONE`
      const content = 'פרטים על סינון נתיב';
      this.apiService.contact(content, 3, 'NONE', data.phoneNumber, (data.mail ? data.mail : ''), fname, lname, 2).subscribe(
        data => {
          console.log('success contact', data)
          Swal.fire("", 'פנייתכם התקבלה, נחזור אליכם בהקדם', 'success')
        },
        error => {
          this.enableSubmit = true;
          console.log('error contact', error)
          this.contactForm.reset();
          Swal.fire("", "אירעה שגיאה בשליחת הבקשה", 'error')
        }
      )
    }
    catch (error) {
      this.enableSubmit = true;
      this.contactForm.reset();
      console.log('error contact', error)
      this.contactSuccess = 'ארעה שגיאה בשליחת הבקשה'
      Swal.fire("", "אירעה שגיאה בשליחת הבקשה", 'error')
    }

  }
  onFocusInput(event) {
    this.authenticated = true;
  }
  navigateToForum() {
    window.open('https://video.enativ.com/', '_blank');
  }

  nativLogin() {
    window.open('http://bo.enativ.com/login', '_blank');
  }

  clickNativ(event, num) {
    event.stopPropagation();
    switch (num) {
      case 1:
        this.showNativContent1 = true
        this.showNativContent2 = false
        this.showNativContent3 = false
        this.showNativContent4 = false
        break;
      case 2:
        this.showNativContent2 = true
        this.showNativContent1 = false
        this.showNativContent3 = false
        this.showNativContent4 = false
        break;
      case 3:
        this.showNativContent3 = true
        this.showNativContent1 = false
        this.showNativContent2 = false
        this.showNativContent4 = false
        break;
      case 4:
        this.showNativContent4 = true
        this.showNativContent1 = false
        this.showNativContent2 = false
        this.showNativContent3 = false
        break;
      default:
        break;
    }
  }
  mouseLeaveNativ() {
    this.showNativContent1 = false
    this.showNativContent2 = false
    this.showNativContent3 = false
    this.showNativContent4 = false
  }
}
