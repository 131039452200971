<div class="text-center">
    <h3 id="section-5-header">{{ 'home.apps.header' | translate }}</h3>
    <div class="apps-div">
        <div class="apps row">
            <div [ngClass]="isMobile ?'col-md-6':'col-md-4'" class="app">
                <div class="part-header app-header">{{ 'home.apps.currencies' | translate }}</div>
                <app-currencies></app-currencies>
            </div>
            <div [ngClass]="isMobile ?'col-md-6':'col-md-4'" class="app">
                <div class="part-header app-header">{{ 'home.apps.weather' | translate }}</div>
                <div id="weather-div" class="text-center app-div">
                    <div>
                        <a class="weatherwidget-io" href="https://forecast7.com/he/31d0534d85/israel/"
                            data-label_1="ישראל" data-label_2="" data-theme="original" data-basecolor="#db9090"
                            data-highcolor="#ffffff" data-lowcolor="#ffffff" data-suncolor="#fffdf9"
                            data-cloudfill="#f6f8f9" data-raincolor="#ffffff"></a>
                    </div>
                </div>
            </div>
            <div [ngClass]="isMobile ?'col-md-6':'col-md-4'" class="app">
                <div class="part-header app-header">{{ 'home.apps.times' | translate }}</div>
                <app-kosher-zmanim></app-kosher-zmanim>
            </div>
        </div>
    </div>
</div>