<div class="responsive-div">
    <div class="c26 doc-content">
        <p class="c1 c0"><span class="c8 c14"></span></p><a id="t.d20da721c8c7acd34b0108527e3531c1cd65cb76"></a><a
            id="t.0"></a>
        <table class="c42" dir="rtl">
            <tr class="c4">
                <td class="c51" colspan="4" rowspan="1">
                    <p class="c6" dir="rtl"><span class="c35 c32">&#1492;&#1504;&#1495;&#1497;&#1493;&#1514;
                            &#1500;&#1492;&#1514;&#1511;&#1504;&#1514; &#1504;&#1514;&#1497;&#1489;</span></p>
                </td>
            </tr>
            <tr class="c4">
                <td class="c22 c30" colspan="4" rowspan="1">
                    <p class="c6 c0" dir="rtl"><span class="c35 c32"></span></p>
                </td>
            </tr>
            <tr class="c4">
                <td class="c38" colspan="4" rowspan="1">
                    <p class="c6" dir="rtl"><span class="c3">&#1513;&#1500;&#1489; &#1488;&#39; -
                            &#1492;&#1514;&#1511;&#1504;&#1514; &#1514;&#1493;&#1499;&#1504;&#1493;&#1514;
                            &#1504;&#1495;&#1493;&#1510;&#1493;&#1514;</span></p>
                    <p class="c1" dir="rtl"><span class="c12 c7">&#1497;&#1513;
                            &#1500;&#1492;&#1493;&#1512;&#1497;&#1491;/&#1500;&#1492;&#1514;&#1511;&#1497;&#1503;
                            &#1488;&#1514; &#1492;&#1514;&#1493;&#1499;&#1504;&#1493;&#1514;
                            &#1513;&#1500;&#1492;&#1500;&#1503; &#1488;&#1501; &#1506;&#1491;&#1497;&#1497;&#1503;
                            &#1488;&#1497;&#1504;&#1503; &#1502;&#1493;&#1514;&#1511;&#1504;&#1493;&#1514;.</span></p>
                </td>
            </tr>
            <tr class="c4">
                <td class="c22 c44" colspan="1" rowspan="1">
                    <p class="c0 c6" dir="rtl"><span class="c12 c7"></span></p>
                </td>
                <td class="c2" colspan="1" rowspan="1">
                    <p class="c6" dir="rtl"><span class="c3">&#1514;&#1493;&#1499;&#1504;&#1492;</span></p>
                </td>
                <td class="c22 c45" colspan="1" rowspan="1">
                    <p class="c6" dir="rtl"><span class="c3">&#1511;&#1497;&#1513;&#1493;&#1512;
                            &#1500;&#1492;&#1493;&#1512;&#1491;&#1492;</span></p>
                </td>
                <td class="c22 c23" colspan="1" rowspan="1">
                    <p class="c6" dir="rtl"><span class="c3">&#1508;&#1506;&#1493;&#1500;&#1492;
                            &#1504;&#1491;&#1512;&#1513;&#1514;</span></p>
                </td>
            </tr>
            <tr class="c4">
                <td class="c15" colspan="1" rowspan="1">
                    <p class="c1 c17 c0" dir="rtl"><span class="c3"></span></p>
                </td>
                <td class="c21" colspan="1" rowspan="1">
                    <p class="c1" dir="rtl"><span class="c3">&#1493;&#1497;&#1494;&#39;&#1493;&#1488;&#1500;
                            C++#1</span>
                    </p>
                    <p class="c1" dir="rtl"><span class="c25 c7">&#1512;&#1499;&#1497;&#1489; &#1513;&#1500;
                            &#1502;&#1497;&#1497;&#1511;&#1512;&#1493;&#1505;&#1493;&#1508;&#1496;
                            &#1504;&#1491;&#1512;&#1513; &#1500;&#1492;&#1508;&#1506;&#1500;&#1514;
                            &#1504;&#1514;&#1497;&#1489;</span></p>
                </td>
                <td class="c24" colspan="1" rowspan="1">
                    <p class="c1" dir="rtl"><span class="c18 c7"><a class="c10"
                                href="https://download.microsoft.com/download/5/B/C/5BC5DBB3-652D-4DCE-B14A-475AB85EEF6E/vcredist_x86.exe">Visual
                                C++ 2010</a></span></p>
                </td>
                <td class="c16" colspan="1" rowspan="1">
                    <p class="c1" dir="rtl"><span class="c12 c7">&#1492;&#1493;&#1512;&#1491;&#1492;
                            &#1493;&#1492;&#1514;&#1511;&#1504;&#1492;</span></p>
                </td>
            </tr>
            <tr class="c4">
                <td class="c15" colspan="1" rowspan="1">
                    <p class="c1 c17 c0" dir="rtl"><span class="c3"></span></p>
                </td>
                <td class="c21" colspan="1" rowspan="1">
                    <p class="c1" dir="rtl"><span class="c3">&#1493;&#1497;&#1494;&#39;&#1493;&#1488;&#1500;
                            C++#2</span>
                    </p>
                    <p class="c1" dir="rtl"><span class="c25 c7">&#1512;&#1499;&#1497;&#1489; &#1513;&#1500;
                            &#1502;&#1497;&#1497;&#1511;&#1512;&#1493;&#1505;&#1493;&#1508;&#1496;
                            &#1504;&#1491;&#1512;&#1513; &#1500;&#1492;&#1508;&#1506;&#1500;&#1514;
                            &#1504;&#1514;&#1497;&#1489;</span></p>
                </td>
                <td class="c24" colspan="1" rowspan="1">
                    <p class="c1" dir="rtl"><span class="c5"><a class="c10"
                                href="https://download.microsoft.com/download/A/8/0/A80747C3-41BD-45DF-B505-E9710D2744E0/vcredist_x64.exe">Visual
                                C++ 2010</a></span></p>
                </td>
                <td class="c16" colspan="1" rowspan="1">
                    <p class="c1" dir="rtl"><span class="c12 c7">&#1492;&#1493;&#1512;&#1491;&#1492;
                            &#1493;&#1492;&#1514;&#1511;&#1504;&#1492;</span></p>
                </td>
            </tr>
            <tr class="c4">
                <td class="c15" colspan="1" rowspan="1">
                    <p class="c1 c17 c0" dir="rtl"><span class="c3"></span></p>
                </td>
                <td class="c21" colspan="1" rowspan="1">
                    <p class="c1" dir="rtl"><span class="c3">&#1493;&#1497;&#1494;&#39;&#1493;&#1488;&#1500;
                            C++#3</span>
                    </p>
                    <p class="c1" dir="rtl"><span class="c25 c7">&#1512;&#1499;&#1497;&#1489; &#1513;&#1500;
                            &#1502;&#1497;&#1497;&#1511;&#1512;&#1493;&#1505;&#1493;&#1508;&#1496;
                            &#1504;&#1491;&#1512;&#1513; &#1500;&#1492;&#1508;&#1506;&#1500;&#1514;
                            &#1504;&#1514;&#1497;&#1489;</span></p>
                </td>
                <td class="c24" colspan="1" rowspan="1">
                    <p class="c1" dir="rtl"><span class="c5"><a class="c10"
                                href="https://download.microsoft.com/download/0/6/4/064F84EA-D1DB-4EAA-9A5C-CC2F0FF6A638/vc_redist.x64.exe">Visual
                                C++2015</a></span></p>
                </td>
                <td class="c16" colspan="1" rowspan="1">
                    <p class="c1" dir="rtl"><span class="c12 c7">&#1492;&#1493;&#1512;&#1491;&#1492;
                            &#1493;&#1492;&#1514;&#1511;&#1504;&#1492;</span></p>
                </td>
            </tr>
            <tr class="c4">
                <td class="c15 c22" colspan="1" rowspan="1">
                    <p class="c1 c17 c0" dir="rtl"><span class="c3"></span></p>
                </td>
                <td class="c2" colspan="1" rowspan="1">
                    <p class="c1" dir="rtl"><span class="c3">&#1493;&#1497;&#1494;&#39;&#1493;&#1488;&#1500;
                            C++#4</span>
                    </p>
                    <p class="c1" dir="rtl"><span class="c25 c7">&#1512;&#1499;&#1497;&#1489; &#1513;&#1500;
                            &#1502;&#1497;&#1497;&#1511;&#1512;&#1493;&#1505;&#1493;&#1508;&#1496;
                            &#1504;&#1491;&#1512;&#1513; &#1500;&#1492;&#1508;&#1506;&#1500;&#1514;
                            &#1504;&#1514;&#1497;&#1489;</span></p>
                </td>
                <td class="c11" colspan="1" rowspan="1">
                    <p class="c1" dir="rtl"><span class="c5"><a class="c10"
                                href="https://download.microsoft.com/download/9/3/F/93FCF1E7-E6A4-478B-96E7-D4B285925B00/vc_redist.x86.exe">Visual
                                C++2015</a></span></p>
                </td>
                <td class="c16 c22" colspan="1" rowspan="1">
                    <p class="c1" dir="rtl"><span class="c12 c7">&#1492;&#1493;&#1512;&#1491;&#1492;
                            &#1493;&#1492;&#1514;&#1511;&#1504;&#1492;</span></p>
                </td>
            </tr>
            <tr class="c4">
                <td class="c15" colspan="1" rowspan="1">
                    <p class="c1 c17 c0" dir="rtl"><span class="c3"></span></p>
                </td>
                <td class="c21" colspan="1" rowspan="1">
                    <p class="c1" dir="rtl"><span class="c3">&#1499;&#1500;&#1497; &#1492;&#1505;&#1512;&#1492;
                            &#1500;&#1514;&#1493;&#1499;&#1504;&#1514; &#1504;&#1514;&#1497;&#1489;</span></p>
                    <p class="c1" dir="rtl"><span class="c7 c25">(&#1499;&#1500;&#1497; &#1513;&#1500;
                            &#1504;&#1514;&#1497;&#1489; &#1500;&#1502;&#1511;&#1512;&#1497;&#1501;
                            &#1513;&#1504;&#1491;&#1512;&#1513;&#1514; &#1492;&#1505;&#1512;&#1514;
                            &#1492;&#1514;&#1493;&#1499;&#1504;&#1492;. &#1492;&#1508;&#1506;&#1500;&#1514;&#1493;
                            &#1491;&#1493;&#1512;&#1513;&#1514; &#1511;&#1493;&#1491; &#1492;&#1505;&#1512;&#1492;
                            &#1513;&#1504;&#1497;&#1514;&#1503; &#1500;&#1511;&#1489;&#1500;
                            &#1502;&#1504;&#1510;&#1497;&#1490;&#1497; &#1504;&#1514;&#1497;&#1489;)</span></p>
                </td>
                <td class="c24" colspan="1" rowspan="1">
                    <p class="c1" dir="rtl"><span class="c18 c7"><a class="c10"
                                href="https://www.enativ.com/166">&#1499;&#1500;&#1497;
                                &#1492;&#1505;&#1512;&#1492;</a></span></p>
                </td>
                <td class="c16" colspan="1" rowspan="1">
                    <p class="c1" dir="rtl"><span class="c12 c7">&#1492;&#1493;&#1512;&#1491;&#1492;
                            &#1489;&#1500;&#1489;&#1491;</span></p>
                </td>
            </tr>
            <tr class="c4">
                <td class="c15 c22" colspan="1" rowspan="1">
                    <p class="c1 c17 c0" dir="rtl"><span class="c3"></span></p>
                </td>
                <td class="c2" colspan="1" rowspan="1">
                    <p class="c1" dir="rtl"><span class="c3">&#1499;&#1500;&#1497; &#1488;&#1489;&#1495;&#1493;&#1503;
                            &#1500;&#1514;&#1511;&#1497;&#1504;&#1493;&#1514; &#1504;&#1514;&#1497;&#1489;</span></p>
                    <p class="c1" dir="rtl"><span class="c25 c7">(&#1499;&#1500;&#1497; &#1513;&#1500;
                            &#1504;&#1514;&#1497;&#1489; &#1513;&#1502;&#1489;&#1510;&#1506;
                            &#1488;&#1489;&#1495;&#1493;&#1503; &#1500;&#1502;&#1510;&#1489;
                            &#1492;&#1514;&#1511;&#1497;&#1504;&#1493;&#1514; &#1513;&#1500;
                            &#1514;&#1493;&#1499;&#1504;&#1514; &#1504;&#1514;&#1497;&#1489;)</span></p>
                </td>
                <td class="c11" colspan="1" rowspan="1">
                    <p class="c1" dir="rtl"><span class="c18 c7"><a class="c10"
                                href="https://www.enativ.com/199">&#1499;&#1500;&#1497;
                                &#1488;&#1489;&#1495;&#1493;&#1503;</a></span></p>
                </td>
                <td class="c16 c22" colspan="1" rowspan="1">
                    <p class="c1" dir="rtl"><span class="c12 c7">&#1492;&#1493;&#1512;&#1491;&#1492;
                            &#1489;&#1500;&#1489;&#1491;</span></p>
                </td>
            </tr>
            <tr class="c4">
                <td class="c15" colspan="1" rowspan="1">
                    <p class="c1 c0 c17" dir="rtl"><span class="c3"></span></p>
                </td>
                <td class="c21" colspan="1" rowspan="1">
                    <p class="c1" dir="rtl"><span class="c3">&#1513;&#1500;&#1497;&#1496;&#1492;
                            &#1502;&#1512;&#1495;&#1493;&#1511;</span></p>
                    <p class="c1" dir="rtl"><span class="c25 c7">(TeamViewer QS)</span></p>
                </td>
                <td class="c24" colspan="1" rowspan="1">
                    <p class="c1" dir="rtl"><span class="c5"><a class="c10"
                                href="https://www.enativ.com/5">&#1513;&#1500;&#1497;&#1496;&#1492;
                                &#1502;&#1512;&#1495;&#1493;&#1511; TeamViewer</a></span></p>
                </td>
                <td class="c16" colspan="1" rowspan="1">
                    <p class="c1" dir="rtl"><span class="c12 c7">&#1492;&#1493;&#1512;&#1491;&#1492;
                            &#1489;&#1500;&#1489;&#1491;</span></p>
                </td>
            </tr>
            <tr class="c4">
                <td class="c15 c22" colspan="1" rowspan="1">
                    <p class="c1 c17 c0" dir="rtl"><span class="c3"></span></p>
                </td>
                <td class="c2" colspan="1" rowspan="1">
                    <p class="c1" dir="rtl"><span class="c3">&#1513;&#1500;&#1497;&#1496;&#1492;
                            &#1502;&#1512;&#1495;&#1493;&#1511;</span></p>
                    <p class="c1" dir="rtl"><span class="c25 c7">(AnyDesk)</span></p>
                </td>
                <td class="c11" colspan="1" rowspan="1">
                    <p class="c1" dir="rtl"><span class="c5"><a class="c10"
                                href="https://download.enativ.com/AnyDesk.exe">&#1513;&#1500;&#1497;&#1496;&#1492;
                                &#1502;&#1512;&#1495;&#1493;&#1511; AnyDesk</a></span></p>
                </td>
                <td class="c16 c22" colspan="1" rowspan="1">
                    <p class="c1 c0" dir="rtl"><span class="c7 c8"></span></p>
                </td>
            </tr>
            <tr class="c4">
                <td class="c15" colspan="1" rowspan="1">
                    <p class="c1 c17 c0" dir="rtl"><span class="c3"></span></p>
                </td>
                <td class="c21" colspan="1" rowspan="1">
                    <p class="c1" dir="rtl"><span class="c3">&#1499;&#1500;&#1497; &#1513;&#1500;&#1497;&#1495;&#1514;
                            &#1500;&#1493;&#1490; &#1505;&#1497;&#1504;&#1493;&#1503;</span></p>
                    <p class="c1" dir="rtl"><span class="c25 c7">(&#1499;&#1500;&#1497; &#1513;&#1500;
                            &#1504;&#1514;&#1497;&#1489; &#1513;&#1502;&#1488;&#1508;&#1513;&#1512;
                            &#1500;&#1513;&#1500;&#1493;&#1495; &#1500;&#1510;&#1493;&#1493;&#1514;
                            &#1492;&#1514;&#1502;&#1497;&#1499;&#1492; &#1500;&#1493;&#1490; &#1513;&#1500;
                            &#1502;&#1492;
                            &#1513;&#1504;&#1514;&#1497;&#1489; &#1502;&#1505;&#1504;&#1503; &#1499;&#1491;&#1497;
                            &#1500;&#1488;&#1489;&#1495;&#1503; &#1505;&#1497;&#1504;&#1493;&#1503; &#1500;&#1488;
                            &#1512;&#1510;&#1493;&#1497;)</span></p>
                </td>
                <td class="c24" colspan="1" rowspan="1">
                    <p class="c1" dir="rtl"><span class="c7 c18"><a class="c10"
                                href="https://www.enativ.com/102">&#1497;&#1493;&#1510;&#1512;
                                &#1492;&#1500;&#1493;&#1490;&#1497;&#1501;</a></span></p>
                </td>
                <td class="c16" colspan="1" rowspan="1">
                    <p class="c1" dir="rtl"><span class="c12 c7">&#1492;&#1493;&#1512;&#1491;&#1492;
                            &#1489;&#1500;&#1489;&#1491;</span></p>
                </td>
            </tr>
            <tr class="c4">
                <td class="c15" colspan="1" rowspan="1">
                    <p class="c1 c17 c0" dir="rtl"><span class="c3"></span></p>
                </td>
                <td class="c21" colspan="1" rowspan="1">
                    <p class="c1" dir="rtl"><span class="c3">&#1514;&#1506;&#1493;&#1491;&#1514;
                            &#1488;&#1489;&#1496;&#1495;&#1492; &#1504;&#1514;&#1497;&#1489;</span></p>
                </td>
                <td class="c24" colspan="1" rowspan="1">
                    <p class="c1" dir="rtl"><span class="c5"><a class="c10"
                                href="https://www.enativ.com/123">&#1514;&#1506;&#1493;&#1491;&#1514;
                                &#1488;&#1489;&#1496;&#1495;&#1492; &#1513;&#1500;
                                &#1504;&#1514;&#1497;&#1489;</a></span>
                    </p>
                </td>
                <td class="c16" colspan="1" rowspan="1">
                    <p class="c1" dir="rtl"><span class="c7 c12">&#1492;&#1493;&#1512;&#1491;&#1492;
                            &#1493;&#1492;&#1514;&#1511;&#1504;&#1492;</span></p>
                </td>
            </tr>
        </table>
        <p class="c6 c0"><span class="c12 c7"></span></p><a id="t.c3f44a38ac160bdc8c9b31011e0f6e40621e1a27"></a><a
            id="t.1"></a>
        <table class="c36" dir="rtl">
            <tr class="c0">
                <td class="c31" colspan="1" rowspan="2">
                    <p class="c6" dir="rtl"><span class="c3">&#1513;&#1500;&#1489; &#1489;&#39; -
                            &#1492;&#1514;&#1511;&#1504;&#1514; &#1504;&#1514;&#1497;&#1489;</span></p>
                </td>
            </tr>
            <tr class="c0"></tr>
            <tr class="c4">
                <td class="c31" colspan="1" rowspan="1">
                    <p class="c1" dir="rtl"><span class="c7">&#1497;&#1513; &#1500;&#1504;&#1505;&#1493;&#1514;
                            &#1500;&#1492;&#1514;&#1511;&#1497;&#1503; &#1488;&#1514; &#1504;&#1514;&#1497;&#1489;
                            &#1489;&#1488;&#1502;&#1510;&#1506;&#1493;&#1514; </span><span
                            class="c32">&#1492;&#1502;&#1514;&#1511;&#1497;&#1503;
                            &#1492;&#1502;&#1500;&#1488;</span><span
                            class="c12 c7">&nbsp;&#1513;&#1500;&#1492;&#1500;&#1503;. &#1488;&#1501;
                            &#1492;&#1514;&#1511;&#1504;&#1492; &#1494;&#1493; &#1504;&#1499;&#1513;&#1500;&#1492;
                            &#1488;&#1494; &#1497;&#1513; &#1500;&#1492;&#1514;&#1511;&#1497;&#1503; &#1488;&#1514;
                            &#1490;&#1512;&#1505;&#1514; &#1504;&#1514;&#1497;&#1489;
                            &#1492;&#1502;&#1514;&#1488;&#1497;&#1502;&#1492; &#1500;&#1502;&#1506;&#1512;&#1499;&#1514;
                            &#1492;&#1492;&#1508;&#1506;&#1500;&#1492; &#1513;&#1500;&#1499;&#1501;</span></p>
                </td>
            </tr>
        </table>
        <p class="c6 c0"><span class="c12 c7"></span></p><a id="t.733484125f5f5b25d8bd8de06aeee56bff4fa372"></a><a
            id="t.2"></a>
        <table class="c36" dir="rtl">
            <tr class="c4">
                <td class="c40" colspan="1" rowspan="1">
                    <p class="c6 c0" dir="rtl"><span class="c28"></span></p>
                </td>
                <td class="c33" colspan="1" rowspan="1">
                    <p class="c6 c0" dir="rtl"><span class="c7 c43"></span></p>
                </td>
            </tr>
            <tr class="c4">
                <td class="c22 c34" colspan="1" rowspan="1">
                    <p class="c6" dir="rtl"><span class="c7">&#1511;&#1493;&#1489;&#1509; SETUP</span></p>
                </td>
                <td class="c22 c27" colspan="1" rowspan="1">
                    <p class="c6" dir="rtl"><span class="c32 c49"><a class="c10"
                                href="https://enativ.com/1">&#1500;&#1495;&#1509;
                                &#1500;&#1492;&#1493;&#1512;&#1491;&#1492;</a></span></p>
                </td>
            </tr>
            <tr class="c4">
                <td class="c20" colspan="1" rowspan="1">
                    <p class="c6" dir="rtl"><span class="c7">&#1511;&#1493;&#1489;&#1509; MSI
                            &#1506;&#1489;&#1493;&#1512;
                            Win 10 32 bit</span></p>
                </td>
                <td class="c19" colspan="1" rowspan="1">
                    <p class="c6" dir="rtl"><span class="c5"><a class="c10"
                                href="https://www.enativ.com/download/versions/last/v45/Nativ_x86wwd.msi">&#1500;&#1495;&#1509;
                                &#1500;&#1492;&#1493;&#1512;&#1491;&#1492;</a></span></p>
                </td>
            </tr>
            <tr class="c4">
                <td class="c20" colspan="1" rowspan="1">
                    <p class="c6" dir="rtl"><span class="c7">&#1511;&#1493;&#1489;&#1509; MSI
                            &#1506;&#1489;&#1493;&#1512;
                            Win 10 64 bit</span></p>
                </td>
                <td class="c19" colspan="1" rowspan="1">
                    <p class="c6" dir="rtl"><span class="c5"><a class="c10"
                                href="https://www.enativ.com/download/versions/last/v45/Nativ_x64wwd.msi">&#1500;&#1495;&#1509;
                                &#1500;&#1492;&#1493;&#1512;&#1491;&#1492;</a></span></p>
                </td>
            </tr>
            <tr class="c4">
                <td class="c20" colspan="1" rowspan="1">
                    <p class="c6" dir="rtl"><span class="c7"><a class="c10"
                                href="https://enativ.com/2">&#1502;&#1506;&#1491;&#1499;&#1503;
                                &#1490;&#1512;&#1505;&#1492;</a></span></p>
                </td>
                <td class="c19" colspan="1" rowspan="1">
                    <p class="c6"><span class="c5"><a class="c10" href="https://enativ.com/2">&#1500;&#1495;&#1509;
                                &#1500;&#1492;&#1493;&#1512;&#1491;&#1492;</a></span></p>
                </td>
            </tr>
        </table>
        <p class="c6 c0"><span class="c8 c32"></span></p><a id="t.21ac2140b3714fcda10a4c182ec6e91a5981b939"></a><a
            id="t.3"></a>
        <table class="c36" dir="rtl">
            <tr class="c4">
                <td class="c31" colspan="1" rowspan="1">
                    <p class="c6" dir="rtl"><span class="c3">&#1513;&#1500;&#1489; &#1490;&#39;</span></p>
                </td>
            </tr>
            <tr class="c4">
                <td class="c31" colspan="1" rowspan="1">
                    <p class="c6" dir="rtl"><span class="c3">&#1492;&#1495;&#1512;&#1490;&#1514;
                            &#1504;&#1514;&#1497;&#1489;
                            &#1489;&#1488;&#1504;&#1496;&#1497;&#1493;&#1497;&#1512;&#1493;&#1505;, FIREWALL
                            &#1493;&#1513;&#1512;&#1514;&#1497; &#1508;&#1512;&#1493;&#1511;&#1505;&#1497;</span></p>
                </td>
            </tr>
            <tr class="c4">
                <td class="c31" colspan="1" rowspan="1">
                    <p class="c1" dir="rtl"><span class="c12 c7">&#1497;&#1513;
                            &#1500;&#1492;&#1495;&#1512;&#1497;&#1490;
                            &#1488;&#1514; &#1492;&#1511;&#1489;&#1510;&#1497;&#1501;,
                            &#1492;&#1514;&#1497;&#1511;&#1497;&#1493;&#1514;,
                            &#1493;&#1492;&#1499;&#1514;&#1493;&#1489;&#1493;&#1514;
                            &#1513;&#1514;&#1502;&#1510;&#1488;&#1493; &#1489;&#1499;&#1514;&#1493;&#1489;&#1514;
                            &#1513;&#1500;&#1492;&#1500;&#1503; &#1489;&#1514;&#1493;&#1499;&#1504;&#1493;&#1514;
                            &#1492;&#1488;&#1504;&#1496;&#1497;&#1493;&#1497;&#1512;&#1493;&#1505;
                            &#1513;&#1500;&#1499;&#1501;, &#1489;FIREWALL &#1493;&#1489;&#1513;&#1512;&#1514;&#1497;
                            &#1492;&#1508;&#1512;&#1493;&#1511;&#1505;&#1497; (&#1490;&#1501;
                            &#1511;&#1489;&#1510;&#1497;
                            PAC)</span></p>
                </td>
            </tr>
            <tr class="c4">
                <td class="c31 c50" colspan="1" rowspan="1">
                    <p class="c1"><span class="c5"><a class="c10"
                                href="https://docs.google.com/spreadsheets/d/1yu4jx1Qev5hjM--Fb7SIsMUQRS4OXZ-Wq3EJRqZxBmQ/edit#gid=0">https://docs.google.com/spreadsheets/d/1yu4jx1Qev5hjM--Fb7SIsMUQRS4OXZ-Wq3EJRqZxBmQ/edit#gid=0</a></span>
                    </p>
                </td>
            </tr>
        </table>
        <p class="c0 c47" dir="rtl"><span class="c46"></span></p>
    </div>
</div>