<h1 class="text-center">העלאת תוכן לאזור האישי באתר</h1>
<hr>
<div class="content">
    <h3>העלאת תמונה </h3>
    <label class="image-upload-container btn btn-bwm">
        <span> בחר תמונה:</span>
        <input #imageInput type="file" accept="image/jpg" (change)="processFile(imageInput)">
    </label>
    <label class="image-upload-container btn btn-bwm">
        <span> בחר תמונה מתוך מאגר:</span>
        <select name="places" (change)="updateImage($event.target.value)">
            <option value="-1"></option>
            <option *ngFor="let i of imagesList" [value]="i" [selected]="i.name=='images/user-bg.png' ? true : null">
                {{i}}
            </option>
        </select>
    </label>
    <p>{{resAlert}}</p>
    <label>התמונה המוצגת באתר:</label>
    <div class="row">
        <div class="col-3"></div>
        <div class="col-6"><img class="img-bg" [src]="bgURL" /></div>
        <div class="col-3"></div>
    </div>
</div>
<hr>
<div class="content">
    <h3>העלאת תמונה לגודל 1366 </h3>
    <label class="image-upload-container btn btn-bwm">
        <span> בחר תמונה:</span>
        <input #imageInput1366 type="file" accept="image/jpg" (change)="processFile1366(imageInput1366)">
    </label>
    <p>{{resAlert1366}}</p>
    <label>התמונה המוצגת באתר:</label>
    <div class="row">
        <div class="col-3"></div>
        <div class="col-6"><img class="img-bg" [src]="bgURL1366" /></div>
        <div class="col-3"></div>
    </div>
</div>
<hr>
<div class="content">
    <p>{{alertResAlert}}</p>
    <h3>העלאת הודעה </h3>
    <div class="row">
        <div class="col-3"></div>
        <div class="col-6">
            <form [formGroup]="alertForm" class="home-form text-center" (ngSubmit)="onSubmit(alertForm.value)">
                <div class="form-group">
                    <textarea type="text" (focus)="clearAlert()" formControlName="alertText"
                        class="form-control text-center" id="alertText" placeholder="תוכן הודעה"
                        [ngModel]="curAlert"></textarea>
                </div>
                <div class="row">
                    <div class="col-12">
                        <button type="submit" class="btn-nativ form-btn">העלאה
                            &#10095;&#10095;</button>
                    </div>
                </div>
            </form>
        </div>
        <div class="col-3"></div>
    </div>
</div>