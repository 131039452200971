<div *ngIf="isShow" class="chat-messages" [ngClass]="showInput ? '':'robotMessages'">
    <button type="button" class="close" (click)="toggleChat()" aria-label="Close">
        <span aria-hidden="true"><img src="../../../assets/images/chat/close.png" /></span>
    </button>
    <div class="chat-header">
        <p>צ'אט נתיב</p>
    </div>
    <span class="pointer back-arrow" (click)="next(currentStep,'welcomeMessage')" ngbTooltip="מעבר לתחילת השיחה"><img
            *ngIf="!isThing && !notHome && !showInput" src="../../../assets/images/chat/back-arrow.png" /></span>
    <div *ngIf="startChat || chatProcNow['start']">
        <div [ngClass]="{ 'chat-messages-show-container': !startLogout }" class="chat-messages-show-container"
            [scrollTop]="!startLogout?scrollMe.scrollHeight:0" #scrollMe>
            <ul *ngIf="!loginSoftware" id="message-list" class="chat-messages-show-list">
                <li *ngFor="let msg of messages">
                    <div [ngClass]="msg.type=='nativ'?'row':'user-pro'">
                        <p [ngClass]="msg.type=='nativ'?'name-nativ':'name-user'">{{writeName}} {{msg.time}}</p>
                        <img [src]="msg.imgSrc" [ngClass]="msg.type=='nativ'?'nativ-img':''" />
                        <div *ngIf="msg.type=='nativ'" class="text-right d-flex flex-column align-items-end time-nativ">
                            {{msg.time}}</div>
                    </div>
                    <div id="message-id" class="chat-text" [ngClass]="msg.type=='user' ?'user-text':'nativ-text'"
                        [innerHTML]="msg.text">{{msg.text}}</div>
                </li>
            </ul>
            <div class="container" *ngIf="loginSoftware">
                <button class="btn-nativ btn-chat row" (click)="connectSoftware()">יש להתחבר לתוכנת נתיב</button>
            </div>
            <div class="container" *ngIf="chatProcNow['techSupport']">
                <button class="btn-nativ btn-chat row"
                    (click)="next('techSupport',chatProcess.techSupport.choice[0].nextStep,chatProcess.techSupport.choice[0].text)">{{
                    chatProcess.techSupport.choice[0].text }}</button>
                <button class="btn-nativ btn-chat row"
                    (click)="next('techSupport',chatProcess.techSupport.choice[1].nextStep,chatProcess.techSupport.choice[1].text)">{{
                    chatProcess.techSupport.choice[1].text }}</button>
                <button class="btn-nativ btn-chat row"
                    (click)="next('techSupport',chatProcess.techSupport.choice[2].nextStep,chatProcess.techSupport.choice[2].text)">{{
                    chatProcess.techSupport.choice[2].text }}</button>
                <!-- <button class="btn-nativ btn-chat row"
                    (click)="next('techSupport',chatProcess.techSupport.choice[3].nextStep,chatProcess.techSupport.choice[3].text)">{{
                    chatProcess.techSupport.choice[3].text }}</button>
                <button class="btn-nativ btn-chat row"
                    (click)="next('techSupport',chatProcess.techSupport.choice[4].nextStep,chatProcess.techSupport.choice[4].text)">{{
                    chatProcess.techSupport.choice[4].text }}</button> -->
                <button class="btn-nativ btn-chat row"
                    (click)="next('techSupport',chatProcess.techSupport.choice[5].nextStep,chatProcess.techSupport.choice[5].text)"
                    *ngIf="hasSim">{{ chatProcess.techSupport.choice[5].text }}</button>
            </div>
            <div class="container" *ngIf="chatProcNow['moreAction']">
                <button class="btn-nativ btn-chat row"
                    (click)="next('moreAction',chatProcess.moreAction.choice[0].nextStep,chatProcess.moreAction.choice[0].text)">{{
                    chatProcess.moreAction.choice[0].text }}</button>
                <button class="btn-nativ btn-chat row"
                    (click)="next('moreAction',chatProcess.moreAction.choice[1].nextStep,chatProcess.moreAction.choice[1].text)">{{
                    chatProcess.moreAction.choice[1].text }}</button>
                <button class="btn-nativ btn-chat row"
                    (click)="next('moreAction',chatProcess.moreAction.choice[2].nextStep,chatProcess.moreAction.choice[2].text)">{{
                    chatProcess.moreAction.choice[2].text }}</button>
                <button class="btn-nativ btn-chat row"
                    (click)="next('moreAction',chatProcess.moreAction.choice[3].nextStep,chatProcess.moreAction.choice[3].text)">{{
                    chatProcess.moreAction.choice[3].text }}</button>
            </div>
            <div class="container" *ngIf="chatProcNow['commProblems']">
                <button class="btn-nativ btn-chat row"
                    (click)="next('commProblems',chatProcess.commProblems.choice[0].nextStep,chatProcess.commProblems.choice[0].text)">{{
                    chatProcess.commProblems.choice[0].text }}</button>
                <button class="btn-nativ btn-chat row"
                    (click)="next('commProblems',chatProcess.commProblems.choice[1].nextStep,chatProcess.commProblems.choice[1].text)">{{
                    chatProcess.commProblems.choice[1].text }}</button>
                <!-- <button class="btn-nativ btn-chat row"
                    (click)="next('commProblems',chatProcess.commProblems.choice[2].nextStep,chatProcess.commProblems.choice[2].text)">{{
                    chatProcess.commProblems.choice[2].text }}</button> -->
                <!-- <button class="btn-nativ btn-chat row"
                    (click)="next('commProblems',chatProcess.commProblems.choice[3].nextStep,chatProcess.commProblems.choice[3].text)">{{
                    chatProcess.commProblems.choice[3].text }}</button> -->
            </div>
            <div class="container" *ngIf="chatProcNow['helpInstallGuide']">
                <button class="btn-nativ btn-chat row"
                    (click)="next('helpInstallGuide',chatProcess.helpInstallGuide.choice[0].nextStep,chatProcess.helpInstallGuide.choice[0].text)">{{
                    chatProcess.helpInstallGuide.choice[0].text }}</button>
                <button class="btn-nativ btn-chat row"
                    (click)="next('helpInstallGuide',chatProcess.helpInstallGuide.choice[1].nextStep,chatProcess.helpInstallGuide.choice[1].text)">{{
                    chatProcess.helpInstallGuide.choice[1].text }}</button>
            </div>
            <div class="container" *ngIf="chatProcNow['siteWaitNotWorkSite']">
                <button class="btn-nativ btn-chat row"
                    (click)="next('siteWaitNotWorkSite',chatProcess.siteWaitNotWorkSite.choice[0].nextStep,chatProcess.siteWaitNotWorkSite.choice[0].text)">{{
                    chatProcess.siteWaitNotWorkSite.choice[0].text }}</button>
                <button class="btn-nativ btn-chat row"
                    (click)="next('siteWaitNotWorkSite',chatProcess.siteWaitNotWorkSite.choice[1].nextStep,chatProcess.siteWaitNotWorkSite.choice[1].text)">{{
                    chatProcess.siteWaitNotWorkSite.choice[1].text }}</button>
            </div>
            <div class="container" *ngIf="chatProcNow['typeProbInstall']">
                <button class="btn-nativ btn-chat row"
                    (click)="next('typeProbInstall',chatProcess.typeProbInstall.choice[0].nextStep,chatProcess.typeProbInstall.choice[0].text)">{{
                    chatProcess.typeProbInstall.choice[0].text }}</button>
                <button class="btn-nativ btn-chat row"
                    (click)="next('typeProbInstall',chatProcess.typeProbInstall.choice[1].nextStep,chatProcess.typeProbInstall.choice[1].text)">{{
                    chatProcess.typeProbInstall.choice[1].text }}</button>
            </div>
            <div class="container" *ngIf="chatProcNow['showCountPromotion']">
                <button class="btn-nativ btn-chat row"
                    (click)="next('showCountPromotion',chatProcess.showCountPromotion.choice[0].nextStep,chatProcess.showCountPromotion.choice[0].text)">{{
                    chatProcess.showCountPromotion.choice[0].text }}</button>
                <button class="btn-nativ btn-chat row"
                    (click)="next('showCountPromotion',chatProcess.showCountPromotion.choice[1].nextStep,chatProcess.showCountPromotion.choice[1].text)">{{
                    chatProcess.showCountPromotion.choice[1].text }}</button>
            </div>
            <div class="container" *ngIf="chatProcNow['otherComputerNoExistsEmp']">
                <button class="btn-nativ btn-chat row"
                    (click)="next('otherComputerNoExistsEmp',chatProcess.otherComputerNoExistsEmp.choice[0].nextStep,chatProcess.otherComputerNoExistsEmp.choice[0].text)">{{
                    chatProcess.otherComputerNoExistsEmp.choice[0].text }}</button>
                <button class="btn-nativ btn-chat row"
                    (click)="next('otherComputerNoExistsEmp',chatProcess.otherComputerNoExistsEmp.choice[1].nextStep,chatProcess.otherComputerNoExistsEmp.choice[1].text)">{{
                    chatProcess.otherComputerNoExistsEmp.choice[1].text }}</button>
            </div>
            <div class="container" *ngIf="chatProcNow['otherComputerExistsEmp']">
                <button class="btn-nativ btn-chat row"
                    (click)="next('otherComputerExistsEmp',chatProcess.otherComputerExistsEmp.choice[0].nextStep,chatProcess.otherComputerExistsEmp.choice[0].text)">{{
                    chatProcess.otherComputerExistsEmp.choice[0].text }}</button>
                <button class="btn-nativ btn-chat row"
                    (click)="next('otherComputerExistsEmp',chatProcess.otherComputerExistsEmp.choice[1].nextStep,chatProcess.otherComputerExistsEmp.choice[1].text)">{{
                    chatProcess.otherComputerExistsEmp.choice[1].text }}</button>
            </div>
            <div class="container" *ngIf="chatProcNow['addNewLicence']">
                <button class="btn-nativ btn-chat row"
                    (click)="next('addNewLicence',chatProcess.addNewLicence.choice[0].nextStep,chatProcess.addNewLicence.choice[0].text)">{{
                    chatProcess.addNewLicence.choice[0].text }}</button>
                <button class="btn-nativ btn-chat row"
                    (click)="next('addNewLicence',chatProcess.addNewLicence.choice[1].nextStep,chatProcess.addNewLicence.choice[1].text)">{{
                    chatProcess.addNewLicence.choice[1].text }}</button>
            </div>
            <div class="container" *ngIf="chatProcNow['addNewLicensesConfirmSum']">
                <button class="btn-nativ btn-chat row"
                    (click)="next('addNewLicensesConfirmSum',chatProcess.addNewLicensesConfirmSum.choice[0].nextStep,chatProcess.addNewLicensesConfirmSum.choice[0].text)">{{
                    chatProcess.addNewLicensesConfirmSum.choice[0].text }}</button>
                <button class="btn-nativ btn-chat row"
                    (click)="next('addNewLicensesConfirmSum',chatProcess.addNewLicensesConfirmSum.choice[1].nextStep,chatProcess.addNewLicensesConfirmSum.choice[1].text)">{{
                    chatProcess.addNewLicensesConfirmSum.choice[1].text }}</button>
            </div>
            <!-- <div class="" *ngIf="chatProcNow['installGuide']">
                <div>{{ chatProcess.installGuide.message }}</div>
                <a href="http://www.enativ.com/install-guide">http://www.enativ.com/install-guide</a>
            </div> -->
            <!-- <div class="" *ngIf="chatProcNow['promotionSite']">
                <div *ngIf="promote">{{ chatProcess.promotionSite.message.promote }}</div>
                <div *ngIf="notPromote">{{ chatProcess.promotionSite.message.notPromote }}</div>
            </div> -->
            <!-- <div class="" *ngIf="chatProcNow['howManySitesThisMonth']">
                <div>{{ chatProcess.howManySitesThisMonth.message }}</div>
                <a href="http://www.enativ.com/user-profile">http://www.enativ.com/user-profile</a>
            </div> -->
            <!-- <div class="" *ngIf="chatProcNow['resetLicence']">
                <div *ngIf="oldVersion">{{ chatProcess.resetLicence.message.oldVersion }}</div>
                <div *ngIf="Did1vipOr3ResetThisMonth">{{ chatProcess.resetLicence.message.Did1vipOr3ResetThisMonth }}</div>
                <div *ngIf="resetLicence">{{ chatProcess.resetLicence.message.resetLicence }}</div>
            </div> -->
            <div class="container" *ngIf="chatProcNow['actionsInSim']">
                <button class="btn-nativ btn-chat row"
                    (click)="next('actionsInSim',chatProcess.actionsInSim.choice[0].nextStep,chatProcess.actionsInSim.choice[0].text)">{{
                    chatProcess.actionsInSim.choice[0].text }}</button>
                <button class="btn-nativ btn-chat row"
                    (click)="next('actionsInSim',chatProcess.actionsInSim.choice[1].nextStep,chatProcess.actionsInSim.choice[1].text)"
                    *ngIf="!hasBiggerGiga">{{ chatProcess.actionsInSim.choice[1].text }}</button>
            </div>
            <div class="container" *ngIf="chatProcNow['howManySitesThisMonth']">
                <button class="btn-nativ btn-chat row"
                    (click)="next('howManySitesThisMonth',chatProcess.howManySitesThisMonth.choice[0].nextStep,chatProcess.howManySitesThisMonth.choice[0].text)">{{
                    chatProcess.howManySitesThisMonth.choice[0].text }}</button>
                <button class="btn-nativ btn-chat row"
                    (click)="next('howManySitesThisMonth',chatProcess.howManySitesThisMonth.choice[1].nextStep,chatProcess.howManySitesThisMonth.choice[1].text)"
                    *ngIf="!hasBiggerGiga">{{ chatProcess.howManySitesThisMonth.choice[1].text }}</button>
            </div>
            <div class="container" *ngIf="chatProcNow['robotOrOtomat']">
                <button class="btn-nativ btn-chat row" *ngIf="existEmployees"
                    (click)="next('robotOrOtomat',chatProcess.robotOrOtomat.choice[0].nextStep,chatProcess.robotOrOtomat.choice[0].text)">{{
                    chatProcess.robotOrOtomat.choice[0].text }}</button>
                <button class="btn-nativ btn-chat row"
                    (click)="next('robotOrOtomat',chatProcess.robotOrOtomat.choice[1].nextStep,chatProcess.robotOrOtomat.choice[1].text)"
                    q>{{ chatProcess.robotOrOtomat.choice[1].text }}</button>
            </div>
            <div class="container" *ngIf="chatProcNow['showSufring']">
                <button class="btn-nativ btn-chat row"
                    (click)="next('showSufring',chatProcess.showSufring.choice[0].nextStep,chatProcess.showSufring.choice[0].text)">{{
                    chatProcess.showSufring.choice[0].text }}</button>
                <button class="btn-nativ btn-chat row"
                    (click)="next('showSufring',chatProcess.showSufring.choice[1].nextStep,chatProcess.showSufring.choice[1].text)">{{
                    chatProcess.showSufring.choice[1].text }}</button>
            </div>
            <div class="container" *ngIf="chatProcNow['notSucsessManagerPass']">
                <button class="btn-nativ btn-chat row"
                    (click)="next('notSucsessManagerPass',chatProcess.notSucsessManagerPass.choice[0].nextStep,chatProcess.notSucsessManagerPass.choice[0].text)">{{
                    chatProcess.notSucsessManagerPass.choice[0].text }}</button>
                <button class="btn-nativ btn-chat row"
                    (click)="next('notSucsessManagerPass',chatProcess.notSucsessManagerPass.choice[1].nextStep,chatProcess.notSucsessManagerPass.choice[1].text)">{{
                    chatProcess.notSucsessManagerPass.choice[1].text }}</button>
            </div>
            <div class="container" *ngIf="chatProcNow['gigaOptions']">
                <button class="btn-nativ btn-chat row"
                    (click)="next('gigaOptions',chatProcess.gigaOptions.choice[0].nextStep,chatProcess.gigaOptions.choice[0].text)"
                    *ngIf="showGigaOption(chatProcess.gigaOptions.choice[0].text)">{{
                    chatProcess.gigaOptions.choice[0].text }}</button>
                <button class="btn-nativ btn-chat row"
                    (click)="next('gigaOptions',chatProcess.gigaOptions.choice[1].nextStep,chatProcess.gigaOptions.choice[1].text)"
                    *ngIf="showGigaOption(chatProcess.gigaOptions.choice[0].text)">{{
                    chatProcess.gigaOptions.choice[1].text }}</button>
                <button class="btn-nativ btn-chat row"
                    (click)="next('gigaOptions',chatProcess.gigaOptions.choice[2].nextStep,chatProcess.gigaOptions.choice[2].text)"
                    *ngIf="showGigaOption(chatProcess.gigaOptions.choice[0].text)">{{
                    chatProcess.gigaOptions.choice[2].text }}</button>
                <button class="btn-nativ btn-chat row"
                    (click)="next('gigaOptions',chatProcess.gigaOptions.choice[3].nextStep)">{{
                    chatProcess.gigaOptions.choice[3].text }}</button>
            </div>
            <div class="container" *ngIf="chatProcNow['howLong']">
                <button class="btn-nativ btn-chat row"
                    (click)="next('howLong',chatProcess.howLong.choice[0].nextStep,chatProcess.howLong.choice[0].text)">{{
                    chatProcess.howLong.choice[0].text }}</button>
                <button class="btn-nativ btn-chat row"
                    (click)="next('howLong',chatProcess.howLong.choice[1].nextStep,chatProcess.howLong.choice[1].text)">{{
                    chatProcess.howLong.choice[1].text }}</button>
            </div>
            <div class="container" *ngIf="chatProcNow['waitForUpdate']">
                <button class="btn-nativ btn-chat row"
                    (click)="next('waitForUpdate',chatProcess.waitForUpdate.choice[0].nextStep,chatProcess.waitForUpdate.choice[0].text)">{{
                    chatProcess.waitForUpdate.choice[0].text }}</button>
            </div>
            <div class="container" *ngIf="chatProcNow['notSuccsesUpdate']">
                <button class="btn-nativ btn-chat row"
                    (click)="next('notSuccsesUpdate',chatProcess.notSuccsesUpdate.choice[0].nextStep,chatProcess.notSuccsesUpdate.choice[0].text)">{{
                    chatProcess.notSuccsesUpdate.choice[0].text }}</button>
                <button class="btn-nativ btn-chat row"
                    (click)="next('notSuccsesUpdate',chatProcess.notSuccsesUpdate.choice[1].nextStep,chatProcess.notSuccsesUpdate.choice[1].text)">{{
                    chatProcess.notSuccsesUpdate.choice[1].text }}</button>
            </div>
            <div class="container" *ngIf="chatProcNow['whiteList']">
                <button class="btn-nativ btn-chat row"
                    (click)="next('whiteList',chatProcess.whiteList.choice[0].nextStep,chatProcess.whiteList.choice[0].text)">{{
                    chatProcess.whiteList.choice[0].text }}</button>
                <button class="btn-nativ btn-chat row"
                    (click)="next('whiteList',chatProcess.whiteList.choice[1].nextStep,chatProcess.whiteList.choice[1].text)">{{
                    chatProcess.whiteList.choice[1].text }}</button>
            </div>
            <div class="container" *ngIf="chatProcNow['chooseValidation']">
                <button class="btn-nativ btn-chat row"
                    (click)="next('chooseValidation',chatProcess.chooseValidation.choice[0].nextStep,chatProcess.chooseValidation.choice[0].text)">{{
                    chatProcess.chooseValidation.choice[0].text }}</button>
                <button class="btn-nativ btn-chat row"
                    (click)="next('chooseValidation',chatProcess.chooseValidation.choice[1].nextStep,chatProcess.chooseValidation.choice[1].text)">{{
                    chatProcess.chooseValidation.choice[1].text }}</button>
            </div>
            <div class="container" *ngIf="chatProcNow['employeeOrRecord']">
                <button class="btn-nativ btn-chat row"
                    (click)="next('employeeOrRecord',chatProcess.employeeOrRecord.choice[0].nextStep,chatProcess.employeeOrRecord.choice[0].text)">{{
                    chatProcess.employeeOrRecord.choice[0].text }}</button>
                <button class="btn-nativ btn-chat row"
                    (click)="next('employeeOrRecord',chatProcess.employeeOrRecord.choice[1].nextStep,chatProcess.employeeOrRecord.choice[1].text)">{{
                    chatProcess.employeeOrRecord.choice[1].text }}</button>
            </div>
            <div class="container" *ngIf="chatProcNow['succesChangeGiga']">
                <div>{{ chatProcess.succesChangeGiga.message }}</div>
            </div>
            <div class="container" *ngIf="chatProcNow['enterNumSim']">
                <!-- <input class="btn-chat row" type="text" [(ngModel)]="ntlogin">
                <button class="btn-nativ btn-chat row"
                    (click)="next('enterNumSim',chatProcess.enterNumSim.choice[0].nextStep,chatProcess.enterNumSim.choice[0].text)">{{
                    chatProcess.enterNumSim.choice[0].text }}</button> -->
                <button class="btn-nativ btn-chat row"
                    (click)="next('enterNumSim',chatProcess.enterNumSim.choice[1].nextStep,chatProcess.enterNumSim.choice[1].text)">{{
                    chatProcess.enterNumSim.choice[1].text }}</button>
            </div>
            <div class="container" *ngIf="chatProcNow['whichSim']">
                <button class="btn-nativ btn-chat row"
                    (click)="next('whichSim',chatProcess.whichSim.choice[0].nextStep,chatProcess.whichSim.choice[0].text)">{{
                    chatProcess.whichSim.choice[0].text }}</button>
                <button class="btn-nativ btn-chat row"
                    (click)="next('whichSim',chatProcess.whichSim.choice[1].nextStep,chatProcess.whichSim.choice[1].text)">{{
                    chatProcess.whichSim.choice[1].text }}</button>
            </div>
            <div class="container" *ngIf="chatProcNow['addLicenseSuccess']">
                <button class="btn-nativ btn-chat row"
                    (click)="next('addLicenseSuccess',chatProcess.addLicenseSuccess.choice.nextStep,chatProcess.addLicenseSuccess.choice.text)">{{
                    chatProcess.addLicenseSuccess.choice.text }}</button>
            </div>
            <div class="container" *ngIf="chatProcNow['moreHelp']">
                <button class="btn-nativ btn-chat row"
                    (click)="next('moreHelp',chatProcess.moreHelp.choice[0].nextStep,chatProcess.moreHelp.choice[0].text)">{{
                    chatProcess.moreHelp.choice[0].text }}</button>
                <button class="btn-nativ btn-chat row"
                    (click)="next('moreHelp',chatProcess.moreHelp.choice[1].nextStep,chatProcess.moreHelp.choice[1].text)">{{
                    chatProcess.moreHelp.choice[1].text }}</button>
                <button class="btn-nativ btn-chat row" *ngIf="existEmployees"
                    (click)="next('moreHelp',chatProcess.moreHelp.choice[2].nextStep,chatProcess.moreHelp.choice[2].text)">{{
                    chatProcess.moreHelp.choice[2].text }}</button>
            </div>
            <div class="container" *ngIf="chatProcNow['moreHelpNoEmployee']">
                <button class="btn-nativ btn-chat row"
                    (click)="next('moreHelpNoEmployee',chatProcess.moreHelpNoEmployee.choice[0].nextStep,chatProcess.moreHelpNoEmployee.choice[0].text)">{{
                    chatProcess.moreHelpNoEmployee.choice[0].text }}</button>
                <button class="btn-nativ btn-chat row"
                    (click)="next('moreHelpNoEmployee',chatProcess.moreHelpNoEmployee.choice[1].nextStep,chatProcess.moreHelpNoEmployee.choice[1].text)">{{
                    chatProcess.moreHelpNoEmployee.choice[1].text }}</button>
            </div>
            <div class="container" *ngIf="chatProcNow['typeProblem']">
                <button class="btn-nativ btn-chat row"
                    (click)="puyTypeProb(chatProcess.typeProblem.choice[0].text); next('typeProblem',chatProcess.typeProblem.choice[0].nextStep,chatProcess.typeProblem.choice[0].text)">{{
                    chatProcess.typeProblem.choice[0].text }}</button>
                <button class="btn-nativ btn-chat row"
                    (click)="puyTypeProb(chatProcess.typeProblem.choice[1].text); next('typeProblem',chatProcess.typeProblem.choice[1].nextStep,chatProcess.typeProblem.choice[1].text)">{{
                    chatProcess.typeProblem.choice[1].text }}</button>
                <button class="btn-nativ btn-chat row"
                    (click)="puyTypeProb('גם תמונה לא טובה וגם תוכן בעייתי'); next('typeProblem',chatProcess.typeProblem.choice[2].nextStep,chatProcess.typeProblem.choice[2].text)">{{
                    chatProcess.typeProblem.choice[2].text }}</button>
            </div>
            <div class="container" *ngIf="chatProcNow['isBlockSite']">
                <button class="btn-nativ btn-chat row"
                    (click)="next('isBlockSite',chatProcess.isBlockSite.choice[0].nextStep,chatProcess.isBlockSite.choice[0].text)">{{
                    chatProcess.isBlockSite.choice[0].text }}</button>
                <button class="btn-nativ btn-chat row"
                    (click)="next('isBlockSite',chatProcess.isBlockSite.choice[1].nextStep,chatProcess.isBlockSite.choice[1].text)">{{
                    chatProcess.isBlockSite.choice[1].text }}</button>
            </div>
            <div class="container" *ngIf="chatProcNow['addExplainProbSite']">
                <button class="btn-nativ btn-chat row"
                    (click)="next('addExplainProbSite',chatProcess.addExplainProbSite.choice[0].nextStep,chatProcess.addExplainProbSite.choice[0].text)">{{
                    chatProcess.addExplainProbSite.choice[0].text }}</button>
                <button class="btn-nativ btn-chat row"
                    (click)="next('addExplainProbSite',chatProcess.addExplainProbSite.choice[1].nextStep,chatProcess.addExplainProbSite.choice[1].text)">{{
                    chatProcess.addExplainProbSite.choice[1].text }}</button>
            </div>
            <div class="container" *ngIf="chatProcNow['finishResetLicence']">
                <button class="btn-nativ btn-chat row"
                    (click)="next('finishResetLicence',chatProcess.finishResetLicence.choice[0].nextStep,chatProcess.finishResetLicence.choice[0].text)">{{
                    chatProcess.moreHelp.choice[0].text }}</button>
                <button class="btn-nativ btn-chat row"
                    (click)="next('finishResetLicence',chatProcess.finishResetLicence.choice[1].nextStep,chatProcess.finishResetLicence.choice[1].text)">{{
                    chatProcess.finishResetLicence.choice[1].text }}</button>
                <button class="btn-nativ btn-chat row"
                    (click)="next('finishResetLicence',chatProcess.finishResetLicence.choice[2].nextStep,chatProcess.finishResetLicence.choice[2].text)">{{
                    chatProcess.finishResetLicence.choice[2].text }}</button>
            </div>
            <div class="container" *ngIf="chatProcNow['thisComputer']">
                <button class="btn-nativ btn-chat row"
                    (click)="next('thisComputer',chatProcess.thisComputer.choice[0].nextStep,chatProcess.thisComputer.choice[0].text)">{{
                    chatProcess.thisComputer.choice[0].text }}</button>
                <button class="btn-nativ btn-chat row"
                    (click)="next('thisComputer',chatProcess.thisComputer.choice[1].nextStep,chatProcess.thisComputer.choice[1].text)">{{
                    chatProcess.thisComputer.choice[1].text }}</button>
            </div>
            <div class="container" *ngIf="chatProcNow['isReset']">
                <button class="btn-nativ btn-chat row"
                    (click)="next('isReset',chatProcess.isReset.choice[0].nextStep,chatProcess.isReset.choice[0].text)">{{
                    chatProcess.isReset.choice[0].text }}</button>
                <button class="btn-nativ btn-chat row"
                    (click)="next('isReset',chatProcess.isReset.choice[1].nextStep,chatProcess.isReset.choice[1].text)">{{
                    chatProcess.isReset.choice[1].text }}</button>
            </div>
            <div class="container" *ngIf="chatProcNow['yourComOrNewComp']">
                <button class="btn-nativ btn-chat row"
                    (click)="next('yourComOrNewComp',chatProcess.yourComOrNewComp.choice[0].nextStep,chatProcess.yourComOrNewComp.choice[0].text)">{{
                    chatProcess.yourComOrNewComp.choice[0].text }}</button>
                <button class="btn-nativ btn-chat row"
                    (click)="next('yourComOrNewComp',chatProcess.yourComOrNewComp.choice[1].nextStep,chatProcess.yourComOrNewComp.choice[1].text)">{{
                    chatProcess.yourComOrNewComp.choice[1].text }}</button>
            </div>
            <div class="container" *ngIf="chatProcNow['notSuccessUpdate']">
                <button class="btn-nativ btn-chat row"
                    (click)="next('notSuccessUpdate',chatProcess.notSuccessUpdate.choice[0].nextStep,chatProcess.notSuccessUpdate.choice[0].text)">{{
                    chatProcess.notSuccessUpdate.choice[0].text }}</button>
                <button class="btn-nativ btn-chat row"
                    (click)="next('notSuccessUpdate',chatProcess.notSuccessUpdate.choice[1].nextStep,chatProcess.notSuccessUpdate.choice[1].text)">{{
                    chatProcess.notSuccessUpdate.choice[1].text }}</button>
            </div>
            <div class="container" *ngIf="chatProcNow['overSiteQuota']">
                <button class="btn-nativ btn-chat row"
                    (click)="next('overSiteQuota',chatProcess.overSiteQuota.choice[0].nextStep,chatProcess.overSiteQuota.choice[0].text)">{{
                    chatProcess.overSiteQuota.choice[0].text }}</button>
                <button class="btn-nativ btn-chat row"
                    (click)="next('overSiteQuota',chatProcess.overSiteQuota.choice[1].nextStep,chatProcess.overSiteQuota.choice[1].text)">{{
                    chatProcess.overSiteQuota.choice[1].text }}</button>
            </div>
            <div class="container" *ngIf="chatProcNow['whichMail']">
                <button class="btn-nativ btn-chat row"
                    (click)="next('whichMail',chatProcess.whichMail.choice[0].nextStep,chatProcess.whichMail.choice[0].text)">{{
                    chatProcess.whichMail.choice[0].text }}</button>
                <button class="btn-nativ btn-chat row"
                    (click)="next('whichMail',chatProcess.whichMail.choice[1].nextStep,chatProcess.whichMail.choice[1].text)">{{
                    chatProcess.whichMail.choice[1].text }}</button>
            </div>
            <div class="container" *ngIf="chatProcNow['helpOptions']">
                <button class="btn-nativ btn-chat row"
                    (click)="next('helpOptions',chatProcess.helpOptions.choice[0].nextStep, chatProcess.helpOptions.choice[0].text)">{{
                    chatProcess.helpOptions.choice[0].text }}</button>
                <button class="btn-nativ btn-chat row"
                    (click)="next('helpOptions',chatProcess.helpOptions.choice[1].nextStep, chatProcess.helpOptions.choice[1].text)">{{
                    chatProcess.helpOptions.choice[1].text }}</button>
                <button class="btn-nativ btn-chat row" *ngIf="!vipUser"
                    (click)="next('helpOptions',chatProcess.helpOptions.choice[2].nextStep, chatProcess.helpOptions.choice[2].text)">{{
                    chatProcess.helpOptions.choice[2].text }}</button>
            </div>
            <div class="container" *ngIf="chatProcNow['sites']">
                <button class="btn-nativ btn-chat row"
                    (click)="next('sites',chatProcess.sites.choice[0].nextStep,chatProcess.sites.choice[0].text)">{{
                    chatProcess.sites.choice[0].text }}</button>
                <button class="btn-nativ btn-chat row"
                    (click)="next('sites',chatProcess.sites.choice[1].nextStep,chatProcess.sites.choice[1].text)">{{
                    chatProcess.sites.choice[1].text }}</button>
                <button class="btn-nativ btn-chat row"
                    (click)="next('sites',chatProcess.sites.choice[2].nextStep,chatProcess.sites.choice[2].text)">{{
                    chatProcess.sites.choice[2].text }}</button>
                <button class="btn-nativ btn-chat row"
                    (click)="next('sites',chatProcess.sites.choice[3].nextStep,chatProcess.sites.choice[3].text)">{{
                    chatProcess.sites.choice[3].text }}</button>
                <button class="btn-nativ btn-chat row"
                    (click)="next('sites',chatProcess.sites.choice[4].nextStep,chatProcess.sites.choice[4].text)">{{
                    chatProcess.sites.choice[4].text }}</button>
            </div>
            <div class="container" *ngIf="chatProcNow['noNativSoftware']">
                <button class="btn-nativ btn-chat row"
                    (click)="next('noNativSoftware',chatProcess.noNativSoftware.choice[0].nextStep, chatProcess.noNativSoftware.choice[0].text)">{{
                    chatProcess.noNativSoftware.choice[0].text }}</button>
                <button class="btn-nativ btn-chat row"
                    (click)="next('noNativSoftware',chatProcess.noNativSoftware.choice[1].nextStep, chatProcess.noNativSoftware.choice[1].text)">{{
                    chatProcess.noNativSoftware.choice[1].text }}</button>
            </div>
            <div class="container" *ngIf="chatProcNow['record2']">
                <button class="btn-nativ btn-chat row"
                    (click)="next('record2',chatProcess.record2.choice[0].nextStep, chatProcess.record2.choice[0].text)">{{
                    chatProcess.record2.choice[0].text }}</button>
                <button class="btn-nativ btn-chat row"
                    (click)="next('record2',chatProcess.record2.choice[1].nextStep, chatProcess.record2.choice[1].text)">{{
                    chatProcess.record2.choice[1].text }}</button>
            </div>
            <div class="container" *ngIf="chatProcNow['makeRecord']">
                <button class="btn-nativ btn-chat row"
                    (click)="next('makeRecord',chatProcess.makeRecord.choice.nextStep, chatProcess.makeRecord.choice.text)">{{
                    chatProcess.makeRecord.choice.text }}</button>
            </div>
            <div class="container" *ngIf="chatProcNow['sitesForThisNonth']">
                <button class="btn-nativ btn-chat row"
                    (click)="next('sitesForThisNonth',chatProcess.sitesForThisNonth.choice[0].nextStep, chatProcess.sitesForThisNonth.choice[0].text)">{{
                    chatProcess.sitesForThisNonth.choice[0].text }}</button>
                <button class="btn-nativ btn-chat row"
                    (click)="next('sitesForThisNonth',chatProcess.sitesForThisNonth.choice[1].nextStep, chatProcess.sitesForThisNonth.choice[1].text)">{{
                    chatProcess.sitesForThisNonth.choice[1].text }}</button>
            </div>
            <div class="container" *ngIf="chatProcNow['isFeedback']">
                <button class="btn-nativ btn-chat row"
                    (click)="next('isFeedback',chatProcess.isFeedback.choice[0].nextStep, chatProcess.isFeedback.choice[0].text)">{{
                    chatProcess.isFeedback.choice[0].text }}</button>
                <button class="btn-nativ btn-chat row"
                    (click)="next('isFeedback',chatProcess.isFeedback.choice[1].nextStep, chatProcess.isFeedback.choice[1].text)">{{
                    chatProcess.isFeedback.choice[1].text }}</button>
            </div>
            <div class="container feedback-chat" *ngIf="chatProcNow['feedbackQ1Emp']">
                <ul>
                    <div class="form-check form-check-inline">
                        <li class="list-group-item"><img src="../../../assets/images/feedback/good.png" /></li>
                        <li class="list-group-item" *ngFor="let item of checklist1">
                            <input type="checkbox" value="{{item.id}}" [checked]="item.isSelected"
                                (change)="change1(item)" />
                            <label class="form-check-label" for="gridRadios1">
                                {{item.value}}
                            </label>
                        </li>
                        <li class="list-group-item"><img class="last" src="../../../assets/images/feedback/bad.png" />
                        </li>
                    </div>
                </ul>
            </div>
            <div class="container feedback-chat" *ngIf="chatProcNow['feedbackQ2Emp']">
                <ul>
                    <div class="form-check form-check-inline">
                        <li class="list-group-item"><img src="../../../assets/images/feedback/good.png" /></li>
                        <li class="list-group-item" *ngFor="let item of checklist2">
                            <input type="checkbox" value="{{item.id}}" [checked]="item.isSelected"
                                (change)="change2(item)" />
                            <label class="form-check-label" for="gridRadios2">
                                {{item.value}}
                            </label>
                        </li>
                        <li class="list-group-item"><img class="last" src="../../../assets/images/feedback/bad.png" />
                        </li>
                    </div>
                </ul>
            </div>
            <div class="container feedback-chat" *ngIf="chatProcNow['feedbackQ3Emp']">
                <ul>
                    <div class="form-check form-check-inline">
                        <li class="list-group-item"><img src="../../../assets/images/feedback/good.png" /></li>
                        <li class="list-group-item" *ngFor="let item of checklist3">
                            <input type="checkbox" value="{{item.id}}" [checked]="item.isSelected"
                                (change)="change3(item)" />
                            <label class="form-check-label" for="gridRadios3">
                                {{item.value}}
                            </label>
                        </li>
                        <li class="list-group-item"><img class="last" src="../../../assets/images/feedback/bad.png" />
                        </li>
                    </div>
                </ul>
            </div>
            <div class="container feedback-chat" *ngIf="chatProcNow['feedbackQ1Robot']">
                <ul>
                    <div class="form-check form-check-inline">
                        <li class="list-group-item"><img src="../../../assets/images/feedback/good.png" /></li>
                        <li class="list-group-item" *ngFor="let item of checklist1">
                            <input type="checkbox" value="{{item.id}}" [checked]="item.isSelected"
                                (change)="change1(item)" />
                            <label class="form-check-label" for="gridRadios1">
                                {{item.value}}
                            </label>
                        </li>
                        <li class="list-group-item"><img class="last" src="../../../assets/images/feedback/bad.png" />
                        </li>
                    </div>
                </ul>
            </div>
            <div class="container feedback-chat" *ngIf="chatProcNow['feedbackQ2Robot']">
                <ul>
                    <div class="form-check form-check-inline">
                        <li class="list-group-item"><img src="../../../assets/images/feedback/good.png" /></li>
                        <li class="list-group-item" *ngFor="let item of checklist2">
                            <input type="checkbox" value="{{item.id}}" [checked]="item.isSelected"
                                (change)="change2(item)" />
                            <label class="form-check-label" for="gridRadios2">
                                {{item.value}}
                            </label>
                        </li>
                        <li class="list-group-item"><img class="last" src="../../../assets/images/feedback/bad.png" />
                        </li>
                    </div>
                </ul>
            </div>
            <div class="container feedback-chat" *ngIf="chatProcNow['feedbackQ3Robot']">
                <ul>
                    <div class="form-check form-check-inline">
                        <li class="list-group-item"><img src="../../../assets/images/feedback/good.png" /></li>
                        <li class="list-group-item" *ngFor="let item of checklist3">
                            <input type="checkbox" value="{{item.id}}" [checked]="item.isSelected"
                                (change)="change3(item)" />
                            <label class="form-check-label" for="gridRadios3">
                                {{item.value}}
                            </label>
                        </li>
                        <li class="list-group-item"><img class="last" src="../../../assets/images/feedback/bad.png" />
                        </li>
                    </div>
                </ul>
            </div>
            <div class="chat-messages-start" *ngIf="this.chatProcNow['start']">
                <form [formGroup]="contactForm" id="contactForm" class="contact-form text-center"
                    (ngSubmit)="onSubmit(contactForm.value)">

                    <div class="usernamepass">
                        <div class="bold" *ngIf="startLogout">{{ chatProcess.start.message }}</div>
                        <div class="">
                            <div class="input-div text-right">
                                <div class="form-group">
                                    <label for="username">{{ 'form.username' | translate }}</label>
                                    <div class="">
                                        <input (keyup)="isKeyStart('username')" type="text" formControlName="username"
                                            class="form-control input-username-pass" id="username"
                                            [ngClass]="{ 'is-invalid': isKey['username'] && f.username.errors }"
                                            [(ngModel)]="username">
                                        <div *ngIf="isKey['username'] && f.username.errors" class="invalid-feedback">
                                            <div *ngIf="f.username.errors.required || f.username.errors.pattern">{{
                                                'form.validation.emptyUsername' |
                                                translate
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="">
                            <div class="input-div text-right">
                                <div class="form-group">
                                    <label for="password">{{ 'form.password' | translate }}</label>
                                    <div class="">
                                        <input (keyup)="isKeyStart('password')" type="password" autocomplete="off"
                                            formControlName="password" class="form-control input-username-pass"
                                            id="password"
                                            [ngClass]="{ 'is-invalid': isKey['password'] && f.password.errors }"
                                            [(ngModel)]="password">
                                        <div *ngIf="isKey['password'] && f.password.errors" class="invalid-feedback">
                                            <div *ngIf="f.password.errors.required">{{ 'form.validation.emptyPassword' |
                                                translate }}</div>
                                        </div>
                                        <div *ngIf="isKey['password'] && f.password.errors" class="invalid-feedback">
                                            <div *ngIf="f.password.errors.pattern"> {{
                                                'form.validation.invalidPassword' |
                                                translate }} </div>
                                        </div>
                                        <div *ngIf="isKey['password']" class="invalid-feedback">
                                            <div *ngIf="">{{ 'form.validation.invalidPassword' |
                                                translate
                                                }}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="!authenticated" class="invalid-submit">{{authenticatedError}}</div>
                    </div>
                    <div class="forget-password">
                        <a (click)="openModalForgetPass(modalForgetPass)">{{ 'form.forget-password' | translate }}</a>
                    </div>
                    <button class="btn-nativ form-btn next-step-btn" [disabled]="!enableSubmit" type="submit"
                        form="contactForm">
                        {{ chatProcess.start.choice[0].text }}
                    </button>
                    <button type="button" class="btn-nativ form-btn next-step-btn" routerLink="/sales"
                        (click)="next('start',chatProcess.start.choice[1].nextStep, chatProcess.start.choice[1].text)">
                        {{ chatProcess.start.choice[1].text }}
                    </button>
                </form>
            </div>
            <!-- <div class="container" *ngIf="chatProcNow['writeUrl']">
                <input type="url" class="btn-chat row input-url" (keyup.enter)="checkUrl()" [(ngModel)]="url"
                    required>
                <div class="invalid-url text-right">
                    <div>{{ errorURL }}</div>
                </div>
                <button class="btn-nativ btn-chat row" (click)="checkUrl()" [disabled]="!checkURL">{{
                    chatProcess.writeUrl.choice[0].text }}</button>
            </div> -->
            <!-- <div class="container" *ngIf="chatProcNow['openSite']"> -->
            <!-- <input type="url" class="btn-chat row input-url" (keyup.enter)="checkUrl('openSite',true)"
                    [(ngModel)]="url" required> -->
            <!-- <div class="invalid-url text-right">
                    <div>{{ errorURL }}</div>
                </div> -->
            <!-- <button class="btn-nativ btn-chat row" (click)="checkUrl('openSite',true)" [disabled]="!checkURL">{{
                    chatProcess.openSite.choice[0].text }}</button> -->
            <!-- <img class="imgShowContainer" src="../../../assets/images/chat/input-line.png" /> -->
            <!-- <input class="chat-messages-input" type="url" [(ngModel)]="url" (keyup.enter)="checkUrl('openSite',true)"
                    placeholder="כתוב הודעה...">
                <img class="chat-messages-create-button pointer" src="../../../assets/images/chat/ic_sharp-send.png" [disabled]="!checkURL"
                    (click)="checkUrl('openSite',true)" /> -->
            <!-- </div> -->
            <!-- <div class="container" *ngIf="chatProcNow['grayList']">
                <input type="url" class="btn-chat row input-url" (keyup.enter)="checkUrl()" [(ngModel)]="url"
                    required>
                <div class="invalid-url text-right">
                    <div>{{ errorURL }}</div>
                </div>
                <button class="btn-nativ btn-chat row" (click)="checkUrl()" [disabled]="!checkURL">{{
                    chatProcess.openSite.choice[0].text }}</button>
            </div> -->
            <!--כשהרובוט חושב-->
            <div *ngIf="isThing" class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
        <!-- 
        <div *ngIf="isThing" class="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div> -->

        <img *ngIf="showInput" class="imgShowContainer" src="../../../assets/images/chat/input-line.png" />
        <div *ngIf="showInput" class="chat-messages-create-container">
            <input class="chat-messages-create-input" type="text" [disabled]="noReplyFromEmployee" [(ngModel)]="message"
                (keyup.enter)="sendMessage()" placeholder="כתוב הודעה..." accept="image/*">
            <img class="chat-messages-create-button pointer" src="../../../assets/images/chat/ic_sharp-send.png"
                (click)="sendMessage()" />
        </div>
        <img *ngIf="showInputUrl" class="imgShowContainer" src="../../../assets/images/chat/input-line.png" />
        <div *ngIf="showInputUrl" class="chat-messages-create-container">
            <input class="chat-messages-create-input" type="url" [(ngModel)]="url" (keyup.enter)="checkUrl()"
                [formControl]="urlFormControl" placeholder="הקלד כתובת אתר..." accept="image/*">
            <img class="chat-messages-create-button pointer" src="../../../assets/images/chat/ic_sharp-send.png"
                (click)="checkUrl()" />
        </div>
        <img *ngIf="showInputPass" class="imgShowContainer" src="../../../assets/images/chat/input-line.png" />
        <div *ngIf="showInputPass" class="chat-messages-create-container">
            <input class="chat-messages-create-input" type="text" [(ngModel)]="password" (keyup.enter)="checkPass()"
                placeholder="כתוב סיסמה...">
            <img class="chat-messages-create-button pointer" src="../../../assets/images/chat/ic_sharp-send.png"
                (click)="checkPass()" />
        </div>
        <img *ngIf="showInputManagerPass" class="imgShowContainer" src="../../../assets/images/chat/input-line.png" />
        <div *ngIf="showInputManagerPass" class="chat-messages-create-container">
            <input class="chat-messages-create-input" type="text" [(ngModel)]="managerPassword"
                (keyup.enter)="checkManagerPass()" placeholder="כתוב סיסמה...">
            <img class="chat-messages-create-button pointer" src="../../../assets/images/chat/ic_sharp-send.png"
                (click)="checkManagerPass()" />
        </div>
        <img *ngIf="showInputNtLogin" class="imgShowContainer" src="../../../assets/images/chat/input-line.png" />
        <div *ngIf="showInputNtLogin" class="chat-messages-create-container">
            <input class="chat-messages-create-input" type="text" [(ngModel)]="ntlogin"
                (keyup.enter)="next('enterNumSim',chatProcess.enterNumSim.choice[0].nextStep,ntlogin)"
                placeholder="כתוב מספר סים...">
            <img class="chat-messages-create-button pointer" src="../../../assets/images/chat/ic_sharp-send.png"
                (click)="next('enterNumSim',chatProcess.enterNumSim.choice[0].nextStep,ntlogin)" />
        </div>
        <img *ngIf="showInputSitesCode" class="imgShowContainer" src="../../../assets/images/chat/input-line.png" />
        <div *ngIf="showInputSitesCode" class="chat-messages-create-container">
            <input class="chat-messages-create-input" type="text" [(ngModel)]="sitesCode"
                (keyup.enter)="checkSitesCodeF()" placeholder="הכנס קוד להוספת אתרים...">
            <img class="chat-messages-create-button pointer" src="../../../assets/images/chat/ic_sharp-send.png"
                (click)="checkSitesCodeF()" />
        </div>
        <img *ngIf="showInputMail" class="imgShowContainer" src="../../../assets/images/chat/input-line.png" />
        <div *ngIf="showInputMail" class="chat-messages-create-container">
            <input class="chat-messages-create-input" type="email" [(ngModel)]="mail" [formControl]="emailFormControl"
                (keyup.enter)="checkMail()" placeholder="הכנס מייל...">
            <img class="chat-messages-create-button pointer" src="../../../assets/images/chat/ic_sharp-send.png"
                (click)="checkMail()" />
        </div>
        <img *ngIf="showInputPhone" class="imgShowContainer" src="../../../assets/images/chat/input-line.png" />
        <div *ngIf="showInputPhone" class="chat-messages-create-container">
            <input class="chat-messages-create-input" type="phone" [(ngModel)]="phone" [formControl]="phoneFormControl"
                (keyup.enter)="checkPhone()" placeholder="הקלד מספר פלאפון...">
            <img class="chat-messages-create-button pointer" src="../../../assets/images/chat/ic_sharp-send.png"
                (click)="checkPhone()" />
        </div>
        <img *ngIf="showInputFreeText" class="imgShowContainer" src="../../../assets/images/chat/input-line.png" />
        <div *ngIf="showInputFreeText" class="chat-messages-create-container">
            <input class="chat-messages-create-input" type="text" [(ngModel)]="freeText" (keyup.enter)="sendFreeText()"
                placeholder="הקלד...">
            <img class="chat-messages-create-button pointer" src="../../../assets/images/chat/ic_sharp-send.png"
                (click)="sendFreeText()" />
        </div>
        <img *ngIf="showInputNumLicense" class="imgShowContainer" src="../../../assets/images/chat/input-line.png" />
        <div *ngIf="showInputNumLicense" class="chat-messages-create-container">
            <input class="chat-messages-create-input" type="number" [(ngModel)]="numOfLicenses"
                (keyup.enter)="checkNumLicenses()" placeholder="הקלד מספר רשיונות...">
            <img class="chat-messages-create-button pointer" src="../../../assets/images/chat/ic_sharp-send.png"
                (click)="checkNumLicenses()" />
        </div>
    </div>
    <!-- <div *ngIf="showButtons" class="chat-messages-create-container buttons">
        <div class="col-6">
            <button class="btn-nativ chat-contact-btn" [routerLink]="['/contact', 'tech-support']">לפתיחת פניה
                לתמיכה טכנית</button>
            <button class="btn-nativ chat-contact-btn" [routerLink]="['/contact', 'money']">לפתיחת פניה
                למחלקת גביה</button>
        </div>
        <div class="col-6">
            <button class="btn-nativ chat-contact-btn" [routerLink]="['/contact', 'sites-support']">לפתיחת פניה
                לתמיכת אתרים</button>
            <button class="btn-nativ chat-contact-btn" [routerLink]="['/contact', 'buy']">לפתיחת פניה
                למכירות</button>
        </div>
    </div> -->
</div>


<ng-template #modalForgetPass let-modal class="forgot-modal modal-md">
    <div class="modal-header">
        <div class="row">
            <div class="col-md-1 close-modal-header">
                <button type="button" class="close" (click)="close(modal)" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 dep-modal-header text-right">
                <div class="row">
                    <div class="col-md-12 text-center">
                        <h5 class="modal-title">{{ 'forgot-pass.header' | translate }}</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-body">
        <form class="home-form text-center" [formGroup]="forgotForm"
            (ngSubmit)="onSubmitForgot(forgotForm.value,modal)">
            <label>{{ 'forgot-pass.sub-header' | translate }}:
            </label>
            <div class="row text-center">
                <div class="col-md-12">
                    <div class="form-group">
                        <input formControlName="username" type="text" class="form-control text-right" id="username"
                            [ngClass]="{ 'is-invalid': forgotSubmitted && fp.username.errors }">
                        <div *ngIf="forgotSubmitted && fp.username.errors" class="invalid-feedback">
                            <div *ngIf="fp.username.errors.required">
                                {{ 'form.validation.emptyusername' | translate }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <button type="submit" class="btn-nativ form-btn square-btn">{{ 'global.send' | translate }}</button>
                </div>
            </div>
            <div class="row forgot-username-div">
                <div class="col-12">
                    <a (click)="close(modal)" [routerLink]="['/contact', 'tech-support']">{{
                        'forgot-pass.forgot-username' | translate }}</a>
                </div>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #modalMoney let-modal class="modal contact-modal">
    <div class="modal-header">
        <div class="row">
            <div class="col-md-1 close-modal-header">
                <button type="button" class="close" (click)="close(modal,'isGvia')" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 dep-modal-header text-right">
                <div class="row">
                    <div class="col-3 pl-0 text-left">
                        <img [src]="modalSrc" />
                    </div>
                    <div class="col-9 pr-0">
                        <h5 class="modal-title">{{ modalHeader }}</h5>
                        <div class="not-mobile">{{ 'contact.modal-header' | translate }}</div>
                    </div>
                </div>
            </div>
        </div>
        <h5 class="mobile m-modal-header">{{ 'contact.modal-header' | translate }}</h5>
    </div>

    <div class="modal-body">
        <form [formGroup]="contactFormMoney" class="contact-form-chat text-center"
            (ngSubmit)="onSubmitMoney(contactFormMoney.value,modal,depNumber)">
            <div class="row">
                <div class="col-md-5 input-div text-right">
                    <div class="form-group">
                        <label for="fullname">*{{ 'form.fullname' | translate }}</label>
                        <div class="row">
                            <input type="text" formControlName="fullname" class="form-control" id="fullname"
                                [ngClass]="{ 'is-invalid': submittedMoney && fm.fullname.errors }"
                                [ngModel]="authenticationService.userData && authenticationService.userData.firstname && authenticationService.userData.lastname ? authenticationService.userData.firstname  + ' ' +  authenticationService.userData.lastname : ''">
                            <div *ngIf="submittedMoney && fm.fullname.errors" class="invalid-feedback">
                                <div *ngIf="fm.fullname.errors.required">{{ 'form.validation.emptyName' | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-5 input-div text-right">
                    <div class="form-group">
                        <label for="username">{{ 'form.username' | translate }}</label>
                        <div class="row">
                            <input type="text" formControlName="username" class="form-control" id="username"
                                [ngClass]="{ 'is-invalid': submittedMoney && fm.username.errors }"
                                [ngModel]="authenticationService.userData ? authenticationService.userData.username : ''">
                            <div *ngIf="submittedMoney && fm.username.errors" class="invalid-feedback">
                                <div *ngIf="fm.username.errors.required">{{ 'form.validation.emptyUsername' | translate
                                    }} </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-5 input-div text-right">
                    <div class="form-group">
                        <label for="phonenumber">*{{ 'form.phonenumber' | translate }}</label>
                        <div class="row">
                            <input type="text" formControlName="phonenumber" class="form-control" id="phonenumber"
                                [ngClass]="{ 'is-invalid': submittedMoney && fm.phonenumber.errors }">
                            <div *ngIf="submittedMoney && fm.phonenumber.errors" class="invalid-feedback">
                                <div *ngIf="fm.phonenumber.errors.required">{{ 'form.validation.emptyPhonenumber' |
                                    translate }}</div>
                            </div>
                            <div *ngIf="submittedMoney && fm.phonenumber.errors" class="invalid-feedback">
                                <div *ngIf="fm.phonenumber.errors.pattern"> {{ 'form.validation.invalidPhonenumber' |
                                    translate }} </div>
                            </div>
                            <div *ngIf="submittedMoney && !validPhoneNumber" class="invalid-feedback">
                                <div *ngIf="!validPhoneNumber">{{ 'form.validation.invalidPhonenumber' | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-5 input-div text-right">
                    <div class="form-group">
                        <label for="email">*{{ 'form.email' | translate }}</label>
                        <div class="row">
                            <input type="email" formControlName="email" class="form-control" id="email"
                                [ngClass]="{ 'is-invalid': submittedMoney && fm.email.errors }" [ngModel]="email">
                            <div *ngIf="submittedMoney && fm.email.errors" class="invalid-feedback">
                                <div *ngIf="fm.email.errors.required">{{ 'form.validation.emptyEmail' | translate }}
                                </div>
                            </div>
                            <div *ngIf="submittedMoney && fm.email.errors" class="invalid-feedback">
                                <div *ngIf="fm.email.errors.email || fm.email.errors.pattern">{{
                                    'form.validation.invalidEmail' | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 text-right">
                <div class="form-group">
                    <label for="content">*{{ 'form.content' | translate }}</label>
                    <div class="row">
                        <textarea type="text" formControlName="content" class="form-control" id="content"
                            [ngClass]="{ 'is-invalid': submittedMoney && fm.content.errors }"
                            placeholder="{{userContent}}" [ngModel]="buyContent">
                        </textarea>
                        <div *ngIf="submittedMoney && fm.content.errors" class="invalid-feedback">
                            <div *ngIf="fm.content.errors.required">{{ 'form.validation.emptyContent' | translate }}
                            </div>
                            <div *ngIf="fm.content.errors.minlength">{{ 'form.validation.emptyContent' | translate }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer justify-content-center">
                <div *ngIf="!contacted" class="invalid-submit">{{contactedError}}</div>
                <button [disabled]="!enableSubmitMoney" type="submit" class="btn-nativ form-btn">{{ 'global.send' |
                    translate
                    }}</button>
            </div>
        </form>
    </div>
</ng-template>

<!-- <ng-template #modalVrify let-modal class="modal-md" let-c="close" let-d="dismiss">
    <app-verify-code-modal [formData]="formData" [docId]="docId" [c]="c" [d]="d">
    </app-verify-code-modal>
</ng-template> -->

<ng-template #modalVerify let-modal class="modal-md" let-c="close" let-d="dismiss">
    <app-verify-code-modal [formData]="formVerify" [docId]="docId" [c]="c" [d]="d">
    </app-verify-code-modal>
</ng-template>

<audio #myAudio>
    <source src="../../../assets/audio/get-message-chat.mp3" type="audio/mpeg">
</audio>