<div class="home-page" [ngClass]="{ 'mobile': isMobile }">
    <button #scrollBtn (click)="topFunction()" class="btn-nativ scroll-btn" title="Go to top"><i
            class="fas fa-chevron-up"></i></button>
    <!-- <button (click)="toggleChat(true)" class="btn-nativ scroll-btn chat-btn"><img
            src="../../../assets/images/chat/open-btn-1.png" /></button>
    <div *ngIf="isShowChat">
        <app-chat-inbox [isShow]="isShowChat" (isShowChatEvent)="toggleChat($event)"></app-chat-inbox>
    </div> -->
    <div id="section-1" class="first-div">
        <app-home-carousel></app-home-carousel>
        <div id="section-2-before"></div>
    </div>
    <!-- <button pageScroll href="#section-5-before" class="btn-nativ pre apps-btn">
        <div class="arrows-div">&#171;</div>
    </button>
    <button pageScroll href="#section-5-before" class="btn-nativ apps-btn">{{ 'home.apps.shortcut' | translate }}
        <div class="arrows-div">&#171;</div>
    </button> -->
    <div id="section-2">
        <div class="kosher align-items-center">
            <div class="col-md-5 img-col">
                <img class="pointer" routerLink="/kosher" src="../../assets/images/kosher.png" />
            </div>
            <div class="col-md-7 text-col text-right">
                <h4 class="pointer" routerLink="/kosher">{{ 'home.kosher' | translate }}</h4>
            </div>
        </div>
        <div class="steps-div">
            <h3 class="steps-header text-center">{{ 'home.steps.header' | translate }}</h3>
            <div class="content responsive-div">
                <div class="step-content" data-aos="fade-up" data-aos-duration="500" data-aos-mirror="true"
                    data-aos-once="true">
                    <div class="step-header step-1"></div>
                    <div>{{ 'home.steps.step1.text' | translate }} </div>
                </div>
                <div class="step-content" data-aos="fade-up" data-aos-duration="800" data-aos-mirror="true"
                    data-aos-once="true">
                    <div class="step-header step-2"></div>
                    <div>{{ 'home.steps.step2.text' | translate }} </div>
                </div>
                <div class="step-content" data-aos="fade-up" data-aos-duration="1100" data-aos-mirror="true"
                    data-aos-once="true">
                    <div class="step-header step-3"></div>
                    <div>{{ 'home.steps.step3.text' | translate }} </div>
                </div>
            </div>
            <div class="responsive-div text-center" data-aos="fade-up" data-aos-duration="1400" data-aos-mirror="true"
                data-aos-once="true">
                <div class="step-content text-center">
                    <div class="step-header step-all"></div>
                    <div>{{ 'home.steps.step-all.text' | translate }} </div>
                </div>
            </div>
        </div>
        <!-- <div *ngIf='omerDate  && omerDate.days  ' class="row omer-div">
            <span>{{omerDate.days + omerDate.weeks}}</span>
        </div> -->
    </div>
    <div id="section-3">
        <div class="col-md-12 text-center header-div" data-aos="fade-up" data-aos-duration="200" data-aos-mirror="true"
            data-aos-once="true">
            <h1 class="header">{{ 'home.pathes.header' | translate }}</h1>
            <h4 class="sub-header">{{ 'home.pathes.sub-header' | translate }}</h4>
        </div>
        <div class="pathes-div responsive-div">
            <div class="path-div path-1" data-aos="fade-up" data-aos-duration="500" data-aos-mirror="true"
                data-aos-once="true">
                <h4 class="path-header text-center"><img src="../../../assets/images/home/new/path-1-logo.png" /></h4>
                <!-- <div class="row"> -->
                <!-- <div class="col-4 icon-div justify-content-center align-self-center vertical-center">
                        <img src="../../../assets/images/home/new/path-1-i.png" />
                    </div> -->
                <div class="path-content">
                    {{ 'home.pathes.path1.text' | translate }}
                </div>
                <!-- </div> -->
            </div>

            <div class="path-div path-2" data-aos="fade-up" data-aos-duration="900" data-aos-mirror="true"
                data-aos-once="true">
                <h4 class="path-header text-center"><img src="../../../assets/images/home/new/path-2-logo.png" /></h4>
                <!-- <div class="row"> -->
                <!-- <div class="col-4 icon-div justify-content-center align-self-center vertical-center">
                        <img src="../../../assets/images/home/new/path-2-i.png" />
                    </div> -->
                <div class="path-content">
                    {{ 'home.pathes.path2.text' | translate }}
                </div>
                <!-- </div> -->
            </div>
            <div class="path-div path-3" data-aos="fade-up" data-aos-duration="1300" data-aos-mirror="true"
                data-aos-once="true">
                <h4 class="path-header text-center"><img src="../../../assets/images/home/new/path-3-logo.png" /></h4>
                <!-- <div class="row"> -->
                <!-- <div class="col-4 icon-div justify-content-center align-self-center vertical-center">
                        <img src="../../../assets/images/home/new/path-3-i.png" />
                    </div> -->
                <div class="path-content">
                    {{ 'home.pathes.path3.text' | translate }}
                </div>
                <!-- </div> -->
            </div>
            <div class="path-div path-4" data-aos="fade-up" data-aos-duration="1700" data-aos-mirror="true"
                data-aos-once="true">
                <h5 class="path-header text-center">{{ 'home.pathes.path4.header' | translate }}</h5>
                <!-- <div class="row"> -->
                <!-- <div class="col-4 icon-div justify-content-center align-self-center vertical-center">
                        <img src="../../../assets/images/home/new/path-4-i.png" />
                    </div> -->
                <div class="path-content">
                    {{ 'home.pathes.path4.text' | translate }}
                </div>
                <!-- </div> -->
            </div>
        </div>
    </div>
    <!-- <div class="row content-div text-right" (click)="clickNativ($event,1)">
            <div [ngClass]="isMobile ?'col-md-6':'col-md-3'" class="path-div">
                <h4 class="path-header">{{ 'home.pathes.path1.header' | translate }}</h4>
                <div class="row">
                    <div class="col-xs-3 icon-div justify-content-center align-self-center vertical-center"
                        data-aos-once="true" data-aos-mirror="true" data-aos="flip-left"
                        data-aos-easing="ease-out-cubic" data-aos-duration="500"><img
                            src="../../../assets/images/i-path-1.png" />
                    </div>
                    <div class="col-xs-9 float-left path-content">
                        {{ 'home.pathes.path1.text' | translate }}
                    </div>
                    <div class="col-md-12 nativ-content-mobile">למידע נוסף <div class="arrows-div">&#171;</div>
                    </div>
                </div>
            </div>
            <div *ngIf="showNativContent1 && isMobile" class="nativ-content row" id="nativ-content1">
                <div class="col-xs-1 mx-auto align-self-center">
                    <img src="../../../assets/images/nativ-1.png" />
                </div>
                <div class="nativ-div col-xs-11 justify-content-center align-self-center">
                    <h4>נתיב דואר אלטרוני</h4>
                    מסלול זה מאפשר גישה למייל באאוטלוק או בג'מייל.
                    <br />
                    שאר האתרים יהיו חסומים.
                </div>
            </div>
            <div [ngClass]="isMobile ?'col-md-6':'col-md-3'" class="path-div text-right" (click)="clickNativ($event,2)">
                <h4 class="path-header">{{ 'home.pathes.path2.header' | translate }}</h4>
                <div class="row">
                    <div class="col-xs-3 icon-div justify-content-center align-self-center vertical-center"
                        data-aos-once="true" data-aos-mirror="true" data-aos="flip-left"
                        data-aos-easing="ease-out-cubic" data-aos-duration="500"><img
                            src="../../../assets/images/i-path-2.png" />
                    </div>
                    <div class="col-xs-9 float-left path-content">
                        {{ 'home.pathes.path2.text' | translate }}
                    </div>
                    <div class="col-md-12 nativ-content-mobile">למידע נוסף <div class="arrows-div">&#171;</div>
                    </div>
                </div>
            </div>
            <div *ngIf="showNativContent2 && isMobile" class="nativ-content row" id="nativ-content2">
                <div class="col-xs-1 mx-auto align-self-center">
                    <img src="../../../assets/images/nativ-2.png" />
                </div>
                <div class="nativ-div col-xs-11 justify-content-center align-self-center">
                    <h4>נתיב שימושי מורחב</h4>
                    בנתיב שימושי מורחב מאגר עצום של אתרים נקיים,
                    <br />
                    אותו תוכלו להתאים לפי צרכיכם.
                </div>
            </div>
            <div [ngClass]="isMobile ?'col-md-6':'col-md-3'" class="path-div text-right" (click)="clickNativ($event,3)">
                <h4 class="path-header">{{ 'home.pathes.path3.header' | translate }}</h4>
                <div class="row">
                    <div class="col-xs-3 icon-div justify-content-center align-self-center vertical-center"
                        data-aos-once="true" data-aos-mirror="true" data-aos="flip-left"
                        data-aos-easing="ease-out-cubic" data-aos-duration="500"><img
                            src="../../../assets/images/i-path-3.png" />
                    </div>
                    <div class="col-xs-9 float-left path-content">
                        {{ 'home.pathes.path3.text' | translate }}
                    </div>
                    <div class="col-md-12 nativ-content-mobile">למידע נוסף <div class="arrows-div">&#171;</div>
                    </div>
                </div>
            </div>
            <div *ngIf="showNativContent3 && isMobile" class="nativ-content row" id="nativ-content3">
                <div class="col-xs-1 mx-auto align-self-center">
                    <img src="../../../assets/images/nativ-3.png" />
                </div>
                <div class="nativ-div col-xs-11 justify-content-center align-self-center">
                    <h4>נתיב עיסקי מורחב</h4>
                    המסלול העיסקי נוצר עבור המשתמשים בקשת רחבה יותר של אתרים.
                    <br />
                    במסלול העיסקי ניתן להגדיר את רמת ההגנה בהתאם לצורך האישי.
                </div>
            </div>
            <div [ngClass]="isMobile ?'col-md-6':'col-md-3'" class="path-div text-right" (click)="clickNativ($event,4)">
                <h4 class="path-header">{{ 'home.pathes.path4.header' | translate }}</h4>
                <div class="row">
                    <div class="col-xs-3 icon-div justify-content-center align-self-center vertical-center"
                        data-aos-once="true" data-aos-mirror="true" data-aos="flip-left"
                        data-aos-easing="ease-out-cubic" data-aos-duration="500"><img
                            src="../../../assets/images/i-path-4.png" /></div>
                    <div class="col-xs-9 float-left path-content">
                        {{ 'home.pathes.path4.text' | translate }}
                    </div>
                    <div class="col-md-12 nativ-content-mobile">למידע נוסף <div class="arrows-div">&#171;</div>
                    </div>
                </div>
            </div>
        </div> -->
    <!-- <div *ngIf="showNativContent4 && isMobile" class="nativ-content row" id="nativ-content4">
            <div class="col-xs-1 mx-auto align-self-center">
                <img src="../../../assets/images/nativ-4.png" />
            </div>
            <div class="nativ-div col-xs-11 justify-content-center align-self-center">
                <h4>נתיב סטיק</h4>
                הנתיב הסלולרי מאפשר גלישה בטוחה במקומות שונים,
                <br />
                מלבד הרשת עצמה המוגנת תוכלו להתקין את התוכנה גם על המחשב,
                <br />
                וכך תבטחו אתכם מפני כל רשת אחרת לא מסוננת.
            </div>
        </div> -->
    <!-- <div *ngIf="showNativContent1 && !isMobile" class="nativ-content row text-right" id="nativ-content1">
            <div class="col-md-1">
                <img src="../../../assets/images/nativ-1.png" />
            </div>
            <div class="nativ-div col-md-10 justify-content-center align-self-center">
                <h4>{{ 'home.pathes.path1.header' | translate }}</h4>
                {{ 'home.pathes.path1.content' | translate }}
            </div>
        </div> -->
    <!-- <div *ngIf="showNativContent2 && !isMobile" class="nativ-content row text-right" id="nativ-content2">
            <div class="col-md-1">
                <img src="../../../assets/images/nativ-2.png" />
            </div>
            <div class="nativ-div col-md-10 justify-content-center align-self-center">
                <h4>{{ 'home.pathes.path2.header' | translate }}</h4>
                {{ 'home.pathes.path2.content' | translate }}
            </div>
        </div> -->
    <!-- <div *ngIf="showNativContent3 && !isMobile" class="nativ-content row text-right" id="nativ-content3">
            <div class="col-md-1">
                <img src="../../../assets/images/nativ-3.png" />
            </div>
            <div class="nativ-div col-md-10 justify-content-center align-self-center">
                <h4>{{ 'home.pathes.path3.header' | translate }}</h4>
                {{ 'home.pathes.path3.content' | translate }}
            </div>
        </div> -->
    <!-- <div *ngIf="showNativContent4 && !isMobile" class="nativ-content row text-right" id="nativ-content4">
            <div class="col-md-1">
                <img src="../../../assets/images/nativ-4.png" />
            </div>
            <div class="nativ-div col-md-10 justify-content-center align-self-center">
                <h4>{{ 'home.pathes.path4.header' | translate }}</h4>
                {{ 'home.pathes.path4.content' | translate }}
            </div>
        </div> -->


    <div id="section-4" name="section-4">
        <div class="row">
            <div class="col-md-12 text-center">
                <h2>{{ 'home.contact.header' | translate }}</h2>
                <h5>{{ 'home.contact.sub-header' | translate }}</h5>
            </div>
        </div>
        <div class="row">
            <div [ngClass]="isMobile ?'col-md-8':'col-md-5'" class="mx-auto">
                <form class="text-right contact-form" [formGroup]="contactForm"
                    (ngSubmit)="onContact(contactForm.value)">
                    <div class="form-group">
                        <label for="name">{{ 'form.fullname' | translate }}</label>
                        <div class="row">
                            <div class="img-div" [ngClass]="isMobile ?'col-xs-3':'col-md-3'">
                                <img src="../../assets/images/i-name.png" />
                            </div>
                            <div class="col-md-9">
                                <input type="text" formControlName="name" class="form-control" id="name"
                                    [ngClass]="{ 'is-invalid': contacted && cf.name.errors }">
                                <div *ngIf="contacted && cf.name.errors" class="invalid-feedback">
                                    <div *ngIf="cf.name.errors.required">{{ 'form.validation.emptyName' | translate }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="phoneNumber">{{ 'form.phonenumber' | translate }}</label>
                        <div class="row">
                            <div class="img-div" [ngClass]="isMobile ?'col-xs-3':'col-md-3'">
                                <img src="../../assets/images/i-phone.png" />
                            </div>
                            <div class="col-md-9">
                                <input type="text" formControlName="phoneNumber" class="form-control" id="phoneNumber"
                                    [ngClass]="{ 'is-invalid': contacted && cf.phoneNumber.errors }">
                                <div *ngIf="contacted && cf.phoneNumber.errors" class="invalid-feedback">
                                    <div
                                        *ngIf="cf.phoneNumber.errors.required && cf.phoneNumber.errors.required !== 'IS'">
                                        {{ 'form.validation.emptyPhonenumber' |
                                        translate }} </div>
                                    <div *ngIf="cf.phoneNumber.errors.required == 'IS'">
                                        אנו חוזרים לטלפון ישראלי בלבד.
                                    </div>
                                </div>
                                <div *ngIf="contacted && cf.phoneNumber.errors" class="invalid-feedback">
                                    <div *ngIf="cf.phoneNumber.errors.pattern">{{ 'form.validation.invalidPhonenumber' |
                                        translate }} </div>
                                </div>
                                <div *ngIf="contacted && !validPhoneNimber" class="invalid-feedback">
                                    <div *ngIf="!validPhoneNimber">{{ 'form.validation.invalidPhonenumber' | translate
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="name">{{ 'form.email' | translate }}</label>
                        <div class="row">
                            <div class="img-div" [ngClass]="isMobile ?'col-xs-3':'col-md-3'">
                                <img src="../../assets/images/i-email.png" />
                            </div>
                            <div class="col-md-9">
                                <input type="text" formControlName="mail" class="form-control" id="mail"
                                    [ngClass]="{ 'is-invalid': contacted && cf.mail.errors }">
                                <div *ngIf="contacted && cf.mail.errors" class="invalid-feedback">
                                    <div *ngIf="cf.mail.errors.required">{{
                                        'form.validation.emptyEmail' | translate }}
                                    </div>
                                    <div *ngIf="cf.mail.errors.email || cf.mail.errors.pattern">{{
                                        'form.validation.invalidEmail' | translate }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-2">
                        </div>
                        <div class="col-md-10">
                            <button [disabled]="!enableSubmit" type="submit" class="btn-nativ form-btn">{{ 'global.send'
                                | translate }}
                                &#10095;&#10095;</button>
                        </div>
                        <div class="col-md-12">
                            <div *ngIf="contactSuccess" class="valid-submit">{{contactSuccess}}</div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div id="section-5-before"></div>
    </div>
</div>
<div id="section-5" class="text-center" data-aos="fade-up" data-aos-once="true" data-aos-duration="500"
    data-aos-mirror="true">
    <app-daily-apps></app-daily-apps>
</div>
<app-bottom-nav></app-bottom-nav>