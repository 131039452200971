import { Component, OnInit, ViewChild, TemplateRef, HostListener } from '@angular/core';
import { AuthenticationService } from './../../services/authentication/authentication.service'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from '../../services/api/api.service';
import { SalesService } from '../../services/sales/sales.service'
import * as AOS from 'aos';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { Router } from "@angular/router";

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  @ViewChild('modal') public modalRef: TemplateRef<any>;
  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.close(this.curModal)
  }
  showModal = false;
  modalHeader: string;
  modalName = '';
  contactForm: any;
  submitted = false;
  contacted = true;
  contactedError = "";
  depNumber = 0;
  dep = "";
  validPhoneNimber = true;
  enableSubmit = true;
  priority = 2;
  userContent = "";
  urlParams = {};
  buyContent = ""
  dpt = 0;
  emptyPhoneNumber = false;
  fullname = ''
  fullnameSales = ''
  emailSales = ''
  phoneSales = ''
  phoneNumber = ''
  email = ''
  curModal: any;
  constructor(public authenticationService: AuthenticationService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private route: ActivatedRoute,
    public translateService: TranslateService,
    private router: Router,
    private salesService: SalesService) {
    this.contactForm = this.formBuilder.group({
      fullname: '',
      username: '',
      phonenumber: '',
      email: '',
      content: ''
    });
    this.fullname = this.authenticationService.userData && this.authenticationService.userData.firstname && this.authenticationService.userData.lastname ? this.authenticationService.userData.firstname + ' ' + this.authenticationService.userData.lastname : ''
    // this.phoneNumber = (this.authenticationService.userData && this.authenticationService.userData.status == "2") || this.emptyPhoneNumber ? '' : (this.authenticationService.userData ? this.authenticationService.userData.homephone : '')
    this.email = this.authenticationService.userData && this.authenticationService.userData.status == "2" ? '' : this.authenticationService.userData && this.authenticationService.userData.email ? this.authenticationService.userData.email.split(',')[0] : ''
  }
  ngOnInit(): void {
    AOS.init({
      offset: 200,
      duration: 600,
      easing: 'ease-in-sine',
      delay: 100,
    });
    this.contactForm = new FormGroup({
      fullname: new FormControl('', Validators.required),
      username: new FormControl(),
      // phonenumber: new FormControl('', [Validators.required, Validators.pattern("^(\\+\\d{1,3}( )?)?((\\(\\d{3}\\))|\\d{3})[- .]?\\d{3}[- .]?\\d{3}$")]),
      phonenumber: new FormControl('', [Validators.required, Validators.pattern(/^0(([23489]{1}\d{7})|[5]{1}\d{8}|(([7]\d{1,2}\-\d{7})|([7]\d{8})))$/)]),
      email: new FormControl('', [Validators.required, Validators.email, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[A-Za-z]{2,4}$")]),
      content: new FormControl('', [Validators.required, Validators.minLength(6)])
    });
    this.route.paramMap.subscribe(params =>
      this.dep = params.get('dep')
    )
  }
  ngAfterViewInit() {
    this.route
      .queryParams
      .subscribe(params => {
        if (params["pid"] && params["dpt"] && params["rsn"] && params["ml"]) {
          this.urlParams["pid"] = params["pid"]
          this.urlParams["dpt"] = params["dpt"]
          this.userContent = "תוכן פנייתך המקורית: " + "\n" + params["rsn"] + "\n" + "תגובת המערכת: " + "\n" + params["ml"];
          this.dpt = params["dpt"];
          this.openModal(this.modalRef, 'tech-support')
        }
      })
    // https://enativ.com/contact/move-from-bezeq
    if (this.dep === 'buy') {
      console.log('from buy');
      this.openModal(this.modalRef, 'buy')
    }
    else if (this.dep === 'sites-support') {
      console.log('from sites-support');
      this.openModal(this.modalRef, 'sites-support')
    }
    else if (this.dep === 'money') {
      console.log('from money');
      this.openModal(this.modalRef, 'money')
    }
    else if (this.dep === 'buy-api') {
      this.openModal(this.modalRef, 'buy')
      this.userContent = "אני מעונין לשמוע על הסינון חכם api";
      this.buyContent = "אני מעונין לשמוע על הסינון חכם api";
    }
    else if (this.dep === 'join-sale') {
      this.openModal(this.modalRef, 'buy')
      this.userContent = "מתעניין במבצע הצטרפות עד י''א תשרי"
    }
    else if (this.dep === 'move-to-bezeq') {
      this.openModal(this.modalRef, 'buy')
      this.userContent = "מתענין במעבר לספק בזק בינלאומי"
      this.buyContent = "מתענין במעבר לספק בזק בינלאומי"
      this.priority = 0;
    }
    else if (this.dep === 'change-triple-c') {
      this.openModal(this.modalRef, 'buy')
      this.userContent = "מעונין להחליף את ספק טריפלסי"
      this.buyContent = "מעונין להחליף את ספק טריפלסי"
    }
    else if (this.dep === "path-1") {
      this.openModal(this.modalRef, 'buy')
      this.userContent = "מתענין במסלול שימושי מורחב"
      this.buyContent = "מתענין במסלול שימושי מורחב"
    }
    else if (this.dep === "path-2") {
      this.openModal(this.modalRef, 'buy')
      this.userContent = "מתענין במסלול עיסקי מורחב"
      this.buyContent = "מתענין במסלול עיסקי מורחב"
    }
    else if (this.dep === "path-3") {
      this.openModal(this.modalRef, 'buy')
      this.userContent = "מתענין במסלול מייל בלבד/ מייל מורחב"
      this.buyContent = "מתענין במסלול מייל בלבד/ מייל מורחב"
    }
    else if (this.dep === "path-4") {
      this.openModal(this.modalRef, 'buy')
      this.userContent = "מתענין במסלול אישי"
      this.buyContent = "מתענין במסלול אישי"
    }
    else if (this.dep === 'tech-support') {
      this.openModal(this.modalRef, 'tech-support')
    }
    else if (this.dep === 'tech-support-scheduling') {
      this.openModal(this.modalRef, 'tech-support')
      this.userContent = "אני מעוניין בשינוי או הוספת תזמון"
      this.buyContent = "אני מעוניין בשינוי או הוספת תזמון"
    }
    else if (this.dep === 'upgrade-version') {
      this.openModal(this.modalRef, 'tech-support')
      this.userContent = "מעוניין בשידרוג גירסה"
      this.buyContent = "מעוניין בשידרוג גירסה"
      this.emptyPhoneNumber = true;
    }
    else if (this.dep === 'filter-and-provider') {
      // const saleDetails = this.salesService.saleDetails
      // this.fullnameSales = saleDetails["start"].fullname
      // this.emailSales = saleDetails["start"].email
      // this.phoneSales = saleDetails["start"].phoneNumber
      this.openModal(this.modalRef, 'buy')
      this.userContent = "מעוניין בסינון + ספק"
      this.buyContent = "מעוניין בסינון + ספק"
    }
    else if (this.dep === 'sim') {
      this.openModal(this.modalRef, 'buy')
      this.userContent = "מעוניין ברכישת יותר מסים אחד"
      this.buyContent = "מעוניין ברכישת יותר מסים אחד"
    }
    else if (this.dep === 'sim-and-stick') {
      this.openModal(this.modalRef, 'buy')
      this.userContent = "מעוניין ברכישת יותר מסים + סטיק אחד"
      this.buyContent = "מעוניין ברכישת יותר מסים + סטיק אחד"
    }
    else if (this.dep == "sales-10") {
      this.openModal(this.modalRef, 'buy')
      this.userContent = "מעוניין ברישום של 10 מחשבים ומעלה"
      this.buyContent = "מעוניין ברישום של 10 מחשבים ומעלה"
    }
    else if (this.dep === 'install') {
      this.openModal(this.modalRef, 'tech-support')
      this.userContent = "מעוניין בהתקנת התוכנה לאחר רישום לנתיב"
      this.buyContent = "מעוניין בהתקנת התוכנה לאחר רישום לנתיב"
    }
    else if (this.dep === "move-from-bezeq") {
      this.openModal(this.modalRef, 'buy')
      this.userContent = "ברור על מעבר מבזק בנלאומי"
      this.buyContent = "ברור על מעבר מבזק בנלאומי"
    }
    else if (this.dep === "okmail") {
      this.openModal(this.modalRef, 'buy')
      this.userContent = "מעוניין בתחליף לאוקימייל"
      this.buyContent = "מעוניין בתחליף לאוקימייל"
    }
    else if (this.dep === "alternative-for-triplec") {
      this.openModal(this.modalRef, 'buy')
      this.userContent = "אשמח שנציג יחזור אלי לתיאום ספק חלופי לטריפלסי"
      this.buyContent = "אשמח שנציג יחזור אלי לתיאום ספק חלופי לטריפלסי"
    }
    else if (this.dep === "new-year") {
      this.openModal(this.modalRef, 'docs')
      this.userContent = "לקראת השנה החדשה, רציתי להעביר לצוות נתיב ש..."
      this.buyContent = "לקראת השנה החדשה, רציתי להעביר לצוות נתיב ש..."
    }
     else if (this.dep === 'add-lic') {
      this.openModal(this.modalRef, 'tech-support')
      this.userContent = "מעוניין בהוספת רשיון"
      this.buyContent = "מעוניין בהוספת רשיון"
    }
  }
  openModal(targetModal, header) {
    this.modalName = header;
    switch (header) {
      case 'buy': {
        this.translateService.get('contact.deps.buy.modal-header').subscribe((translated: string) => {
          this.modalHeader = translated;
        });
        this.depNumber = this.dpt == 0 ? 3 : this.dpt;
        break;
      }
      case 'sites-support': {
        // this.modalHeader = this.translateService.instant('contact.deps.sites-support.modal-header');
        this.translateService.get('contact.deps.sites-support.modal-header').subscribe((translated: string) => {
          this.modalHeader = translated;
        });
        this.depNumber = this.dpt == 0 ? 2 : this.dpt;
        break;
      }
      case 'tech-support': {
        this.translateService.get('contact.deps.tech-support.modal-header').subscribe((translated: string) => {
          this.modalHeader = translated;
        });
        this.depNumber = this.dpt == 0 ? 1 : this.dpt;
        break;
      }
      case 'money': {
        // this.modalHeader = this.translateService.instant('contact.deps.money.modal-header');
        this.translateService.get('contact.deps.money.modal-header').subscribe((translated: string) => {
          this.modalHeader = translated;
        });
        this.depNumber = this.dpt == 0 ? 5 : this.dpt;
        break;
      }
      case 'unfit-content': {
        // this.modalHeader = this.translateService.instant('contact.deps.unfit-content.modal-header');
        this.translateService.get('contact.deps.unfit-content.modal-header').subscribe((translated: string) => {
          this.modalHeader = translated;
        });
        this.depNumber = this.dpt == 0 ? 99 : this.dpt;
        break;
      }
      case 'serious': {
        // this.modalHeader = this.translateService.instant('contact.deps.serious.modal-header');
        this.translateService.get('contact.deps.serious.modal-header').subscribe((translated: string) => {
          this.modalHeader = translated;
        });
        this.depNumber = this.dpt == 0 ? 100 : this.dpt;
        break;
      }
      case 'docs': {
        this.translateService.get('contact.deps.forms.modal-header').subscribe((translated: string) => {
          this.modalHeader = translated;
        });
        this.depNumber = this.dpt == 0 ? 23 : this.dpt;
        break;
      }
      default: {
        this.depNumber = this.dpt == 0 ? 1 : this.dpt;
        this.modalHeader = header
        break;
      }
    }

    this.curModal = this.modalService.open(targetModal, {
      centered: true,
      backdrop: true,
      size: 'lg'
    });
    this.curModal.result.then(() => { this.submitted = false; this.contactForm.reset(); }, () => { this.submitted = false; this.contactForm.reset(); })
  }
  close(modal) {
    this.submitted = false;
    modal.dismiss()
  }


  get f() {
    return this.contactForm.controls;
  }
  onFocusInput(event) {
    this.contacted = true;
  }
  onSubmit(formData: any, modal) {
    this.submitted = true;
    this.enableSubmit = false;
    if (this.contactForm.invalid) {
      this.enableSubmit = true;
      return;
    }
    const phoneNumber = formData.phonenumber.replace(/\D/g, '');
    if (phoneNumber.length < 9 || phoneNumber.length > 15) {
      this.contactForm.get('phonenumber').setErrors('invalid phonenumber')
      this.validPhoneNimber = false;
      this.enableSubmit = true;
      return
    }
    const userArgent = window.navigator.userAgent
    if (this.depNumber !== 3 && (userArgent.includes("Linux") || userArgent.includes("Android") || userArgent.includes("iPhone OS"))) {
      modal.dismiss()
      Swal.fire("", "ניתן לפתוח פניות בנושא סינון למחשבים רק ממחשב", 'error')
      this.enableSubmit = true;
      return;
    }
    try {
      this.contactForm.reset();
      modal.dismiss()
      if (this.urlParams["pid"] && this.urlParams["dpt"]) {
        this.apiService.updateProcpect(this.urlParams["pid"], this.urlParams["dpt"], formData.content).subscribe(
          data => {
            console.log('success updateProcpect', data)
            this.enableSubmit = true;
            this.router.navigate(['/main'])
            Swal.fire("", "פנייתך התקבלה ותטופל בהקדם", 'success')
          },
          error => {
            console.log('error updateProcpect', error)
            this.enableSubmit = true;
            this.contacted = false;
            console.log('error contact', error)
            Swal.fire("", "אירעה שגיאה בשליחת הפניה", 'error')
          }
        )
      }
      else {
        const login = formData.username ? formData.username : this.authenticationService.userData && this.authenticationService.userData.username ? this.authenticationService.userData.username : 'NONE';
        formData.phonenumber = formData.phonenumber.replace(/\D/g, '');
        const name = formData.fullname.split(" ")
        const fname = name[0];
        const lname = name[1] ? name[1] : "";
        this.apiService.addEmailToUser(login, formData.email, 'contact').subscribe()
        this.apiService.contact(formData.content, this.depNumber, login, formData.phonenumber, formData.email, fname, lname, this.priority).subscribe(
          data => {
            console.log('success contact', data)
            this.enableSubmit = true;
            this.router.navigate(['/main'])
            Swal.fire("", "פנייתך התקבלה ותטופל בהקדם", 'success')
          },
          error => {
            this.enableSubmit = true;
            this.contacted = false;
            console.log('error contact', error)
            Swal.fire("", "אירעה שגיאה בשליחת הפניה", 'error')
          }
        )
      }
    } catch (error) {
      console.log(error)
      Swal.fire("", "אירעה שגיאה בשליחת הפניה", 'error')
    }
  }
}

