import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from '../../services/api/api.service';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-tech-login',
  templateUrl: './tech-login.component.html',
  styleUrls: ['./tech-login.component.scss']
})
export class TechLoginComponent implements OnInit {

  loginForm: any;
  submitted = false;
  authenticated = true;
  authenticatedError = "";
  isNativInstalled = true;
  enableSubmit = true
  sentCode = ""
  formData: any;
  docId = ""
  isVerifyCall = true;

  constructor(private apiService: ApiService,
    private formBuilder: FormBuilder,
    private router: Router,
    public authenticationService: AuthenticationService,
    private modalService: NgbModal) {

    this.loginForm = this.formBuilder.group({
      username: '',
      phonenumber: ''
    });

    this.apiService.codeSent$.subscribe(value => {
      this.sentCode = value;
    });
  }

  ngOnInit(): void {
    this.loginForm = new FormGroup({
      username: new FormControl(''),
      phonenumber: new FormControl('')
    });
    this.docId = "time-" + new Date().getTime().toString();
  }

  get f() {
    return this.loginForm.controls;
  }

  onFocusInput(event) {
    this.authenticated = true;
    this.loginForm.get('username').setErrors(null)
    this.loginForm.get('phonenumber').setErrors(null)
    this.loginForm.get('username').clearValidators();
    this.loginForm.get('phonenumber').clearValidators();
  }

  onSubmit(loginData: any, modal) {
    this.submitted = true;
    this.enableSubmit = false;
    this.authenticatedError = '';
    console.log('login has been submitted', loginData);
    if (loginData.username == "" && loginData.phonenumber == "") {
      this.loginForm.get('username').setErrors({ required: 'true' })
      this.loginForm.get('phonenumber').setErrors({ required: 'true' })
      this.enableSubmit = true;
      return;
    }
    else {
      try {
        this.apiService.techLogin(loginData).subscribe(
          data => {
            console.log('success login', data)
            data["data"].userPhonenumber = loginData.phonenumber
            this.authenticationService.authenticateTech(data["data"])
            if (this.isVerifyCall && this.sentCode !== ("valid-" + this.docId)) {
              this.enableSubmit = true;
              loginData.function = "techLogin";
              loginData["userData"] = data;
              loginData.isTech = true;
              this.formData = loginData;
              this.openModal(modal)
              return;
            }
            else {
              this.enableSubmit = true;
              this.router.navigate(['/tech'])
            }
          },
          error => {
            this.enableSubmit = true;
            this.authenticated = false;
            // this.authenticatedError = 'זמנית השרות אינו פעיל';
            if (error.error && error.error.text && error.error.text === "User does not exists!") {
              this.authenticatedError = 'שם משתמש שגוי';
            }
            else if (error.error && error.error.res && error.error.res == "no tech user") {
              // this.authenticatedError = 'מספר הטלפון אותר אולם אינו שייך למשתמש DIRECT. נא לפנות למוקד *5365';
              Swal.fire("", 'נמצא משתמש, אולם הוא אינו משתמש DIRECT.\n נא לפנות למוקד 5365*', 'error')
            }
            else {
              // this.authenticatedError = 'משתמש לא קיים'
              Swal.fire("", 'משתמש לא קיים', 'error')
            }
            console.log('error login', error)
          }
        )
      }
      catch (error) {
        console.log(error)
        this.enableSubmit = true;
        Swal.fire("", "אירעה שגיאה בכניסה למערכת", 'error')
      }
    }
  }

  openModal(targetModal) {
    const modal = this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static',
      keyboard: false,
      size: 'md'
    });
  }
  close(modal) {
    modal.dismiss()
  }

}
