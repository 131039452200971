<div class="profile-div">
    <div class="profile-header-div first-div text-center">
        <h1 class="profile-header header">{{ 'profile.header' | translate }}</h1>
    </div>
    <div class=" part-two d-flex justify-content-center">
        <div class="text-center mx-auto profile-content">
            <div class="justify-content-center align-items-center text-right profile-data">
                <div class="col-md-12 text-center">
                    <h3 class="bold-tytle">{{ 'profile.user-header' | translate }}, {{displayFullname}}
                    </h3>
                </div>
                <div class="row justify-content-md-center">
                    <div class="col-md-5">
                        <label class="data-lbl name-lbl bold text-center">:{{ 'profile.username' | translate }}
                            <ng-multiselect-dropdown [settings]="dropdownSettingsUsers" [data]="users"
                                [(ngModel)]="selectedItemsUsers" (onSelect)="onItemSelectUsers($event)"
                                (onDeSelect)="onItemDeSelectUsers()"
                                placeholder={{this.authenticationService.userData.username}}>
                            </ng-multiselect-dropdown>
                        </label>
                    </div>
                </div>
                <div class="short-width">
                    <div class="row">
                        <div class="col-md-12">
                            <label class="data-lbl">
                                <span class="bold">{{ 'profile.mail' | translate }}: </span>
                                {{displayEmail}}</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <label class="data-lbl height"><span class="bold">{{ 'profile.path' | translate
                                    }}:<br></span>
                                {{displayAcivelist}}<br>
                                <div *ngIf="displayPath==='Private Basic'"><img
                                        src="../../../assets/images/user-profile/private-basic.png" />
                                </div>
                                <div *ngIf="displayPath!=='Private Basic'"><img
                                        src="../../../assets/images/user-profile/private+.png" />
                                </div>
                            </label>
                        </div>
                        <div class="col-md-6">
                            <div class="data-lbl height"><span class="bold">{{ 'profile.lic-num' | translate
                                    }}:<br></span>
                                {{displayNmboflicenses}} <br>
                                <div class="row d-flex justify-content-center btn-div">
                                    <a [routerLink]="['/sales']" routerLinkActive="active"
                                        class="btn-nativ">{{'profile.btn-sales'|translate }}
                                    </a>
                                </div>
                                <div *ngIf="serialsData.length">
                                    <div class="text-center show-table" (click)="openLicenseModal(licenseModal)">
                                        {{'profile.show-license'|translate }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div *ngIf="netType != 'סטיק'" class="col-md-12">
                            <label class="data-lbl"><span class="bold">{{ 'profile.net-type' | translate }}:</span>
                                {{netType}}</label>
                        </div>
                        <div *ngIf="netType == 'סטיק' && userData.stick.length == 1" class="col-md-5">
                            <label class="data-lbl"><span class="bold">{{ 'profile.net-type' | translate }}:</span>
                                {{netType}}</label>
                        </div>
                        <div *ngIf="netType == 'סטיק' && userData.stick.length == 1" class="col-md-7">
                            <label class="data-lbl"><span class="bold">{{ 'profile.sim-num' | translate }}:</span>
                                {{simNum}}</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-7">
                            <label *ngIf="netType == 'סטיק' && userData.stick.length == 1" class="data-lbl"><span
                                    class="bold">{{ 'profile.sim-company' |
                                    translate }}:</span> {{simCompany}}</label>
                        </div>
                        <div class="col-md-5">
                            <label *ngIf="netType == 'סטיק' && userData.stick.length == 1" class="data-lbl"><span
                                    class="bold">{{ 'profile.sim-used' |
                                    translate }}:</span> {{simUsed}}</label>
                        </div>
                    </div>
                    <div *ngIf="netType == 'סטיק' && userData.stick.length == 1"
                        class="row d-flex justify-content-center btn-div">
                        <button (click)="upgrade(simCompany,simNum)" class="btn-nativ upgrade">{{ 'profile.btn' |
                            translate }}
                        </button>
                        <button *ngIf="displaySimActions" (click)="sync(simNum)" class="btn-nativ">{{
                            'profile.table.btn-sync' | translate }}
                        </button>

                        <button *ngIf="displaySimActions" (click)="delete(simNum,$event)" class="btn-nativ">{{
                            'profile.table.btn-delete' | translate }}
                        </button>
                    </div>

                    <div *ngIf="sticksData.length">
                        <div class="text-center show-table" (click)="toggleTable()">*{{ 'profile.more-sims' | translate
                            }}
                        </div>

                        <table *ngIf="showTable" class="table text-center">
                            <thead>
                                <tr>
                                    <th scope="col" class="date">
                                        {{ 'profile.table.sim-num' | translate}}
                                    </th>
                                    <th scope="col" class="name">{{ 'profile.table.sim-company' | translate }}</th>
                                    <th scope="col" class="status">{{ 'profile.table.sim-used' | translate }}
                                    </th>
                                    <th scope="col" class="status"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let stick of sticksData; index as i">
                                    <td>{{stick.simNum}}</td>
                                    <td class="date">{{ stick.simCompany }} </td>
                                    <td class="name">{{stick.simUsed}} </td>
                                    <td class="name"><button (click)="upgrade(stick.simCompany,stick.simNum)"
                                            class="btn-nativ">{{ 'profile.table.btn' | translate }} &#10095;</button>
                                    </td>
                                    <td *ngIf="displaySimActions" class="name"><button (click)="sync(stick.simNum)"
                                            class="btn-nativ">{{
                                            'profile.table.btn-sync' | translate }} &#10095;</button> </td>
                                    <td *ngIf="displaySimActions" class="name"><button
                                            (click)="delete(stick.simNum,$event)" class="btn-nativ">{{
                                            'profile.table.btn-delete' | translate }} &#10095;</button> </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div *ngIf="netType == 'סטיק'" class="row d-flex justify-content-center btn-div">
                        <button *ngIf="displaySimActions" (click)="openModal(modal)" class="btn-nativ">{{
                            'profile.table.btn-new' | translate }}
                        </button>
                    </div>
                    <div class="text-center free-sites">
                        {{ 'profile.free-sites' | translate: {'num-sites': displayQuota } }} </div><br>
                </div>
                <div class="d-flex justify-content-center">
                    <a (click)="navigateMySites()" routerLinkActive="active"
                        class="btn-nativ btn-nativ-page">{{'profile.my-sites-page'|translate }}
                    </a>
                </div>
                <div class="d-flex scheduling justify-content-center">
                    <a (click)="navigateScheduling()" routerLinkActive="active"
                        class="btn-nativ btn-nativ-page">{{'profile.scheduling'|translate }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #modal let-modal class="modal create-sim-modal">
    <div class="modal-header">
        <div class="row">
            <div class="col-md-1 close-modal-header">
                <button type="button" class="close" (click)="close(modal)" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 dep-modal-header profile text-right">
                <div class="row">
                    <div class="col-3 pl-0 text-left">
                    </div>
                    <div class="col-9 pr-0">
                        <!-- <h5 class="modal-title">{{modalHeader}}</h5> -->
                        <div class="not-mobile">{{ 'profile.modal.header' | translate }}</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <h5 class="mobile m-modal-header">{{ 'contact.modal-header' | translate }}</h5> -->
    </div>
    <div class="modal-body">
        <form [formGroup]="createSimForm" class="create-form text-center"
            (ngSubmit)="onSubmit(createSimForm.value,modal)">
            <div class="row">
                <div class="col-md-5 input-div text-right">
                    <div class="form-group">
                        <label for="simCompany">*{{ 'profile.form.sim-company' | translate }}</label>
                        <div class="row">
                            <select formControlName="simCompany" class="form-control" id="simCompany"
                                class="form-select" aria-label="Default select example"
                                [ngClass]="{ 'is-invalid': submitted && f.simCompany.errors }"
                                (change)="getPackages($event.target.value)">
                                <option disabled></option>
                                <option value="1">סלקום</option>
                                <option value="2">019</option>
                            </select>
                            <div *ngIf="submitted && f.simCompany.errors" class="invalid-feedback">
                                <div *ngIf="f.simCompany.errors.required">{{ 'form.validation.emptySimCompany' |
                                    translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-5 input-div text-right">
                    <div class="form-group">
                        <label for="package">*{{ 'profile.form.package' | translate }}</label>
                        <div class="row">
                            <select formControlName="package" class="form-control" id="package" class="form-select"
                                aria-label="Default select example"
                                [ngClass]="{ 'is-invalid': submitted && f.package.errors }" disabled>
                                <option disabled></option>
                                <option *ngFor="let p of packages" [value]="p">
                                    {{p}} ג'יגה
                                </option>
                            </select>
                            <div *ngIf="submitted && f.package.errors" class="invalid-feedback">
                                <div *ngIf="f.package.errors.required">{{ 'form.validation.emptyPackage' | translate
                                    }} </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-5 input-div text-right">
                    <div class="form-group">
                        <label for="serialNum">*{{ 'profile.form.serial-num' | translate }}</label>
                        <div class="row">
                            <input type="text" formControlName="serialNum" class="form-control" id="serialNum"
                                [ngClass]="{ 'is-invalid': submitted && f.serialNum.errors }">
                            <div *ngIf="submitted && f.serialNum.errors" class="invalid-feedback">
                                <div *ngIf="!invalidSerNum && f.serialNum.errors.required">{{
                                    'form.validation.emptySerialNum' | translate
                                    }}
                                </div>
                                <div *ngIf="!invalidSerNum && invalidSerNumLength">
                                    {{
                                    'form.validation.invalidSerialNumLength' | translate
                                    }}
                                </div>
                                <div *ngIf="invalidSerNum">{{
                                    'form.validation.invalidSerialNum' | translate
                                    }}
                                </div>
                                <div *ngIf="!invalidSerNum && f.serialNum.errors.pattern">{{
                                    'form.validation.invalidSerialNum' | translate
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="showSimNum" class="col-md-5 input-div text-right">
                    <div class="form-group">
                        <label for="simNum">*{{ 'profile.form.sim-num' | translate }}</label>
                        <div class="row">
                            <input type="text" formControlName="simNum" class="form-control" id="simNum"
                                [ngClass]="{ 'is-invalid': submitted && f.simNum.errors }">
                            <div *ngIf="submitted && f.simNum.errors" class="invalid-feedback">
                                <div *ngIf="!invalidSimNum && f.simNum.errors.required">{{ 'form.validation.emptySimNum'
                                    |
                                    translate }}</div>
                                <div *ngIf="!invalidSimNum && f.simNum.errors.minlength || f.simNum.errors.maxlength">{{
                                    'form.validation.invalidSimNumLength' | translate
                                    }}
                                </div>
                                <div *ngIf="!invalidSimNum && f.simNum.errors.pattern">{{
                                    'form.validation.invalidSimNum' | translate
                                    }}
                                </div>
                                <div *ngIf="invalidSimNum">{{
                                    'form.validation.invalidSimNum' | translate
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer justify-content-center">
                <!-- <div *ngIf="!contacted" class="invalid-submit">{{contactedError}}</div> -->
                <button [disabled]="!enableSubmit" type="submit" class="btn-nativ form-btn">{{ 'global.create' |
                    translate
                    }}</button>
            </div>
        </form>
    </div>
</ng-template>
<ng-template #passModal let-modal class="modal pass-modal">
    <div class="modal-header">
        <div class="row">
            <div class="col-md-1 close-modal-header">
                <button type="button" class="close" (click)="close(modal)" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 dep-modal-header profile text-right">
                <div class="row">
                    <div class="col-3 pl-0 text-left">
                    </div>
                    <div class="col-9 pr-0">
                        <div class="not-mobile">{{ 'profile.sync-modal.header' | translate }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-body">
        <form [formGroup]="syncPassForm" class="create-form text-center"
            (ngSubmit)="onSubmitPass(syncPassForm.value,modal)">
            <div class="row">
                <div class="col-md-10 input-div text-right">
                    <div class="form-group">
                        <label for="password">*{{ 'profile.form.password' | translate }}</label>
                        <div class="row">
                            <input (focus)="onFocus()" formControlName="password" type="text" class="form-control"
                                id="password" [ngClass]="{ 'is-invalid': submitted && fp.password.errors }">
                            <div *ngIf="submitted && fp.password.errors" class="invalid-feedback">
                                <div *ngIf="fp.password.errors.required">{{ 'form.validation.emptyPassword' | translate
                                    }}
                                </div>
                                <div *ngIf="fp.password.errors && invalidPass">{{ 'form.validation.invalidPassword' |
                                    translate }} </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal-footer justify-content-center">
                <!-- <div *ngIf="!contacted" class="invalid-submit">{{contactedError}}</div> -->
                <button [disabled]="!enableSubmit" type="submit" class="btn-nativ form-btn">{{ 'profile.table.btn-sync'
                    |
                    translate
                    }}</button>
            </div>
        </form>
    </div>
</ng-template>
<ng-template #licenseModal let-modal class="modal license-modal">
    <div class="modal-header">
        <div class="row">
            <div class="col-md-1 close-modal-header">
                <button type="button" class="close" (click)="closeLicense(modal)" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 dep-modal-header profile">
                <div class="text-center tytle">
                    <div class="not-mobile">{{ 'profile.list-of-licenses' | translate }}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-body">
        <div class="row">
            <div>
                <div class="text-center initializeFilters" (click)="initializeFilters()">{{'my-sites.reset-filters' |
                    translate }}</div>
            </div>
        </div>
        <div class="row">
            <div class="license">
                <table class="table text-center">
                    <thead>
                        <tr>
                            <th></th>
                            <th scope="col" [ngClass]="lang === 'EN' ? 'login-licenseEN' : 'login-license'">
                                <div class="d-flex align-items-center justify-content-center">
                                    <div (click)="licenseTableSort('login')">
                                        <i *ngIf="sorted !== 'login'&&sorted !== 'login desc'" class="fa fa-sort"
                                            aria-hidden="true"></i>
                                        <i *ngIf="sorted === 'login desc'" class="fa fa-sort-desc"
                                            aria-hidden="true"></i>
                                        <i *ngIf="sorted === 'login'" class="fa fa-sort-asc" aria-hidden="true"></i>
                                    </div>
                                    <ng-multiselect-dropdown [settings]="dropdownSettingsLogin" [data]="logins"
                                        [(ngModel)]="selectedItemsLogin" (onSelect)="onItemSelect($event)"
                                        (onDeSelect)="onItemDeSelect($event,selectedItemsLogin)" [placeholder]="' '">
                                    </ng-multiselect-dropdown>
                                </div>
                            </th>
                            <th scope="col"
                                [ngClass]="lang === 'EN' ? 'computerName-licenseEN' : 'computerName-license'">
                                <div class="d-flex align-items-center justify-content-center">
                                    <div (click)="licenseTableSort('computerName')">
                                        <i *ngIf="sorted !== 'computerName'&&sorted !== 'computerName desc'"
                                            class="fa fa-sort" aria-hidden="true"></i>
                                        <i *ngIf="sorted === 'computerName desc'" class="fa fa-sort-desc"
                                            aria-hidden="true"></i>
                                        <i *ngIf="sorted === 'computerName'" class="fa fa-sort-asc"
                                            aria-hidden="true"></i>
                                    </div>
                                    <ng-multiselect-dropdown [settings]="dropdownSettingsComputerName"
                                        [data]="computerNames" [(ngModel)]="selectedItemsComputerName"
                                        (onSelect)="onItemSelect($event)"
                                        (onDeSelect)="onItemDeSelect($event,selectedItemsComputerName)"
                                        [placeholder]="' '">
                                    </ng-multiselect-dropdown>
                                </div>
                            </th>
                            <th scope="col"
                                [ngClass]="lang === 'EN' ? 'computerLastLogin-licenseEN' : 'computerLastLogin-license'">
                                <div class="d-flex align-items-center justify-content-center">
                                    <div (click)="licenseTableSort('computerLastLogin')">
                                        <i *ngIf="sorted !== 'computerLastLogin'&&sorted !== 'computerLastLogin desc'"
                                            class="fa fa-sort" aria-hidden="true"></i>
                                        <i *ngIf="sorted === 'computerLastLogin desc'" class="fa fa-sort-desc"
                                            aria-hidden="true"></i>
                                        <i *ngIf="sorted === 'computerLastLogin'" class="fa fa-sort-asc"
                                            aria-hidden="true"></i>
                                    </div>
                                    <ng-multiselect-dropdown [settings]="dropdownSettingsComputerLastLogin"
                                        [data]="computerLastLogins" [(ngModel)]="selectedItemsComputerLastLogin"
                                        (onSelect)="onItemSelect($event)"
                                        (onDeSelect)="onItemDeSelect($event,selectedItemsComputerLastLogin)"
                                        [placeholder]="' '">
                                    </ng-multiselect-dropdown>
                                </div>
                            </th>
                            <th scope="col"
                                [ngClass]="lang === 'EN' ? 'enativVersion-licenseEN' : 'enativVersion-license'">
                                <div class="d-flex align-items-center justify-content-center">
                                    <div (click)="licenseTableSort('enativVersion')">
                                        <i *ngIf="sorted !== 'enativVersion'&&sorted !== 'enativVersion desc'"
                                            class="fa fa-sort" aria-hidden="true"></i>
                                        <i *ngIf="sorted === 'enativVersion desc'" class="fa fa-sort-desc"
                                            aria-hidden="true"></i>
                                        <i *ngIf="sorted === 'enativVersion'" class="fa fa-sort-asc"
                                            aria-hidden="true"></i>
                                    </div>
                                    <ng-multiselect-dropdown [settings]="dropdownSettingsEnativVersion"
                                        [data]="enativVersions" [(ngModel)]="selectedItemsEnativVersion"
                                        (onSelect)="onItemSelect($event)"
                                        (onDeSelect)="onItemDeSelect($event,selectedItemsEnativVersion)"
                                        [placeholder]="' '">
                                    </ng-multiselect-dropdown>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let serial of serialsFilterData; index as i">
                            <td [attr.data-label]="'' | translate">{{i+1}}</td>
                            <td [attr.data-label]="'profile.license-table.login' | translate">{{serial.login}}</td>
                            <td [attr.data-label]="'profile.license-table.computer-name' | translate">
                                {{serial.computerName}}</td>
                            <td class="date"
                                [attr.data-label]="'profile.license-table.computer-last-login' | translate">{{
                                serial.computerLastLogin
                                }} </td>
                            <td class="name" [attr.data-label]="'profile.license-table.version' | translate">
                                {{serial.enativVersion}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</ng-template>
<app-bottom-nav></app-bottom-nav>