<div class="row background-color">
    <div class="col-md-2 text-left ext-adv-div">
        <div class="col-md-12 text-center mx-auto">
            <img *ngIf="showAdvs" class="adv-img" [src]="advsData[0].src"
                (click)="navigateToAdvSite(advsData[0].link,'right')" />
            <!-- <iframe *ngIf="showAdvs" scrolling="no" class="" frameBorder="0" class="adv-iframe-side"
                src="../../../assets/images/advs/ezer-mezion/ezer_mezion176x736.html"></iframe>
            <span style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 10;"
                (click)="navigateToAdvSite(advsData[0].link,'right')"></span> -->
        </div>
    </div>
    <div class="col-md-2 image-container">
        <div class="small-image-container">
            <img id="cart" src="../../../assets/images/add-site/cart.png" />
            <h5 class="sites-header">{{ 'add-site.header' | translate }}</h5>
            <h6 class="sites-sub-header">{{ 'add-site.sub-header' | translate }}</h6>
        </div>
    </div>
    <div class="col-md-6 text-center content-div">
        <div class="row">
            <div class="col-md-12 text-center mx-auto border-width">
                <h1 class="sites-header">{{ 'add-site.content-header' | translate }}</h1>
                <div class="justify-content-center align-items-center form-div">
                    <form [formGroup]="addSiteForm" (ngSubmit)="onSubmit(addSiteForm.value)" autocomplete="off">
                        <div class="col-md-12 mx-auto">
                            <div class="tytle">
                                <h6 [ngClass]="{ 'red-header': overQuota }">{{ displayQuota }} </h6>
                                <h6 *ngIf="gviaproblem" class="red-header">{{ gviaproblemAlert }} </h6>
                            </div>
                            <div class="makeBorder">
                                <h4 class="form-header">{{ 'form.header' | translate }}</h4>
                                <h6 class="form-sub-header" [innerHTML]="'form.sub-header' | translate"
                                    id="innerHTMLRed"></h6>
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <div>
                                                <div
                                                    [ngClass]="{ 'username-input-with-placeholder': !disabledUsername }">
                                                    <input #usernameInput [attr.disabled]="disabledUsername ? '' : null"
                                                        type="text" formControlName="username" class="form-control"
                                                        id="username" placeholder="{{ 'form.username' | translate }}"
                                                        [ngModel]="displayUserName"
                                                        [ngClass]="{ 'is-invalid': submitted && f.username.errors }">
                                                </div>
                                                <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                                                    <div *ngIf="f.username.errors.required">
                                                        {{ 'form.validation.emptyUsername' | translate }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="passRequired" class="col-md-4">
                                        <div class="form-group after-div">
                                            <div class="addSitePass-input-with-placeholder">
                                                <input #addSitePassInput autocomplete="new-password"
                                                    formControlName="password"
                                                    [type]="isPasswordVisible ? 'text' : 'password'"
                                                    class="form-control text-right" id="password"
                                                    placeholder="{{ 'form.addSitesPassword' | translate }}"
                                                    [ngClass]="{ 'is-invalid': (submitted && f.password.errors) || (submitted && !validPass) }">
                                            </div>
                                            <i [ngClass]="isPasswordVisible ? 'fa fa-eye' : 'fa fa-eye-slash'"
                                                class="col-2 eye-slash icon" (click)="togglePasswordVisibility()"></i>
                                            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                                <div *ngIf="f.password.errors.required">
                                                    {{ 'form.validation.emptyPassword' |translate }}</div>
                                            </div>
                                            <div *ngIf="submitted && !validPass" class="invalid-feedback">
                                                <div *ngIf="!validPass">{{ 'form.validation.invalidPassword' | translate
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="displayEmailInput || matrixUser"
                                        [ngClass]="{ 'col-md-4': passRequired, 'col-md-8': !passRequired }">
                                        <div class="form-group after-div">
                                            <div
                                                [ngClass]="{ 'mail-input-with-placeholder': matrixUser || isIaiUser || isEltaServer }">
                                                <input #mailInput autocomplete="off" formControlName="email"
                                                    type="email" class="form-control text-left" id="email"
                                                    [ngModel]="emailInput"
                                                    placeholder="{{(isIaiUser ? 'form.iai-email'  : 'form.second-email') | translate }}"
                                                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                                                    [ngClass]="{ 'right-aligned-placeholder': isHe }">
                                            </div>
                                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                                <div *ngIf="f.email.errors.required">
                                                    {{ 'form.validation.emptyEmail' | translate }}</div>
                                                <div *ngIf="f.email.errors.email">
                                                    {{ 'form.validation.invalidEmail' | translate }}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="showChangeUser">
                                        <div class="col-md-12">
                                            <div class="form-group after-div">
                                                <div class="password-input-with-placeholder">
                                                    <input #passwordInput autocomplete="off"
                                                        formControlName="userPassword" type="password"
                                                        class="form-control text-right" id="userPassword"
                                                        placeholder="{{ 'form.password' | translate }}"
                                                        [ngClass]="{ 'is-invalid': submitted && f.userPassword.errors }">
                                                </div>
                                                <div *ngIf="submitted && f.userPassword.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.userPassword.errors.required">
                                                        {{ 'form.validation.emptyPassword' | translate }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 text-right">
                                        <label>לבקשת אתר עבור שם משתמש אחר לחצו <a
                                                (click)="changeUser()">כאן</a></label>
                                    </div>
                                </div>
                                <div class="form-group after-div row">
                                    <div class="col-md-6">
                                        <div class="url-input-with-placeholder">
                                            <input #urlInput type="text" autocomplete="disabled" formControlName="url"
                                                class="form-control" id="url"
                                                placeholder="{{ 'form.site-address' | translate }}" [ngModel]="url"
                                                [ngClass]="{ 'is-invalid': submitted && f.url.errors }"
                                                [ngClass]="{ 'right-aligned-placeholder': isHe }">
                                        </div>
                                        <div *ngIf="submitted && f.url.errors" class="invalid-feedback">
                                            <div *ngIf="f.url.errors.required">{{ 'form.validation.emptySiteaddress' |
                                                translate
                                                }}
                                            </div>
                                        </div>
                                        <div *ngIf="submitted && f.url.errors" class="invalid-feedback">
                                            <div *ngIf="f.url.errors.pattern"> {{ 'form.validation.invalidUrl' |
                                                translate }} </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <div>
                                                <input type="text" formControlName="comments" class="form-control"
                                                    id="comments" placeholder="{{ 'form.comments' | translate }}">
                                                <span class="comment-alert">{{'add-site.form.comment-alert' |
                                                    translate}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div *ngIf="matrixUser" class="col-md-4">
                                        <div class="form-group after-div">
                                            <div class="fullname-input-with-placeholder">
                                                <input #fullnameInput autocomplete="off" formControlName="name"
                                                    type="text" class="form-control text-right" id="name"
                                                    placeholder="{{ 'form.fullname' | translate }}"
                                                    [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                                            </div>
                                            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                                <div *ngIf="f.name.errors.required">
                                                    {{ 'form.validation.emptyName' | translate }}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="matrixUser" class="col-md-4">
                                        <div class="form-group after-div">
                                            <div class="project-input-with-placeholder">
                                                <input #projectInput autocomplete="off" formControlName="project"
                                                    type="text" class="form-control text-right" id="project"
                                                    placeholder="{{ 'form.project' | translate }}"
                                                    [ngClass]="{ 'is-invalid': submitted && f.project.errors }">
                                            </div>
                                            <div *ngIf="submitted && f.project.errors" class="invalid-feedback">
                                                <div *ngIf="f.project.errors.required">
                                                    {{ 'form.validation.emptyProject' | translate }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div *ngIf="displayEmailDB" class="col-md-4">
                                        <div class="form-group after-div">
                                            <input autocomplete="off" formControlName="email" type="email"
                                                class="form-control text-right yellow" id="email"
                                                placeholder="{{ 'form.email' | translate }}"
                                                [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                                <div *ngIf="f.email.errors.required">
                                                    {{ 'form.validation.emptyEmail' | translate }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="overQuota" class="row">
                                    <div class="col-md-12">
                                        <div class="form-group after-div">
                                            <div>
                                                <label class="form-check-label">
                                                    <input type="checkbox" class="form-check-input checkbox-quota"
                                                        value="true" (change)="checkValue($event)">סמן לאישור גביה עבור
                                                    הוספת אתרים מעבר למיכסה
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="pt-1 pb-1 add-more">
                                    <div>{{ 'add-site.form.add-more-header' | translate }}
                                    </div>
                                </div>
                                <div class="send-btn">
                                    <button [disabled]="!enableSubmit" type="submit"
                                        class="btn-nativ form-btn">{{'global.send'|translate }}
                                        <img *ngIf="!isThink" src='../../../assets/images/new/nativ-gif-2.gif' />
                                    </button>
                                </div>
                                <div class="row">
                                    <div class="col-md-5">
                                        <div class="form-group">
                                            <div>
                                                <input type="text" formControlName="comments1" class="form-control"
                                                    id="comments1" placeholder="{{ 'form.comments' | translate }}">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-7">
                                        <div class="form-group">
                                            <div>
                                                <input type="text" formControlName="url1" class="form-control" id="url1"
                                                    placeholder="{{ 'form.site-address' | translate }}">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-5">
                                        <div class="form-group after-div">
                                            <div>
                                                <input type="text" formControlName="comments2" class="form-control"
                                                    id="comments2" placeholder="{{ 'form.comments' | translate }}">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-7">
                                        <div class="form-group after-div">
                                            <div>
                                                <input type="text" formControlName="url2" class="form-control" id="url2"
                                                    placeholder="{{ 'form.site-address' | translate }}">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button [disabled]="!enableSubmit" type="submit"
                                    class="btn-nativ form-btn send-all-btn">{{'global.send-all'|translate }}
                                    <img *ngIf="!enableSubmit" src='../../../assets/images/new/nativ-gif-2.gif' />
                                </button>
                            </div>
                        </div>
                    </form>

                </div>
            </div>

        </div>
    </div>
    <div class="col-md-2 text-right ext-adv-div">
        <div class="col-md-12 text-center mx-auto">
            <img *ngIf="showAdvs" class="adv-img" [src]="advsData[1].src"
                (click)="navigateToAdvSite(advsData[1].link,'left')" />
            <!-- <iframe *ngIf="showAdvs" scrolling="no" class="" frameBorder="0" class="adv-iframe-side"
                src="../../../assets/images/advs/ezer-mezion/ezer_mezion176x736.html"></iframe>
            <span style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 10;"
                (click)="navigateToAdvSite(advsData[1].link,'left')"></span> -->
        </div>
    </div>
    <div *ngIf="showAdvs && advsData[2].show" class="ext-adv-div floating-adv">
        <button type="button" class="close" (click)="closeAdv(2)" aria-label="Close" style="z-index: 1000;">
            <span aria-hidden="true">&times;</span>
        </button>
        <img [src]="advsData[2].src" (click)="navigateToAdvSite(advsData[2].link,'bottom')" />
        <!-- <iframe *ngIf="showAdvs" scrolling="no" class="" frameBorder="0" class="adv-iframe-bottom"
            src="../../../assets/images/advs/ezer-mezion/ezer_mezion70x904.html"></iframe>
        <span class="adv-mask" (click)="navigateToAdvSite(advsData[2].link,'bottom')"></span> -->
    </div>
</div>

<app-bottom-nav></app-bottom-nav>