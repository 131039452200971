import { Component, OnInit, ViewChild, ElementRef, HostListener, ChangeDetectionStrategy } from '@angular/core';
import * as AOS from 'aos';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AboutComponent implements OnInit {
  moreData = false;
  moreDataNativ = false;
  constructor(public translateService: TranslateService) { }

  @ViewChild('showMoreData') showMoreDataEl: ElementRef;
  @ViewChild('showMore') showMoreEl: ElementRef;
  @ViewChild('scrollBtn') scrollBtnEl: ElementRef;

  @HostListener("window:scroll", ["$event"])
  onWindowScroll(event) {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      this.scrollBtnEl.nativeElement.style.display = "block";
    } else {
      this.scrollBtnEl.nativeElement.style.display = "none";
    }
  }

  ngOnInit(): void {
    AOS.init({
      offset: 200,
      duration: 600,
      easing: 'ease-in-sine',
      delay: 100,
      mirror: true
    });
  }

  topFunction() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  readMore() {
    this.moreData = !this.moreData;
    if (this.moreData) {
      this.showMoreDataEl.nativeElement.text = this.translateService.instant('about.read-less');
    }
    else {
      this.showMoreDataEl.nativeElement.text = this.translateService.instant('about.read-more');
    }
  }
  showMoreNativ() {
    this.moreDataNativ = !this.moreDataNativ;
    if (this.moreDataNativ) {
      this.showMoreEl.nativeElement.text = this.translateService.instant('about.read-less');
    }
    else {
      this.showMoreEl.nativeElement.text = this.translateService.instant('about.read-more');
    }
  }
}
