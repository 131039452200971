import { Component, OnInit, ViewChild, ElementRef, HostListener, ChangeDetectionStrategy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as AOS from 'aos';

@Component({
  selector: 'app-kosher',
  templateUrl: './kosher.component.html',
  styleUrls: ['./kosher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KosherComponent implements OnInit {

  moreData1 = false;
  moreData2 = false;
  moreData3 = false;

  audio1: any;
  play1 = false;
  audio2: any;
  play2 = false;
  audio3: any;
  play3 = false;
  audio4: any;
  play4 = false;
  audio5: any;
  play5 = false;

  constructor(public translateService: TranslateService) { }

  @ViewChild('showMoreData1') showMoreDataEl1: ElementRef;
  @ViewChild('showMoreData2') showMoreDataEl2: ElementRef;
  @ViewChild('showMoreData3') showMoreDataEl3: ElementRef;

  @ViewChild('playAudio_1') playAudioEl1: ElementRef;
  @ViewChild('playAudio_2') playAudioEl2: ElementRef;
  @ViewChild('playAudio_3') playAudioEl3: ElementRef;
  @ViewChild('playAudio_4') playAudioEl4: ElementRef;
  @ViewChild('playAudio_5') playAudioEl5: ElementRef;

  @ViewChild('scrollBtn') scrollBtnEl: ElementRef;
  @HostListener("window:scroll", ["$event"])
  onWindowScroll(event) {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      this.scrollBtnEl.nativeElement.style.display = "block";
    } else {
      this.scrollBtnEl.nativeElement.style.display = "none";
    }
  }
  ngOnInit(): void {
    AOS.init({
      offset: 200,
      duration: 600,
      easing: 'ease-in-sine',
      delay: 100,
    });
  }

  topFunction() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }
  readMore1() {
    this.moreData1 = !this.moreData1;
    if (this.moreData1) {
      this.showMoreDataEl1.nativeElement.text = "קרא/י פחות"
    }
    else {
      this.showMoreDataEl1.nativeElement.text = "קרא/י עוד"
    }
  }

  readMore2() {
    this.moreData2 = !this.moreData2;
    if (this.moreData2) {
      this.showMoreDataEl2.nativeElement.text = "קרא/י פחות"
    }
    else {
      this.showMoreDataEl2.nativeElement.text = "קרא/י עוד"
    }
  }

  readMore3() {
    this.moreData3 = !this.moreData3;
    if (this.moreData3) {
      this.showMoreDataEl3.nativeElement.text = "קרא/י פחות"
    }
    else {
      this.showMoreDataEl3.nativeElement.text = "קרא/י עוד"
    }
  }

  playAudio1() {
    this.play1 = !this.play1;
    if (!this.play1) {
      this.audio1.pause();
      this.playAudioEl1.nativeElement.style.backgroundImage = "url('../../../assets/images/kosher/new/play1.png')";
    }
    else {
      this.playAudioEl1.nativeElement.style.backgroundImage = "url('../../../assets/images/kosher/new/pause1.png')";
      this.audio1 = new Audio();
      this.audio1.src = "../../../assets/audio/harav-shteinman.wav";
      this.audio1.load();
      this.audio1.play();
    }
  }
  playAudio2() {
    this.play2 = !this.play2;
    if (!this.play2) {
      this.audio2.pause();
      this.playAudioEl2.nativeElement.style.backgroundImage = "url('../../../assets/images/kosher/new/play2.png')";
    }
    else {
      this.playAudioEl2.nativeElement.style.backgroundImage = "url('../../../assets/images/kosher/new/pause2.png')";
      this.audio2 = new Audio();
      this.audio2.src = "../../../assets/audio/harav-shtain.mp3";
      this.audio2.load();
      this.audio2.play();
    }
  }
  playAudio3() {
    this.play3 = !this.play3;
    if (!this.play3) {
      this.audio3.pause();
      this.playAudioEl3.nativeElement.style.backgroundImage = "url('../../../assets/images/kosher/new/play3.png')";
    }
    else {
      this.playAudioEl3.nativeElement.style.backgroundImage = "url('../../../assets/images/kosher/new/pause3.png')";
      this.audio3 = new Audio();
      this.audio3.src = "../../../assets/audio/harav-shvartz.mp3";
      this.audio3.load();
      this.audio3.play();
    }
  }
  playAudio4() {
    this.play4 = !this.play4;
    if (!this.play4) {
      this.audio4.pause();
      this.playAudioEl4.nativeElement.style.backgroundImage = "url('../../../assets/images/kosher/new/play4.png')";
    }
    else {
      this.playAudioEl4.nativeElement.style.backgroundImage = "url('../../../assets/images/kosher/new/pause4.png')";
      this.audio4 = new Audio();
      this.audio4.src = "../../../assets/audio/harav-rozen.mp3";
      this.audio4.load();
      this.audio4.play();
    }
  }
  playAudio5() {
    this.play5 = !this.play5;
    if (!this.play5) {
      this.audio5.pause();
      this.playAudioEl5.nativeElement.style.backgroundImage = "url('../../../assets/images/kosher/new/play5.png')";
    }
    else {
      this.playAudioEl5.nativeElement.style.backgroundImage = "url('../../../assets/images/kosher/new/pause5.png')";
      this.audio5 = new Audio();
      this.audio5.src = "../../../assets/audio/harav-zicherman.mp3";
      this.audio5.load();
      this.audio5.play();
    }
  }

}

