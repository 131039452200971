import { Component, OnInit, ViewChild, ElementRef, HostListener, TemplateRef } from '@angular/core';
import { ApiService } from '../../services/api/api.service';
import { SalesService } from '../../services/sales/sales.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import * as AOS from 'aos';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication/authentication.service'
import { UtilsService } from '../../services/utils/utils.service'
import { ResponsiveService } from '../../services/responsive/responsive.service'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import { NavbarService } from '../../services/navbar/navbar.service';
import { ChatService } from '../../services/chat/chat.service'
import { AuthenticationGuardService } from 'src/app/authentication-guard.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-home-logged-in',
  templateUrl: './home-logged-in.component.html',
  styleUrls: ['./home-logged-in.component.scss']
})
export class HomeLoggedInComponent implements OnInit {

  loginForm: any;
  searchSiteForm: any;
  submitted = false;
  contacted = false;
  authenticated = true;
  authenticatedError = "";
  contactSuccess = "";
  categories = [];
  mCategories = []
  showNativContent1 = false;
  showNativContent2 = false;
  showNativContent3 = false;
  showNativContent4 = false;
  pendingMsgs = [];
  isOpen = true;
  isMobile = false;
  invalidLogin = false;
  sites = [];
  sitesData = [];
  showSites = false;
  sitesLength = 0;
  loading = false;
  page = 1;
  pageSize = 20;
  collectionSize: number;
  searchSitesInput: string;
  pendingMsgsCount = 0;
  pendingMsgsSubject = 'אישור אתר חריג';
  pendingDocs = [];
  isUserLoggedIn = true;
  userParams = {};
  stations = [];
  validPhoneNimber = true;
  disabledAddSites = false;
  nativVersion = ""
  innerWidth = 0
  bgURL = "https://www.enativ.com/user-bg.png"
  subscription: Subscription;
  omerDate = {
    "days": "",
    "weeks": ""
  };
  alertText = "";
  alertHeader = "";
  mobileCategories = false;
  isShowChat = false;
  isStickUser = false;
  refreshing = false;
  displayUpgradeVersion = true;
  isShowAlert = false;
  isShowServerAlert = true;
  displayInSmallScreen = false;
  isShowNewAlert = true
  noNativVersion = false;
  toUpgrade = false;
  upgradeAlertContact = false
  updatedVersion = true
  firstname = ""
  lastname = ""
  username = ""
  zmanimTzais = ""
  updateOmer = false
  displayMironLink = false
  mironUrl = ""
  displayOmer = false
  showVideoButton = false
  displayChatBtn = false
  //advs
  showAdvs = false
  advsData = [
    { src: "../../../assets/images/advs/malam/home.jpg", show: true, link: "malam" },
    { src: "../../../assets/images/advs/malam/home.jpg", show: true, link: "malam" }
  ]

  //manager pass modal
  syncPassForm: any;
  managerSubmitted = false;
  invalidPass = false;
  enableSubmit = true;
  @ViewChild('passModal') public passModal: TemplateRef<any>;
  isPasswordVisible = false;
  userServer: boolean = false;

  constructor(private apiService: ApiService,
    private salesService: SalesService,
    private formBuilder: FormBuilder,
    private router: Router,
    public authenticationService: AuthenticationService,
    public chatService: ChatService,
    public responsiveService: ResponsiveService,
    private modalService: NgbModal,
    public translateService: TranslateService,
    private route: ActivatedRoute,
    private utilsService: UtilsService,
    public nav: NavbarService,
    private authGuardService: AuthenticationGuardService) {
    this.authenticationService.authenticated$.subscribe(value => {
      this.isUserLoggedIn = value;
      if (this.isUserLoggedIn) {
        if (!this.authenticationService.userData.usertypeid || this.authenticationService.userData.UserName !== this.authenticationService.userData.login) {
          this.authenticationService.login(this.authenticationService.userData).subscribe(
            data => {
              this.authenticationService.userData = { ...this.authenticationService.userData, ...data["UserData"] }
              localStorage.setItem('nativUser', JSON.stringify(this.authenticationService.userData));

              this.initData()
            },
            error => {
              console.log("home-logged-in:login error:" + JSON.stringify(error))
            }
          )
        }
        else {
          this.initData()
        }
      }
    })

    this.apiService.categories$.subscribe(value => {
      this.categories = value.categories;
      this.mCategories = value.mCategories
    });

    this.initForms(true)
    this.subscription = this.utilsService.getImage().subscribe(image => {
      this.bgURL = image;
    });
    this.syncPassForm = this.formBuilder.group({
      password: '',
    });
    // this.changeAdvsPlace()
  }
  @ViewChild('scrollBtn') scrollBtnEl: ElementRef;

  @HostListener("window:scroll", ["$event"])
  onWindowScroll(event) {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      this.scrollBtnEl.nativeElement.style.display = "block";
    } else {
      this.scrollBtnEl.nativeElement.style.display = "none";
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 1366) {
      this.displayInSmallScreen = true;
    }
    else {
      this.displayInSmallScreen = false;
    }
    if (this.innerWidth <= 1200) {
      this.mobileCategories = true;
    }
    else {
      this.mobileCategories = false;
    }
  }
  changeAdvsPlace() {
    const now = new Date();
    const minute = now.getMinutes();
    if (minute % 3 === 0) {
      this.advsData = [
        { src: "../../../assets/images/advs/malam/home.jpg", show: true, link: "malam" },
        { src: "../../../assets/images/advs/malam/home.jpg", show: true, link: "malam" }
      ]
    }
    else if (minute % 3 === 1) {
      this.advsData = [
        { src: "../../../assets/images/advs/malam/home.jpg", show: true, link: "malam" },
        { src: "../../../assets/images/advs/malam/home.jpg", show: true, link: "malam" }
      ]
    }
  }
  initData() {
    this.firstname = this.authenticationService.userData.firstname
    this.lastname = this.authenticationService.userData.lastname
    this.username = this.authenticationService.userData.username
    this.showAlertFromServer()
    this.checkShowVideoButton()
    this.checkShowAdvs()
    // this.showGPTAlert()
    if (this.authenticationService.userData.appNativVersion == "none") {
      this.noNativVersion = true
    }
    else {
      this.nativVersion = this.authenticationService.userData.appNativVersion ? this.authenticationService.userData.appNativVersion : this.nativVersion
    }
    this.authenticationService.shortNativLogin().subscribe(
      loginData => {
        console.log('success shortNativLogin', loginData)
      },
      error => {
        console.log('error shortNativLogin', error)
        if (this.authenticationService.userData.id == environment.iaiServerUserId || this.authenticationService.userData.id == environment.eltaServer ||
          this.authenticationService.userData.id == environment.iaiServer1UserId || this.authenticationService.userData.id == environment.arachimL1UserId ||
          this.authenticationService.userData.id == environment.arachimL2UserId || this.authenticationService.userData.id == environment.arachimL3UserId ||
          this.authenticationService.userData.id == environment.arachimL4UserId || this.authenticationService.userData.id == environment.arachimL5UserId ||
          this.authenticationService.userData.id == environment.arachimL6UserId || this.authenticationService.userData.id == environment.arachimL7UserId ||
          this.authenticationService.userData.isOrgServer == 1) {
          this.userServer = true
        }
      }
    )

    const splitVersion = this.nativVersion.split(".")
    if (parseInt(splitVersion[0]) == 4 && parseInt(splitVersion[1]) == 5) {
      this.apiService.getLastNativVersion().subscribe(
        data => {
          const lastVersion = data["res"]
          if (lastVersion != "" && this.nativVersion !== lastVersion) {
            const splitLastVersion = lastVersion.split(".")
            if (parseInt(splitVersion[2]) < parseInt(splitLastVersion[2])) {
              this.toUpgrade = true
              this.updatedVersion = false
              this.showAlert()
            }
            else if (parseInt(splitVersion[2]) == parseInt(splitLastVersion[2])) {
              if (parseInt(splitVersion[3]) < parseInt(splitLastVersion[3])) {
                this.toUpgrade = true
                this.updatedVersion = false
                this.showAlert()
              }
            }
          }
        },
        error => {
          console.log('error getLastNativVersion', error)
        }
      )
    }
    else {
      this.updatedVersion = false
      if (this.authenticationService.userData.lowVersion && this.authenticationService.userData.BlockActivity.includes("3")) {
        this.showAlert(true)
      }
    }

    if (this.innerWidth <= 1200) {
      this.mobileCategories = true;
    }
    else {
      this.mobileCategories = false;
    }
    if (this.categories.length == 0) {
      this.apiService.initCategories()
    }
    this.getPendingDocs();

    const usertypeid = this.authenticationService.userData.usertypeid
    if (["9", "A", "C", "7", "B"].includes(usertypeid.substring(5, 6)) || this.authenticationService.userData.noaddsites == "1") {
      this.disabledAddSites = true
    }
    const userType = this.utilsService.getUserType(usertypeid)
    if (userType.USER_OPEN_ALL_SITES) {
      this.translateService.get('alerts.open-user').subscribe((translated: string) => {
        Swal.fire({
          title: "",
          text: translated,
          icon: "warning"
        })
      });

    }
  }
  checkShowVideoButton() {
    const userData = this.authenticationService.userData
    const userType = this.utilsService.getUserType(userData.usertypeid)
    this.authenticationService.getUserSitesData(userData.login).subscribe(res => {
      if (res['isVideoSiteBlocked'] || userType.IS_USER_GUR || userType.IS_USER_BOYAN || userType.IS_USER_MAIL_ONLY || userData.noaddsites == 1) {
        this.showVideoButton = false
      }
      else
        // this.showVideoButton = false
        this.showVideoButton = true
    })
  }
  toggleChat(value) {
    this.isShowChat = value;
  }
  topFunction() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }
  showAlert(openContact = false) {
    const showAlertStorage = localStorage.getItem('upgrade-alert')
    this.isShowAlert = showAlertStorage !== "false"
    this.upgradeAlertContact = openContact
  }

  showEmailsAlert() {
    const enativAlert = "<div>לקוחות יקרים!\n  תזכורת בנוגע להפסקת שירותי המייל האישי של נתיב\n לפרטים נוספים כנסו לקישור המצורף:\n  <a target='_blank' href='https://enativ.com/enativ-re.pdf'>כאן</a>\n אנא הזדרזו לטפל בענין\n בברכה, צוות נתיב.</div>"
    const kavnakiAlert = "<div>לקוחות יקרים!\n תזכורת בנוגע להפסקת שירותי המייל האישי של נתיב-קו נקי   \nלפרטים נוספים כנסו לקישור המצורף:\n  <a target='_blank' href='https://enativ.com/kavnaki-re.pdf'>כאן</a>\n אנא הזדרזו לטפל בענין\n  בברכה, צוות נתיב.</div>"
    this.apiService.checkUserEmail().subscribe(
      data => {
        console.log('checkUserEmail success:', data)
        if (data["res"] == false) {
          this.isShowServerAlert = false;
        }
        else {
          if (data["res"] == "kavnaki") {
            this.alertText = kavnakiAlert
          }
          else {
            this.alertText = enativAlert
          }
          const alertContentStorage = localStorage.getItem('server-alert-content')
          if (alertContentStorage !== this.alertText) {
            this.isShowServerAlert = true;
          }
          else if (localStorage.getItem('server-alert')) {
            const showAlertStorage = parseInt(localStorage.getItem('server-alert'))
            this.isShowServerAlert = showAlertStorage < 3
          }
          else {
            this.isShowServerAlert = true;
          }
        }
      },
      error => console.log('error checkUserEmail', error)
    )
  }

  showGPTAlert() {
    const chatAlert = "<div>בעקבות בעיות כשרות באתר openai, החל מהיום ניתן יהיה לגלוש באתר זה בדפדפן פירפוקס בלבד. \n כמו כן, השאלות שיישלחו לרובוט יעברו סינון ובמידה וימצא תוכן בעייתי בשאלה לא ניתן יהיה להמשיך את ההתכתבות באותו הצ'אט ותוצג הודעת השגיאה 'Something went wrong.If this issue persists please contact us through our help center at help.openai.com.' \n במצב כזה ניתן יהיה לפתוח צ'אט חדש.\n\n בברכת עבודה פוריה ונקיה,\n צוות נתיב.</div>"
    this.apiService.isGTPTAlert().subscribe(
      data => {
        console.log('isGTPTAlert success:', data)
        if (!data["res"]) {
          this.isShowServerAlert = false;
        }
        else {
          this.alertText = chatAlert
          const alertContentStorage = localStorage.getItem('server-alert-content')
          if (alertContentStorage !== this.alertText) {
            this.isShowServerAlert = true;
          }
          else if (localStorage.getItem('server-alert')) {
            const showAlertStorage = parseInt(localStorage.getItem('server-alert'))
            this.isShowServerAlert = showAlertStorage < 3
          }
          else {
            this.isShowServerAlert = true;
          }
        }
      },
      error => console.log('error showGPTAlert', error)
    )
  }

  showAlertFromServer() {
    this.apiService.getAlert().subscribe(
      data => {
        // this.alertText = "local";
        if (data != "") {
          // const splitText = data.split("<b>")[1].split("</b>")
          // const text = splitText[1]
          // const header = splitText[0]
          // this.alertHeader = header;
          // this.alertText = text;

          // miron link
          // this.mironUrl = data;
          // if (this.mironUrl !== "") {
          //   this.displayMironLink = true
          //   const usertypeid = this.authenticationService.userData.usertypeid
          //   const userType = this.utilsService.getUserType(usertypeid)
          //   if (userType.IS_USER_GUR) {
          //     this.displayMironLink = false
          //   }
          // }

          this.alertText = data;
          if (this.alertText.includes("CHAT_BTN")) {
            this.alertText = this.alertText.replace("CHAT_BTN", '')
            this.displayChatBtn = true
          }

          const alertContentStorage = localStorage.getItem('server-alert-content')
          if (alertContentStorage !== this.alertText) {
            this.isShowServerAlert = true;
          }
          else if (localStorage.getItem('server-alert')) {
            const showAlertStorage = parseInt(localStorage.getItem('server-alert'))
            this.isShowServerAlert = showAlertStorage < 3
          }
          else {
            this.isShowServerAlert = true;
          }
        }

        else {
          this.isShowServerAlert = false;
        }
        if (this.alertText.includes("https://enativ.com/harav-rozen.mp3")) {
          const usertypeid = this.authenticationService.userData.usertypeid
          const userType = this.utilsService.getUserType(usertypeid)
          if (userType.IS_USER_GUR) {
            this.isShowServerAlert = false
          }
        }
      },
      error => {
        console.log('error showAlertFromServer', error)
        return false;
      }
    )
  }
  showBtn() {
    return this.innerWidth <= 360
  }
  getAdvImage() {
    if (this.innerWidth <= 320) {
      return "../../../assets/images/mobile/320/home/adv-bg-mobile.jpg"
    }
    else if (this.innerWidth < 768) {
      return "../../../assets/images/mobile/360/home/adv-bg-mobile.png"
    }
  }

  getSiteIcon(i) {
    // return "../../../assets/images/home/new/sites-icon" + i + ".png"
    return "../../../assets/images/sites-icon" + i + ".png"
  }

  getSiteIconNew(i) {
    return "../../../assets/images/home/new/sites-icon-color" + i + ".png"
  }
  getSearchBanner(i) {
    if (innerWidth <= 1366) {
      return "../../../assets/images/mobile/1366/home/search-banner-" + i + ".png"
    }
    else {
      return "../../../assets/images/search-banner-" + i + "-new.jpg"
    }
  }
  showNewStyleAlert() {
    const showAlertStorage = localStorage.getItem('newStyle-alert')
    this.isShowNewAlert = showAlertStorage !== "false";
    if (this.isShowNewAlert) {
      localStorage.setItem('newStyle-alert', "false");
      Swal.fire({ text: 'לקוחות יקרים,\n \n   האזור האישי השתדרג עבורכם!\n\n   כדי להעניק לכם חווית שימוש נעימה יותר:\n    הפעולות נעילת משתמש, והחלפת משתמש-\n  נמצאות בתפריט הנפתח בצד שמאל    ', icon: 'success' })
    }

  }
  ngOnInit(): void {
    this.nav.show();

    this.subscription = this.utilsService.getImage().subscribe(image => {
      this.bgURL = image;
    });
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 1366) {
      this.displayInSmallScreen = true;
    }
    else {
      this.displayInSmallScreen = false;
    }
    this.authenticationService.authenticated$.subscribe(value => {
      this.isUserLoggedIn = value;
      if (this.isUserLoggedIn) {

        this.showAlerts();
      }

    });

    this.getConfirmation()
    this.getInfo();
    AOS.init({
      offset: 200,
      duration: 600,
      easing: 'ease-in-sine',
      delay: 100,
      mirror: true
    });
    this.initForms()
    this.onResizeScreen();
    this.responsiveService.checkWidth();
    // if (this.isUserLoggedIn) {
    //   this.apiService.initCategories()
    //   this.getPendingDocs();
    // }
    this.route.paramMap.subscribe(params => {
      if (Object.keys(params["params"]).length !== 0) {
        if (params.get('type') == "exit") {
          this.authenticationService.deauthenticate(true);
        }
      }
    })
    this.getOmerDays();
    this.runOncePerHour()
    // this.showAlerts();
  }

  ngAfterViewInit() {
    //כאשר משתמש התע"א רוצה להיכנס לרשימת אתרים מגיע לכאן בשביל סיסמת ניהול 
    this.route.queryParams.subscribe(params => {
      if (params && params.mysitepass) {
        setTimeout(() => {
          this.openPassModal(this.passModal)
        }, 1500);
      }
    });
  }

  getConfirmation() {
    this.route
      .queryParams
      .subscribe(params => {
        if (params["confirmationcode"]) {
          this.router.navigate(
            ['.'],
            { relativeTo: this.route }
          );
          this.salesService.verifyUser(params["confirmationcode"]).subscribe(
            data => {
              if (data["res"]) {
                console.log('verifyUser success')
                Swal.fire({
                  text: "האימות עבר בהצלחה",
                  icon: "info"
                })
              }
              else {
                Swal.fire({
                  text: "האימות נכשל",
                  icon: "error"
                })
              }
            },
            error => {
              console.log('error verifyUser', error)
              Swal.fire({
                text: "אימות נכשל",
                icon: "error"
              })
            }

          )
        }
      }
      )
  }

  showAlerts() {
    this.route
      .queryParams
      .subscribe(params => {
        let text;
        let type = 'info';
        let title = "";
        let res;
        if (params["success"] || params["gray"] || params["upgrade"]) {
          res = params["success"];
          if (res == "0") {
            text = "קרתה תקלה, נסה שנית מאוחר יותר";
            type = 'warning';
            title = 'שים לב';
          }
          else {
            text = this.getText(res);
          }
          if (params["gray"] == "1") {
            text = "לא ניתן לשנות מסלול ברשימה אפורה";
          }
          if (params["upgrade"] == "1") {
            text = "מצאנו שהנך עושה שימוש בגירסה לא עדכנית של תוכנת נתיב שעלולה להיות לא בטוחה, נא פנה למוקד השירות על מנת לשדרג את גירסת התוכנה שברשותך";
            type = 'warning';
            title = 'שים לב'
          }
          Swal.fire({
            title: title,
            text: text,
            icon: type == "warning" ? "warning" : "info"
          })
          this.router.navigate(['/main'])
        }
        else {
          if (params["1"] && params["2"] && params["3"] && params["4"]) {
            this.apiService.sendUserParams(params).subscribe(
              data => {
                console.log('sendUserParams success')
              },
              error => console.log('error sendUserParams', error)
            )
          }
          else if (params["inactive"]) {
            this.apiService.inactiveUser(params).subscribe(
              data => {
                console.log('inactiveUser success')
              },
              error => console.log('error inactiveUser', error)
            )
          }
          else if (params["new019"]) {
            if (this.authenticationService.userData) {
              const userData = this.authenticationService.userData;
              this.openContact019(userData);
            }
          }
          else if (params["path"]
            || (params["d"] && params["url"] && params["sid"] && params["notrec"])
            || params["mcbiuid"]
            || params["plmiuid"]
            || (params["fsdfewhrewirhxcbapprvdmvuddspbz"] && params["ud"] && params["url"] && ('cmnt' in params))
            || (params["675hggjgjg24"] && params["typvbboodfg"] && params["ieuryeiuryeiuryweiru"] && params["sid"])
            || (params["rtyrtfdjgk6546858654yhghb"] && params["akytuerhmxsdk"] && params["878798hgbvukibkoyoioy"] && params["sid"])
            || (params["url"] && params["sid"] && params["approve"] && params["uid"])
            || (params["ufgthpo"] && params["11joLgn161"])) {
            this.apiService.knTemplate(params).subscribe(
              data => {
                console.log('knTemplate success');
                res = data[0];
                text = this.getText(res);
                Swal.fire({
                  title: title,
                  text: text,
                  icon: type == "warning" ? "warning" : "info"
                })
                if (params["d"] && params["url"] && params["sid"] && params["notrec"]) {
                  this.router.navigate(['/my-sites'])
                }
              },
              error => console.log('error knTemplate', error)
            )
          }
          if (params["chat"] == "1") {
            this.chatService.showChat(true)
          }
        }
      });
  }

  runOncePerHour() {
    const hourInMilliseconds = 1000 * 60 * 60;
    const that = this
    setInterval(function () {
      const hour = new Date().getHours();
      const minutes = new Date().getMinutes();
      const tzaitArr: any = that.zmanimTzais.split(":")
      const tzaisHour = tzaitArr[0]
      const tzaisMin = tzaitArr[1]
      if (((hour > tzaisHour) || (hour == tzaisHour && minutes >= tzaisMin)) && !that.updateOmer) {
        console.log("runOncePerHour: get Omer Api: ", new Date())
        that.updateOmer = true
        that.getOmerApi(true)
      }
    }, hourInMilliseconds);
  }

  getOmerDays() {
    const lat = 32.08
    const long = 34.82
    let date: any = new Date().toLocaleDateString()
    date = date.replaceAll(".", "/")
    this.apiService.getKosherZmanim(date, lat, long, 0).subscribe(
      data => {
        console.log('success getKosherZmanim', data)
        const dates = data["DailyZmanim"];
        var datesObjs = dates.map(x => ({
          name: x[0],
          date: x[1],
          index: x[2]
        }));
        this.zmanimTzais = datesObjs.find(o => o.name === 'צה״כ').date;
        const hour = new Date().getHours();
        const minutes = new Date().getMinutes();
        const tzaitArr: any = this.zmanimTzais.split(":")
        const tzaisHour = tzaitArr[0]
        const tzaisMin = tzaitArr[1]
        if ((hour > tzaisHour) || (hour == tzaisHour && minutes >= tzaisMin)) {
          this.getOmerApi(true)
        }
        else {
          this.getOmerApi(false)
        }
      },
      error => {
        console.log('error getKosherZmanim', error)
      }
    )

  }

  getOmerApi(addDay: any) {
    this.apiService.getOmer({ addDay }).subscribe(data => {
      var response = data != null ? data["d"] : "";
      if (response != '' && response != null) {
        response = response.split('שהם');
        this.omerDate.days = response[0];
        if (response.length > 1)
          this.omerDate.weeks = 'שהם' + response[1];
      }
    },
      error => console.log('error', error)
    )
  }

  openContact019(userData) {
    //const contactData = `Content=לקוח אקספון מעוניין בהקמת ספק 019&Dept=5&Login=${userData.login}&tel=${userData.homephone}&mail=${userData.email.split(',')[0]}&fname=${userData.firstname}&lname=${userData.lastname}`
    const content = 'לקוח אקספון מעוניין בהקמת ספק 019';
    this.apiService.contact(content, 5, userData.login, userData.homephone, userData.email.split(',')[0], userData.firstname, userData.lastname, 2).subscribe(
      // this.apiService.contact(contactData).subscribe(
      data => {
        console.log('success contact', data)
        Swal.fire("", "פנייתך נפתחה בהצלחה ונציג ייצור איתך קשר בהקדם", 'success')
      },
      error => {
        console.log('error contact', error)
        Swal.fire("", "אירעה שגיאה בשליחת הפניה", 'error')
      }
    )
  }

  getText(res) {
    switch (res) {
      case "1":
        return "בקשתך נקלטה ותענה בהקדם";
        break;
      case "11":
        return "השידרוג בוצע בהצלחה!";
        break;
      case "2":
        return "הטופס נשלח לכתובת המייל הרשומה במערכת";
        break;
      case "3":
        return "החסימה הוסרה מהקישור המבוקש";
        break;
      case "4":
        return "האתר נוסף לחשבונכם";
        break;
      case "5":
        return "האתר קיים בחשבונכם";
        break;
      case "6":
        return "על פי בקשתך התווסף לרשימת האתרים שלך פרופיל תיירות ונופש";
        break;
      case "7":
        return "הפרופיל כבר קיים ברשימת האתרים שלך";
        break;
      default:
        return "בקשתך התקבלה";
        break;
    }
  }

  getInfo() {
    this.route
      .queryParams
      .subscribe(params => {
        if (params["infotype"] && params["iarg1"] && params["iarg2"]) {
          window.location.href = 'http://api.enativ.com/GetInfo.aspx?InfoType=3&IArg1=4&IArg2=7';
        }
      })
  }
  refreshSites() {
    this.sitesData = this.sites
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }

  getSites(id, name) {
    this.showSites = true;
    this.loading = true;
    this.apiService.getSites(id, name).subscribe(
      data => {
        console.log('getSites ', data["d"])
        this.sites = data["d"];
        this.collectionSize = this.sites.length;
        this.page = 1;
        this.refreshSites();
        this.sitesLength = this.collectionSize >= 20 ? 20 : this.collectionSize
        this.loading = false;
      },
      error => console.log('error getSites', error)
    )
  }
  searchSites() {
    if (this.searchSitesInput.trim().length >= 2) {
      this.showSites = true;
      this.loading = true;
      this.apiService.searchSites(this.searchSitesInput).subscribe(
        data => {
          console.log('searchSites ', data["d"])
          this.sites = data["d"];
          this.collectionSize = this.sites.length;
          this.page = 1;
          this.refreshSites();
          this.sitesLength = this.collectionSize >= 20 ? 20 : this.collectionSize
          this.loading = false;

        },
        error => console.log('error getSites', error)
      )
    }
  }
  showCategories() {
    this.showSites = false;
  }
  onResizeScreen() {
    this.responsiveService.getMobileStatus().subscribe(isMobile => {
      this.isMobile = isMobile;
    });
  }

  onSlide($event: any) {

  }
  close(modal) {
    this.submitted = false;
    modal.dismiss()
  }

  initForms(create = false) {
    if (create) {
      this.loginForm = this.formBuilder.group({
        username: '',
        password: ''
      });
      this.searchSiteForm = this.formBuilder.group({
        search: ''
      });
    }
    else {
      this.loginForm = new FormGroup({
        username: new FormControl('', Validators.required),
        password: new FormControl('', Validators.required)
      });
      this.searchSiteForm = new FormGroup({
        search: new FormControl('', Validators.required)
      });
    }
  }
  loadWeather(s, id) {
    var js, fjs = document.getElementsByTagName(s)[0];
    js = document.createElement(s);
    js.id = id;
    js.src = 'https://weatherwidget.io/js/widget.min.js';
    fjs.parentNode.insertBefore(js, fjs);
  }
  getStations() {
    this.apiService.getStations().subscribe(
      data => {
        console.log('getStations ', data)
        this.stations = data["res"].map(st => st.shortName).sort();
      },
      error => console.log('error getWeather', error)
    )

  }
  getWeather() {
    this.apiService.getWeather("178").subscribe(
      data => {
        console.log('getWeather ', data)
      },
      error => console.log('error getWeather', error)
    )

  }
  get f() {
    return this.loginForm.controls;
  }

  onSubmit(loginData: any) {
    this.submitted = true;
    console.warn('login has been submitted', loginData);
    if (this.loginForm.invalid) {
      return;
    }
    this.apiService.login(loginData).subscribe(
      data => {
        console.log('success login', data)
        data["data"]["UserData"].username = loginData.username
        this.authenticationService.authenticate(data["data"]["UserData"]);
        // this.apiService.initCategories()
        // this.getPendingDocs();
      },
      error => {
        this.authenticated = false;
        if (error.error && error.error.text && error.error.text === "User does not exists!") {
          this.authenticatedError = 'שם משתמש שגוי';
        }
        else {
          this.authenticatedError = 'שם משתמש או סיסמא שגויים';
        }
        console.log('error login', error)
      }
    )
  }

  onFocusInput(event) {
    this.authenticated = true;
  }

  navigateToVideo() {
    window.open('https://video.enativ.com/', '_blank');
  }

  nativLogin() {
    window.open('http://bo.enativ.com/login', '_blank');
  }

  clickNativ(event, num) {
    event.stopPropagation();
    switch (num) {
      case 1:
        this.showNativContent1 = true
        this.showNativContent2 = false
        this.showNativContent3 = false
        this.showNativContent4 = false
        break;
      case 2:
        this.showNativContent2 = true
        this.showNativContent1 = false
        this.showNativContent3 = false
        this.showNativContent4 = false
        break;
      case 3:
        this.showNativContent3 = true
        this.showNativContent1 = false
        this.showNativContent2 = false
        this.showNativContent4 = false
        break;
      case 4:
        this.showNativContent4 = true
        this.showNativContent1 = false
        this.showNativContent2 = false
        this.showNativContent3 = false
        break;
      default:
        break;
    }
  }
  mouseLeaveNativ() {
    this.showNativContent1 = false
    this.showNativContent2 = false
    this.showNativContent3 = false
    this.showNativContent4 = false
  }
  getPendingDocs() {
    this.apiService.getPendingDocs().subscribe(
      data => {
        console.log('success getPendingDocs', data)
        this.pendingDocs = data["d"];
        this.pendingMsgsCount = this.pendingDocs.length;
        this.apiService.updatePendingDocs(this.pendingDocs);
      },
      error => console.log('error getPendingDocs', error)
    )
  }
  closeDoc(docId) {
    Swal.fire({
      text: 'האם את/ה בטוח/ה שהינך רוצה לסגור את הבקשה?',
      showCancelButton: true,
      confirmButtonText: 'סגור בקשה',
      cancelButtonText: `ביטול`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.apiService.closeDoc(docId).subscribe(
          data => {
            console.log('success closeDoc', data)
            this.getPendingDocs();
            Swal.fire("", 'הבקשה נסגרה בהצלחה', 'success')
          },
          error => {
            console.log('error closeDoc', error)
            Swal.fire("", 'סגירת הבקשה נכשלה', 'error')
          }
        )
      }
    })

  }
  navigateToDoc(type, docId, url) {
    switch (type) {
      case 2:
        this.router.navigate(['/docs/unusual-site-approval-doc'], { queryParams: { docid: docId, url: url } })
        break;
      case 5:
      case 7:
        this.router.navigate(['/docs/close-subscription-doc'], { queryParams: { docid: docId } })
        break;
      case 3:
        this.router.navigate(['/docs/temp-removal-doc'], { queryParams: { docid: docId } })
        break;
      case 4:
        this.router.navigate(['/docs/temp-open-doc'], { queryParams: { docid: docId } })
        break;
      case 6:
        this.router.navigate(['/docs/to-gray-list-doc'], { queryParams: { docid: docId } })
        break;
      case 8:
        window.open('https://www.enativ.com/download/%D7%94%D7%95%D7%A8%D7%90%D7%AA_%D7%A7%D7%91%D7%A2_%D7%A0%D7%AA%D7%99%D7%91.pdf', '_blank');
        break;
      default:
        break;
    }
  }

  refreshNativ() {
    // window.open('https://bo.enativ.com/refresh', '_blank');
    this.refreshing = true;
    try {
      this.apiService.refreshNativ().subscribe(
        data => {
          this.refreshing = false;
          if (data.indexOf("נכשל") != -1) {
            console.log('error refreshNativ', data)
            Swal.fire("", "אירעה שגיאה ברענון ההגדרות", 'error')
          }
          else {
            console.log('refreshNativ success');
            Swal.fire("", data, 'success')
          }
          // Swal.fire({
          //   title: "",
          //   text: data,
          //   icon: "success",
          //   // imageUrl: "../../../assets/images/refresh-btn.png"
          // })
        },
        error => {
          this.refreshing = false;
          console.log('error refreshNativ', error)
          Swal.fire("", "אירעה שגיאה ברענון ההגדרות", 'error')
          // Swal.fire({
          //   title: "",
          //   text: "שגיאה",
          //   icon: "success",
          //   imageUrl: "../../../assets/images/refresh-btn.png"
          // })
        }
      )
    }
    catch (error) {
      this.refreshing = false;
      console.log('error refreshNativ', error)
      Swal.fire("", "אירעה שגיאה ברענון ההגדרות", 'error')
    }
  }
  updateVersion() {
    if (this.upgradeAlertContact) {
      this.router.navigate(['/contact', 'upgrade-version'])
    }
    else {
      Swal.fire({
        text: "בלחיצה על אישור, תפתח כרטיסיה חדשה וקובץ העידכון ירד למחשב ",
        icon: 'warning',
        confirmButtonText: 'אישור'
      }).then((result) => {
        if (result.isConfirmed) {
          const newurl = new URL("http://enativ.com/2");
          newurl.protocol = window.location.protocol;
          window.open(
            newurl.href,
            '_blank'
          );
        }
      })
    }
  }
  closeAlert() {
    this.isShowAlert = false;
    localStorage.setItem('upgrade-alert', "false");
  }
  closeServerAlert() {
    this.isShowServerAlert = false;
    const alertContentStorage = localStorage.getItem('server-alert-content')
    if (alertContentStorage !== this.alertText) {
      localStorage.setItem('server-alert', "1");
      localStorage.setItem('server-alert-content', this.alertText);
    }
    else if (localStorage.getItem('server-alert')) {
      let showAlertStorage = parseInt(localStorage.getItem('server-alert'))
      localStorage.setItem('server-alert', (showAlertStorage += 1).toString());
    }
    else {
      localStorage.setItem('server-alert', "1");
      localStorage.setItem('server-alert-content', this.alertText);
    }
  }
  openModal(targetModal) {
    const modal = this.modalService.open(targetModal, {
      centered: true,
      backdrop: true,
      size: 'md',
      windowClass: 'alert-modal'
    });
  }

  showChat() {
    this.chatService.showChat(true)
  }

  navigateToMySites() {
    if (this.authenticationService.userData.id == environment.iaiServerUserId || this.authenticationService.userData.id == environment.eltaServer) {
      this.openPassModal(this.passModal)
    }
    else {
      this.router.navigate(['/my-sites'])
    }
  }

  ///manager pass modal

  get fp() {
    return this.syncPassForm.controls;
  }

  openPassModal(targetModal) {
    const modal = this.modalService.open(targetModal, {
      centered: true,
      backdrop: true,
      size: 'sm'
    });
    modal.result.then(() => { this.managerSubmitted = false; this.syncPassForm.reset(); }, () => { this.managerSubmitted = false; this.syncPassForm.reset(); })
  }

  closeManager(modal) {
    this.managerSubmitted = false;
    modal.dismiss()
  }

  onFocus() {
    this.syncPassForm.get('password').setErrors(null);
    this.invalidPass = false;
  }

  onSubmitPass(formData: any, modal) {
    this.invalidPass = false;
    this.managerSubmitted = true;
    if (this.syncPassForm.invalid) {
      this.enableSubmit = true;
      return;
    }
    if (formData.password == this.authenticationService.userData.ManagerPassword) {
      modal.dismiss();
      this.authGuardService.setButtonClickUserProfile(true);
      this.router.navigate(["/my-sites-expanded"])
    }
    else {
      this.invalidPass = true;
      this.syncPassForm.get('password').setErrors('invalid password');
    }
  }

  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  navigateToAdvSite(site, location) {
    if (site == "asraf") {
      const to = "0587804574a@gmail.com"
      const subject = "הי ישראל הגעתי מנתיב גם אני רוצה לקבל בדיקה חינם ולחסוך אלפי שקלים בחודש"
      const body = encodeURI("נא למלא פרטים\nשם:\nטלפון:")
      window.open(`https://mail.google.com/mail/u/0/?view=cm&fs=1&to=${to}&su=${subject}&body=${body}&tf=1`, '_blank');
    }
    else if (site == "news-hot") {
      window.open("https://achadashhot.com/", '_blank');
    }
    else if (site == "news-hot-mail") {
      site = "news-hot"
      const to = "Hachadashhot@gmail.com"
      const subject = "גם אני רוצה להתעדכן בחדשות מבית חדשHOT"
      window.open(`https://mail.google.com/mail/u/0/?view=cm&fs=1&to=${to}&su=${subject}&body=&tf=1`, '_blank');
    }
    else if (site == "elisheva-mail") {
      site = 'bool-ey'
      const to = "elishevadout@gmail.com"
      const subject = "הגעתי מאתר נתיב אשמח לקבל קטלוג למייל"
      window.open(`https://mail.google.com/mail/u/0/?view=cm&fs=1&to=${to}&su=${subject}&body=&tf=1`, '_blank');
    }
    else if (site == "elisheva-site") {
      site = 'bool-ey'
      window.open("https://bool-ey.studio/gallery/", '_blank');
    }
    else if (site == "infinity-ground") {
      window.open("https://www.lighter-life.co.il/?utm_source=nativ", '_blank');
    }
    else if (site == "besadno") {
      window.open("https://bsdnosk.co.il/podcast/?utm_source=netiv&utm_medium=baner", '_blank');
    }
    else if (site == "shtiglitz-group") {
      window.open("https://lp.shtiglitz.co.il/smart-j/?utm_source=netiv&utm_medium=baner", '_blank');
    }
    else if (site == "tzameret") {
      window.open("https://tsameret.co.il/adhd/", '_blank');
    }
    else if (site == "artex-home") {
      window.open("https://rtex.co.il/", '_blank');
    }
    else if (site == "nativ-cloud") {
      window.open("https://enativ.com", '_blank');
    }
    else if (site == "ezer-mitzion") {
      window.open("https://www.ami.org.il/donation-campaign/?utm_source=whatsappharedi&utm_medium=nativ&utm_campaign=7-9&utm_content=5", "_blank")
    }
    else if (site == "malam") {
      window.open("https://www.malaam.org.il/lp?utm_source=nativ&utm_medium=banner&utm_campaign=%D7%A0%D7%AA%D7%99%D7%91&utm_term=%D7%90%D7%97%D7%A8&utm_content=%D7%90%D7%97%D7%A8&utm_client=%D7%9E%D7%9C%D7%9E", "_blank")
    }
    else {
      site = "nativ"
      window.open(`https://enativ.com`, '_blank');
    }
    this.apiService.saveAdvActions({ action: 'click', adv: site, page: 'main', bunnerLocation: location, sourceSite: 'nativ-site' }).subscribe(
      data => {
        console.log(`success saveAdvActions ${data}`)
      },
      error => {
        console.log(`error saveAdvActions ${error}`)
      }
    )
  }

  openAdv(advNum) {
    const time = 1000 * 5
    const that = this
    setTimeout(() => {
      that.advsData[advNum].show = true;
    }, time);
  }

  closeAdv(advNum) {
    const time = 1000 * 60 * 5
    this.advsData[advNum].show = false;
    const that = this
    setTimeout(() => {
      that.advsData[advNum].show = true;
    }, time);
  }

  checkShowAdvs() {
    if (this.authenticationService.userData?.usertypeid) {
      const userData = this.authenticationService.userData
      const userType = this.utilsService.getUserType(userData.usertypeid)
      if (userType.IS_USER_MAIL_ONLY || (userData.usertypeid.substring(userData.usertypeid.length - 2, userData.usertypeid.length - 1) == "4")) {
        this.showAdvs = false
      }
      // else {
      //   this.showAdvs = true
      // }
    }
  }
}

