<div class="text-center time-div">
    {{ 'bottom-nav.header' | translate }}
    <br />
    <div class="bold">
        {{ 'bottom-nav.sub-header' | translate }}
    </div>
</div>
<div *ngIf="displayAddress" class="text-center address-div">
    <div class="bold">
        {{ 'bottom-nav.address' | translate }}
    </div>
</div>
<div id="bottom-nav" class="text-center">
    <h1>5365*</h1>
    <h4>03-6199199</h4>
    <h6>{{ 'bottom-nav.content' | translate }}</h6>
    <nav class="navbar navbar-expand-lg navbar-light">
        <div class="container d-flex justify-content-center">
            <ul class="nav">
                <li class="nav-item main">
                    <a class="nav-link" routerLinkActive="active" routerLink="/main" href="#">{{ 'app.home' | translate
                        }}</a>
                </li>
                <li class="nav-item main">
                    <a class="nav-link" href="#" routerLink="/kosher">{{ 'app.kosher' | translate }}</a>
                </li>
                <li class="nav-item main">
                    <a class="nav-link" href="#" routerLink="/contact">{{ 'app.contact' | translate }}</a>
                </li>
                <li class="nav-item main">
                    <a class="nav-link" href="#" routerLink="/support">{{ 'app.support' | translate }}</a>
                </li>
                <li class="nav-item main">
                    <a class="nav-link" href="#" routerLink="/about">{{ 'app.about' | translate }}</a>
                </li>
            </ul>
        </div>
    </nav>
    <div class="">
        <router-outlet></router-outlet>
    </div>
</div>