<button #scrollBtn (click)="topFunction()" class="btn-nativ scroll-btn" title="Go to top"><i
        class="fas fa-chevron-up"></i></button>
<div class="kosher-header-div first-div text-center row align-items-center">
    <div class="text-center col-12 mx-auto align-self-center">
        <h1 class="kosher-header header">{{ 'app.kosher' | translate }}</h1>
    </div>
</div>
<div class="kosher-buttons">
    <div class="row justify-content-center align-items-center text-center">
        <div class="col-md-4 text-center btn-div first">
            <img *ngIf='this.translateService.currentLang=="he"' class="pointer" pageScroll href="#kosher-div"
                src="../../../assets/images/kosher/kosher-img-bg.png" />
            <img *ngIf='this.translateService.currentLang=="en"' class="pointer" pageScroll href="#kosher-div"
                src="../../../assets/images/kosher/kosher-img-bg.png" />
            <div class="img-text pointer" pageScroll href="#kosher-div">{{ 'kosher.rabbis-Recommendations' | translate }}</div>
        </div>
        <div class="col-md-4 text-center btn-div">
            <img *ngIf='this.translateService.currentLang=="he"' class="pointer" pageScroll href="#quotes-div"
                src="../../../assets/images/kosher/customers-img-bg.png" />
            <img *ngIf='this.translateService.currentLang=="en"' class="pointer" pageScroll href="#quotes-div"
                src="../../../assets/images/kosher/customers-img-bg.png" />
            <div class="img-text pointer" pageScroll href="#quotes-div">{{ 'kosher.quotes' | translate }}</div>
        </div>
        <div class="col-md-4 text-center btn-div">
            <img *ngIf='this.translateService.currentLang=="he"' class="pointer" pageScroll href="#nl-div"
                src="../../../assets/images/kosher/nl-img-bg.png" />
            <img *ngIf='this.translateService.currentLang=="en"' class="pointer" pageScroll href="#nl-div"
                src="../../../assets/images/kosher/nl-img-bg.png" />
            <div class="img-text pointer" pageScroll href="#nl-div">{{ 'kosher.nl' | translate }}</div>
        </div>
    </div>
</div>
<div id="kosher-div" class="kosher-div text-center">
    <div class="row justify-content-center align-items-center kosher-row gray">
        <div class="col-md-12 img-content">
            <img src="../../../assets/images/kosher/kosher-9-1.png" data-aos="fade-up" data-aos-once="true"
                data-aos-duration="400" />
            <img src="../../../assets/images/kosher/kosher-9-2.png" data-aos="fade-up" data-aos-once="true"
                data-aos-duration="400" />
        </div>
    </div>
    <div class="row justify-content-center align-items-center kosher-row ">
        <div class="col-md-12 img-content">
            <img src="../../../assets/images/kosher/kosher-10-2.png" data-aos="fade-up" data-aos-once="true"
                data-aos-duration="400" />
            <img src="../../../assets/images/kosher/kosher-10-1.png" data-aos="fade-up" data-aos-once="true"
                data-aos-duration="400" />
        </div>
    </div>
    <div class="row justify-content-center align-items-center kosher-row gray">
        <div class="col-md-12 img-content">
            <img src="../../../assets/images/kosher/new/kosher-1-1.png" data-aos="fade-up" data-aos-once="true"
                data-aos-duration="400" />
            <img src="../../../assets/images/kosher/new/kosher-1-2.png" data-aos="fade-up" data-aos-once="true"
                data-aos-duration="400" />
        </div>
    </div>
    <div class="row justify-content-center align-items-center kosher-row">
        <div class="col-md-12 img-content">
            <img src="../../../assets/images/kosher/new/kosher-2-1.png" data-aos="fade-up" data-aos-once="true"
                data-aos-duration="400" />
            <img src="../../../assets/images/kosher/new/kosher-2-2.png" data-aos="fade-up" data-aos-once="true"
                data-aos-duration="400" />
        </div>
    </div>
    <div class="row justify-content-center align-items-center kosher-row blue">
        <div class="col-md-12 img-content">
            <img src="../../../assets/images/kosher/new/kosher-3-1.png" data-aos="fade-up" data-aos-once="true"
                data-aos-duration="400" />
            <img src="../../../assets/images/kosher/new/kosher-3-2.png" data-aos="fade-up" data-aos-once="true"
                data-aos-duration="400" />
        </div>
    </div>
    <div class="row justify-content-center align-items-center kosher-row">
        <div class="col-md-12 img-content">
            <img src="../../../assets/images/kosher/new/kosher-4-1.png" data-aos="fade-up" data-aos-once="true"
                data-aos-duration="400" />
            <img src="../../../assets/images/kosher/new/kosher-4-2.png" data-aos="fade-up" data-aos-once="true"
                data-aos-duration="400" />
        </div>
    </div>
    <div class="row justify-content-center align-items-center kosher-row blue">
        <div class="col-md-12 img-content">
            <img src="../../../assets/images/kosher/new/kosher-5-1.png" data-aos="fade-up" data-aos-once="true"
                data-aos-duration="400" />
            <img src="../../../assets/images/kosher/new/kosher-5-2.png" data-aos="fade-up" data-aos-once="true"
                data-aos-duration="400" />
        </div>
    </div>
    <div class="row justify-content-center align-items-center kosher-row">
        <div class="col-md-12 img-content">
            <img src="../../../assets/images/kosher/new/kosher-6-1.png" data-aos="fade-up" data-aos-once="true"
                data-aos-duration="400" />
            <img src="../../../assets/images/kosher/new/kosher-6-2.png" data-aos="fade-up" data-aos-once="true"
                data-aos-duration="400" />
        </div>
    </div>
    <div class="row justify-content-center align-items-center kosher-row gray">
        <div class="col-md-12 img-content">
            <img src="../../../assets/images/kosher/new/kosher-7-1.png" data-aos="fade-up" data-aos-once="true"
                data-aos-duration="400" />
            <img src="../../../assets/images/kosher/new/kosher-7-2.png" data-aos="fade-up" data-aos-once="true"
                data-aos-duration="400" />
        </div>
    </div>
    <div class="row justify-content-center align-items-center kosher-row">
        <div class="col-md-12 img-content">
            <img src="../../../assets/images/kosher/new/kosher-8-1.png" data-aos="fade-up" data-aos-once="true"
                data-aos-duration="400" />
            <img src="../../../assets/images/kosher/new/kosher-8-2.png" data-aos="fade-up" data-aos-once="true"
                data-aos-duration="400" />
            <div id="quotes-div"></div>
        </div>

    </div>
</div>
<div class="quotes-div">
    <h1 class="div-header text-center">{{ 'kosher.quotes' | translate }}</h1>
    <div class="row align-items-center">
        <div
            class="quote-4 text-center col-md-4 mx-auto align-self-center justify-content-center d-flex align-items-center">
            <img src="../../../assets/images/kosher/quote-4.png" />
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 text-left">
            <img src="../../../assets/images/kosher/quote-2.png" />
        </div>
        <div class="col-md-6 text-right">
            <img src="../../../assets/images/kosher/quote-3.png" />
        </div>
    </div>
    <div class="row align-items-center">
        <div class="text-center col-md-4 mx-auto align-self-center justify-content-center d-flex align-items-center">
            <img src="../../../assets/images/kosher/quote-1.png" />
        </div>
    </div>
</div>
<div class="audio-div">
    <h1 class="div-header text-center">{{ 'kosher.audio' | translate }}</h1>
    <div class="row mx-auto align-self-center justify-content-center d-flex align-items-center">
        <div class="row">
            <div #playAudio_1 class="col-md-6 play-audio pointer text-center audio1" (click)="playAudio1()"></div>
            <div #playAudio_2 class="col-md-6 play-audio pointer text-center audio2" (click)="playAudio2()"></div>
        </div>
        <div class="row">
            <div #playAudio_3 class="col-md-4 play-audio pointer text-center audio3" (click)="playAudio3()"></div>
            <div #playAudio_4 class="col-md-4 play-audio pointer text-center audio4" (click)="playAudio4()"></div>
            <div #playAudio_5 class="col-md-4 play-audio pointer text-center audio5" (click)="playAudio5()"></div>
        </div>
    </div>
    <div id="nl-div"></div>
</div>
<div class="nl-div">
    <h1 class="div-header text-center">{{ 'kosher.nl' | translate }}</h1>
    <div class="row justify-content-center align-items-center nl-row blue">
        <div class="row nl-content">
            <div class="col-md-6 nl-1">
                <div class="content">
                    <div class="nl-row-header text-center">התפרסם בעיתון הדרך</div>
                    <img *ngIf="!moreData1" src="../../../assets/images/kosher/new/nl-1-1.png" />
                    <img *ngIf="moreData1" src="../../../assets/images/kosher/new/nl-1.png" />
                </div>
                <div class="show-more-div text-center">
                    <a #showMoreData1 class="show-more-data1 pointer" (click)="readMore1()">קרא/י עוד</a>
                </div>
            </div>
            <div class="col-md-6">
                <div class="content">
                    <img src="../../../assets/images/kosher/new/nl-2.png" />
                </div>
            </div>
        </div>
    </div>
    <div class="nl-row">
        <div class="nl-content">
            <div class="row justify-content-center align-items-center">
                <div class="col-md-4">
                    <div class="nl-row-header text-center">התפרסם בעיתון יתד נאמן</div>
                </div>
            </div>
            <div class="row justify-content-center align-items-center">
                <div class="row">
                    <div class="col-md-4">
                        <div class="content">
                            <img *ngIf="!moreData2" src="../../../assets/images/kosher/new/nl-3-1.png" />
                            <img *ngIf="moreData2" src="../../../assets/images/kosher/new/nl-3.png" />
                        </div>
                        <div class="show-more-div text-center">
                            <a #showMoreData2 class="show-more-data2 pointer" (click)="readMore2()">קרא/י עוד</a>
                        </div>
                    </div>
                    <div class="col-md-8 nl-2">
                        <div class="content">
                            <img src="../../../assets/images/kosher/new/nl-4.png" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="nl-row gray">
        <div class="nl-content">
            <div class="row justify-content-center align-items-center">
                <div class="col-md-3">
                    <div class="nl-row-header text-center">התפרסם בעיתון קטיפה</div>
                </div>
            </div>
            <div class="row justify-content-center align-items-center">
                <div class="row">
                    <div class="col-md-12">
                        <div class="content">
                            <img *ngIf="!moreData3" src="../../../assets/images/kosher/new/nl-5-1.png" />
                            <img *ngIf="moreData3" src="../../../assets/images/kosher/new/nl-5.png" />
                        </div>
                        <div class="show-more-div text-center">
                            <a #showMoreData3 class="show-more-data3 pointer" (click)="readMore3()">קרא/י עוד</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-bottom-nav></app-bottom-nav>