<ngb-carousel interval="5000">
    <ng-template ngbSlide>
        <div class="picsum-img-wrapper img-1">
            <div class="row div-content justify-content-center align-items-center"
                [ngClass]="mobile ? 'text-center' : 'text-right'">
                <div class="caption" [ngClass]="mobile ? 'col-md-12' : 'col-md-6'">
                    <h3 class="home-bg-word">{{ 'home.carousel.caption1.word' | translate }}</h3>
                    <h3 class="home-bg-header">{{ 'home.carousel.caption1.header' | translate }}</h3>
                    <h6 class="home-bg-text">{{ 'home.carousel.caption1.text' | translate }}</h6>
                    <button [routerLink]="['/sales']" routerLinkActive="active" id="login-btn"
                        class="btn-nativ btn-large btn-sm blinking caption-btn-1">{{
                        'app.join' | translate }}
                        &#10095;&#10095;</button>
                    <div *ngIf="mobile">
                        <button (click)="openModal(modal)" class="btn-to-login btn-nativ btn-large btn-sm mobile">{{
                            'home.enter' | translate
                            }}</button>
                        <br />
                        <i class="fas fa-chevron-circle-down fa-3x" pageScroll href="#section-2-before"></i>
                    </div>
                </div>
                <div *ngIf="alertText && isShowServerAlert" class="col-md-3 alert">
                    <div class="alerts-div server-alert">
                        <div class="alert-text">
                            <div [innerHTML]="alertText">
                                <!-- {{alertText}} -->
                            </div>
                            <button *ngIf="displayChatBtn" class="btn-nativ" (click)="showChat()">אני רוצה לדבר איתכם
                                בצ'אט</button>
                            <div class="row justify-content-center">
                                <button type="button" class="close server" aria-label="Close"
                                    (click)="closeServerAlert()">
                                    <span aria-hidden="true">סגור חלון</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="!mobile" class="caption buttons">
                    <div class="row">
                        <button class="btn-to-login btn-nativ btn-large btn-sm btn-gray contact-btn"
                            [routerLink]="['/sales']" routerLinkActive="active"> {{ 'home.join' | translate
                            }}&nbsp;&nbsp;&nbsp;&nbsp;</button>
                    </div>
                    <div class="row">
                        <button class="btn-to-login btn-nativ btn-large btn-sm enter-btn" [routerLink]="['/enter']"
                            routerLinkActive="active">{{ 'home.enter' | translate }}
                        </button>
                    </div>
                    <div class="row">
                        <button class="btn-to-login btn-nativ btn-large btn-sm btn-gray apps-btn" pageScroll
                            href="#section-5-before">{{ 'home.apps.shortcut-btn' | translate
                            }}<i class="fa fa-arrow-circle-down" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template ngbSlide>
        <div class="picsum-img-wrapper img-2">
            <div class="row div-content justify-content-center align-items-center"
                [ngClass]="mobile ? 'text-center' : 'text-right'">
                <div class="caption" [ngClass]="mobile ? 'col-md-12' : 'col-md-6'">
                    <h3 class="home-bg-word">{{ 'home.carousel.caption2.word' | translate }}</h3>
                    <h3 class="home-bg-header">{{ 'home.carousel.caption2.header' | translate }}</h3>
                    <h6 class="home-bg-text">{{ 'home.carousel.caption2.text' | translate }}</h6>
                    <button [routerLink]="['/sales']" routerLinkActive="active" id="login-btn"
                        class="btn-nativ btn-large btn-sm blinking caption-btn-2">{{
                        'app.join' | translate }}
                        &#10095;&#10095;</button>
                    <div *ngIf="mobile">
                        <button (click)="openModal(modal)" class="btn-to-login btn-nativ btn-large btn-sm mobile">{{
                            'home.enter' | translate
                            }}</button>
                        <br />
                        <i class="fas fa-chevron-circle-down fa-3x" pageScroll href="#section-2-before"></i>
                    </div>
                </div>

                <div *ngIf="alertText && isShowServerAlert" class="col-md-3 alert">
                    <div class="alerts-div server-alert">
                        <div class="alert-text">
                            <div [innerHTML]="alertText">
                                <!-- {{alertText}} -->
                            </div>
                            <button *ngIf="displayChatBtn" class="btn-nativ" (click)="showChat()">אני רוצה לדבר איתכם
                                בצ'אט</button>
                            <div class="row justify-content-center">
                                <button type="button" class="close server" aria-label="Close"
                                    (click)="closeServerAlert()">
                                    <span aria-hidden="true">סגור חלון</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="!mobile" class="caption buttons">

                    <div class="row">
                        <button class="btn-to-login btn-nativ btn-large btn-sm btn-gray contact-btn"
                            [routerLink]="['/sales']" routerLinkActive="active"> {{ 'home.join' | translate
                            }}&nbsp;&nbsp;&nbsp;&nbsp;
                        </button>
                    </div>
                    <div class="row">
                        <button class="btn-to-login btn-nativ btn-large btn-sm enter-btn" [routerLink]="['/enter']"
                            routerLinkActive="active">{{ 'home.enter' | translate }}
                        </button>
                    </div>
                    <div class="row">
                        <button class="btn-to-login btn-nativ btn-large btn-sm btn-gray apps-btn" pageScroll
                            href="#section-5-before">{{ 'home.apps.shortcut-btn' | translate
                            }}<i class="fa fa-arrow-circle-down" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template ngbSlide>
        <div class="picsum-img-wrapper img-3">
            <div class="row div-content justify-content-center align-items-center"
                [ngClass]="mobile ? 'text-center' : 'text-right'">
                <div class="caption" [ngClass]="mobile ? 'col-md-12' : 'col-md-6'">
                    <h3 class="home-bg-word">{{ 'home.carousel.caption3.word' | translate }}</h3>
                    <h3 class="home-bg-header">{{ 'home.carousel.caption3.header' | translate }}</h3>
                    <h6 class="home-bg-text">{{ 'home.carousel.caption3.text' | translate }}</h6>
                    <button [routerLink]="['/sales']" routerLinkActive="active" id="login-btn"
                        class="btn-nativ btn-large btn-sm blinking caption-btn-3">{{
                        'app.join' | translate }}
                        &#10095;&#10095;</button>
                    <div *ngIf="mobile">
                        <button (click)="openModal(modal)" class="btn-to-login btn-nativ btn-large btn-sm mobile">{{
                            'home.enter' | translate
                            }}</button>
                        <br />
                        <i class="fas fa-chevron-circle-down fa-3x" pageScroll href="#section-2-before"></i>
                    </div>
                </div>
                <div *ngIf="alertText && isShowServerAlert" class="col-md-3 alert">
                    <div class="alerts-div server-alert">
                        <div class="alert-text">
                            <div [innerHTML]="alertText">
                                <!-- {{alertText}} -->
                            </div>
                            <button *ngIf="displayChatBtn" class="btn-nativ" (click)="showChat()">אני רוצה לדבר איתכם
                                בצ'אט</button>
                            <div class="row justify-content-center">
                                <button type="button" class="close server" aria-label="Close"
                                    (click)="closeServerAlert()">
                                    <span aria-hidden="true">סגור חלון</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="!mobile" class="caption buttons">
                    <div class="row">
                        <button class="btn-to-login btn-nativ btn-large btn-sm btn-gray contact-btn"
                            [routerLink]="['/sales']" routerLinkActive="active"> {{ 'home.join' | translate
                            }}&nbsp;&nbsp;&nbsp;&nbsp;
                        </button>
                    </div>
                    <div class="row">
                        <button class="btn-to-login btn-nativ btn-large btn-sm enter-btn" [routerLink]="['/enter']"
                            routerLinkActive="active">{{ 'home.enter' | translate }}
                        </button>
                    </div>
                    <div class="row">
                        <button class="btn-to-login btn-nativ btn-large btn-sm btn-gray apps-btn" pageScroll
                            href="#section-5-before">{{ 'home.apps.shortcut-btn' | translate
                            }}<i class="fa fa-arrow-circle-down" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</ngb-carousel>
<ng-template #modal let-modal class="home-modal">
    <div class="modal-header">
        <div class="row">
            <div class="col-md-1 close-modal-header">
                <button type="button" class="close" (click)="close(modal)" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
    </div>

    <div class="modal-body">
        <form [formGroup]="loginForm" class="home-form text-center" (ngSubmit)="onSubmit(loginForm.value,modal)">
            <h5 class="form-header">{{ 'home.enter' | translate }}</h5>
            <div class="form-group">
                <input formControlName="username" type="text" (focus)="onFocusInput($event)"
                    class="form-control text-center" id="username" placeholder="{{ 'form.username' | translate }}"
                    [ngClass]="{ 'is-invalid': submitted && f.username.errors }">
                <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                    <div *ngIf="f.username.errors.required">{{ 'form.validation.emptyUsername' | translate }}
                    </div>
                </div>
            </div>
            <div class="form-group">
                <input formControlName="password" type="password" (focus)="onFocusInput($event)"
                    class="form-control text-center" id="password" placeholder="{{ 'form.password' | translate }}"
                    [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                    <div *ngIf="f.password.errors.required">{{ 'form.validation.emptyPassword' | translate }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div *ngIf="!authenticated" class="invalid-submit">{{authenticatedError}}</div>
                    <button type="submit" class="btn-nativ form-btn">{{ 'global.enter' | translate }}
                        &#10095;&#10095;</button>
                </div>
            </div>
        </form>
    </div>
</ng-template>