import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from '../../services/api/api.service';
import { Router } from "@angular/router";
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { NavbarService } from '../../services/navbar/navbar.service';


@Component({
  selector: 'app-add-site-arachim',
  templateUrl: './add-site-arachim.component.html',
  styleUrls: ['./add-site-arachim.component.scss']
})
export class AddSiteArachimComponent implements OnInit {
  addSiteForm: any;
  submitted = false;
  showChangeUser = false;
  url = "";
  passRequired = false;
  validPass = true;
  displayUserName;
  isUserLoggedIn = false;
  displayQuota = "";
  overQuota = false;
  isChecked = false;
  enableSubmit = true;
  disabledUsername = true;
  displayEmailInput = true;
  gviaproblemAlert = "עקב בעיה בגביה הנך מנוע מלהוסיף אתרים, אנא פנה להנהלת חשבונות להמשך בירור";
  gviaproblem = false;
  displayNameInput = false;
  displayProjectInput = false;
  matrixUser = false;
  userData: any;
  displayEmailDB = false;
  emailDbAlert = "לקוח יקר,<br />תגובות מערכת נתיב על בקשות האתרים נשלחות ללקוח למייל,<br />כיון שבכרטיס המשתמש שלך במערכת לא קיימת כתובת מייל לא נוכל לשלוח אליך את התשובות.<br />אם ברשותך כתובת מייל נא ציין אותה בתיבה המודגשת בצהוב<br /></td>"
  sukotAlert = 'על פי הוראת הרבנים המלווים את חברת נתיב <br /> עקב קדושת המועד <br /> המענה בימי חול המועד סוכות יהיה במתכונת חירום <br />  <div class = "bold" > ומיועד לצורכי עבודה בלבד! <br/> </div>  יש לבדוק שבקשתך עונה על ההגדרה בשלמות <br /> כדי למנוע אי נעימות. <br /><br /> לתשומת לבך: אתר נתיב עומד לרשותך עם מגוון שירותים יעילים <br /> שיכולים לתת לך מענה בקלות ובמהירות.'
  changedUser = false;
  reqDec = -1
  reqCats = ""
  reqSid = -1
  reqRef = ""
  userId = 0
  userDataAuth: any;
  reqIp = ""
  queryParams: any;
  name = ""

  constructor(private formBuilder: FormBuilder,
    private apiService: ApiService,
    private router: Router,
    public authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private nav: NavbarService) {
    this.addSiteForm = this.formBuilder.group({
      username: '',
      password: '',
      userPassword: '',
      url: '',
      comments: '',
      email: '',
      name: '',
      project: '',
      username1: '',
      url1: '',
      comments1: '',
      username2: '',
      url2: '',
      comments2: '',
      ip: '',
      siteDesc: ''
    });
    this.authenticationService.authenticated$.subscribe(value => {
      this.isUserLoggedIn = value;
      this.userDataAuth = this.authenticationService.userData;
      this.initPage(this.userDataAuth);
    });
  }

  initPage(userData) {
    if (this.isUserLoggedIn) {
      if (this.userDataAuth.lowVersion && this.userDataAuth.BlockActivity.includes("2")) {
        this.router.navigate(["/main"])
        Swal.fire("", "לקוח/ה יקר/ה \n\n במחשב זה מותקנת גירסה ישנה של נתיב, \n לא ניתן להוסיף אתרים בגירסה זו. \n נא ליצור קשר עם מוקד התמיכה לצורך שידרוג מיידי במספר 5365* או 036199199 ", 'warning')
      }
      try {
        this.apiService.getUserData(userData.username).subscribe(
          data => {
            console.log('success getUserData', data["d"])
            this.userData = data["userData"]["d"];
            const userData = this.userData
            const userType = userData.usertypeid.substring(5, 6)
            if (userType.indexOf("9") > -1 || userType.indexOf("A") > -1 || userType.indexOf("C") > -1 || userType.indexOf("7") > -1 || userType.indexOf("B") > -1) {
              this.router.navigate(['/main'])
            }
            if (userData.noaddsites == "1") {
              Swal.fire("", "על פי הגדרות המשתמש, אין אפשרות להוסיף אתרים לחשבונך. לבירורים פנה לשירות הלקוחות של נתיב", 'warning')
              this.router.navigate(['/main'])
            }
            // if (["9", "A", "C", "7", "B"].includes(userData.usertypeid.substring(5, 6)) || userData.noaddsites == "1") {
            //   this.router.navigate(['/main'])
            // }
            this.displayUserName = userData.username;
            this.disabledUsername = this.displayUserName ? true : false;
            if (userData.ReceiveMailMessage == "2") {
              this.displayEmailInput = false;
            }
            if (userData.usertypeid[userData.usertypeid.length - 1] == "M") {
              this.matrixUser = true;
            }
            if (userData.isOrgServer == 1) {
              this.addSiteForm.get('email').setValidators([Validators.required, Validators.email])
            }
            if (this.matrixUser) {
              this.addSiteForm.get('name').setValidators(Validators.required)
              this.addSiteForm.get('project').setValidators(Validators.required)
              this.addSiteForm.get('email').setValidators([Validators.required, Validators.email])
            }

            // this.passRequired = userData.AddSitesCode != null && userData.AddSitesCode != "";

            if (this.passRequired) {
              this.addSiteForm.get('password').setValidators(Validators.required)
              this.addSiteForm.get('password').setErrors('invalid password')
            }
            if (userData.gviaproblem == "1") {
              this.enableSubmit = false;
              this.gviaproblem = true;
            }
            else {
              this.displayQuota = this.userData.quota < 1 ?
                "עברת את מכסת האתרים ללא תשלום לחודש זה" :
                "נותרו לך " + this.userData.quota + " אתרים להוספה ללא תשלום לחודש זה";

              if (this.userData.quota < 1) {
                if (userData.usertypeid.substring(5, 6) == "4") {
                  this.router.navigate(['/docs/change-path-doc'])
                }
                else {
                  this.displayQuota = "עברת את מכסת האתרים ללא תשלום לחודש זה";
                  this.overQuota = true;
                  this.enableSubmit = false;
                }
              }
              else {
                this.displayQuota = "נותרו לך " + this.userData.quota + " אתרים להוספה ללא תשלום לחודש זה";
              }

            }
          },
          error => {
            console.log('error getUserData', error)
          }
        )
      }
      catch (error) {
        console.log('error getUserData', error)
      }
    }
    else if (this.userId > 0) {
      this.apiService.probDocLogin(this.userId).subscribe(
        data => {
          try {
            console.log('success probDocLogin', data)
            data = data["data"]
            if (data && data["UserData"]) {
              data["UserData"].username = data["UserData"].login
              this.authenticationService.authenticate(data["UserData"]);
            }
          }
          catch (error) {
            console.log(error)
          }
        },
        error => {
          console.log('error probDocLogin', error)
        }
      )
    }
  }

  ngOnInit(): void {
    document.body.style.paddingTop = "0px"
    this.nav.hide()
    // Swal.fire("", this.sukotAlert, 'warning')
    this.buildForm();
    this.disabledUsername = this.displayUserName ? true : false;

    this.route
      .queryParams
      .subscribe(params => {
        if (params["URL"] || params["url"]) {
          const url = params["URL"] ? params["URL"] : params["url"];
          const middle = url.length / 2
          const part1 = url.substring(0, middle)
          const part2 = url.substring(middle, url.length)
          if (part1 == part2) {
            this.url = part1;
          }
          else {
            this.url = url;
          }
        }
        if (params["userid"]) {
          this.userId = params["userid"]
        }
        if (params["iip"]) {
          this.reqIp = params["iip"]
          if (this.reqIp !== "") {
            this.apiService.getLicDataByIp(this.reqIp).subscribe(
              res => {
                if (res["d"].length) {
                  const licData = res["d"][0]
                  this.name = licData.ComputerName
                }
              },
              error => {

              }
            )
          }
        }
        if (params["dec"]) {
          this.reqDec = params["dec"]
        }
        if (params["cats"]) {
          this.reqCats = params["cats"]
        }
        if (params["sid"]) {
          this.reqSid = params["sid"]
        }
        if (params["ref"]) {
          this.reqRef = params["ref"]
        }
        if (params["netsparkuserid"]) {
          this.apiService.netsparkLogin(params["netsparkuserid"]).subscribe(
            data => {
              try {
                console.log('success netsparkLogin', data)
                if (data["data"]["UserData"]) {
                  data["data"]["UserData"].username = data["data"]["UserData"].login
                  this.authenticationService.authenticate(data["data"]["UserData"]);
                  this.displayUserName = this.authenticationService.userData.username;
                }
              }
              catch (error) {
                console.log(error)
              }
            },
            error => {
              console.log('error netsparkLogin', error)
            }
          )
        }
      })

  }

  buildForm() {
    this.addSiteForm = new FormGroup({
      username: new FormControl('', Validators.required),
      url: new FormControl('', [Validators.required, Validators.pattern("^\\S*$")]),
      userPassword: new FormControl(),
      password: new FormControl(''),
      comments: new FormControl(''),
      email: new FormControl('', [Validators.email, Validators.required]),
      name: new FormControl('', [Validators.required]),
      project: new FormControl(''),
      username1: new FormControl(),
      url1: new FormControl(),
      comments1: new FormControl(),
      username2: new FormControl(),
      url2: new FormControl(),
      comments2: new FormControl(),
      ip: new FormControl(),
      siteDesc: new FormControl('')
    });
  }

  get f() {
    return this.addSiteForm.controls;
  }
  getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  onSubmit(siteData: any) {
    if (this.enableSubmit) {
      this.submitted = true;
      this.enableSubmit = false;
      if (this.changedUser) {
        this.initPage({ username: siteData.username });
        this.changedUser = false
        this.enableSubmit = true;
        return;
      }
      else if (this.addSiteForm.invalid) {
        this.enableSubmit = true;
        return;
      }
      else if (this.userData.email.replace(/,/g, '').length == 0 && !this.displayEmailDB) {
        this.enableSubmit = true;
        this.displayEmailDB = true;
        Swal.fire("", this.emailDbAlert, 'warning')
        return;
      }
      else {
        if (this.passRequired) {
          this.validPass = siteData["password"] == this.userData.AddSitesCode
        }
        if (this.validPass) {
          // siteData.comments = "הסיבה לבקשה: " + (siteData.comments ? siteData.comments.replace(/&/g, ' and ') : "")
          // siteData.siteDesc = siteData.siteDesc ? siteData.siteDesc.replace(/&/g, ' and ') : ""
          siteData.comments = "שם המבקש: " + siteData.name + "**כתובת IP: " + this.reqIp

          if (siteData.url.indexOf("stick.enativ.com") > -1) {//if (siteData.url.includes("stick.enativ.com")) {
            siteData.url = this.getParameterByName('url', siteData.url)
          }
          siteData["sites"] = [{ url: siteData.url, comments: siteData.comments }]
          if (siteData.url1 != null && siteData.url1.trim().length > 4) {
            if (siteData.url1.indexOf("stick.enativ.com") > -1) {// if (siteData.url1.includes("stick.enativ.com")) {
              siteData.url1 = this.getParameterByName('url', siteData.url1)
            }
            siteData["sites"].push({ url: siteData.url1, comments: siteData.comments1 })
          }
          if (siteData.url2 != null && siteData.url2.trim().length > 4) {
            if (siteData.url2.indexOf("stick.enativ.com") > -1) {//if (siteData.url2.includes("stick.enativ.com")) {
              siteData.url2 = this.getParameterByName('url', siteData.url2)
            }
            siteData["sites"].push({ url: siteData.url2, comments: siteData.comments2 })
          }
          siteData["email"] = siteData["email"] == null ? "" : siteData["email"];
          siteData["sites"] = JSON.stringify(siteData["sites"]);

          if (this.displayEmailDB) {
            if (siteData["email"] !== "") {
              this.apiService.updateUserEmail(siteData["email"]).subscribe(
                data => {
                  if (data["d"]) {
                    const storageData = JSON.parse(localStorage.getItem('nativUser'))
                    storageData.email = siteData["email"]
                    localStorage.setItem('nativUser', JSON.stringify(storageData));
                    this.addSite(siteData);
                  }
                },
                error => {
                  console.log('error updateUserEmail', error)
                }
              )
            }
            else {
              this.addSite(siteData);
            }
          }
          else {
            this.addSite(siteData);
          }
        }
        else {
          this.enableSubmit = true;
          this.addSiteForm.get('password').setErrors('invalid password')
          return
        }
      }
    }
  }

  changeUser() {
    this.changedUser = true

    this.addSiteForm.get('username').reset()
    this.addSiteForm.get('userPassword').reset()
    this.disabledUsername = false;
    this.showChangeUser = true;
    this.addSiteForm.get('userPassword').setValidators(Validators.required)
    this.matrixUser = false;
    this.passRequired = false;
    this.gviaproblem = false;
    this.overQuota = false;

    this.addSiteForm.get('name').clearValidators();
    this.addSiteForm.get('project').clearValidators();
    this.addSiteForm.get('email').clearValidators();
    this.addSiteForm.get('password').clearValidators();
    this.addSiteForm.get('name').setErrors(null);
    this.addSiteForm.get('project').setErrors(null);
    this.addSiteForm.get('email').setErrors(null);
    this.addSiteForm.get('password').setErrors(null);

  }
  checkValue(e) {
    if (e.target.checked) {
      this.enableSubmit = true;
    }
    else {
      this.enableSubmit = false;
    }
  }

  addSite(siteData) {
    console.log('add site has been submitted', siteData);
    try {
      const windowsUsers = this.userDataAuth.WindowsUsers ? (Object.keys(this.userDataAuth.WindowsUsers)[0] + "-" + Object.values(this.userDataAuth.WindowsUsers)[0]) : ""
      const windowsMachine = this.userDataAuth.WindowsMachine ? this.userDataAuth.WindowsMachine : ""
      const nativVersion = this.userDataAuth.NativVersion ? this.userDataAuth.NativVersion : ""
      const reqIp = this.reqIp
      const reqData = JSON.stringify({
        cats: this.reqCats, dec: this.reqDec, sid: this.reqSid, ref: this.reqRef,
        windowsMachine, windowsUsers: encodeURI(windowsUsers), nativVersion, reqIp
      })
      this.apiService.addSite(siteData, reqData).subscribe(
        data => {
          console.log('success send addSite', data)
          this.enableSubmit = true;
          if (data["d"]) {
            if (data["d"] == -9) {
              Swal.fire("", "קיימת כבר בקשה לאתר זה", 'warning')
              console.log('error addSite', data)
            }
            else if (data["d"] == -99) {
              Swal.fire("", "אירעה שגיאה בשליחת הבקשה", 'warning')
              console.log('error addSite', data)
            }
            else if (data["d"] == -98) {
              Swal.fire("", "עקב בעיה בגביה הנך מנוע מלהוסיף אתרים! אנא פנה להנהלת חשבונות להמשך בירור", 'warning')
              console.log('error addSite', data)
            }
            else {
              Swal.fire("", "הבקשה נשלחה", 'success')
              this.router.navigate(['/my-sites'])
            }
          }
          else {
            Swal.fire("", "אירעה שגיאה בשליחת הבקשה", 'error')
            console.log('error addSite', data)
          }
        },
        error => {
          this.enableSubmit = true;
          Swal.fire("", "אירעה שגיאה בשליחת הבקשה", 'error')
          console.log('error addSite', error)
        }
      )
    }
    catch (error) {
      console.log(error)
      this.enableSubmit = true;
      Swal.fire("", "אירעה שגיאה בשליחת הבקשה", 'error')
    }
  }

}
