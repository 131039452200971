import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticationGuardService } from '../app/authentication-guard.service';

import { KosherComponent } from './components/kosher/kosher.component'
import { ContactComponent } from './components/contact/contact.component'
import { AboutComponent } from './components/about/about.component'
import { MailComponent } from './components/mail/mail.component'
import { MySitesComponent } from './components/my-sites/my-sites.component'
import { MySitesExpandedComponent } from './components/my-sites-expanded/my-sites-expanded.component'
import { AddSiteComponent } from './components/add-site/add-site.component'
import { RemoveSiteComponent } from './components/remove-site/remove-site.component'
import { UserProfileComponent } from './components/user-profile/user-profile.component'
import { EnterSiteComponent } from './components/enter-site/enter-site.component';
import { VersionsComponent } from './components/versions/versions.component';
import { InstallInstructionsComponent } from './components/install-instructions/install-instructions.component'
import { NoPageFoundComponent } from './components/no-page-found/no-page-found.component';
import { UploadImageComponent } from './components/upload-image/upload-image.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { TechComponent } from './components/tech/tech.component'
import { TechLoginComponent } from './components/tech-login/tech-login.component'
import { TechExitComponent } from './components/tech-exit/tech-exit.component'
import { CopyEmailsGuideComponent } from './components/copy-emails-guide/copy-emails-guide.component';
import { HomeLoggedInComponent } from './components/home-logged-in/home-logged-in.component';
import { HomeMainComponent } from './components/home-main/home-main.component';
import { AddSiteArachimComponent } from './components/add-site-arachim/add-site-arachim.component';
import { InstallNativN4Component } from './components/install-nativ-n4/install-nativ-n4.component';

const routes: Routes = [
  { path: 'no-page-found', component: NoPageFoundComponent },
  { path: '', redirectTo: '/main', pathMatch: 'full' },
  { path: 'main', component: HomeMainComponent, canActivate: [AuthenticationGuardService] },
  { path: 'main-in', component: HomeLoggedInComponent, canActivate: [AuthenticationGuardService] },
  { path: 'main-out', component: HomeMainComponent, canActivate: [AuthenticationGuardService] },
  { path: 'main/:type', component: HomeMainComponent, canActivate: [AuthenticationGuardService] },
  { path: 'kosher', component: KosherComponent },
  { path: 'contact/:dep', component: ContactComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'about', component: AboutComponent },
  { path: 'mails', component: MailComponent, canActivate: [AuthenticationGuardService] },
  { path: 'my-sites', component: MySitesComponent, canActivate: [AuthenticationGuardService] },
  { path: 'my-sites-expanded', component: MySitesExpandedComponent, canActivate: [AuthenticationGuardService] },
  { path: 'add-sites', component: AddSiteComponent },
  { path: 'add-site-arachim', component: AddSiteArachimComponent },
  { path: 'remove-sites', component: RemoveSiteComponent },
  { path: 'user-profile', component: UserProfileComponent, canActivate: [AuthenticationGuardService] },
  { path: 'enter', component: EnterSiteComponent },
  { path: 'download/versions', component: VersionsComponent },
  { path: 'n4', component: InstallNativN4Component },
  { path: 'upload-image', component: UploadImageComponent, canActivate: [AuthenticationGuardService] },
  { path: 'feedback', component: FeedbackComponent },
  { path: 'tech', component: TechComponent },
  { path: 'tech-login', component: TechLoginComponent },
  { path: 'tech-exit', component: TechExitComponent },
  { path: 'copy-emails-guide', component: CopyEmailsGuideComponent },

  { path: 'install-guide', redirectTo: '/guide/install-guide' },
  { path: 'summer-in-nativ.jpg', redirectTo: '/adv/summer-adv' },
  { path: 'new-server-adv', redirectTo: '/adv/new-server-adv' },
  { path: 'contact_en.aspx', redirectTo: '/contact' },
  { path: 'certificates.aspx', redirectTo: '/kosher' },
  { path: 'kn_support.aspx', redirectTo: '/support' },
  { path: 'kn_support_en.aspx', redirectTo: '/support' },
  { path: 'about.aspx', redirectTo: '/about' },
  { path: 'join.aspx', redirectTo: '/contact/buy' },
  { path: 'ext_login.aspx', redirectTo: '/main/exit' },
  { path: 'mypendingsites.aspx', redirectTo: '/my-sites' },
  { path: 'removesite.aspx', redirectTo: '/remove-sites' },
  { path: 'changepath.aspx', redirectTo: '/docs/change-path-doc' },
  { path: 'main.aspx', component: HomeMainComponent, canActivate: [AuthenticationGuardService] },
  { path: 'kn_template.aspx', component: HomeMainComponent, canActivate: [AuthenticationGuardService] },
  { path: 'default.aspx', component: HomeMainComponent, canActivate: [AuthenticationGuardService] },
  { path: 'addsite.aspx', component: AddSiteComponent, canActivate: [AuthenticationGuardService] },
  { path: "AddSite.aspx", component: AddSiteComponent, canActivate: [AuthenticationGuardService] },
  { path: "Addsite.aspx", component: AddSiteComponent, canActivate: [AuthenticationGuardService] },
  { path: 'contact.aspx', component: ContactComponent, canActivate: [AuthenticationGuardService] },
  { path: 'getinfo.aspx', component: HomeMainComponent, canActivate: [AuthenticationGuardService] },
  { path: 'feedback.aspx', component: FeedbackComponent, canActivate: [AuthenticationGuardService] },

  {
    path: 'n4',
    component: HomeMainComponent,
    resolve: {
      url: 'externalUrlRedirectResolver'
    },
    data: {
      externalUrl: '//www.enativ.com/n4.html'
    }
  },
  {
    path: '1',
    component: HomeMainComponent,
    resolve: {
      url: 'externalUrlRedirectResolver'
    },
    data: {
      externalUrl: '//www.enativ.com/1'
    }
  },
  { path: 'sales', loadChildren: () => import('./modules/site-sales/site-sales.module').then(m => m.SiteSalesModule) },
  { path: 'support', loadChildren: () => import('./modules/support/support.module').then(m => m.SupportModule) },
  { path: 'docs', loadChildren: () => import('./modules/docs/docs.module').then(m => m.DocsModule) },
  { path: 'guide', loadChildren: () => import('./modules/guide/guide.module').then(m => m.GuideModule) },
  { path: 'adv', loadChildren: () => import('./modules/adv/adv.module').then(m => m.AdvModule) },
  { path: 'chat-documentation', loadChildren: () => import('./modules/chat-documentation/chat-documentation.module').then(m => m.ChatDocumentationModule) },
  { path: 'channels-admin', loadChildren: () => import('./modules/channels-admin/channels-admin.module').then(m => m.ChannelsAdminModule), canActivate: [AuthenticationGuardService] },
  { path: 'email-management', loadChildren: () => import('./modules/email-management/email-management.module').then(m => m.EmailManagementModule) },
  { path: 'scheduling', loadChildren: () => import('./modules/scheduling/scheduling.module').then(m => m.SchedulingModule) },
  { path: '**', component: NoPageFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
