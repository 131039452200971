import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ApiService } from '../../services/api/api.service';

@Component({
  selector: 'app-currencies',
  templateUrl: './currencies.component.html',
  styleUrls: ['./currencies.component.scss']
})
export class CurrenciesComponent implements OnInit {
  moreCurrencies: boolean = false;
  currencies = [{ id: 'usd', name: '1 דולר ארצות הברית' },
  { id: 'gbp', name: '1 לישט בריטניה' },
  { id: 'jpy', name: '100 יין יפן' },
  { id: 'eur', name: '1 אירו האיחוד המוניטרי האירופי' },
  { id: 'aud', name: '1 דולר אוסטרליה' },
  { id: 'cad', name: '1 דולר קנדה' },
  { id: 'dkk', name: '1 כתר דנמרק' },
  { id: 'nok', name: '1 כתר נורווגיה' },
  { id: 'zar', name: '1 רנד דרום אפריקה' },
  { id: 'sek', name: '1 כתר שוודיה' },
  { id: 'chf', name: '1 פרנק שוויץ' },
  { id: 'jod', name: '1 דינר ירדן שטרי כסף' },
  { id: 'lbp', name: '10 לירה לבנון שטרי כסף' },
  { id: 'egp', name: '1 לירה מצרים שטרי כסף' }]
  retry = 0
  constructor(private apiService: ApiService) {
    this.getData();
  }

  @ViewChild('showMoreData') showMoreDataEl: ElementRef;

  ngOnInit(): void {
  }
  showMore() {
    this.moreCurrencies = !this.moreCurrencies;
    if (this.moreCurrencies) {
      this.showMoreDataEl.nativeElement.text = "פחות פרטים"
    }
    else {
      this.showMoreDataEl.nativeElement.text = "ראה עוד"
    }
  }

  getData() {
    this.getDataApi()
  }

  getDataApi() {
    this.apiService.getCurrencies().subscribe(
      data => {
        if (data["exchangeRates"] && Object.keys(data["exchangeRates"]).length) {
          console.log('success getCurrencies', data)
          data["exchangeRates"].forEach(currency => {
            const id = currency.key.toLowerCase();
            const curCurency = this.currencies.find(record => record.id === id)
            curCurency["value"] = currency.currentExchangeRate
            curCurency["changeValue"] = currency.currentChange.toString().slice(0, 6)
          });
        }
      },
      error => {
        console.log('error getCurrencies', error);
        this.retry++;
        if (this.retry <= 2) {
          console.log('error getCurrencies - retry ' + this.retry);
          this.getData();
        }

      }
    )
  }

}
