<!-- <ng-template #modal let-modal class="modal-md"> -->
<div class="modal-header verify-code">
    <div class="row">
        <div class="col-md-1 close-modal-header">
            <button type="button" class="close" (click)="exit()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 dep-modal-header text-right">
            <div class="row">
                <div class="col-md-12 text-center">
                    <h5 class="modal-title">{{ 'verify-code.header' | translate }}</h5>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal-body verify-code text-center">
    <label class="verify-header">{{ verifyCodeHeader }}</label>
    <div *ngIf='!showCodeForm'>
        <div class="col-12 text-center phone-number-div input-div">
            <input *ngIf="phoneNumber2 == ''" [disabled]="true" type="text" class="form-control phone-number"
                placeholder="{{phoneNumber}}">
            <select *ngIf="phoneNumber2 !== ''" name="phones" class="form-control phone-numbers"
                (change)="changePhoneNumber($event.target.value)">
                <option [selected]="true" [value]="number">
                    {{phoneNumber}}
                </option>
                <option [value]="number2">
                    {{phoneNumber2}}
                </option>
            </select>
        </div>
        <div *ngIf="smsOption" class="row sms-div">
            <div class="col-12">
                <div class="text-center">{{'verify-code.options-header' | translate}}</div>
                <button (click)="chooseType('Call')" class="btn-type btn-nativ btn-blue form-btn square-btn"
                    [ngClass]="callType !== 'Call' ? 'inactive' : ''">{{'verify-code.call-option' | translate}}</button>
                <button (click)="chooseType('SMS')" class="btn-type btn-nativ btn-blue form-btn square-btn"
                    [ngClass]="callType !== 'SMS' ? 'inactive' : ''">{{'verify-code.sms-option' | translate}}</button>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <button [disabled]="!enableVerify" (click)="sendVerifyCode()"
                    class="btn-nativ form-btn square-btn submit-verify-code">{{loadingCode ?
                    ('verify-code.btn-loading' | translate) :( 'verify-code.btn-send' | translate )}}</button>
            </div>
        </div>
    </div>

    <div *ngIf='showCodeForm'>
        <form [formGroup]="verifyForm" (ngSubmit)="continue(verifyForm.value)">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <div class="input-div">
                            <input type="text" class="form-control" formControlName="phoneCode" id="phoneCode"
                                placeholder="* קוד אימות" [ngClass]="{ 'is-invalid': submitted && f.phoneCode.errors }">
                            <div *ngIf="submitted && f.phoneCode.errors" class="invalid-feedback">
                                <div>{{ 'form.validation.invalidCode' | translate }} </div>
                            </div>
                            <button [disabled]="!enableSubmit" type="submit"
                                class="btn-nativ form-btn square-btn submit-verify-code">המשך</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <button [disabled]="!enableVerify" (click)="sendVerifyCodeAgain()"
                        class="btn-nativ btn-blue form-btn square-btn submit-verify-code">שלח שוב</button>
                </div>
            </div>
        </form>
    </div>
    <div class="row">
        <div class="col-12">
            <label class="to-support">{{ 'verify-code.to-support' | translate }}</label>
        </div>
    </div>
</div>
<!-- </ng-template> -->