import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  authenticated$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  authenticatedTech$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isNativInstalled$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  userData;
  techUserData;
  logout = false;
  protocol = window.location.protocol;
  host = window.location.host;
  noNativSoftware = false;
  loggedInNouser = false;
  constructor(private router: Router,
    private httpClient: HttpClient) { }

  public authenticate(data) {
    if (data["appNativVersion"] && data["appNativVersion"] != "none") {
      const splitVersion = data["appNativVersion"].split(".")
      if (parseInt(splitVersion[0]) >= 4) {
        if (parseInt(splitVersion[1]) >= 5) {
          if (parseInt(splitVersion[2]) == 12) {
            if (parseInt(splitVersion[3]) <= 57) {
              data["lowVersion"] = "true"
            }
          }
          else if (parseInt(splitVersion[2]) < 12) {
            data["lowVersion"] = "true"
          }
        }
        else {
          data["lowVersion"] = "true"
        }
      }
      else {
        data["lowVersion"] = "true"
      }
    }

    console.log("authenticate appNativVersion:" + data["appNativVersion"])
    if (localStorage.getItem('nativUser')) {
      const sData = JSON.parse(localStorage.getItem('nativUser'))
      localStorage.setItem('nativUser', JSON.stringify({ ...sData, ...data }));
      this.userData = { ...sData, ...data };
    }
    else {
      localStorage.setItem('nativUser', JSON.stringify(data));
      this.userData = data;
    }

    this.authenticated$.next(true);
    this.logout = false;
  }

  public authenticateTech(data) {
    data["id"] = data["UserID"]
    localStorage.setItem('nativTechLoggedin', "true");
    localStorage.setItem('nativTechUser', JSON.stringify(data));
    this.techUserData = data;
    this.authenticatedTech$.next(true);
  }

  public deauthenticateTech() {
    localStorage.removeItem('nativTechUser');
    this.techUserData = null;
    this.authenticatedTech$.next(false);
  }

  public enterSite(userLoginData) {
    this.shortNativLogin().subscribe(
      loginData => {
        console.log('success shortNativLogin', loginData)
        if (loginData != null && loginData["UserName"] != "nouser" && loginData["UserName"] != "" && loginData["UserID"] != "-1") {
          userLoginData["appNativVersion"] = loginData["NativVersion"]
          console.log("enterSite-shortNativLogin NativVersion:" + loginData["NativVersion"])
          this.authenticate(userLoginData);
          this.router.navigate(['/main'])
        }
        else {
          userLoginData["appNativVersion"] = "none"
          console.log("enterSite-shortNativLogin: nouser - NativVersion: none")
          this.authenticate(userLoginData);
          this.router.navigate(['/main'])
        }

      },
      error => {
        console.log("enterSite-shortNativLogin: error", error)
        this.nativLogin().subscribe(
          loginData => {
            console.log('success nativLogin', loginData)
            if (loginData != null && loginData["UserName"] != "nouser" && loginData["UserID"] != "-1") {
              userLoginData["appNativVersion"] = loginData["NativVersion"]
              console.log("enterSite-nativLogin - NativVersion:" + loginData["NativVersion"])
              this.authenticate(userLoginData);
              this.router.navigate(['/main'])
            }
            else {
              userLoginData["appNativVersion"] = "none"
              console.log("enterSite-nativLogin: nouser - NativVersion: none")
              this.authenticate(userLoginData);
              this.router.navigate(['/main'])
            }

          },
          error => {
            console.log('error nativLogin', error)
            userLoginData["appNativVersion"] = "none"
            console.log("enterSite-nativLogin: error - NativVersion: none")
            this.authenticate(userLoginData);
            this.router.navigate(['/main'])
          }
        )
      }
    )
  }

  loginStickUser(userLoginData) {
    this.isStickUser(userLoginData).subscribe(
      data => {
        if (data["res"]) {
          this.login({ UserName: userLoginData.login, UserID: userLoginData.id }).subscribe(
            data => {
              // data = data["data"]
              data["UserData"].username = userLoginData["login"]
              data["UserData"].isStickUser = true;
              this.isNativInstalled$.next(true);
              this.authenticate(data["UserData"]);
            },
            error => {
              this.isNativInstalled$.next(false);
              this.deauthenticate(false)
            }
          )
        }
        else {
          this.isNativInstalled$.next(false);
          this.deauthenticate(false)
        }
      },
      error => {
        this.isNativInstalled$.next(false);
        this.deauthenticate(false)
      }
    )
  }

  goToNativLoginPage() {
    window.location.href = `${this.protocol}//bo.enativ.com/login`
  }

  isStickUser(data) {
    return this.httpClient.get(`api/is_stick_user`,
      {
        headers: { 'Content-Type': 'application/json' },
        params:
          { userId: data.id }
      })
  }

  public deauthenticate(logoutNativ) {
    localStorage.removeItem('managerPassword');
    localStorage.removeItem('nativUser');
    this.userData = null;
    this.authenticated$.next(false);
    if (logoutNativ) {
      this.logoutNativ().subscribe(
        loginData => {
          console.log('success logoutNativ', loginData)
        },
        error => {
          console.log('error logoutNativ', error)
        }
      );
    }
  }

  public isLoggedIn() {
    this.nativLogin().subscribe(
      loginData => {
        console.log('success nativLogin', loginData)
        if (loginData != null && loginData["UserName"] != "nouser" && loginData["UserID"] != "-1") {
          const data = { userData: {} }
          data["UserData"] = loginData
          data["UserData"].username = loginData["UserName"]
          data["UserData"].appNativVersion = loginData["NativVersion"]
          console.log("isLoggedIn-nativLogin NativVersion:" + loginData["NativVersion"])
          this.authenticate(data["UserData"]);
        }
        else {
          console.log("isLoggedIn-nativLogin nouser")
          this.loggedInNouser = true
          this.getLoginData();
        }
      },
      error => {
        console.log('error nativLogin', error)
        this.shortNativLogin().subscribe(
          loginData => {
            console.log('success shortNativLogin', loginData)
            if (loginData != null && loginData["UserName"] != "nouser" && loginData["UserID"] != "-1") {
              const data = { userData: {} }
              data["UserData"] = loginData
              data["UserData"].username = loginData["UserName"]
              data["UserData"].appNativVersion = loginData["NativVersion"]
              console.log("isLoggedIn-shortNativLogin NativVersion:" + loginData["NativVersion"])
              this.authenticate(data["UserData"]);
            }
            else {
              console.log("isLoggedIn-shortNativLogin nouser")//
              this.loggedInNouser = true
              this.getLoginData();
            }
          },
          error => {
            console.log('error shortNativLogin', JSON.stringify(error))//
            this.noNativSoftware = true
            this.getLoginData();
          }
        )
      }
    )
  }

  public isChangedLoggedIn() {
    this.nativLogin().subscribe(
      loginData => {
        console.log('success nativLogin', loginData)
        if (this.userData.username !== loginData["UserName"]) {
          console.log("isChangedLoggedIn-nativLogin - diff username:" + loginData["UserName"])
          if (loginData != null && loginData["UserName"] != "nouser" && loginData["UserID"] != "-1") {
            const data = { userData: {} }
            data["UserData"] = loginData
            data["UserData"].username = loginData["UserName"]
            data["UserData"].appNativVersion = loginData["NativVersion"]
            console.log("isChangedLoggedIn-nativLogin NativVersion:" + loginData["NativVersion"])
            this.authenticate(data["UserData"]);
          }
          else {
            console.log("isChangedLoggedIn-nativLogin nouser")
            this.getLoginData();
          }
        }
      },
      error => {
        console.log('error nativLogin', error)
        this.shortNativLogin().subscribe(
          loginData => {
            console.log('success shortNativLogin', loginData)
            if (this.userData.username !== loginData["UserName"]) {
              console.log("isChangedLoggedIn-shortNativLogin - diff username:" + loginData["UserName"])
              if (loginData != null && loginData["UserName"] != "nouser" && loginData["UserID"] != "-1") {
                const data = { userData: {} }
                data["UserData"] = loginData
                data["UserData"].username = loginData["UserName"]
                data["UserData"].appNativVersion = loginData["NativVersion"]
                console.log("isChangedLoggedIn-shortNativLogin NativVersion:" + loginData["NativVersion"])
                this.authenticate(data["UserData"]);
              }
              else {
                console.log("isChangedLoggedIn-shortNativLogin nouser")
                this.getLoginData();
              }
            }
          },
          error => {
            console.log('error shortNativLogin', JSON.stringify(error))
            // this.getLoginData();
          }
        )
      }
    )
  }

  isStickUserLoggedin() {
    if (localStorage.getItem('nativUser')) {
      const userData = JSON.parse(localStorage.getItem('nativUser'))
      if (userData.isStickUser) {
        this.loginStickUser(userData);

        // this.userData = userData;
        // this.authenticate(this.userData);
      }
      else this.deauthenticate(false);
    }
  }

  getLoginData() {
    if (localStorage.getItem('nativUser')) {
      this.userData = JSON.parse(localStorage.getItem('nativUser'))
      this.userData["appNativVersion"] = "none"
      console.log("getLoginData: NativVersion: none")
      this.authenticate(this.userData);
      return true
    }
    else {
      this.deauthenticate(false);
      return false
    }
  }
  getSessionData() {
    if (localStorage.getItem('nativUser')) {
      this.userData = JSON.parse(localStorage.getItem('nativUser'))
      this.authenticate(this.userData);
      return true
    }
    else {
      this.deauthenticate(false);
      return false
    }
  }
  nativLogin() {
    return this.httpClient.get(`${this.protocol}//${this.host}/filterstatus`,
      { headers: { 'Content-Type': 'application/json' } })
  }
  shortNativLogin() {
    return this.httpClient.get(`${this.protocol}//${this.host}/shortstatus`,
      { headers: { 'Content-Type': 'application/json' } })
  }
  login(data) {
    let server = "api.enativ.com"
    if (this.host.indexOf("devsite") > -1 || this.host.indexOf("localhost") > -1) {
      server = "dev-api.enativ.com"
    }
    return this.httpClient.get(`${this.protocol}//${server}/ApiSite/GetDataBylogin.aspx?login=${data.UserName ? data.UserName : data.username}&uid=${data.UserID ? data.UserID : data.id}`)
  }
  logoutNativ() {
    return this.httpClient.get(`${this.protocol}//bo.enativ.com/logout`,
      { headers: { 'Content-Type': 'application/json' } })
  }
  getUserSitesData(login) {
    return this.httpClient.get(`api/get_users_sites_data?login=${login}`,
      { headers: { 'Content-Type': 'application/json' } })
  }
}
