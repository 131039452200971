import { Component, OnInit, HostListener } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from '../../services/api/api.service';
import { AuthenticationService } from '../../services/authentication/authentication.service'
import { ChatService } from '../../services/chat/chat.service'

@Component({
  selector: 'app-home-carousel',
  templateUrl: './home-carousel.component.html',
  styleUrls: ['./home-carousel.component.scss']
})
export class HomeCarouselComponent implements OnInit {
  innerWidth = 0
  authenticated = true;
  submitted = false;
  mobile = false
  loginForm: any;
  authenticatedError = ""
  alertText = ""
  isShowServerAlert = true
  displayChatBtn = false

  constructor(private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    public authenticationService: AuthenticationService,
    public chatService: ChatService) {
    this.loginForm = this.formBuilder.group({
      username: '',
      password: ''
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 1010) {
      this.mobile = true
    }
    else {
      this.mobile = false
    }
  }

  showChat() {
    this.chatService.showChat(true)
  }

  showAlertFromServer() {
    this.apiService.getAlert().subscribe(
      data => {
        // this.alertText = "local";
        if (data != "") {
          // const splitText = data.split("<b>")[1].split("</b>")
          // const text = splitText[1]
          // const header = splitText[0]
          // this.alertHeader = header;
          // this.alertText = text;

          // miron link
          // this.mironUrl = data;
          // if (this.mironUrl !== "") {
          //   this.displayMironLink = true
          //   const usertypeid = this.authenticationService.userData.usertypeid
          //   const userType = this.utilsService.getUserType(usertypeid)
          //   if (userType.IS_USER_GUR) {
          //     this.displayMironLink = false
          //   }
          // }


          this.alertText = data;
          if (this.alertText.includes("CHAT_BTN")) {
            this.alertText = this.alertText.replace("CHAT_BTN", '')
            this.displayChatBtn = true
          }

          const alertContentStorage = localStorage.getItem('server-alert-content')
          if (alertContentStorage !== this.alertText) {
            this.isShowServerAlert = true;
          }
          else if (localStorage.getItem('server-alert')) {
            const showAlertStorage = parseInt(localStorage.getItem('server-alert'))
            this.isShowServerAlert = showAlertStorage < 3
          }
          else {
            this.isShowServerAlert = true;
          }
        }

        else {
          this.isShowServerAlert = false;
        }
      },
      error => {
        console.log('error showAlertFromServer', error)
        return false;
      }
    )
  }

  closeServerAlert() {
    this.isShowServerAlert = false;
    const alertContentStorage = localStorage.getItem('server-alert-content')
    if (alertContentStorage !== this.alertText) {
      localStorage.setItem('server-alert', "1");
      localStorage.setItem('server-alert-content', this.alertText);
    }
    else if (localStorage.getItem('server-alert')) {
      let showAlertStorage = parseInt(localStorage.getItem('server-alert'))
      localStorage.setItem('server-alert', (showAlertStorage += 1).toString());
    }
    else {
      localStorage.setItem('server-alert', "1");
      localStorage.setItem('server-alert-content', this.alertText);
    }
  }

  ngOnInit(): void {
    this.onResize(event)
    this.showAlertFromServer()
    this.loginForm = new FormGroup({
      username: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required)
    });
  }

  get f() {
    return this.loginForm.controls;
  }

  onFocusInput(event) {
    this.authenticated = true;
  }

  onSubmit(loginData: any, modal) {
    this.submitted = true;
    console.warn('login has been submitted', loginData);
    if (this.loginForm.invalid) {
      return;
    }
    this.apiService.login(loginData).subscribe(
      data => {
        console.log('success login', data)
        data["data"]["UserData"].username = loginData.username
        this.authenticationService.enterSite(data["data"]["UserData"]);//enterSite instead authenticate
        this.close(modal);
      },
      error => {
        this.authenticated = false;
        if (error.error && error.error.text && error.error.text === "User does not exists!") {
          this.authenticatedError = 'שם משתמש שגוי';
        }
        else {
          this.authenticatedError = 'שם משתמש או סיסמא שגויים';
        }
        console.log('error login', error)
      }
    )
  }

  openModal(targetModal: any) {
    const modal = this.modalService.open(targetModal, {
      centered: true,
      backdrop: true,
      size: 'lg'
    });
    modal.result.then(() => { this.submitted = false; this.loginForm.reset(); }, () => { this.submitted = false; this.loginForm.reset(); })
  }

  close(modal) {
    this.submitted = false;
    modal.dismiss()
  }

}
