<div id="zmanim-div" class="text-center app-div">
    <div class="zmanim-content text-right">
        <div class="zmanim-part">
            <div class="zman">{{zmanim.date}} {{zmanim.desc}}</div>
            <div class="zman">זריחה: {{zmanim.sunrise}}</div>
            <div class="zman">סוף זמן ק. שמע (מג"א): {{zmanim.sofZmanShmaMGA}}</div>
        </div>
        <div class="zmanim-part">
            <div class="zman">סוף זמן ק. שמע (גר"א): {{zmanim.sofZmanShmaGRA}}</div>
            <div class="zman">סוף זמן תפילה (מג"א): {{zmanim.sofZmanTfilaMGA}}</div>
            <div class="zman">סוף זמן תפילה (גר"א): {{zmanim.sofZmanTfilaGRA}}</div>

        </div>
        <div class="zmanim-part">
            <div class="zman">חצות היום: {{zmanim.chatzos}}</div>
            <div class="zman">מנחה גדולה: {{zmanim.minchaGedola}}</div>
            <div class="zman">מנחה קטנה: {{zmanim.minchaKetana}}</div>
        </div>
        <div class="zmanim-part">
            <div class="zman">פלג המנחה: {{zmanim.plagHamincha}}</div>
            <div class="zman">שקיעה: {{zmanim.sunset}}</div>
            <div class="zman">צאת הכוכבים: {{zmanim.tzais}}</div>
        </div>
        <div class="zmanim-part">
            <div class="zman">פרשת השבוע: {{zmanim.parasha}}</div>
            <div class="zman">הדלקת נרות שבת: {{zmanim.nerotShabbat}}</div>
            <div class="zman">צאת שבת: {{zmanim.tzaitShabbat}}</div>
        </div>
        <div class="zmanim-part last">
            <div class="zman source"> הזמנים לפי אופק:
                <select name="places"
                    (change)="getZmanimApi($event.target.value.split(',')[0],$event.target.value.split(',')[1],$event.target.value.split(',')[2],$event.target.options[$event.target.options.selectedIndex].text)">
                    <option *ngFor="let p of places" [value]="p.value" [selected]="p.name==place ? true : null">
                        {{p.name}}
                    </option>
                </select>
            </div>
            <div class="zman source"> עפ"י "חזון שמים"</div>

        </div>
    </div>
</div>